import axios from "axios";

export default {
    actions: {
        fetchCustomers(ctx) {
            return axios
                .get("customer-list/group/")
                .then(response => {
                    // adding prop isEditable for each object

                    const customers = response.data
                        .map(program => ({isEditable: false, isReplace: false, ctaButtonsBlock: false, statusSaving: false, statusReplacing: false, ...program}))
                        .reverse();

                    ctx.commit("updateCustomers", customers)


                }).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        },
        async fetchCustomersApp(ctx) {
            await axios
                .get("customer-list/group/")
                .then(response => {
                    const customers = response.data;
                    ctx.commit("updateCustomersApp", customers)
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        },
        editDomain(ctx, domain) {
            ctx.commit('editDomain', domain)
        },
        async createCustomer(ctx, {newCust, currentMonth, currentYear, customerSite, customerName}) {
            await axios
                .put("customer-month-links-update/", {
                    data: {
                        customer_id: newCust.id,
                        links_number: parseInt(newCust.month_links_number),
                        month: currentMonth,
                        year: currentYear,
                    }
                }).then(() => {

                    const newCustomer = {
                        id: newCust.id,
                        customer_group_id: newCust.customer_group,
                        isEditable: false,
                        isReplace: false,
                        is_active: true,
                        customer_site: customerSite,
                        customer_name: customerName,
                        language: newCust.language,
                        language_alt: newCust.language_alt,
                        links_number: newCust.links_number,
                        month_links_number: newCust.month_links_number,
                        month_links_to_order: newCust.month_links_number,
                        notes: newCust.notes
                    }

                    ctx.commit('createCustomer', newCustomer)
                }).catch((error) => {
                    console.log(error);
                })
        }
    },
    mutations: {
        updateLoadingStatus(state, index) {
          state.customers[index].loadingStatus = !state.customers[index].loadingStatus
        },
        updateCustomers(state, customers) {
            state.customers = customers;
        },
        updateCustomersApp(state, customers) {
            state.customersApp = customers;
        },
        createCustomer(state, newCustomer) {
            state.customers.unshift(newCustomer);
        },
        deactivateOneCustomer(state, customer) {
            state.customers.forEach(item => {
                if (item.id == customer.id) {
                    item.is_active = false
                }
            })
        },
        activateOneCustomer(state, customer) {
            state.customers.forEach(item => {
                if (item.id == customer.id) {
                    item.is_active = true
                }
            })
        },
        editCustomer(state, customer) {
            state.customers.forEach(item => {
                if (item.id == customer.id) {
                    Object.assign(item, customer)
                }
            })
        },
        deactivateGroupCustomers(state, customers) {
            let newCustomers = customers.data;
            newCustomers.forEach(newCustomer => {
                const itemIndex = state.customers.findIndex(x => x.id === newCustomer.id)
                state.customers[itemIndex].is_active = false
            })

        },
    },
    state: {
        customers: [],
        customersApp: []
    },
    getters: {
        allCustomers(state) {
            return state.customers;
        },
        allCustomersApp(state) {
            return state.customersApp;
        }
    }
}