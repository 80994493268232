<template>
    <div class="minmax-field-wrapper">
        <input type="text" placeholder="Min" v-model="minValue">
        <input type="text" placeholder="Max" v-model="maxValue">
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "MinMaxFilter",
        props: ['filter_type_min', 'filter_type_max'],
        data() {
            return {
                minValue: '',
                maxValue: ''
            }
        },

        computed: {
            ...mapState({
                returnFilters: state => state.filters.filters,
            }),
        },
        watch: {
            minValue: {
                handler(val) {
                    let keyValueMin = this.filter_type_min
                    this.$store.commit('updateFilters', {keyValueMin, val})
                    this.$store.dispatch('getData', this.returnFilters)
                },
            },
            maxValue: {
                handler(val) {
                    let keyValueMax = this.filter_type_max
                    this.$store.commit('updateFilters', {keyValueMax, val})
                    this.$store.dispatch('getData', this.returnFilters)
                }
            },
            returnFilters: {
                handler() {
                    this.minValue = '';
                    this.maxValue = '';
                }
            },
        },
        methods: {

        },
        mounted() {
            // this.$store.commit('updateFilters', this.filter_type)
        }
    }
</script>

<style scoped lang="scss">
    .minmax-field-wrapper {
        display: flex;
        justify-content: space-between;
        input {
            border: 1px solid #e5e7eb!important;
            border-radius: 4px;
            width: 48%;
            height: 30px;
        }
    }
</style>