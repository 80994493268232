<template>
    <div class="graphs-wrapper live-domains-wrapper">
        <D3LineChart :config="chart_config" :datum="chart_data" :title="'Live domains'"></D3LineChart>
    </div>
</template>

<script>
    import { D3LineChart } from 'vue-d3-charts';
    import {mapState} from "vuex";

    export default {
        name: "LiveDomains",
        components: {
            D3LineChart,
        },
        data() {
            return {
                chart_data: [],
                chart_config: {
                    values: [ 'domainsToBeLive', 'domainsLive', 'domainDown'],
                    date: {
                        key: 'date',
                        inputFormat: '%Y-%m-%d',
                        outputFormat: '%Y-%m-%d',
                    },
                    tooltip: {
                        labels: ['Domains to be live', 'Live', 'Down']
                    },
                    axis: {
                        yFormat: '.1s',
                        yTicks: 8,
                        yTitle: 'Domains',
                        xTicks: 1,
                    },
                    color: {
                        scheme: ['#198754', '#198754', '#ff0000']
                    },
                    curve: 'curveLinear',
                    points: {
                        visibleSize: 3,
                        hoverSize: 6,
                    },
                    transition: {
                        duration: 350,
                        ease: "easeLinear",
                    },
                }
            }
        },
        computed: {
            ...mapState({
                returnGraphDomainsData: state => state.graphsData.graphOnlineData
            })
        },
        watch: {
            returnGraphDomainsData: {
                handler() {
                    this.updateLiveDomainsGrapgh();
                },
                deep: true
            }
        },
        methods: {
            updateLiveDomainsGrapgh() {
                let readyDomainsLive = [];
                this.returnGraphDomainsData.domainsLive.forEach(domainLive => {
                    readyDomainsLive.push({domainsLive: domainLive})
                })
                this.returnGraphDomainsData.domainsToBeLive.forEach((value, index) => {
                    readyDomainsLive[index].domainsToBeLive = value
                })
                this.returnGraphDomainsData.domainsDown.forEach((value, index) => {
                    readyDomainsLive[index].domainDown = value
                })
                this.returnGraphDomainsData.dateGraph.forEach((date, index) => {
                    readyDomainsLive[index].date = date
                })
                this.chart_data = readyDomainsLive
            }
        },
        mounted() {
            this.updateLiveDomainsGrapgh();
        }
    }
</script>

<style scoped>
</style>