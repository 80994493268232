<template>
    <div class="table-provider-wrapper">
        <h2 class="options-main-title title-font-style">Server List</h2>
        <v-table :data="serverList"
                 :filters="filtersServer"
                 :currentPage.sync="currentPageServer"
                 :pageSize="5"
                 @totalPagesChanged="totalPagesServer = $event"
                 class="table table-responsive-md btn-table">
            <thead slot="head">
            <tr>
                <th colspan="2">

                </th>
                <th colspan="2">
                    Server name
                </th>
                <th colspan="3">
                    IP Address
                </th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr>
                <td colspan="7">
                    <input placeholder="Filter by server name..." class="form-control"
                           v-model="filtersServer.serverName.value"/>
                </td>
<!--                <td colspan="3">-->
<!--                    <input placeholder="Filter by provider" class="form-control"-->
<!--                           v-model="filtersServer.serverName.value"/>-->
<!--                </td>-->
            </tr>
            <tr class="options-add">
                <td colspan="2" class="domain-table__cell">
                    <button
                            style="width: auto; background: inherit;" class="btn btn-indigo btn-sm m-0"
                            id="tooltip-create-provider"
                            v-on:click="createServers">
<!--                        <font-awesome-icon :icon="['fas', 'plus-square']"/>-->
                        <font-awesome-icon icon="fa-regular fa-plus" class="fa-xl" />
                    </button>
                    <b-tooltip target="tooltip-create-provider" triggers="hover">
                        Add server
                    </b-tooltip>
                </td>
                <td colspan="2" class="options-table__cell"><input type="text" v-model="inputs_servers.name"
                                                       placeholder="Type server name..."></td>
<!--                <td>-->
<!--                    <input type="text" v-model="inputs_servers.login" placeholder="Type login...">-->
<!--                </td>-->
<!--                <td>-->
<!--                    <input type="text" v-model="inputs_servers.password" placeholder="Type password...">-->
<!--                </td>-->
                <td colspan="3">
                    <input type="text" v-model="inputs_servers.ip_address" placeholder="Type IP address...">
                </td>
            </tr>

            <tr class=""
                v-for="(program, indexProgram) in displayData"
                :key="indexProgram">
                <td>
                    <button
                            @click="editItemServer(indexProgram, displayData)"
                            class=""
                            style="background: inherit;"
                            :id="'tooltip-edit-provider' + indexProgram"

                    >
                        <font-awesome-icon v-if="!program.isEditable" icon="fa-light fa-edit" class="fa-lg"/>
                        <font-awesome-icon v-else icon="fa-light fa-save" class="fa-lg"/>

                    </button>
                    <b-tooltip :target="'tooltip-edit-provider' + indexProgram" triggers="hover">
                        Edit
                    </b-tooltip>
                </td>
                <td>
                    <button class="" type="button" value="Remove" style="background: inherit;"
                              :id="'tooltip-remove-provider' + indexProgram"
                              v-on:click="removeServer(program)" variant="danger">
<!--                        <font-awesome-icon :icon="['fas', 'trash']"/>-->
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />
                    </button>
                    <b-tooltip :target="'tooltip-remove-provider' + indexProgram" triggers="hover">
                        Remove
                    </b-tooltip>
                </td>
                <td v-for="(column, indexColumn) in columns_servers" :key="indexColumn" colspan="3">
                    <input
                            v-model.lazy="program[column]"
                            :readonly="!program.isEditable"
                            :class="{ 'editable': program.isEditable }"
                    >

                </td>
                <td>
                    <input v-model.lazy="program.ip_address" :readonly="!program.isEditable" :class="{ 'editable': program.isEditable }">
                </td>
            </tr>
            </tbody>
        </v-table>
        <smart-pagination
                :currentPage.sync="currentPageServer"
                :totalPages="totalPagesServer"
                :maxPageLinks="5"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import { mapState } from 'vuex';

    export default {
        name: "ServerTable",
        data() {
            return {
                // providers: [],
                currentPageServer: 1,
                totalPagesServer: 0,
                filtersServer: {
                    serverName: {value: '', keys: ['name']},
                },
                columns_servers: [
                    'name'
                ],
                inputs_servers: {
                    name: '',
                    // short_name: '',
                    login: '',
                    password: '',
                    ip_address: ''
                },
                counter: 0,
                isBlockedButtons: false
            }
        },
        computed: {
            ...mapState({
                serverList: state => state.serverList
            })
        },
        methods: {
            createServers: function () {

                if (this.inputs_servers.name === '' || this.inputs_servers.ip_address === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'Server name field is required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })

                } else {

                    axios.post('server/list', {

                            name: this.inputs_servers.name,
                            // short_name: this.inputs_servers.short_name,
                            admin: this.inputs_servers.login,
                            password: this.inputs_servers.password,
                            ip_address: this.inputs_servers.ip_address

                    }).then(res => {

                        const newServer = res.data
                        newServer.isEditable = false
                        this.$store.commit('createServer', newServer)
                        this.inputs_servers.name = this.inputs_servers.short_name = this.inputs_servers.ip_address = '';
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Server successfully added!',
                            timer: 2000,
                            showConfirmButton: false,
                        })
                    })
                }
            },
            removeServer: function (program) {
                let currentElArr = this.serverList.indexOf(program);

                axios
                    .delete('server/list/' + program.id)
                    .then(() => {
                            this.$store.commit('removeServer', currentElArr)

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Removed',
                                text: 'Server successfully removed!',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                        }
                    )
                    .catch(error => {
                        console.log(error)
                    });
            },
            editItemServer(index, displayData) {
                if (!displayData[index].isEditable) {

                    if (this.isBlockedButtons && !displayData[index].isEditable) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Stop",
                            text: "Save previous field before editing next fields",
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                        return false;
                    }

                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = true;

                } else {
                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = false;
                    axios
                        .put('server/list/' + displayData[index].id, {

                                name: displayData[index].name,
                                ip_address: displayData[index].ip_address,
                                // short_name: displayData[index].short_name,
                                // admin: displayData[index].admin,
                                // password: displayData[index].password

                        })
                        .then(
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Changed',
                                text: 'Server successfully changed!',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                        )
                        .catch(error => {
                            console.log(error)
                        });
                }
            }
        },
        created() {}
    }
</script>

<style scoped>

</style>