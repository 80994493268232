<template>
    <!-- Customer Table Ends -->
    <div class="container-fluid body-padding-top">
<!--        <template v-if="loading">-->
<!--&lt;!&ndash;            <div class="loader">Loading...</div>&ndash;&gt;-->
<!--            <div id="preloader">-->
<!--                <div id="loader_new"></div>-->
<!--            </div>-->
<!--        </template>-->
<!--        <template v-else>-->
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="table-title">Order Commercial Content</h1>
                </div>
            </div>

            <div class="domains" style="padding-top: 25px;">
                <div class="container-fluid">
                    <div class="row" v-show="!customerListHide">
                        <div class="col-sm-12 col-md-6" style="text-align: left; ">
                            <div style="display: flex; justify-content: flex-start; margin-left: -13px; margin-bottom: 10px;" class="month-and-year-wrapper">
                                <div>
                                    <select
                                            name="months"
                                            id="months"
                                            v-model="monthToday"
                                            @change="updateCustomersByMonth($event)"
                                            :disabled="this.orderListNumber > 0"
                                    >
                                        <!--<option selected>Auto</option>-->
                                        <option
                                                v-for="month in months"
                                                v-bind:key="month.id"
                                                :value="month.toLowerCase().substring(0, 3)"
                                        >
                                            {{ month }}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <select
                                            name="years"
                                            id="years"
                                            v-model="yearToday"
                                            @change="updateCustomersByYear($event)"
                                            :disabled="this.orderListNumber > 0"

                                    >
                                        <!--<option selected>Auto</option>-->
                                        <option
                                                v-for="year in yearsArray"
                                                v-bind:key="year.id"
                                                :value="year"
                                        >
                                            {{ year }}
                                        </option>
                                    </select>
                                </div>
                                <div style="margin-left: 10px; visibility: hidden;">
                                    <label for="alternative_matching" style="white-space: nowrap;">Alternative Matching: &nbsp;</label>
                                    <input id="alternative_matching" type="checkbox" placeholder="Alternative Matching" v-model="alternativeMatching" style="width: 16px; height: 16px;">
                                    <font-awesome-icon class="checkmark-stop" icon="fa-light fa-stop" />
                                    <font-awesome-icon class="checkmark-checked" icon="fa-light fa-square-check" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row" v-show="!customerListHide" style="    justify-content: space-between;">
                        <div class="col-sm-12 col-md-6 " style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); background: #fff;">
                            <div class="customer-list" id="customerList" v-if="returnCustomers">
                                <template v-if="returnGlobalLoader || loading">
                                    <div id="preloader">
                                        <div id="loader_new"></div>
                                    </div>
                                </template>
                                    <template v-else-if="returnCustomers.length === 0">
                                        <div>
                                            <h5>All orders are done for current month</h5>
                                        </div>
                                    </template>
                                    <v-table
                                            :data="returnCustomers"
                                            :currentPage.sync="currentPageCustomers"
                                            :pageSize="100"
                                            @totalPagesChanged="totalPagesCustomers = $event"
                                            class="table table-responsive-md btn-table"
                                    >
                                        <thead slot="head">
                                        <tr class="md-table-row">
                                            <th rowspan="2" style="vertical-align: middle">
                                                <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                                   style="cursor: pointer;"
                                                                   :class="{ green: checkIfInFilters('customer_group__name__icontains', 'ordering', 'customer_group__name')  }"
                                                                   @click="showClosePopover('customer_name')"/>&nbsp;Customer
                                                Name
                                                <div class="filters-popover filters-popover-ordercompage" id="customer_name">
                                                    <Sorting filter_type="ordering" filter_value="customer_group__name"/>
                                                    <StringFieldFilter filter_type="customer_group__name__icontains"/>
                                                </div>
                                            </th>
                                            <th rowspan="2" style="vertical-align: middle">
                                                <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                                   :class="{ green: checkIfInFilters('name__icontains', 'ordering', 'name')  }"
                                                                   style="cursor: pointer;"
                                                                   @click="showClosePopover('customer_site')"/>&nbsp;Customer
                                                Site
                                                <div class="filters-popover filters-popover-ordercompage" id="customer_site">
                                                    <Sorting filter_type="ordering" filter_value="name"/>
                                                    <StringFieldFilter filter_type="name__icontains"/>
                                                </div>
                                            </th>
                                            <th rowspan="2" style="vertical-align: middle">
                                                <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                                   :class="{ green: checkIfInFilters('language__icontains', 'ordering', 'language')  }"
                                                                   style="cursor: pointer;"
                                                                   @click="showClosePopover('language')"/>&nbsp;Geo
                                                <div class="filters-popover filters-popover-ordercompage" id="language">
                                                    <Sorting filter_type="ordering" filter_value="language"/>
                                                    <StringFieldFilter filter_type="language__icontains"/>
                                                </div>
                                            </th>
                                            <th rowspan="2" style="vertical-align: middle">Geo Alt
                                            </th>
                                            <th rowspan="2" style="vertical-align: middle">
                                                Links per <br/>
                                                Month
                                            </th>
                                            <th rowspan="2" style="vertical-align: middle">
                                                Left to <br/>
                                                order
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Keywords
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody slot="body" slot-scope="{ displayData }">
                                        <tr
                                                class=""
                                                v-for="(customer, indexProgram) in displayData"
                                                v-bind:key="indexProgram"
                                        >



                                            <td>{{ customer.customer_group.name }}</td>
                                            <template
                                                    v-if="
                                                customer.keywords && Object.keys(customer.keywords).length &&
                        customer.keywords.number_of_keywords === 0 ||
                        customer.keywords.number_of_correct_keywords === 0
                      "
                                            >
                                                <td
                                                        style="cursor: pointer"
                                                        class="customerCell"
                                                        v-on:click="showKeywordAlert()"
                                                >
                                                    {{ customer.name }}
                                                </td>
                                            </template>
                                            <template v-else-if="customer.keywords.number_of_wrong_keywords > 0">
                                                <td
                                                        style="cursor: pointer"
                                                        class="customerCell"
                                                        v-on:click="showKeywordAlertCorrupt()"
                                                >
                                                    {{ customer.name }}
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td>
                                                    <button
                                                            :id="'tooltip-ordered-customer-' + customer.id"
                                                            style="cursor: pointer; width: 100%; background: inherit; color: #0a1e61;"

                                                            class="customerCell"
                                                            v-on:click="
                            chooseCustomerID(
                              customer.id,
                              customer.name,
                              customer.language,
                              customer.counters[yearToday][
                                monthToday
                              ].links_left_to_order,
                              $event
                            )
                          "
                                                    >
                                                        {{ customer.name }}
                                                        <span
                                                                v-if="customer.ordered_by.length > 0"
                                                                class="loader-07"
                                                                style="margin-left: 5px"
                                                        ></span>
                                                    </button>
                                                    <b-tooltip
                                                            v-if="customer.ordered_by.length > 0"
                                                            :target="'tooltip-ordered-customer-' + customer.id"
                                                            triggers="hover"
                                                    >
                                                        {{ customer.ordered_by }}
                                                    </b-tooltip>
                                                </td>
                                            </template>
                                            <td>{{ customer.language }}</td>
                                            <td>{{ customer.language_alt }}</td>
                                            <td id="linksPerMonth">
                                                {{
                                                customer.counters[yearToday][
                                                monthToday
                                                ].links_per_month
                                                }}
                                            </td>
                                            <td id="leftToOrder"  >
                                                <strong style="color: red;" v-if="customer.counters[yearToday][
                                                monthToday
                                                ].links_left_to_order -
                                                countLinksInEachOrder(customer.id) > 0"> {{customer.counters[yearToday][
                                                    monthToday
                                                    ].links_left_to_order -
                                                    countLinksInEachOrder(customer.id)}}</strong>
                                                <template v-else>
                                                    {{
                                                    customer.counters[yearToday][
                                                    monthToday
                                                    ].links_left_to_order -
                                                    countLinksInEachOrder(customer.id)
                                                    }}
                                                </template>



                                            </td>
                                            <template v-if="customer.keywords.number_of_keywords === 0">
                                                <td :id="'tooltip-keyword-total' + indexProgram">
                                                    <span style="color: red;">0</span>
                                                </td>
                                                <b-tooltip :target="'tooltip-keyword-total' + indexProgram"
                                                           triggers="hover">
                                                    NO KEYWORDS
                                                </b-tooltip>
                                            </template>

                                            <template v-else>
                                                <td :id="'tooltip-keyword-total' + indexProgram">
                                                    <span v-if="customer.keywords.number_of_wrong_keywords > 0" style="color: red;">{{customer.keywords.number_of_keywords }}</span>
                                                    <span v-else>{{customer.keywords.number_of_keywords }}</span>
                                                </td>
                                                <b-tooltip :target="'tooltip-keyword-total' + indexProgram"
                                                           triggers="hover">
                                                    <ul>
                                                        <li>Correct: {{customer.keywords.number_of_correct_keywords}}</li>
                                                        <li>Corrupt: {{customer.keywords.number_of_wrong_keywords}}</li>
                                                    </ul>
                                                </b-tooltip>
                                            </template>
                                        </tr>
                                        </tbody>
                                    </v-table>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="pagination-wrapper"
                                         style="display: flex; justify-content: center; margin-bottom: 30px;">
                                        <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                                        <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6" style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); flex: 0 0 45%; background: #fff;">
                            <div class="customers-additional-table" style="text-align: left; padding: 20px; list-style: none;">
                                    <div class="row" style="gap: 20px;">
                                        <div class="col-12">
                                            <div>
                                                <LinkSpams />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <MonthDueLinksOrderPage :key="componentKey" :month="returnFilters.month" :year="returnFilters.year" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="ActiveCustomerID != null">

                        <div class="row" v-show="customerListHide" :style="(scrollpx > 70) ? 'position: fixed; left: 10px; right: 0px; width: 100%; top: 140px; background: #fff; z-index: 999999;' : ''">
                            <div class="col-sm-12 col-md-4" style="text-align: left;">
                                <button
                                        v-if="customerListHide"
                                        @click="changeCustomerListHide"
                                        style="background: inherit;"
                                >
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" class="fa-xl"/>
                                    Go back
                                </button>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div>
                                    <label for="providers" style="font-weight: bold"
                                    >Provider:</label
                                    >
                                    <select
                                            name="providers"
                                            id="providers"
                                            v-model="orderList.content_provider_id"
                                            style="line-height: 24px;height: 24px;background: #fff;max-width: 128px;width: 100%;border: 1px solid #C9C9C9;border-radius: 2px;"
                                    >
                                        <option
                                                v-for="provider in providers"
                                                :key="provider.id"
                                                :value="provider.id"
                                        >
                                            {{ provider.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4" style="text-align: right;">
                                <button
                                        id="add-to-order-button"
                                        @click="orderContent"
                                        class="main-button main-button-order"
                                        style="margin-right: 10px;"
                                        :disabled="!returnTrueIfDomainChecked"
                                >Add to order
                                </button>
                                <button
                                        v-if="alternativeMatching"
                                        id="randomize-domains-button"
                                        @click="chooseCustomerID(ActiveCustomerID, ActiveCustomerName, ActiveCustomerLanguage, ActiveCustomerLinksToOrder, ActiveEventTarget)"
                                        class="main-button"
                                        :disabled="!returnTrueIfDomainChecked"
                                >
                                    Randomize domains
                                </button>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 10px;" v-show="customerListHide" :style="(scrollpx > 70) ? 'position: fixed; left: 10px; width: 100%; top: 170px; background: #fff; z-index: 999999; box-shadow: 5px 5px 4px rgb(0,0,0,0.25);' : ''">
                            <div class="col-sm-12 col-md-5" style="text-align: left;" >
                                <div class="total-domains-selected">Selected: <span style="font-weight: bold;">{{selectedDomainsKeywords.length}}</span>
                                </div>
                                <div class="total-domains-selected" v-if="ActiveCustomerLinksToOrder > 0">Left: <span style="font-weight: bold;">{{ActiveCustomerLinksToOrder}}</span>
                                </div>
                                <div class="total-domains-selected" v-else>Left: <span style="font-weight: bold; color: red;">{{ActiveCustomerLinksToOrder}}</span>
                                </div>


                                <div class="total-counts-domains">Available domains: <span style="font-weight: bold;">{{sortedDomainsData}}</span>
                                </div>
                                <div class="active-customer-name">Customer name: <span style="font-weight: bold; margin-right: 10px;">{{ActiveCustomerName}}</span></div>
                                <div class="active-keywords">Customer profiles: <span style="font-weight: bold;">{{returnKeywordsTest()}}</span></div>
                            </div>
                        </div>
                        <div class="row" v-show="customerListHide">
                            <div class="col-sm-12 col-md-8" style="background: #fff;">
                                <template v-if="returnGlobalLoader || loading">
                                    <div id="preloader">
                                        <div id="loader_new"></div>
                                    </div>
                                </template>
                                <div class="domains-list tableFixHead" id="domains-list">
                                    <v-table
                                            :data="domainsListCustomer"
                                            :filters="filters"
                                            :currentPage.sync="currentPage"
                                            :pageSize="activeCustomerLinksFix > 50 ? activeCustomerLinksFix : 50"
                                            @totalPagesChanged="totalPages = $event"
                                            class="table table-responsive-md btn-table"
                                            ref="myDomains"
                                    >
                                        <thead slot="head">
                                        <tr class="md-table-row">
                                            <th>CH</th>
                                            <th>Name</th>
                                            <th>Ref. Domains</th>
                                            <v-th sort-key="number_of_customers"
                                            >Num. of Cust.
                                            </v-th>
<!--                                            <th>Cats.</th>-->
                                            <th>Subject</th>
                                            <th>Geo Main</th>
                                            <th>Geo alt</th>
                                            <th>Select Keyword</th>
                                            <!--<th>Keyword</th>-->
                                            <!--<th>Landing page</th>-->
                                            <!--<th>Phrase match</th>-->
                                            <v-th v-if="alternativeMatching" sort-key="matching">Matching</v-th>
                                            <v-th v-if="!alternativeMatching" sort-key="matching_max">Matching Max</v-th>
<!--                                            <v-th sort-key="matching_min">Matching Min</v-th>-->
                                            <!--<th>Keywords</th>-->
                                            <!--<th>Exact Match</th>-->
                                        </tr>
                                        </thead>
                                        <tbody slot="body" slot-scope="{ displayData }">
                                        <tr class="">
                                            <td></td>
                                            <td>
                                                <input
                                                        placeholder="Filter by name"
                                                        class="form-control"
                                                        v-model="filters.name.value"

                                                />
                                            </td>
                                            <td>
                                                <div
                                                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                                                >
                                                    <input
                                                            v-model="filters.referring_domains.value.min"
                                                            :min="0"
                                                            :max="filters.referring_domains.value.max"
                                                            style="width: 30px; border: 1px solid #ccc;background-color: #fff;"
                                                    />
                                                    <div>&laquo;&raquo;</div>
                                                    <input
                                                            v-model="filters.referring_domains.value.max"
                                                            :min="filters.referring_domains.value.min"
                                                            :max="99"
                                                            style="
                              width: 30px;
                              border: 1px solid #ccc;
                              background-color: #fff;
                            "

                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                                                >
                                                    <input
                                                            v-model="filters.number_of_customers.value.min"
                                                            :min="0"
                                                            :max="filters.number_of_customers.value.max"
                                                            style="
                              width: 30px;
                              border: 1px solid #ccc;
                              background-color: #fff;
                            "

                                                    />
                                                    <div>&laquo;&raquo;</div>
                                                    <input
                                                            v-model="filters.number_of_customers.value.max"
                                                            :min="filters.number_of_customers.value.min"
                                                            :max="99"
                                                            style="
                              width: 30px;
                              border: 1px solid #ccc;
                              background-color: #fff;
                            "

                                                    />
                                                </div>
                                            </td>
<!--                                            <td>-->
<!--                                                <input-->
<!--                                                        placeholder="Filter by cats"-->
<!--                                                        class="form-control"-->
<!--                                                        v-model="filters.cats.value"-->

<!--                                                />-->
<!--                                            </td>-->
                                            <td>
                                                <input
                                                        placeholder="Filter by subject"
                                                        class="form-control"
                                                        v-model="filters.topic.value"

                                                />
                                            </td>
                                            <td>
                                                <input
                                                        placeholder="Filter by lang"
                                                        class="form-control"
                                                        v-model="filters.language.value"

                                                />
                                            </td>
                                            <td>
                                                <input
                                                        placeholder="Filter by langAlt"
                                                        class="form-control"
                                                        v-model="filters.language_alt.value"

                                                />
                                            </td>
                                            <td></td>
                                            <!--<td></td>-->
                                            <!--<td></td>-->
                                            <!--<td></td>-->
                                            <td></td>

                                            <td v-if="alternativeMatching"></td >
                                            <td v-if="!alternativeMatching"></td>

                                        </tr>
                                        <v-tr
                                                class=""
                                                v-for="(program, indexProgram) in displayData"
                                                :key="indexProgram" :row="program"
                                        >
                                            <template
                                                    v-if="
                          program.keyword_info.keyword !==
                          'No valid Keyword found'
                        "
                                            >
                                                <td style="position:relative;">
                                                    <input
                                                            v-on:click="isCheck(indexProgram, displayData)"
                                                            style="
                              width: 100%;
                              display: block;
                              height: 20px;
                              cursor: pointer;
                            "
                                                            v-model="program.isChecked"
                                                            class="customer-domain-checkbox"
                                                            type="checkbox"
                                                            value=""
                                                    />
                                                    <font-awesome-icon class="checkmark-stop" icon="fa-light fa-stop" />
                                                    <font-awesome-icon class="checkmark-checked" icon="fa-light fa-square-check" />
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td style="position: relative;">
                                                    <input
                                                            v-on:click="isCheck(indexProgram, displayData)"
                                                            style="
                              width: 100%;
                              display: block;
                              height: 20px;
                              cursor: pointer;
                            "
                                                            v-model="program.isChecked"
                                                            class="customer-domain-checkbox"
                                                            type="checkbox"
                                                            value=""
                                                            disabled
                                                    />
                                                    <font-awesome-icon class="checkmark-stop" icon="fa-light fa-stop" />
                                                    <font-awesome-icon class="checkmark-checked" icon="fa-light fa-square-check" />
                                                </td>
                                            </template>
                                            <td :id="'tooltip-is-down-' + program.id">
                                                {{program.name}}
                                                <p
                                                        class="loader-07"
                                                        v-if="program.is_down"
                                                        style="font-style: italic;
    font-size: 10px;
    display: flex;
    padding-left: 15px;
    align-items: center;
    margin-bottom: 2px;"
                                                >This domain is down</p>
                                                <p
                                                        class="loader-07"
                                                        v-if="program.order_times >= 3"
                                                        style="font-style: italic;
    font-size: 10px;
    display: flex;
    padding-left: 15px;
    align-items: center;
    margin-bottom: 2px;"
                                                >Ordered more than {{program.order_times}} times</p>
                                            </td>
                                            <td>{{ program.referring_domains }}</td>
                                            <td>{{ program.number_of_customers }}</td>
<!--                                            <td>-->
<!--                                                <p-->
<!--                                                        v-for="cat in program.categories"-->
<!--                                                        v-bind:key="cat.id"-->
<!--                                                        style="line-height: 1"-->
<!--                                                >-->
<!--                                                    {{ cat[0].toUpperCase() + cat.substr(1) }}-->
<!--                                                </p>-->
<!--                                            </td>-->
                                            <td>{{ program.topic }}</td>
                                            <td>{{ program.language }}</td>
                                            <td>{{ program.language_alt }}</td>
                                            <td>
                                                <select
                                                        style="
                            font-size: 14px;
                            padding: 2px 2px;
                            border: 1px solid #26303e;
                          "

                                                        name="keywords"
                                                        id="keywords"
                                                        v-model="program.keyword_info"
                                                        @change="calculateActualLinkFr"
                                                >
                                                    <option
                                                            v-for="item in domainsNotes['0'].keywords.keywords"
                                                            v-bind:key="item.id"
                                                            :value="{
                                                          keyword: item.keyword,
                                                          landing_page: item.landing_page,
                                                          negative_keywords: item.negative_keywords,
                                                          phrase_match: item.phrase_match
                                                        }"
                                                    >
                                                        <!--                                                        <template v-if="item.link_frequency_percentage !== '0%'">-->
                                                        <!--                                                            {{ item.keyword + "◆" + item.landing_page + "◆" +-->
                                                        <!--                                                            item.link_frequency_percentage}}-->
                                                        <!--                                                        </template>-->
                                                        <template v-if="item.link_frequency_percentage !== '0%'">
                                                            {{ item.keyword }}
                                                        </template>
                                                        <template v-else>
                                                            {{'That keyword link frequency 0 %'}}
                                                        </template>


                                                    </option>
                                                </select>
                                            </td>

                                            <!--<td><input type="text" v-model="program.keyword_info.keyword" disabled></td>-->
                                            <!--<td><input type="text" v-model="program.keyword_info.landing_page" style="width: 220px;" disabled></td>-->
                                            <!--<td><input type="text" v-model="program.keyword_info.phrase_match" disabled></td>-->
                                            <td v-if="alternativeMatching">{{ program.matching }}</td>
                                            <!--                                            <td>{{ program.matching_max.toFixed(3)*100 }}</td>-->
                                            <td v-if="!alternativeMatching">{{ program.matching_max }}</td>
                                            <!--                                            <td>{{ program.matching_min.toFixed(3)*100 }}</td>-->
<!--                                            <td>{{ program.matching_min }}</td>-->
                                        </v-tr>
                                        </tbody>
                                    </v-table>
                                    <smart-pagination
                                            class="domain-list-pagination-none"
                                            :currentPage.sync="currentPage"
                                            :totalPages="totalPages"
                                            :maxPageLinks="5"
                                    />
                                </div>
                            </div>

                            <div class="col-sm-12  col-md-4" :style="(scrollpx > 70) ? 'position: fixed; right: 2px; top: 200px;' : ''" style="background: #fff;">
<!--                            <div class="col-sm-12 col-md-5" v-else-if="scrollpx < 100">-->


                                <div v-if="domainsNotes && domainsNotes[0] && domainsNotes[0].keywords && Object.keys(domainsNotes[0].keywords.keywords).length" class="preorder-customer-view tableFixHeadKeywords" id="keywordsList"
                                     style="">
                                    <v-table
                                            :data="domainsNotes[0].keywords.keywords"
                                            :currentPage.sync="currentPageKeywords"
                                            :pageSize="-1"
                                            @totalPagesChanged="totalPagesKeywords = $event"
                                            class="table table-responsive-md btn-table"
                                    >
                                        <thead slot="head">
                                        <tr class="md-table-row">
                                            <th>Keyword</th>
<!--                                            <th>Landing Page</th>-->
                                            <th>Set Link<br> frequency</th>
                                            <th>Actual Link<br> frequency</th>
                                            <th>Accumulated<br> Link frequency</th>
                                            <th>Phrase<br> match</th>
                                            <th style="padding: 0 100px;"
                                                :class="keywordList.notes ? 'hideColumn' : ''">Notes
                                            </th>
                                            <th>Negative<br> Keywords</th>
                                        </tr>
                                        </thead>
                                        <tbody slot="body" slot-scope="{ displayData }">
                                        <tr
                                                class=""
                                                v-for="(program, indexProgram) in displayData"
                                                :key="indexProgram"
                                        >
                                            <template
                                                    v-if="program.keyword && program.link_frequency_percentage != '0%'">
                                                <td>{{ program.keyword }}</td>
                                                <b-tooltip :target="'tooltip-landing-page-' + indexProgram"
                                                           triggers="hover">
                                                    {{program.landing_page}}
                                                </b-tooltip>
                                                <td>{{ program.link_frequency_percentage }}</td>
                                                <td>
                                                    {{
                                                    program.matchCounter
                                                    ? (
                                                    (program.matchCounter / howMuchDomainsChecked) *
                                                    100
                                                    ).toFixed(2) + "%"
                                                    : 0 + "%"
                                                    }}
                                                </td>
                                                <td class="accumulated-link-fr">{{ isNaN((((program.matchCounter + program.accumulataed_percentage) / (domainsListCustomerKeywords[0].number_of_orders + howMuchDomainsChecked)) * 100).toFixed(2)) ? '0%' : (((program.matchCounter + program.accumulataed_percentage) / (domainsListCustomerKeywords[0].number_of_orders + howMuchDomainsChecked)) * 100).toFixed(2) + "%" }}
                                                </td>

                                                <td>{{ program.phrase_match }}</td>
                                                <td :class="keywordList.notes ? 'hideColumn' : ''">{{ program.notes }}
                                                </td>
                                                <td>{{program.negative_keywords}}</td>
                                            </template>

                                        </tr>
                                        </tbody>
                                    </v-table>
                                </div>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
<!--        </template>-->
    </div>
</template>

<script>
    import axios from "axios";
    import JQuery from "jquery";
    // import moment from "moment";
    import _ from "lodash";
    import {mapState} from "vuex";
    import StringFieldFilter from "../components/filters/StringFieldFilter";
    import Sorting from "../components/filters/Sorting";
    // import CountryStats from "../components/CountryStats";
    // import OrdersCreatedApex from "../components/OrdersCreatedApex";
    import MonthDueLinksOrderPage from "../components/MonthDueLinksOrderPage";
    import LinkSpams from "../components/LinkSpams";
    import {eventBus} from "../main";

    window.$ = JQuery;

    export default {
        name: "OrderContentCom",

        components: {StringFieldFilter, MonthDueLinksOrderPage, Sorting, LinkSpams},
        data() {
            return {
                renderComponent: true,
                prevFilterName: '',
                scrollpx: 0,
                alternativeMatching: false,
                customerList: {
                    keywords: false
                },
                keywordList: {
                    notes: false
                },
                message: {message: {}, action: {}},
                columns: ["name", "subject", "language", "language_alt", "status"],
                columnsCustomers: [
                    "customer_name",
                    "customer_site",
                    "language",
                    "language_alt",
                ],
                // paginate: ["resultQuery"],
                customers: [],
                customersLeftToOrder: [],
                customerListHide: false,
                domains: [],
                topics: [],
                language: [],
                language_alt: [],
                providers: [],
                numberZeroToHundred: 30,
                domainName: "",
                counter: 0,
                selectedCategory: "",
                sortList: {
                    language: "",
                    subject: "",
                },
                loading: false,
                loadingFirst: false,
                ActiveCustomerID: null,
                ActiveCustomerName: "",
                ActiveCustomerLanguage: "",
                ActiveCustomerLinksToOrder: null,
                ActiveEventTarget: null,
                contentCustomerIdArray: [],
                domainsListCustomer: [],
                domainsListCustomerKeywords: [],
                checkoutList: {},
                orderBasket: {},
                keywords: [],
                domainsNotes: {},
                filters: {
                    number_of_customers: {
                        value: {min: 0, max: 25},
                        custom: this.numOfCustFilter,
                    },
                    referring_domains: {
                        value: {min: 5, max: 1000},
                        custom: this.numOfRefDomains,
                    },
                    // matching: {value: {min: 0, max: 10}, custom: this.numOfMatching},
                    // matching_max: {value: {min: 0, max: 10}, custom: this.numOfMatching},
                    // matching_min: {value: {min: 0, max: 10}, custom: this.numOfMatching},

                    name: {value: "", keys: ["name"]},
                    cats: {value: "", keys: ["categories"]},
                    topic: {value: "", keys: ["topic"]},
                    language: {value: "", keys: ["language"]},
                    language_alt: {value: "", keys: ["language_alt"]},
                },
                filtersCustomers: {
                    customerName: {value: "", keys: ["customer_name"]},
                    customerSite: {value: "", keys: ["customer_site"]},
                    language: {
                        value: localStorage.getItem("currentLanguage") || "",
                        keys: ["language"],
                    },
                    language_alt: {value: "", keys: ["language_alt"]},
                },
                currentPage: 1,
                currentPageKeywords: 1,
                currentPageCustomers: 1,
                totalPages: 0,
                totalPagesKeywords: 0,
                totalPagesCustomers: 0,
                finalCustomersLeft: [],
                finalCustomersLeftStorage: [],
                months: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                calendarMonthName: this.returnCurrentMonthName(),
                yearsArray: [],
                valArr: [],
                selectedDomainsKeywords: [],
                totalCountOfDomains: 0,
                orderContentCounter: 1,
                sortedDomainsData: 0,
                activeCustomerLinksFix: 0,
                monthToday: this.returnCurrentMonthName().toLowerCase().substring(0, 3),
                yearToday: new Date().getFullYear().toString(),
                invalidKeywords: [],
                componentKey: 0, // Unique key to force re-render
            };
        },
        computed: {
            ...mapState({
                // returnCustomers: state => state.orderCommercialContent.customers,
                loadingStatus: state => state.orderCommercialContent.loading,
                orderList: state => state.orderBasket.globalOrderList,
                orderListNumber: state => state.orderBasket.orderListNumber,
                returnCustomers: state => state.filters.mainData,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                returnCountItems: state => state.filters.countItems,
                returnGlobalLoader: state => state.filters.globalLoader,
                // cleanGeoInputOrderComContent: state => state.cleanGeoInputOrderComContent,
            }),
            returnTrueIfDomainChecked() {
                return this.howMuchDomainsChecked ? true : false;
            },

            // activeCustomerLinksFix() {
            //     let linksLeft = 0;
            //     this.returnCustomers.forEach((item) => {
            //         if (item.id === this.ActiveCustomerID) {
            //             linksLeft =
            //                 item.counters[this.changeLinksToCurrentYear][
            //                     this.changeLinksToCurrentMonth
            //                     ].links_left_to_order;
            //         }
            //     });
            //     return linksLeft;
            // },
            //Count how much domains checked
            howMuchDomainsChecked() {
                let countChecked = 0;
                this.domainsListCustomer.forEach((item) => {
                    if (item.isChecked) {
                        countChecked = countChecked + 1;
                    }
                });
                return countChecked;
            },

            changeLinksToCurrentMonthFullname() {
                let currentMonth = this.orderList.month_of_order;
                return currentMonth;
            },
            changeLinksToPrevMonthFullname() {
                let listOfMonths = this.months;
                let prevMonthIndex =
                    listOfMonths.indexOf(this.changeLinksToCurrentMonthFullname) - 1;
                // return listOfMonths[(prevMonthIndex < 0) ? '12' : prevMonthIndex];
                return listOfMonths[prevMonthIndex] == undefined
                    ? "December"
                    : listOfMonths[prevMonthIndex];
                // return prevMonthIndex;
            },
            changeLinksToCurrentYear() {
                return this.orderList.year_of_order;
            },
            //Return current year
            returnCurrentYear() {
                return new Date().getFullYear();
            },
            //Function to get displayData from the TOP
            getDisplayData(displayData) {
                return displayData;
            },
            // orderListFull() {
            //     return Object.keys(this.orderList.customers).length === 0 ? false : true;
            // },
            currentLanguage() {
                return this.ActiveCustomerLanguage || "";
            },
            // returnSelectedDomainsCounter() {
            //     let tempSelectedDomainsArray = [];
            //     this.domainsListCustomer.forEach((item) => {
            //         if (item.isChecked) {
            //             tempSelectedDomainsArray.push(item);
            //         }
            //     });
            //     return tempSelectedDomainsArray.length;
            // }
        },
        watch: {
            domainsListCustomer: {
                handler() {
                    this.sortedDomainsData = this.$refs.myDomains?.sortedData?.length
                },
                deep: true
            },
            howMuchDomainsChecked: function (value) {
                if (value || value == 0) {
                    this.calculateActualLinkFr();
                }
            },
            totalCountOfDomains: function (value) {
                if (value < this.activeCustomerLinksFix) {
                    alert("We dont have enough domains to create order");
                }
            },
            //Save hide filters to localstorage
            customerList: {
                handler(val) {
                    localStorage.setItem('currentHideFiltersCustomersComContent', JSON.stringify(val));
                },
                deep: true
            },
            keywordList: {
                handler(val) {
                    localStorage.setItem('currentHideFiltersKeywordsComContent', JSON.stringify(val));
                },
                deep: true
            },
            'orderList.language': {
                async handler(val) {
                    if(!val) {
                        let keyNameLanguage = 'language__icontains'
                        let valueLanguage = ''

                        await this.$store.commit('updateFilters', {keyNameLanguage, valueLanguage})
                        this.$store.dispatch('getData', this.returnFilters)
                    }
                },
                // immediate: true
            },
        },
        methods: {
            closePopovers(event) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    const popoverIcon = popover.previousElementSibling;
                    if (!popover.contains(event.target) && !popoverIcon.contains(event.target)) {
                        popover.classList.remove('showPopover');
                    }
                }
            },


            showClosePopover(item) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    if (popover.id !== item) {
                        popover.classList.remove('showPopover');
                    }
                }

                const currentPopover = document.querySelector(`#${item}`);
                currentPopover.classList.toggle('showPopover');

                this.prevFilterName = this.prevFilterName === item ? '' : item;
            },
            checkIfInFilters(setValue1, setValue2, setValue3) {

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ( (key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue1) && value) ) {
                        return true;
                    }
                }
                return false;
            },
            forceRerender() {
                // Remove my-component from the DOM
                this.renderComponent = false;

                this.$nextTick(() => {
                    // Add the component back in
                    this.renderComponent = true;
                });
            },
            //Method Show or Hide Filters
            // showClosePopover(item) {
            //     if (this.prevFilterName) {
            //         if (this.prevFilterName == item) {
            //             let currentPopover = document.querySelector(`${'#' + item}`)
            //             currentPopover.classList.remove("showPopover");
            //             this.prevFilterName = '';
            //         } else {
            //             document.querySelector(`${'#' + this.prevFilterName}`).classList.remove("showPopover")
            //             this.prevFilterName = item;
            //             let currentPopover = document.querySelector(`${'#' + item}`);
            //             currentPopover.classList.toggle("showPopover");
            //         }
            //     } else {
            //         this.prevFilterName = item;
            //         let currentPopover = document.querySelector(`${'#' + item}`);
            //         currentPopover.classList.toggle("showPopover");
            //     }
            // },
            //GPT TEST
            // showClosePopover(item) {
            //     const currentPopover = document.querySelector(`#${item}`);
            //     this.prevFilterName = this.prevFilterName === item ? '' : item;
            //     currentPopover.classList.toggle('showPopover', this.prevFilterName === item);
            // },

            //Pagination 'Prev Page'
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },
            //Get current month first 3 digits
            changeLinksToCurrentMonth() {
                let currentMonth = this.orderList.month_of_order.toLowerCase().substring(0, 3);
                return currentMonth;
            },
            returnKeywordsTest() {
                let keywords = '';
                this.returnCustomers.forEach(item => {
                    if(item.id == this.ActiveCustomerID) {
                        keywords = item.customer_notes
                    }
                })
                return keywords;
            },
            handleScroll() {
                this.scrollpx = window.scrollY;
            },
            countActiveCustomerLinks() {
                this.returnCustomers.forEach((item) => {
                    if (item.id === this.ActiveCustomerID) {
                        this.activeCustomerLinksFix =
                            item.counters[this.yearToday][
                                this.monthToday
                                ].links_left_to_order;
                    }
                });
            },
            //add months prev next buttons
            changeMonth(pos) {
                let currentMonthID = this.months.indexOf(this.orderList.month_of_order)
                this.getDomainsList(this.ActiveCustomerLinksToOrder)
                if (pos == 'next') {
                    if (this.orderList.month_of_order != 'December') {
                        this.orderList.month_of_order = this.months[currentMonthID + 1]
                    } else {
                        this.orderList.month_of_order = this.months[0]
                    }
                } else if (pos == 'prev') {
                    if (this.orderList.month_of_order != 'January') {
                        this.orderList.month_of_order = this.months[currentMonthID - 1]
                    } else {
                        this.orderList.month_of_order = this.months[11]
                    }

                }
            },
            //return current month name
            returnCurrentMonthName() {
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const d = new Date();
                return (monthNames[d.getMonth()]);
            },
            //UI effect
            // showHidecolumnsDropdown(event) {
            //     event.preventDefault();
            //     $('.hidefilter-dropdown').slideToggle("fast");
            // },

            scrollTo(hash) {
                location.hash = "#" + hash;
            },
            changeCustomerListHide() {
                this.customerListHide = false;
                location.hash = "";
            },
            getActualBasket() {
                axios.get("get-cart");
            },
            postActualBasket(list) {
                axios.post("get-cart", {
                    data: list,
                });
            },
            showKeywordAlert() {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Message:',
                    text: 'Customer does not have keywords! Please, choose another customer!',
                    timer: 2000,
                    showConfirmButton: true,
                })
            },
            showKeywordAlertCorrupt() {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Message:',
                    text: 'Revise selected customer keywords!',
                    timer: 2000,
                    showConfirmButton: true,
                })
            },
            countLinksInEachOrder(custId) {
                return _.size(this.orderList.customers[custId]);
            },
            reloadPage() {
                this.$forceUpdate();
            },
            async calculateActualLinkFr() {
                let checkedDomainsArr = [];
                await this.domainsListCustomer.forEach((item) => {
                    if (item.isChecked) {
                        checkedDomainsArr.push(item.keyword_info);
                    }
                });
                if (checkedDomainsArr.length != 0) {
                    this.selectedDomainsKeywords = checkedDomainsArr;
                } else {
                    this.selectedDomainsKeywords = [];
                }

                this.calcMatchesKeyword();
            },
            calcMatchesKeyword() {
                this.domainsNotes[0]?.keywords?.keywords?.forEach((itemInit) => {
                    let counterMatch = 0;
                    this.selectedDomainsKeywords.forEach((item) => {
                        if (
                            itemInit.keyword == item.keyword &&
                            itemInit.landing_page == item.landing_page
                        ) {
                            counterMatch += 1;
                        }
                    });
                    this.$set(itemInit, 'matchCounter', counterMatch);
                });
            },
            getCurrentMonth() {
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ];
                let d = new Date();
                let n = d.getMonth();
                let currentDateOfMonth = d.getDate();
                if (currentDateOfMonth >= 10) {
                    // return months[n + 1];
                    this.$store.commit('updateGlobalCurrentMonth', months[n + 1])
                } else {
                    // return months[n];
                    this.$store.commit('updateGlobalCurrentMonth', months[n])

                }
            },
            getCurrentYear() {
                return new Date().getFullYear().toString();
            },

            //Filters for smartTable
            numOfCustFilter(filterValue, row) {
                return (
                    row.number_of_customers >= filterValue.min &&
                    row.number_of_customers <= filterValue.max
                );
            },
            numOfRefDomains(filterValue, row) {
                return (
                    row.referring_domains >= filterValue.min &&
                    row.referring_domains <= filterValue.max
                );
            },
            numOfMatching(filterValue, row) {
                return row.matching >= filterValue.min && row.matching <= filterValue.max;
            },

            //Function to check if sites are checked
            isCheck: function (index, displayData) {
                if (displayData[index].isChecked) {
                    this.ActiveCustomerLinksToOrder = this.ActiveCustomerLinksToOrder + 1;
                } else {
                    this.ActiveCustomerLinksToOrder = this.ActiveCustomerLinksToOrder - 1;
                }
            },

            //Function when you clicked Add to order
            async orderContent() {
                this.customerListHide = false;
                // this.filtersCustomers.language.value = this.currentLanguage;
                localStorage.setItem("currentLanguage", this.currentLanguage);
                localStorage.setItem("currentMonth", this.monthToday);
                localStorage.setItem("currentYear", this.yearToday);
                // localStorage.removeItem('orderListStore');
                let orders = {};
                orders = this.domainsListCustomer.filter((program) => program.isChecked);
                this.checkoutList = this.domainsListCustomer.filter(
                    (program) => program.isChecked
                );

                if(!this.orderList.customers[this.ActiveCustomerID]) {

                    this.$set(this.orderList.customers, this.ActiveCustomerID, {});

                }

                orders.forEach((item) => {
                    this.$set(this.orderList.customers[this.ActiveCustomerID], item.id, {
                        name: this.ActiveCustomerName,
                        domainName: item.name,
                        phrase_match: item.keyword_info.phrase_match,
                        keyword: item.keyword_info.keyword,
                        landing_page: item.keyword_info.landing_page,
                        negative_keywords: item.keyword_info.negative_keywords,
                        orderNumber: this.orderContentCounter,
                    });
                });

                this.orderList.month_of_order = this.monthToday;
                this.orderList.year_of_order = this.yearToday;
                this.orderList.language = this.ActiveCustomerLanguage;

                this.orderContentCounter += 1;

                this.postActualBasket(this.orderList);

                this.$store.commit('updateGlobalOrderList', this.orderList)


                let keyNameMonth = 'month'
                let keyNameYear = 'year'
                let keyNameLanguage = 'language__icontains'
                let valueMonth = this.monthToday;
                let valueYear = this.yearToday
                let valueLanguage = this.orderList.language

                await this.$store.commit('updateFilters', {keyNameMonth, valueMonth})
                await this.$store.commit('updateFilters', {keyNameYear, valueYear})
                await this.$store.commit('updateFilters', {keyNameLanguage, valueLanguage})


                await this.$store.dispatch('getData', this.returnFilters)
                this.$swal.fire({
                    icon: "success",
                    title: "Done",
                    text: "Content successfully added! You can choose new customer now!",
                    timer: 2000,
                    showConfirmButton: false,
                });

            },

            linksToOrderDomainsCheck(currentLinksToOrder) {
                let allCheckboxes = document.getElementsByClassName(
                    "customer-domain-checkbox"
                );

                if (currentLinksToOrder > 0) {
                    let i = 0;
                    while (currentLinksToOrder) {
                            let checkedTagName =
                                allCheckboxes[i].parentElement.nextElementSibling.innerText;
                            this.domainsListCustomer.forEach((el) => {
                                if ((el.name == checkedTagName && el.keyword_info.keyword !== "No valid Keyword found") || (el.name == checkedTagName && el.is_down === false) || (el.name == checkedTagName && el.order_times < 3)) {
                                    el.isChecked = true;
                                    currentLinksToOrder = currentLinksToOrder - 1
                                }
                            });
                            i++;
                    }
                }
                this.ActiveCustomerLinksToOrder = 0;
            },

            //Get domains for choosed Customer
            async getDomainsList() {
                this.domainsListCustomer = [];
                let currentCustomerId = this.ActiveCustomerID;
                let currentYear = this.yearToday;
                let currentMonth = this.monthToday;


                this.loading = true;
                this.countActiveCustomerLinks();

                this.customersLeftToOrder = [];

                this.returnCustomers.forEach((itemC) => {
                    if (itemC.counters[currentYear][currentMonth].links_left_to_order > 0) {
                        this.customersLeftToOrder.push(itemC);
                    }
                });

                let nlp = '/';

                if(!this.alternativeMatching) {
                    nlp = '-nlp/';
                }

                if (currentCustomerId) {
                    await axios
                        .post(
                            "domain-list-customer-id" + nlp +
                            currentCustomerId +
                            "/" +
                            this.numberZeroToHundred,
                            {
                                data: {
                                    month: this.monthToday,
                                    year: this.yearToday,
                                    linksLeftToOrder: this.activeCustomerLinksFix,
                                },
                            }
                        )
                        .then((response) => {

                            // adding prop isEditable for each object
                            this.domainsListCustomer = response.data.domains
                                .map((item) => ({
                                    isEditable: false,
                                    isChecked: false,
                                    exact_match: false,
                                    ...item,
                                }))
                                .filter(function (item) {
                                    return (
                                        item.topic != null &&
                                        item.is_active &&
                                        item.topic != "None" &&
                                        item.topic != "nan" &&
                                        item.topic != "NAN"
                                    );
                                });
                            this.totalCountOfDomains = _.size(this.domainsListCustomer);
                            this.domainsListCustomerKeywords = response.data.keywords;
                        })
                        .finally(() => (this.loading = false));
                } else {
                    this.loading = false;
                }


                if(this.checkIfCustomerAlreadyInBasket()) {
                    await this.linksToOrderDomainsCheck(this.activeCustomerLinksFix - _.size(this.orderList.customers[this.ActiveCustomerID]));
                } else {
                    await this.linksToOrderDomainsCheck(this.activeCustomerLinksFix);
                }

                await this.autoSelectDomain();

                await this.calculateActualLinkFr();


            },
            checkIfCustomerAlreadyInBasket() {
                if(this.orderList.customers[this.ActiveCustomerID]) {
                    return true;
                } else {
                    return false;
                }
            },

            //Get notes for Customers
            async getCustomerNotes(customerID) {
                this.domainsNotes = await this.returnCustomers.filter(function (n) {
                    return n.id === customerID;
                });
                await this.domainsNotes["0"].keywords.keywords.sort((a, b) =>
                    a["2"] < b["2"] ? 1 : b["2"] < a["2"] ? -1 : 0
                );
            },

            //Function to calc acc links and show for all orders
            getAccLinks() {
                this.domainsListCustomerKeywords.forEach((item) => {
                    this.domainsNotes["0"].keywords.keywords.forEach((item2) => {
                        if (item.keyword == item2.keyword && item.landing_page == item2.landing_page) {
                            let calcAccLinkFr = item.accumulataed_percentage;
                            this.$set(item2, 'accumulataed_percentage', calcAccLinkFr);
                        }
                    });
                });
            },
            //Function exist when you choose any Customer
            async chooseCustomerID(currentID, currentCustomerName, currentLang, currentLinksToOrder, event) {
                this.customerListHide = true;
                this.currentPage = 1;
                this.ActiveCustomerID = currentID;
                this.ActiveCustomerName = currentCustomerName;
                this.ActiveCustomerLanguage = currentLang;
                this.ActiveCustomerLinksToOrder = currentLinksToOrder;
                this.ActiveEventTarget = event;
                event.preventDefault();

                await this.getCustomerNotes(this.ActiveCustomerID);

                await this.getDomainsList();

                await this.getAccLinks();

            },
            async autoSelectDomain() {
                const orderListCheckedDomains =
                    this.orderList.customers[this.ActiveCustomerID];

                if (!orderListCheckedDomains) {
                    return;
                }

                const domainIDsArray = Object.keys(orderListCheckedDomains);

                await this.domainsListCustomer.forEach((el) => {
                    if (domainIDsArray.includes(String(el.id))) {
                        el.isChecked = true;
                    }
                });
            },

            //Function to get all customers when page is created
            async getCustomers() {
                this.customersLeftToOrder = [];
                this.returnCustomers.forEach((itemC) => {
                    this.customersLeftToOrder.push(itemC);
                });
            },

            //Function to get all providers when page is created
            getProviders() {
                axios
                    .get("provider/list?page_size=9999")
                    .then((response) => {
                        // adding prop isEditable for each object
                        this.providers = response.data.results
                            .filter(function (n) {
                                return n;
                            })
                            .reverse();
                        this.orderList.content_provider_id = this.providers[0].id;
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    });
            },

            addYearToArray() {
                let actualYear = this.getCurrentYear();
                this.yearsArray.push(actualYear - 1);
                this.yearsArray.push(actualYear);
                this.yearsArray.push(parseInt(actualYear) + 1);
                this.yearsArray.push(parseInt(actualYear) + 2);
            },
            checkIfDateMoreThanTen() {
                const d = new Date();
                const currentDateOfMonth = d.getDate();
                const currentYear = new Date().getFullYear();
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                if (currentDateOfMonth > 10 && monthNames[d.getMonth()] == 'December') {
                    this.$store.commit('updateMonthIfDateMoreThanTen', 'January')
                    this.$store.commit('updateYearIfMonthIsDecember', currentYear + 1)
                } else if (currentDateOfMonth > 10) {
                    this.$store.commit('updateMonthIfDateMoreThanTen', monthNames[d.getMonth() + 1])
                }
            },
            async updateCustomersByMonth(event) {
                this.$store.commit('updateData', [])
                let keyNameMonth = 'month'
                let valueMonth = event.target.value;

                await this.$store.commit('updateFilters', {keyNameMonth, valueMonth})

                await this.$store.dispatch('getData', this.returnFilters)

            },
            async updateCustomersByYear(event) {
                let keyNameYear = 'year'
                let valueYear = event.target.value

                await this.$store.commit('updateFilters', {keyNameYear, valueYear})

                await this.$store.dispatch('getData', this.returnFilters)
            },
            returnAvailableDomains() {
            },
            getInvalidKeywords() {
                axios.get('get-alert-keywords').then((res) => {
                    this.invalidKeywords = res.data;
                }).catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            }
        },

        updated() {
            // this.myHeight = this.$refs.custBot.offsetHeight
            this.$watch(
                () => {
                    return this.$refs.myDomains?.sortedData?.length
                },
                (val) => {
                    this.sortedDomainsData = val
                }
            )
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
            this.$store.commit('clearData');
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closePopovers);
        },



        async mounted() {

            eventBus.$on('gotOrderBasket', payload => {
                console.log(payload)
                this.componentKey += payload
            });

            document.addEventListener('click', this.closePopovers);
            if (this.$store.getters.isLoggedIn) {

                await this.$store.commit('updateCurrentRoute', this.$route.name)
                //Mutation to clean filters data when you mounted Component
                await this.$store.commit('cleanFilters')

                let keyNameMonth = 'month'
                let keyNameYear = 'year'
                let keyNameLanguage = 'language__icontains'
                let valueMonth = this.monthToday;
                let valueYear = this.yearToday
                let valueLanguage = this.orderList.language

                await this.$store.commit('updateFilters', {keyNameMonth, valueMonth})
                await this.$store.commit('updateFilters', {keyNameYear, valueYear})
                await this.$store.commit('updateFilters', {keyNameLanguage, valueLanguage})

                await this.addYearToArray();
                await this.getProviders();
                await this.getCurrentYear();
                this.getInvalidKeywords();
                axios
                .get('get-cart')
                .then(res => {
                    if(res.data != null) {
                        if(this.orderListNumber == 0) {
                            this.checkIfDateMoreThanTen();
                        }
                        if(this.orderList.month_of_order) {
                            this.monthToday = this.orderList.month_of_order.toLowerCase().substring(0, 3)
                            let valueMonth = this.monthToday;
                            this.$store.commit('updateFilters', {keyNameMonth, valueMonth})
                        }
                        if(this.orderList.year_of_order) {
                            this.yearToday = this.orderList.year_of_order.toString()
                            let valueYear = this.yearToday
                            this.$store.commit('updateFilters', {keyNameYear, valueYear})
                        }
                        this.$store.dispatch('getData', this.returnFilters)
                        this.$store.commit('updateGlobalOrderList', res.data.data)
                    } else {
                        this.$store.dispatch('getData', this.returnFilters)
                    }
                })
                .finally(() => {

                })

            }

            // this.$store.commit('updateOrderListFull', this.orderListFull)

            let currentUser = localStorage.getItem("currentUser");
            this.message.action[currentUser] = {
                routeName: this.$route.name,
            };
            window.socket?.send(JSON.stringify(this.message));
        },

    };
</script>


<style lang="scss" scoped>
    .tableFixHead, .tableFixHeadKeywords          { overflow: auto; height: calc(70vh); }
    .tableFixHeadKeywords thead th { position: sticky; top: 0; z-index: 1; }
    .tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
    .tableFixHead tbody tr:first-child td { position: sticky; top: 33px; z-index: 1; background: #fff; }

    $loader-color: red;
    .loader-07 {
        border: 0 solid transparent;
        border-radius: 50%;
        position: relative;

        &:before,
        &:after {
            content: "";
            border: 0.6em solid $loader-color;
            border-radius: 50%;
            width: inherit;
            height: inherit;
            position: absolute;
            top: 0;
            left: 0;
            animation: loader-07 1s linear infinite;
            opacity: 0;
        }

        &:before {
            animation-delay: 1s;
        }

        &:after {
            animation-delay: 0.5s;
        }
    }

    @keyframes loader-07 {
        0% {
            transform: scale(0);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }

    .warning {
        background-color: red;
    }

    #add-to-order-button {
    }

    .order-com-content {
        position: relative;
        display: flex;
    }

    .left-fixed-bar {
        /*padding-top: 10px;*/
        max-width: 220px;
        width: 100%;
        bottom: 0;
        /*border-right: 1px solid #000;*/
        height: calc(100vh - 145px);
        position: fixed;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }

    .customer-notes {
        height: 40%;
        overflow: hidden;
        /*border-bottom: 1px solid #000;*/
    }

    .customer-notes--title {
        background-color: #d1e8ff;
        padding: 10px 0px;
        margin-bottom: 0px;
    }

    .customer-notes--list {
        overflow-y: auto;
        height: calc(100% - 44px);
        padding: 20px 0px;
        background-color: #edf4ff;
        list-style-position: inside;
    }

    /*table {*/
    /*align-items: center;*/
    /*}*/

    #domains-form {
        padding-left: 235px;
    }

    table td {
        border: 0px solid #000;
    }

    .content-order-main {
        display: flex;
        justify-content: center;
    }

    .order-provider {
        width: 200px;
        display: flex;
        flex-direction: column;
    }

    .order-provider-button {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 35px;
        outline: none;
        border: none;
    }

    .editable {
        border: 2px solid green;
    }

    .domains-search-input {
        height: 26px;
        line-height: 26px;
        width: 200px;
        margin-bottom: 30px;
    }

    .checkout-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        max-width: 350px;
        width: 100%;
        /*margin: 0 auto;*/
    }

    .total-counts-domains {
        color: #000;
        display: inline-block;
        margin-right: 20px;
    }

    .total-domains-selected {
        /*background-color: #26303E;*/
        color: #000;
        display: inline-block;
        margin-right: 20px;
    }
    .active-customer-name {
        color: #000;
        display: inline-block;
    }

    .domains-list {
        width: 100%;
        overflow-x: auto;
    }

    .preorder-customer-view {
        width: 100%;
        /*overflow-x: auto;*/
        height: calc(70vh);
        overflow: scroll;
    }

    .table {
        overflow-x: auto;
        overflow-y: auto;
        table-layout: auto;
        width: 100%;
    }

    .customers-statistics-fixed {
        /*height: 30vh;*/
        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        /*background-color: white;*/
        position: absolute;
        bottom: 0;
        background-color: #26303E;
        /*display: inline-block;*/
        /*position: fixed;*/
        /*bottom: 40px;*/
        /*left: 20px;*/
        /*-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);*/
        /*-moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);*/
        /*box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);*/
        color: #fff;

        input {
            background-color: #cd9367;
            color: #fff;
            margin: 5px 0;
        }

        button {
            width: 100%;
        }
    }

    /*.notes-list {*/
    /*list-style: none;*/
    /*column-count: 4;*/
    /*column-gap: 20px;*/
    /*column-rule: 3px solid orange;*/
    /*}*/

    .customer-list {
        width: 100%;
        /*overflow-x: auto;*/
        overflow: visible;
        table {
            tr {
                th {
                    overflow: visible;
                    position: relative;
                }
            }
        }
    }

    .cl-basket-button {
        display: block;
        background-color: #cd9367;
        color: #fff !important;
    }

    .customer-final-order {
        overflow-y: auto;
        /*height: 80vh;*/
        height: calc(70vh - 135px);
        background-color: #fff;

        h5 {
            background-color: green;
            color: #fff;
            margin-bottom: 0;
        }
    }

    /*.is-open {*/
    /*display: none;*/
    /*}*/

    .has-dropdown {
        cursor: pointer;
    }

    .has-dropdown:not(.is-open) ul {
        display: none;
    }

    /*.domain-list-pagination-none {*/
    /*visibility: hidden;*/
    /*}*/

    td .customer-domain-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: 1;
    }
    .checkmark-checked {
        visibility: hidden;
    }
    .checkmark-stop, .checkmark-checked {
        position: absolute;
        /*top: 0;*/
        /*left: 0;*/
        height: 16px;
        width: 16px;
        z-index: 0;
        /*background-color: #eee;*/
    }
    td:hover input ~ .checkmark-stop {
        background-color: #d7eace;
    }
    td input:checked ~ .checkmark-stop {
        visibility: hidden;
    }
    td input:checked ~ .checkmark-checked {
        visibility: visible;
        color: #fff;
        background: #89B49F;
    }
    .filters-popover {
        top: -50px;
    }
    .keywords-failed-list {
        list-style: none;
        height: 298px;
        overflow: scroll;
    }
</style>

<style>
    .asc:after {
        content: "\25B2";
    }

    .desc:after {
        content: "\25BC";
    }

    /*.currentCustomer {*/
    /*background-color: orange !important;*/
    /*}*/
</style>


