<template>

    <div class="container-fluid body-padding-top">
        <div class="domains main-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12" style="padding-left: 0;">
                        <h1 class="table-title">Domain list</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid background-table" style="padding-top: 10px;">

                <!--Search field button and Bulk add Status update -->

                <div class="row" style="margin-bottom: 10px;">
                    <div class="left-side col-sm-12 col-md-6" style="text-align: left;">
                        <!--Search Filed -->
                        <GlobalSearch filter_type="search"/>

                        <!--Download Button -->
                        <button @click="getExportFile()" class="domains-download-button" id="tooltip-excel-data">
                            <font-awesome-icon icon="fa-light fa-arrow-down-to-line " class="fa-xl"/>
                        </button>
                        <b-tooltip target="tooltip-excel-data" triggers="hover"
                                   placement="topright">
                            Download Excel Data
                        </b-tooltip>


                        <!--Excel Export Monthly Button -->
                        <vue-excel-xlsx
                                v-if="monthlyDomainsOrderStats.length > 0"
                                :data="monthlyDomainsOrderStats"
                                :columns="columnsTableStat"
                                :filename="'monthly-domains-stat-' + randomFileName()"
                                :sheetname="'monthly-domains-stat-' + randomFileName()"
                                style="background-color: #fff; padding: 0;"
                        >
                        <button id="tooltip-excel-monthly-data" class="domains-download-button"
                                v-b-modal.excel-modal >
                            <font-awesome-icon icon="fa-light fa-file-excel" class="fa-xl"/>
                        </button>
                        <b-tooltip target="tooltip-excel-monthly-data" triggers="hover">
                            Export Excel Monthly Data
                        </b-tooltip>
                        </vue-excel-xlsx>

                        <!--Replace Button -->
                        <button id="tooltip-replace-button" class="domains-replace-button"
                                v-b-modal.replace-modal>
                            <font-awesome-icon icon="fa-light fa-arrow-up-arrow-down " class="fa-xl"/>
                        </button>
                        <b-tooltip target="tooltip-replace-button" triggers="hover">
                            Replace domain
                        </b-tooltip>

                        <b-modal id="replace-modal" title="Replace Domains" @ok="replaceDomainOk()">

                            <input type="text" placeholder="Type old domain" v-model="oldDomainRepl">
                            <input type="text" placeholder="Type new domain" v-model="newDomainRepl">

                        </b-modal>
                    </div>
                    <!--Right Side Bulk and Domains Update buttons -->
                    <div class="right-side col-sm-12 col-md-6" style="text-align: right;">
                        <button @click="resetFilters()" class="main-button m-md-2">Reset filters</button>
                        <!--Bulk Add Demo -->
                        <button class="main-button" style="margin-right: 10px;" v-b-modal.bulk-add>Bulk Add</button>

                        <b-modal id="bulk-add" size="xl" title="Bulk Add Domains" @ok="prepareGoogleDocDataToSend()">
                            <div class="bulk-add-top-headers">
                                <div class="row">
                                    <div class="col-4"><p style="display: inline-block;">1. Copy headers </p> <span>
                                        <button style="background: inherit;" @click="copyHeadersToClipboard">
                                        <font-awesome-icon icon="fa-solid fa-copy" class="fa-lg"/>
                                    </button>
                                    </span>
                                    </div>
                                    <div class="col-4"><p>2. Add information in excel</p></div>
                                    <div class="col-4"><p style="display: inline-block;">3. Paste in field below</p>
                                        <span>
                                        <button style="background: inherit;" @click="pasteFromClipboard">
                                        <font-awesome-icon icon="fa-regular fa-paste" class="fa-lg"/>
                                    </button>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="bulk-add-text-input" style="margin-bottom: 20px;">

                                <b-form-textarea
                                        id="textarea"
                                        v-model="bulkAddText"
                                        placeholder="Domain Language Alt. Language"
                                        rows="10"
                                        max-rows="15"
                                        style="font-size: 12px; "
                                ></b-form-textarea>
                            </div>
                            <div class="bulk-add-preview"
                                 style="overflow: scroll; font-size: 12px!important; height: 300px;">
                                <h5>Preview</h5>
                                <table class="table table-responsive-md btn-table table-hover">
                                    <thead>
                                    <tr class="md-table-row">
                                        <th v-for="(header, index) in clipboardData['0']" :key="index">{{header}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="md-table-row" v-for="(data, index) in clipboardData.slice(1)"
                                        :key="index">
                                        <td v-for="(data2, index2) in data" :key="index2">{{data2}}</td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                        </b-modal>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-sm-12 col-md-6 under-side-left" style="text-align: left;">
                        <!--Add New Domains Button -->
                        <button id="tooltip-addnew-button" class="domains-adddomain-button"
                                v-b-modal.addnewdomain-modal>
                            New Domain
                            <font-awesome-icon icon="fa-regular fa-plus" class="fa-sm"/>
                        </button>
                        <b-tooltip target="tooltip-addnew-button" triggers="hover">
                            Add new domain
                        </b-tooltip>

                        <b-modal id="addnewdomain-modal" title="Add New Domain" size="lg" @ok="addNewDomainOk()"
                                 @cancel="resetSettings" @close="resetSettings">
                            <div class="newdomain-wrapper">
                                <div class="newdomain-wrapper-fields">
                                    <div>
                                        <label for="add-domain-name">Domain name:</label>
                                        <input id="add-domain-name" class="column-input-search" type="text"
                                               v-model="inputs.name"
                                               placeholder="Type domain name...">
                                    </div>

                                    <div>
                                        <label for="add-domain-subject">Subject:</label>
                                        <input id="add-domain-subject" type="text" v-model="inputs.subject">
                                    </div>

                                    <div>
                                        <label for="add-domain-lang">Language:</label>
                                        <select id="add-domain-lang" v-model="inputs.language">
                                            <!--                                            <option selected>SE</option>-->
                                            <option v-for="lang in langs" :key="lang.id">
                                                {{ lang.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label for="add-domain-lang-alt">Language Alt:</label>
                                        <select id="add-domain-lang-alt" v-model="inputs.language_alt">
                                            <!--                                            <option selected>SE</option>-->
                                            <option v-for="lang in langsGeo" :key="lang.id">
                                                {{ lang.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label for="add-domain-status">Status:</label>
                                        <select id="add-domain-status" disabled v-model="inputs.status"
                                                style="background: #b0b0b1;">
                                            <option selected>NEW</option>
                                            <option>LIVE</option>
                                            <option>REPLACE</option>
                                            <option>ACTIVATED</option>
                                            <option>FIND REPLACEMENT</option>
                                            <option>STARTER</option>
                                            <option>REACTIVATED</option>
                                            <option>DEACTIVATED</option>
                                            <option>PHASED OUT</option>
                                            <option>WILL BE PHASED OUTt</option>
                                            <option>RETURNED</option>
                                            <option>LOST</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label for="add-domain-ref">Referring domains:</label>
                                        <input id="add-domain-ref" type="text"
                                               v-model="inputs.referring_domains">
                                    </div>

                                    <div>
                                        <label for="add-domain-trustflow">Trust flow:</label>
                                        <input id="add-domain-trustflow" type="text"
                                               v-model="inputs.trust_flow">
                                    </div>
                                    <div>
                                        <label for="add-domain-authority">Domain authority:</label>
                                        <input id="add-domain-authority" type="text"
                                               v-model="inputs.domain_authority">
                                    </div>
                                    <div>
                                        <label for="add-domain-pageauthority">Page authority:</label>
                                        <input id="add-domain-pageauthority" type="text"
                                               v-model="inputs.page_authority">
                                    </div>
                                    <div>
                                        <label for="add-domain-batch">Batch number:</label>
                                        <input id="add-domain-batch" type="text" style="background: #b0b0b1;"
                                               disabled
                                               v-model="inputs.batch_number">
                                    </div>

                                    <div style="width: 100%;">
                                        <label for="add-domain-notes">Notes:</label>
                                        <textarea id="add-domain-notes" name="notes" cols="20"
                                                  rows="5"
                                                  v-model="inputs.notes" placeholder="Type your notes..."></textarea>
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                        <!--Filter Button -->
                        <!--                        <button id="extand-down" class="domains-filter-button">-->
                        <!--                            <font-awesome-icon icon="fa-regular fa-bars-filter " class="fa-xl" />-->
                        <!--                        </button>-->
                        <!--Deactivate Domains Button -->
                        <button class="domains-delete-button" type="button" value="Remove"
                                id="tooltip-deactivate-group-domains-button"
                                v-if="selectedDomains.length > 0"
                                v-on:click.left.exact="deactivateGroupDomain"
                                v-on:click.shift.left.exact="removeGroupDomain" variant="danger"
                        >
                            <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg"/>
                        </button>
                        <b-tooltip target="tooltip-deactivate-group-domains-button" triggers="hover">
                            Deactivate domains
                        </b-tooltip>
                    </div>
                </div>


                <!--                <template v-if="loading">-->
                <!--                    <div id="preloader">-->
                <!--                        <div id="loader_new"></div>-->
                <!--                    </div>-->
                <!--                </template>-->
                <template v-if="globalLoader || loading">
                    <div id="preloader">
                        <div id="loader_new"></div>
                    </div>
                </template>

                <!--                <template v-else>-->
                <div class="row">
                    <div class="col-sm-12">
                        <div class="domains-list main-list">
                            <v-table :data="returnMainData"
                                     :currentPage.sync="currentPage"
                                     :pageSize="100"
                                     @totalPagesChanged="totalPages = $event"
                                     selectionMode="multiple"
                                     selectedClass="table-info"
                                     @selectionChanged="selectedDomains = $event"
                                     ref="myTable"
                                     class="table table-responsive-md btn-table table-hover">
                                <thead slot="head">
                                <tr class="md-table-row main-table-header-row">
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           style="cursor: pointer;"
                                                           :class="{ green: checkIfInFilters('name__icontains', 'ordering', 'name')  }"
                                                           @click="showClosePopover('domain_name')"/>&nbsp; Domain
                                        Name {{returnCountItems}}
                                        <div class="filters-popover" id="domain_name">
                                            <Sorting filter_type="ordering" filter_value="name"/>
                                            <StringFieldFilter filter_type="name__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('subject__icontains', 'ordering', 'subject')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('subject')"/>&nbsp;Subject
                                        <div class="filters-popover" id="subject">
                                            <Sorting filter_type="ordering" filter_value="subject"/>
                                            <StringFieldFilter filter_type="subject__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('language__icontains', 'ordering', 'language')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('language')"/>&nbsp;Geo
                                        <div class="filters-popover" id="language">
                                            <Sorting filter_type="ordering" filter_value="language"/>
                                            <StringFieldFilter filter_type="language__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('language_alt__icontains', 'ordering', 'language_alt')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('language_alt')"/>&nbsp;Alt Geo
                                        <div class="filters-popover" id="language_alt">
                                            <Sorting filter_type="ordering" filter_value="language_alt"/>
                                            <StringFieldFilter filter_type="language_alt__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('status__icontains', 'ordering', 'status')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('status')"/>&nbsp;Status
                                        <div class="filters-popover" id="status">
                                            <Sorting filter_type="ordering" filter_value="status"/>
                                            <StringFieldFilter filter_type="status__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           style="cursor: pointer;"
                                                           :class="{ green: checkIfInFiltersMinMax('referring_domains__gte','referring_domains__lte', 'ordering', 'referring_domains')  }"
                                                           @click="showClosePopover('referring_domains')"/>&nbsp;Ref. Dom.
                                        <div class="filters-popover" id="referring_domains">
                                            <Sorting filter_type="ordering" filter_value="referring_domains"/>
                                            <MinMaxFilter filter_type_min="referring_domains__gte"
                                                          filter_type_max="referring_domains__lte"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFiltersMinMax('trust_flow__gte','trust_flow__lte', 'ordering', 'trust_flow')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('trust_flow')"/>&nbsp;Trust flow
                                        <div class="filters-popover" id="trust_flow">
                                            <Sorting filter_type="ordering" filter_value="trust_flow"/>
                                            <MinMaxFilter filter_type_min="trust_flow__gte"
                                                          filter_type_max="trust_flow__lte"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFiltersMinMax('domain_authority__gte','domain_authority__lte', 'ordering', 'domain_authority')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('domain_authority')"/>&nbsp;Domain aut.
                                        <div class="filters-popover" id="domain_authority">
                                            <Sorting filter_type="ordering" filter_value="domain_authority"/>
                                            <MinMaxFilter filter_type_min="domain_authority__gte"
                                                          filter_type_max="domain_authority__lte"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFiltersMinMax('page_authority__gte','page_authority__lte', 'ordering', 'page_authority')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('page_authority')"/>&nbsp;Page aut.
                                        <div class="filters-popover" id="page_authority">
                                            <Sorting filter_type="ordering" filter_value="page_authority"/>
                                            <MinMaxFilter filter_type_min="page_authority__gte"
                                                          filter_type_max="page_authority__lte"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('batch_number__icontains', 'ordering', 'batch_number')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('batch_number')"/>&nbsp;
                                        Batch
                                        <div class="filters-popover" id="batch_number">
                                            <Sorting filter_type="ordering" filter_value="batch_number"/>
                                            <StringFieldFilter filter_type="batch_number__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFiltersMinMax('number_of_customers__gte','number_of_customers__lte', 'ordering', 'number_of_customers')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('number_of_customers', $event)"/>&nbsp;Num
                                        Cust.
                                        <div class="filters-popover" id="number_of_customers">
                                            <Sorting filter_type="ordering" filter_value="number_of_customers"/>
                                            <MinMaxFilter filter_type_min="number_of_customers__gte"
                                                          filter_type_max="number_of_customers__lte"/>
                                        </div>
                                    </th>
                                    <th>Log</th>
                                    <th>Notes</th>
                                </tr>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">

                                <v-tr class="" v-for="(program, indexProgram) in displayData"
                                      :key="indexProgram" :row="program">
                                    <td>
                                        <button v-b-modal="'edit-domain' + '-' + program.id"
                                                class="domains-domainname-button"
                                                style="background: inherit; color: #0a1e61;"
                                                :readonly="!program.isEditable"
                                                :class="{ 'editable': program.isEditable }"
                                        >{{program.name}}
                                        </button>

                                        <b-modal :id="'edit-domain' + '-' + program.id" title="Edit Domain"
                                                 size="lg"
                                                 @ok="saveEditDomainOk(program)">


                                            <div class="newdomain-wrapper">
                                                <div class="newdomain-wrapper-fields">
                                                    <div>
                                                        <label for="edit-domain-name">Domain name:</label>
                                                        <input id="edit-domain-name" class="column-input-search"
                                                               type="text"
                                                               v-model.lazy="program.name"
                                                               placeholder="Type domain name...">
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-subject">Subject:</label>
                                                        <input id="edit-domain-subject" type="text"
                                                               v-model.lazy="program.subject">
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-lang">Language:</label>
                                                        <select id="edit-domain-lang" v-model="program.language">
                                                            <!--                                                                <option selected>SE</option>-->
                                                            <option v-for="lang in langs" :key="lang.id">
                                                                {{ lang.name }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-lang-alt">Language Alt:</label>
                                                        <select id="edit-domain-lang-alt"
                                                                v-model="program.language_alt">
                                                            <!--                                                                <option selected>SE</option>-->
                                                            <option v-for="lang in langsGeo" :key="lang.id">
                                                                {{ lang.name }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-status">Status:</label>
                                                        <select id="edit-domain-status" v-model="program.status">
                                                            <option selected>NEW</option>
                                                            <option>LIVE</option>
                                                            <option>REPLACE</option>
                                                            <option>ACTIVATED</option>
                                                            <option>FIND REPLACEMENT</option>
                                                            <option>STARTER</option>
                                                            <option>REACTIVATED</option>
                                                            <option>DEACTIVATED</option>
                                                            <option>PHASED OUT</option>
                                                            <option>WILL BE PHASED OUT</option>
                                                            <option>RETURNED</option>
                                                            <option>LOST</option>
                                                        </select>
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-ref">Referring domains:</label>
                                                        <input id="edit-domain-ref" type="text"
                                                               v-model="program.referring_domains">
                                                    </div>

                                                    <div>
                                                        <label for="edit-domain-trustflow">Trust flow:</label>
                                                        <input id="edit-domain-trustflow" type="text"
                                                               v-model="program.trust_flow">
                                                    </div>
                                                    <div>
                                                        <label for="edit-domain-authority">Domain authority:</label>
                                                        <input id="edit-domain-authority" type="text"
                                                               v-model="program.domain_authority">
                                                    </div>
                                                    <div>
                                                        <label for="edit-domain-pageauthority">Page
                                                            authority:</label>
                                                        <input id="edit-domain-pageauthority" type="text"
                                                               v-model="program.page_authority">
                                                    </div>
                                                    <div>
                                                        <label for="edit-domain-batch">Batch number:</label>
                                                        <input id="edit-domain-batch" type="text"
                                                               v-model="program.batch_number">
                                                    </div>
                                                    <div style="width: 100%;">
                                                        <label for="edit-domain-notes">Notes:</label>
                                                        <textarea id="edit-domain-notes" name="notes" cols="20"
                                                                  rows="5"
                                                                  v-model="program.notes"
                                                                  placeholder="Type your notes..."></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="newdomain-wrapper-actions">
                                                <template v-if="program.is_active">

                                                    <button class="" type="button" value="Remove"
                                                            :id="'tooltip-deactivate-domain' + indexProgram"
                                                            style="background: inherit;"
                                                            v-on:click="deactivateDomain(program)"
                                                            :disabled="(program && program.status) ? program.status.toLowerCase() == 'deactivated' || program.status.toLowerCase() == 'find replacement' || program.status.toLowerCase() == 'paused' || program.status.toLowerCase() == 'phased out' || program.status.toLowerCase() == 'will be phased out' || program.status.toLowerCase() == 'returned' || program.status.toLowerCase() == 'lost' : ''"
                                                    >
                                                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg"/>
                                                    </button>
                                                    <b-tooltip :target="'tooltip-deactivate-domain' + indexProgram"
                                                               triggers="hover">
                                                        Deactivate domain
                                                    </b-tooltip>

                                                </template>
                                                <template v-else>

                                                    <button class="" type="button" value="Restore"
                                                            :id="'tooltip-activate-domain' + indexProgram"
                                                            style="background: inherit;"
                                                            :disabled="(program && program.status) ? program.status.toLowerCase() == 'deactivated' || program.status.toLowerCase() == 'find replacement' || program.status.toLowerCase() == 'paused' || program.status.toLowerCase() == 'phased out' || program.status.toLowerCase() == 'will be phased out' || program.status.toLowerCase() == 'returned' || program.status.toLowerCase() == 'lost' : ''"
                                                            v-on:click="activateDomain(program)">
                                                        <!--                                                            <font-awesome-icon :icon="['fas', 'trash-restore']"/>-->
                                                        <font-awesome-icon icon="fa-solid fa-trash-restore-alt"
                                                                           class="fa-lg"/>
                                                    </button>
                                                    <b-tooltip :target="'tooltip-activate-domain' + indexProgram"
                                                               triggers="hover">
                                                        Activate domain
                                                    </b-tooltip>

                                                </template>
                                                <button class="main-button m-1"
                                                        @click="forceDomain(program.id)">Force
                                                    Parse
                                                </button>
                                            </div>

                                        </b-modal>
                                    </td>
                                    <td>{{program.subject}}</td>
                                    <td>{{program.language}}</td>
                                    <td>{{program.language_alt}}</td>
                                    <td>
                                        <select v-model="program.status"
                                                @change="updateDomainMainRow(program, 'status', program.status)">
                                            <option selected>NEW</option>
                                            <option>LIVE</option>
                                            <option>REPLACE</option>
                                            <option>ACTIVATED</option>
                                            <option>FIND REPLACEMENT</option>
                                            <option>STARTER</option>
                                            <option>REACTIVATED</option>
                                            <option>DEACTIVATED</option>
                                            <option>PHASED OUT</option>
                                            <option>WILL BE PHASED OUTt</option>
                                            <option>RETURNED</option>
                                            <option>LOST</option>
                                        </select>
                                    </td>
                                    <td>
                                        {{program.referring_domains}}
                                    </td>
                                    <td>{{program.trust_flow}}
                                    </td>
                                    <td>
                                        {{program.domain_authority}}
                                    </td>
                                    <td>
                                        {{program.page_authority}}
                                    </td>
                                    <td>
                                        {{program.batch_number}}
                                    </td>
                                    <td>
                                        {{program.number_of_customers}}
                                    </td>
                                    <td>
                                        <button v-b-modal="`modal-${program.id}`"
                                                class=""
                                                style="background: inherit;"
                                                @click="showLastUpdatesModal($event, program.id, program.name)">
                                            <font-awesome-icon icon="fa-solid fa-telescope" class="fa-xl"/>
                                        </button>
                                        <b-modal :id="'modal-' + program.id" :title="currentUpdatedDomainName">
                                            <template v-if="currentUpdatedDomain != 'loading' || currentUpdatedDomain">
                                                <div style="height: 600px; overflow: scroll;">
                                                    <div v-for="(item, index) in currentUpdatedDomain"
                                                         :key="index"
                                                         style="border-bottom: 1px solid #000; margin-bottom: 30px;">
                                                        <p style="background-color: #eebe78; margin-bottom: 5px; padding: 0 10px; border-radius: 10px;">
                                                            <strong>Updated
                                                                date:</strong> {{new Date(item.date_created).toLocaleDateString('en-US',
                                                            {year: 'numeric', month: '2-digit', day: '2-digit', hour:
                                                            '2-digit', minute: '2-digit'})}}</p>
                                                        <p style="    background-color: #cdcccc; margin-bottom: 10px; padding: 0 10px; border-radius: 10px;">
                                                            <strong>User:</strong>
                                                            {{item.created_by }}</p>
                                                        <p style="margin-bottom: 5px;"><strong>Changes:</strong></p>
                                                        <p style="background-color: #99c2af; margin-bottom: 5px; padding: 0 10px; border-radius: 10px;">{{ item.message }}</p>
                                                    </div>
                                                </div>

                                            </template>
                                            <template v-else-if="currentUpdatedDomain == 'loading'">
                                                <p>Loading... Please wait a second</p>
                                            </template>
                                            <template v-else>
                                                <p>No Data</p>
                                            </template>
                                        </b-modal>
                                    </td>
                                    <td width="400 | 20%">
                                        <template v-if="program.notes.length">
                                            {{program.notes.slice(0, 25) + '...'}}
                                        </template>
                                        <template v-else>

                                        </template>
                                        <button v-b-modal="'read-notes' + '-' + program.id"
                                                class="main-button"
                                                style="background: inherit; color: #0a1e61;"
                                        >
                                            <template v-if="program.notes.length">
                                                Read more
                                            </template>
                                            <template v-else>
                                                Add Notes
                                            </template>
                                        </button>
                                        <b-modal :id="'read-notes' + '-' + program.id" title="Notes"
                                                 size="md"
                                                 @ok="saveNotes(program)">
                                            <div>
                                                <textarea rows="10" cols="10" style="width: 100%;"
                                                          v-model="program.notes"></textarea>
                                                <!--                                                   {{row.notes}}-->
                                            </div>
                                        </b-modal>
                                        <!--                                            {{program.notes}}-->
                                    </td>
                                </v-tr>

                                </tbody>
                            </v-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="pagination-wrapper"
                             style="display: flex; justify-content: center; margin-bottom: 30px;">
                            <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                            <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                        </div>
                    </div>
                </div>
                <!--            </div>-->
                <!--                </template>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import _ from "lodash";
    import {mapState} from "vuex";
    import StringFieldFilter from "../components/filters/StringFieldFilter";
    import Sorting from "../components/filters/Sorting";
    import MinMaxFilter from "../components/filters/MinMaxFilter";
    import GlobalSearch from "../components/filters/GlobalSearch";

    export default {
        name: 'Domains',

        components: {GlobalSearch, MinMaxFilter, StringFieldFilter, Sorting},
        data() {
            return {
                bulkAddText: 'Domain\tLanguage 1\tLanguage 2\tSubject\tReferring domains\tTrust flow\tDomain authority\tPage authority\tStatus\tActive',
                // allDomains: [],
                totalDomainsCount: 0,
                prevPageValue: '',
                nextPageValue: '',
                searchText: '',
                // checkedCatsForCreateDomain: [],
                selectedDomains: [],
                excludeFiltersStatus: false,
                // isBlockedButtons: false,
                message: {'message': {}, 'action': {}},
                columns: [
                    'name', 'subject', 'language', 'language_alt', 'status', 'referring_domains', 'trust_flow', 'domain_authority', 'page_authority', 'batch_number', 'number_of_customers', 'notes'
                ],
                columnsTableStat: [],
                columnsTable: [
                    {
                        label: "Domain Name",
                        field: "name"
                    },
                    {
                        label: "Subject",
                        field: "subject"
                    },
                    {
                        label: "Geo",
                        field: "language"
                    },
                    {
                        label: "Alt Geo",
                        field: "language_alt"
                    },
                    {
                        label: "Ref. Domains",
                        field: "referring_domains"
                    },
                    {
                        label: "Trust flow",
                        field: "trust_flow"
                    },
                    {
                        label: "Domain authority",
                        field: "domain_authority"
                    },
                    {
                        label: "Page authority",
                        field: "page_authority"
                    },
                    {
                        label: "Status",
                        field: "status"
                    },
                    {
                        label: "Batch",
                        field: 'batch'
                    },
                    {
                        label: "Number Of Customers",
                        field: "number_of_customers"
                    },

                ],
                currentPage: 1,
                currentPageStat: 1,
                totalPages: 0,
                totalPagesStat: 0,
                inputs: {
                    name: '',
                    subject: 'None',
                    language: 'SE',
                    language_alt: '',
                    status: 'NEW',
                    referring_domains: 0,
                    trust_flow: 0,
                    domain_authority: 0,
                    page_authority: 0,
                    notes: '',
                    batch_number: '',
                    server_id: 0,
                    is_active: true,
                },
                // topics: [],
                // topicsById: [],
                langs: [' '],
                langsGeo: [' '],
                groupDomains: [],
                loading: false,
                oldDomainRepl: '',
                newDomainRepl: '',
                timer: null,
                prevFilterName: '',
                clipboardData: [],
                monthlyDomainsOrderStats: [],
                currentUpdatedDomain: [],
                currentUpdatedDomainName: '',
            }
        },
        computed: {
            returnInputName() {
                return this.inputs.name;
            },
            ...mapState({
                // returnAllDomains: state => state.domainList.domains,
                // returnAllTopics: state => state.topicList.topics,
                returnMainData: state => state.filters.mainData,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                returnCountItems: state => state.filters.countItems,
                globalLoader: state => state.filters.globalLoader,
            }),
        },
        watch: {
            bulkAddText: {
                handler() {
                    this.getDataFromClipboard()
                },
                deep: true
            },
            // returnMainData: {
            //     handler() {
            //         this.exportDisplayData()
            //     },
            //     deep: true
            // }
        },
        methods: {
            //Show last Updated for each domain
            showLastUpdatesModal(e, id, name) {
                e.preventDefault();
                this.currentUpdatedDomainName = name
                this.currentUpdatedDomain = []
                this.currentUpdatedDomain = 'loading'
                this.loading = true;
                axios
                    .get('/domain-log/list' + '?id=' + id)
                    .then((res) => {
                        this.loading = false;
                        this.currentUpdatedDomain = res.data.results[0].log
                    })
            },
            closePopovers(event) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    const popoverIcon = popover.previousElementSibling;
                    if (!popover.contains(event.target) && !popoverIcon.contains(event.target)) {
                        popover.classList.remove('showPopover');
                    }
                }
            },
            //Reset filters button
            resetFilters() {
                this.$store.commit('cleanFilters');
                this.$store.dispatch('getData', this.returnFilters)
            },
            //Change status request
            updateDomainMainRow(row, itemName, itemValue) {
                // console.log(row)
                if (this.selectedDomains.length > 0 && itemName && itemValue) {
                    this.selectedDomains.forEach(item => {
                        item[itemName] = itemValue
                        axios
                            .put('domain/list/' + item.id + '/', item)
                            .catch(err => {
                                console.log(err)
                            })
                    })
                } else {
                    axios
                        .put('domain/list/' + row.id + '/', row)
                        .catch(err => {
                            console.log(err)
                        })
                }

            },

            checkIfInFilters(setValue1, setValue2, setValue3) {

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ( (key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue1) && value) ) {
                        return true;
                    }
                }
                return false;
            },
            checkIfInFiltersMinMax(setValue, setValue1, setValue2, setValue3) {
                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ((key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue || key === setValue1) && value) ) {
                        return true;
                    }
                }
                return false;
            },
            getMonthlyDomainsOrderStats() {
                axios
                    .get('monthly-domains-orders-stats')
                    .then(res => {
                        this.monthlyDomainsOrderStats = res.data.data
                        Object.keys(this.monthlyDomainsOrderStats[0]).forEach(header => {
                            this.columnsTableStat.push({
                                label: header,
                                field: header
                            })
                        })

                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            saveNotes(row) {
                axios
                    .put('domain/list/meta/' + row.id, row)
                    .then(() => {
                        this.$forceUpdate();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            //Method to copy Headers from Buld Add Window
            async copyHeadersToClipboard() {
                try {
                    await navigator.clipboard.writeText(this.bulkAddText);
                    // alert('Copied');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Copied!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } catch ($e) {
                    // alert('Cannot copy');
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Cannot copy!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                }
            },
            //Method to paste Data from clipboard to Bulk Add Window
            pasteFromClipboard() {
                navigator.clipboard.readText()
                    .then((clipText) => this.bulkAddText = clipText)
                    .then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Pasted!',
                            timer: 1000,
                            showConfirmButton: false,
                        })
                    })
            },
            //Method to prepare Data bulk add domains and send it to Server
            prepareGoogleDocDataToSend() {
                const sendArray = []
                const headerArray = this.clipboardData['0']
                const dataArray = []
                this.clipboardData.forEach((item, index) => {
                    if (index === 0) return;
                    dataArray.push(item)
                })
                dataArray.forEach(row => {
                    let obj = {}
                    row.forEach((item, indexOfItem) => {
                        obj[headerArray[indexOfItem]] = item
                    })
                    sendArray.push(obj)
                })
                // console.log(sendArray)
                this.loading = true;
                axios
                    .post('domain/bulk-upload/', sendArray)
                    .then(res => {
                        // console.log(res.data)
                        this.loading = false;
                        //Action to load main Data on mounted Component
                        if (_.size(this.returnFilters) > 0) {
                            this.$store.dispatch('getData', this.returnFilters);
                        } else {
                            this.$store.dispatch('getData', {});
                        }
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            html: 'Domains created: ' + res.data['domains created'] + '<br>' + 'Domains updated: ' + res.data['domains updated'] + '<br>' + 'Errors: ' + `${res.data['errors'].length > 0 ? res.data['errors'] : 'No errors'}`,
                            showConfirmButton: true,
                        })
                        this.bulkAddText = 'Domain\tLanguage 1\tLanguage 2\tSubject\tReferring domains\tTrust flow\tDomain authority\tPage authority\tStatus\tActive';
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Server error: ' + error.response.status,
                                text: 'Check if the sent data is correct',
                                // timer: 2000,
                                showConfirmButton: true,
                            })
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            // console.log(error.request);
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Server error:',
                                text: error.message,
                                // timer: 2000,
                                showConfirmButton: true,
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Server error: ',
                                text: error.request,
                                // timer: 2000,
                                showConfirmButton: true,
                            })
                            // Something happened in setting up the request that triggered an Error
                            // console.log('Error', error.message);
                        }
                        // console.log(error.config);
                    })

            },

            //Get data from copy textarea
            getDataFromClipboard() {
                this.clipboardData = []

                const clipboardLines = this.bulkAddText.split('\n');
                // console.log(clipboardLines)
                clipboardLines.map(line => {
                    // split the line into columns
                    const columns = line.split('\t');
                    // console.log(columns)
                    this.clipboardData.push(columns)
                });

            },

            //Method Show or Hide Filters
            // showClosePopover(item) {
            //     if (this.prevFilterName) {
            //         if (this.prevFilterName == item) {
            //             let currentPopover = document.querySelector(`${'#' + item}`)
            //             currentPopover.classList.remove("showPopover");
            //             this.prevFilterName = '';
            //         } else {
            //             document.querySelector(`${'#' + this.prevFilterName}`).classList.remove("showPopover")
            //             this.prevFilterName = item;
            //             let currentPopover = document.querySelector(`${'#' + item}`);
            //             currentPopover.classList.toggle("showPopover");
            //         }
            //     } else {
            //         this.prevFilterName = item;
            //         let currentPopover = document.querySelector(`${'#' + item}`);
            //         currentPopover.classList.toggle("showPopover");
            //     }
            // },
            showClosePopover(item) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    if (popover.id !== item) {
                        popover.classList.remove('showPopover');
                    }
                }

                const currentPopover = document.querySelector(`#${item}`);
                currentPopover.classList.toggle('showPopover');

                this.prevFilterName = this.prevFilterName === item ? '' : item;
            },


            //Pagination Previous Page
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Method to reset inputs fields when create new Domain
            resetSettings() {
                // this.checkedCatsForCreateDomain = [];
                this.inputs.name = this.inputs.language_alt = this.inputs.notes = '';
                this.inputs.subject = 'None';
                this.inputs.language = 'SE';
                this.inputs.status = 'NEW';
                this.inputs.referring_domains = this.inputs.trust_flow = this.inputs.domain_authority = this.inputs.page_authority = this.inputs.server_id = 0;
            },

            //Method to save changes when click OK button after edit domain
            saveEditDomainOk(domain) {
                // console.log('Edit domain - OK')
                // console.log(domain)
                this.editDomain(domain)
                // this.saveChanges(domain.id)
            },

            //Method to save changes when you edit domain
            async editDomain(domain) {
                await axios
                    .put('domain/list/' + domain.id + '/',
                        {
                            name: domain.name,
                            subject: domain.subject,
                            language: domain.language,
                            language_alt: domain.language_alt,
                            referring_domains: domain.referring_domains,
                            trust_flow: domain.trust_flow,
                            domain_authority: domain.domain_authority,
                            page_authority: domain.page_authority,
                            notes: domain.notes,
                            server_id: domain.server_id,
                            status: domain.status
                        }
                    ).then(() => {
                        // console.log(response)
                        // this.$store.dispatch('editDomain', response.data)
                        if (_.size(this.returnFilters) > 0) {
                            this.$store.dispatch('getData', this.returnFilters);
                        } else {
                            this.$store.dispatch('getData', {});
                        }
                        // this.loading = false;
                        // displayData[index].statusSaving = false
                    }).catch(error => {
                        // console.log(error)
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Something went wrong!',
                            text: error.data.status,
                            timer: 1000,
                            showConfirmButton: false,
                        })
                    });
            },

            //Method to Force Domain
            forceDomain(id) {
                // console.log(domain)
                axios
                    .get('update-single-report-crawl/' + id)
            },

            //Method to Replace new domain instead old one
            async replaceDomainOk() {
                this.loading = true
                // let newDomainReplacement = {}
                await axios.post('domain-replace/', {
                    data: {
                        oldDomain: this.oldDomainRepl,
                        newDomain: this.newDomainRepl
                    }
                }).then(response => {
                    // console.log(response.data)
                    let newDomainReplacement = response.data.domain_serializer;
                    let copyDomainReplacementAdd = response.data.new_domain_serializer;
                    this.$store.commit('updateDomain', newDomainReplacement)
                    this.$store.commit('createDomain', copyDomainReplacementAdd)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: 'Domain successfully replaced!',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                    this.oldDomainRepl = '';
                    this.newDomainRepl = '';
                }).catch((error) => {
                    this.loading = false
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.error,
                        timer: 2000,
                        showConfirmButton: false,
                    })
                    // console.log(error.response.data.error)
                })
            },

            //Method for button 'Domain Status Update'
            async domainStatusUpdate() {
                this.loading = true
                await axios
                    .get('domains-status-update-on-demand/')
                    .then(response => {
                        // console.log(response)
                        // this.$store.dispatch("fetchDomains");
                        if (_.size(this.returnFilters) > 0) {
                            this.$store.dispatch('getData', this.returnFilters);
                        } else {
                            this.$store.dispatch('getData', {});
                        }
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: response.data,
                            timer: 2000,
                            showConfirmButton: false,
                        })
                        this.loading = false
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // console.log(error.response.data);
                            console.log(error.response.status);
                            // alert(error.response.status)
                            // console.log(error.response.headers);
                        }
                    })

            },

            //Method to Add New Domain
            async addNewDomainOk() {
                if (this.inputs.name === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'Domain name field is required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })

                } else if (!this.inputs.name.includes('.')) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'Domain name has to be in a format: xxxx.xx',
                        // timer: 1000,
                        showConfirmButton: true,
                    })
                } else {

                    if (this.inputs.name === '') {
                        this.inputs.name = 'NoName'
                    }
                    if (this.inputs.referring_domains === '') {
                        this.inputs.referring_domains = 0
                    }
                    if (this.inputs.trust_flow === '') {
                        this.inputs.trust_flow = 0
                    }
                    if (this.inputs.domain_authority === '') {
                        this.inputs.domain_authority = 0
                    }
                    if (this.inputs.page_authority === '') {
                        this.inputs.page_authority = 0
                    }
                    this.loading = true;
                    axios.post('domain/list/', {
                        data: {
                            name: this.inputs.name,
                            subject: this.inputs.subject,
                            language: this.inputs.language,
                            language_alt: this.inputs.language_alt,
                            referring_domains: this.inputs.referring_domains,
                            trust_flow: this.inputs.trust_flow,
                            domain_authority: this.inputs.domain_authority,
                            page_authority: this.inputs.page_authority,
                            notes: this.inputs.notes,
                            server_id: this.inputs.server_id,
                            status: this.inputs.status
                        }
                    }).then(() => {
                        // this.$store.commit('createDomain', response.data);
                        if (_.size(this.returnFilters) > 0) {
                            this.$store.dispatch('getData', this.returnFilters);
                        } else {
                            this.$store.dispatch('getData', {});
                        }
                        this.loading = false;

                        // const domainID = response.data.id;
                        // this.saveChangesCreateDomain(domainID)
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Domain successfully added!',
                            timer: 1500,
                            showConfirmButton: false,
                        })

                        this.inputs.name = this.inputs.language_alt = this.inputs.notes = '';
                        this.inputs.subject = 'None';
                        this.inputs.language = 'SE';
                        this.inputs.status = 'NEW';
                        this.inputs.referring_domains = this.inputs.trust_flow = this.inputs.domain_authority = this.inputs.page_authority = this.inputs.server_id = 0;
                    }).catch((error) => {
                        if (error.response) {
                            this.loading = false;
                            this.$swal.fire({
                                icon: 'error',
                                title: error.response.data.name,
                                // text: 'Select at least one domain',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                            // alert(error.response.data.name);
                        }
                    })
                }
            },

            //Method to Restore Domain
            activateDomain(program) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You will restore domain",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, restore it!'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post('domain-list/' + program.id + '/', {
                                data: 'activate'
                            })
                            .then(response => {
                                if (response.status == 201) {
                                    program.is_active = true
                                    program.status = 'REACTIVATED'
                                    // if( _.size(this.returnFilters) > 0) {
                                    //     this.$store.dispatch('getData', this.returnFilters);
                                    // } else {
                                    //     this.$store.dispatch('getData', {});
                                    // }
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Activated',
                                        text: 'Domain successfully activated!',
                                        timer: 700,
                                        showConfirmButton: false,
                                    })
                                }

                                // console.log(statusResponse)
                            })
                            .catch((error) => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.data.status,
                                    timer: 2000,
                                    showConfirmButton: false,
                                })
                            });
                    }
                })
            },

            //Method to Remove Group Domains Permanent
            removeGroupDomain() {
                let groupDomainsIDs = [];

                if (!this.selectedDomains.length) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Select at least one domain',
                        // text: 'Select at least one domain',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } else {
                    this.$swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.selectedDomains.forEach(domain => {
                                groupDomainsIDs.push(domain.id)
                            })
                            this.loading = true;
                            axios
                                .delete('domain-bulk-deactivate/', {
                                    data: {data: groupDomainsIDs}
                                })
                                .then(() => {
                                    // this.$store.commit('removeGroupDomains', groupDomainsIDs)
                                    if (_.size(this.returnFilters) > 0) {
                                        this.$store.dispatch('getData', this.returnFilters);
                                    } else {
                                        this.$store.dispatch('getData', {});
                                    }
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Domains have been removed',
                                        // text: 'Select at least one domain',
                                        timer: 1000,
                                        showConfirmButton: false,
                                    })
                                }).finally(() => {
                                this.loading = false;
                            })
                        }
                    })
                }
            },

            //Method to Deactivate Group Domains
            deactivateGroupDomain() {
                let groupDomainsIDs = [];

                if (!this.selectedDomains.length) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Select at least one domain',
                        // text: 'Select at least one domain',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } else {
                    this.selectedDomains.forEach(domain => {
                        groupDomainsIDs.push(domain.id)
                    })
                    this.loading = true;
                    axios
                        .post('domain-bulk-deactivate/', {
                            data: groupDomainsIDs
                        })
                        .then(() => {
                            // this.$store.commit('deactivateGroupDomains', res.data)
                            if (_.size(this.returnFilters) > 0) {
                                this.$store.dispatch('getData', this.returnFilters);
                            } else {
                                this.$store.dispatch('getData', {});
                            }
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Domains have been deactivated',
                                // text: 'Select at least one domain',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        }).finally(() => {
                        this.loading = false;
                    })
                }


            },

            //Method to deactivate Domain
            deactivateDomain(program) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You will deactivate domain",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, deactivate it!'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .post('domain/list/' + program.id + '/', {
                                data: 'deactivate'
                            })
                            .then(response => {
                                if (response.status == 201) {
                                    program.is_active = false
                                    program.status = 'DEACTIVATED'
                                    // if( _.size(this.returnFilters) > 0) {
                                    //     this.$store.dispatch('getData', this.returnFilters);
                                    // } else {
                                    //     this.$store.dispatch('getData', {});
                                    // }
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Deactivated',
                                        text: 'Domain successfully deactivated!',
                                        timer: 700,
                                        showConfirmButton: false,
                                    })
                                }
                            })
                            .catch(error => {
                                console.log(error)
                            });

                    }
                })
            },

            //Method to get All langs
            getLangs() {
                let tempLang = [];
                axios
                    .get("language/list")
                    .then(response => {
                        // adding prop isEditable for each object
                        tempLang = response.data.results
                        tempLang.forEach(item => {
                            this.langs.push({name: item.short_name})
                            this.langsGeo.push({name: item.short_name})
                        })
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    });
            },

            //Method return random name to export excel table
            randomFileName() {
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                for (let i = 0; i < 5; i++)
                    text += possible.charAt(Math.floor(Math.random() * possible.length));

                return text;
            },

            //Method to export excel file
            async getExportFile() {
                let url = 'domain/export?&page_size=99999';
                let filename = 'domains_list.xls';

                this.loading = true;

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    console.log(`${key}: ${value}`);
                    url = url + '&' + key + '=' + value;
                }

                try {
                    const response = await axios.get(url, { responseType: 'blob' });
                    if (response.status === 200) {

                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                            if (filenameMatch != null && filenameMatch[1]) {
                                filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''));
                            }
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        console.log(`File '${filename}' downloaded successfully.`);
                    } else {
                        console.log('Failed to download the file.');
                    }
                } catch (error) {
                    console.error('Error occurred while downloading the file:', error);
                } finally {
                    this.loading = false;
                }
            },
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closePopovers);
        },
        async mounted() {
            document.addEventListener('click', this.closePopovers);
            // this.hideElementsOnBodyClick();
            this.getDataFromClipboard()

            if (this.$store.getters.isLoggedIn) {

                this.getMonthlyDomainsOrderStats();

                this.$store.commit('updateCurrentRoute', this.$route.name)
                //Mutation to clean filters data when you mounted Component
                this.$store.commit('cleanFilters')

                //Action to load main Data on mounted Component
                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }

                //Method to load language Data on mounted Component
                this.getLangs();
                // this.exportDisplayData();
            }


            let currentUser = localStorage.getItem('currentUser')
            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket?.send(JSON.stringify(this.message))
        },
        destroyed() {
            this.$store.commit('clearData');
        }
    }

</script>

<style lang="scss" scoped>
    .bulk-add-top-headers {
        max-width: 80%;
        margin: 0 auto;

        p {
            font-weight: 500;
        }
    }

    //Search Field
    .domains-search-field-wrapper {
        display: inline-block;
        width: 225px;
        position: relative;

    }

    .domains-search-field {
        /*position: relative;*/
        height: 24px;
        line-height: 24px;
        /*background: #FFFFFF;*/
        border: 1px solid #C9C9C9;
        border-radius: 2px;
        width: 225px;

        &:hover {
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }

    .domains-search-icon {
        position: absolute;
        left: 7px;
        top: 3px;
    }

    //Filter Button
    .domains-filter-button {
        background-color: #fff;
        margin-left: 15px;
    }

    //Download Button
    .domains-download-button {
        margin-left: 5px;
        background-color: #fff;
    }

    //Replace Button
    .domains-replace-button {
        background-color: #fff;
        margin-left: 5px;
    }

    //Bulk Button
    .domains-bulk-button {
        background: #FFFFFF;
        border: 1px solid #C9C9C9 !important;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        padding: 0 7px;

        margin-right: 14px;
        /*line-height: 15px;*/
    }

    //Status Update Button
    .domains-update-button {
        background: #FFFFFF;
        border: 1px solid #C9C9C9 !important;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        padding: 0 7px;
        /*line-height: 15px;*/
    }

    //Add New Domain Button
    .domains-adddomain-button {
        background-color: #DDDDE1;
        border-radius: 20px;
        color: #414048;
        padding: 5px 10px;

        &:hover {
            background: #CACACF;
        }

    }

    //Delete Domain Button
    .domains-delete-button {
        margin-left: 5px;
        background-color: #fff;
    }

    //Domain Name Button
    .domains-domainname-button {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /*line-height: 15px;*/
        /* identical to box height */
        color: #000000;
        background-color: #fff;
    }

    .selected-domains {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        ul {
            list-style: none;
            text-align: left;
            column-count: 3;
        }
    }

    .modal-body {
        column-count: 2 !important;
    }

    .domains-list {
        width: 100%;
        overflow: visible;

        @media screen and (max-width: 1150px) {
            overflow: scroll;
        }

        table {
            tr {
                th {
                    overflow: visible;
                    position: relative;
                }

                td {
                    vertical-align: middle;
                }
            }
        }
    }

    .domains-replace-wrapper {
        padding: 10px 0;
        display: flex;
        align-items: center;


        input {
            height: 30px;
            background-color: #eaeaea;

            &:nth-child(2) {
                background-color: #e8fae3;
            }
        }
    }

    /*Replace modal */
    #replace-modal {
        input {
            /*background-color: #3f485c;*/
            margin: 10px 0;
            /*color: #fff;*/
            background: rgba(63, 72, 92, .05);
            color: #3f485c;
            width: 100%;
            height: 40px;
            border-radius: 5px;

            &::placeholder {
                color: #3f485c;
                font-size: 14px;
                opacity: .7;
                /*font-size: 1.5em;*/
            }

        }
    }

    /*Domain Add Wrapper*/
    .newdomain-wrapper-fields {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-right: 10px;

        div {
            width: 48%;
            margin: 0 1%;
        }
    }

    .newdomain-wrapper-fields-edit {
        width: 48%;
        display: flex;
        flex-wrap: wrap;
        padding-right: 10px;

        div {
            width: 48%;
            margin: 0 1%;
        }
    }

    .newdomain-wrapper-checkboxes {
        border-left: 1px solid #dee2e6;
        padding-left: 10px;
        width: 48%;
    }

    .newdomain-wrapper-actions {
        /*border-top: 1px solid #dee2e6;*/
        padding-top: 10px;
        margin: 0 5px;
    }

    .newdomain-wrapper {
        /*max-width: 400px;*/
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        /*flex-direction: column;*/

        /*div {*/
        /*    width: 48%;*/
        /*    margin: 0 1%;*/
        /*}*/

        label {
            /*border-bottom: 2px solid #3f485c;*/
            margin-top: 15px;
            width: 100%;
            color: #3f485c;
            font-weight: 600;
        }

        input {
            /*background-color: #3f485c;*/
            background: rgba(63, 72, 92, .05);
            color: #3f485c;
            width: 100%;
            height: 40px;
            border-radius: 5px;

            &#cats {
                height: auto;
            }

            &#cats-add {
                height: auto;
            }

            &::placeholder {
                color: #3f485c;
                opacity: .7;
                font-size: 14px;
            }
        }

        textarea {
            /*background-color: #3f485c;*/
            background: rgba(63, 72, 92, .05);
            color: #3f485c;
            width: 100%;
            border-radius: 5px;
            padding-left: 20px;
            padding-top: 10px;
            border: none;

            &::placeholder {
                color: #3f485c;
                opacity: .7;
                font-size: 14px;
            }
        }

        select {
            /*background-color: #3f485c;*/
            background: rgba(63, 72, 92, .05);
            color: #3f485c;
            height: 40px;
            border-radius: 5px;
            width: 100%;
            text-align: center;
            border: none;

            &::placeholder {
                color: #fff;
                opacity: .7;
                font-size: 14px;
            }
        }
    }


    /*Domain Name New Functionality Styles*/
    .btn-domain {
        width: 100%;
    }

    .filters-popover {
        @media screen and (max-width: 1150px) {
            top: 55px;
        }
    }




</style>
