<template>
    <div class="container-fluid body-padding-top">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="table-title">Bugged Domains</h1>
            </div>
        </div>
        <div class="bugged-domains-list main-wrapper background-table">
            <div class="container-fluid">
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-sm-12 col-md-6" style="text-align: left;">
                        <GlobalSearch filter_type="search"/>
                    </div>
                    <div class="col-sm-12 col-md-6 actions-button" style="text-align: right;">
                        <button @click="resetFilters()" class="main-button m-md-2">Reset filters</button>
                        <button @click="getExportFile()" class="main-button">Export Excel File</button>
                    </div>
                </div>
                <template v-if="globalLoader || loading">
                    <div id="preloader">
                        <div id="loader_new"></div>
                    </div>
                </template>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="bugged-domains-table">
                            <v-table
                                    :data="sortedArrayByLatestOrders"
                                    :currentPage.sync="currentPage"
                                    :pageSize="-1"
                                    selectionMode="multiple"
                                    selectedClass="table-info"
                                    @totalPagesChanged="totalPages = $event"
                                    @selectionChanged="selectedDomains = $event"
                                    class="table table-responsive-md btn-table table-hover">
                                <thead slot="head">
                                <tr class="md-table-row">
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('domain__name__icontains', 'ordering', 'domain__name')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('name')"/>&nbsp; Domain Name
                                        <div class="filters-popover" id="name">
                                            <Sorting filter_type="ordering" filter_value="domain__name"/>
                                            <StringFieldFilter filter_type="domain__name__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('domain__geo__icontains', 'ordering', 'domain__geo')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('geo')"/>&nbsp; TLD
                                        <div class="filters-popover" id="geo">
                                            <Sorting filter_type="ordering" filter_value="domain__geo"/>
                                            <StringFieldFilter filter_type="domain__geo__icontains"/>
                                        </div>
                                    </th>
                                    <th>Latest Orders</th>
                                    <th>Notes</th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('error__icontains', 'ordering', 'error')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('error')"/>&nbsp; Error
                                        <div class="filters-popover" id="error">
                                            <Sorting filter_type="ordering" filter_value="error"/>
                                            <StringFieldFilter filter_type="error__icontains"/>
                                        </div>
                                    </th>
                                    <th>DNS</th>
                                    <th>IP</th>
                                    <th>SSL EXP</th>
                                    <th>SSL Status</th>
                                    <th>Who is</th>
                                    <th>Date Created</th>
                                </tr>
                                </thead>

                                <tbody slot="body" slot-scope="{displayData}">

                                <v-tr class="" v-for="row in displayData"
                                      :key="row.id" :row="row">
                                    <td>{{row.domain.name}}</td>
                                    <td>{{row.domain.geo}}</td>
                                    <td>
                                        <button v-b-modal="`modal-${row.id}`"
                                                class=""
                                                style="background: inherit;"
                                        >
                                            <font-awesome-icon icon="fa-regular fa-bags-shopping" class="fa-xl"/>
                                        </button>
                                        <b-modal :id="'modal-' + row.id" :title="'Latest Orders'">
                                            <v-table
                                                    :data="row.domain.latest_order"
                                                    class="table table-responsive-md btn-table table-hover"
                                            >
                                <thead slot="head">
                                <tr class="md-table-row">
                                    <th>Customer name</th>
                                    <th>Order Name</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                <template v-if="displayData.length">
                                    <v-tr class="" v-for="row in displayData"
                                          :key="row.id" :row="row">

                                        <td>{{row.customer}}</td>
                                        <td>{{row.name}}
                                            <button style="background: inherit;"
                                                    @click="copyHeadersToClipboard(row.name)">
                                                <font-awesome-icon icon="fa-solid fa-copy" class="fa-sm"/>
                                            </button>
                                        </td>
                                        <td>{{row.date_of_order}}</td>



                                    </v-tr>
                                </template>
                                <template v-else>
                                    <td>No orders</td>
                                </template>

                                </tbody>


                            </v-table>
                            </b-modal>
                            </td>
                            <td width="400 | 20%">
                                <template v-if="row.domain.notes.length">
                                    {{row.domain.notes.slice(0, 25) + '...'}}
                                </template>
                                <template v-else>

                                </template>
                                <button v-b-modal="'read-notes' + '-' + row.id"
                                        class="main-button"
                                        style="background: inherit; color: #0a1e61;"
                                >
                                    <template v-if="row.domain.notes.length">
                                        Read more
                                    </template>
                                    <template v-else>
                                        Add Notes
                                    </template>
                                </button>
                                <b-modal :id="'read-notes' + '-' + row.id" title="Notes"
                                         size="md"
                                         @ok="saveNotes(row)">
                                    <div>
                                                <textarea rows="10" cols="10" style="width: 100%;"
                                                          v-model="row.domain.notes"></textarea>
                                    </div>
                                </b-modal>
                            </td>
                            <td>{{row.error ? row.error : 'No errors'}}</td>
                            <td>{{row.dns ? row.dns : 'No dns'}}</td>
                            <td>{{row.ip ? row.ip : 'No ip'}}</td>
                            <td>{{row.ssl_exp ? row.ssl_exp : 'No data'}}</td>
                            <td>{{row.ssl_status ? row.ssl_status : 'No data'}}</td>
                            <td>{{row.who_is ? row.who_is : 'No data'}}</td>
                            <td>{{row.date_created ? row.date_created : 'No data'}}</td>
                            </v-tr>
                            </tbody>
                            </v-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="pagination-wrapper"
                             style="display: flex; justify-content: center; margin-bottom: 30px;">
                            <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                            <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GlobalSearch from "../components/filters/GlobalSearch";
    import StringFieldFilter from "../components/filters/StringFieldFilter";
    import Sorting from "../components/filters/Sorting";
    import {mapState} from "vuex";
    import axios from "axios";
    import _ from "lodash";
    import {eventBus} from "../main";

    export default {
        name: "BuggedDomainsDRF.vue",
        components: {
            GlobalSearch,
            StringFieldFilter,
            Sorting,
        },
        data() {
            return {
                loading: false,
                currentPage: 1,
                totalPages: 0,
            }
        },
        computed: {
            ...mapState({
                returnMainData: state => state.filters.mainData,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                returnCountItems: state => state.filters.countItems,
                globalLoader: state => state.filters.globalLoader,
            }),
            sortedArrayByLatestOrders() {
                // Map the items with their latest date_of_order
                const itemsWithLatestDates = this.returnMainData.map(item => {
                    const latestDate = Math.max(
                        ...item.domain.latest_order.map(order => new Date(order.date_of_order))
                    );
                    return { item, latestDate };
                });

                // Sort the items based on the latestDate in descending order
                return itemsWithLatestDates.sort((a, b) => b.latestDate - a.latestDate)
                    .map(item => item.item);
            },

        },
        methods: {
            //Method to copy Headers from Buld Add Window
            async copyHeadersToClipboard(text) {
                try {
                    await navigator.clipboard.writeText(text);
                    // alert('Copied');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Copied!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } catch ($e) {
                    // alert('Cannot copy');
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Cannot copy!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                }
            },
            saveNotes(row) {
                axios
                    .patch('bugged-domain/list/' + row.id, row)
                    .then(() => {
                        this.$forceUpdate();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            closePopovers(event) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    const popoverIcon = popover.previousElementSibling;
                    if (!popover.contains(event.target) && !popoverIcon.contains(event.target)) {
                        popover.classList.remove('showPopover');
                    }
                }
            },
            showClosePopover(item) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    if (popover.id !== item) {
                        popover.classList.remove('showPopover');
                    }
                }

                const currentPopover = document.querySelector(`#${item}`);
                currentPopover.classList.toggle('showPopover');

                this.prevFilterName = this.prevFilterName === item ? '' : item;
            },
            //Reset filters button
            resetFilters() {
                this.$store.commit('cleanFilters');
                this.$store.dispatch('getData', this.returnFilters)
            },
            checkIfInFilters(setValue1, setValue2, setValue3) {

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ((key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue1) && value)) {
                        return true;
                    }
                }
                return false;
            },
            //Pagination Previous Page
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },
            //Method to get export file
            async getExportFile() {
                let url = 'bugged-domain/export?&page_size=9000000';
                let filename = 'bugged-domains.xls';

                this.loading = true;

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    url = url + '&' + key + '=' + value;
                }

                try {
                    const response = await axios.get(url, {responseType: 'blob'});

                    if (response.status === 200) {

                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                            if (filenameMatch != null && filenameMatch[1]) {
                                filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''));
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    } else {
                        console.log('Failed to download the file.');
                    }
                } catch (error) {
                    console.error('Error occurred while downloading the file:', error);
                } finally {
                    this.loading = false;
                }
            },
        },
        async mounted() {
            document.addEventListener('click', this.closePopovers);

            if (this.$store.getters.isLoggedIn) {

                this.$store.commit('updateCurrentRoute', this.$route.name)
                //Mutation to clean filters data when you mounted Component
                this.$store.commit('cleanFilters')

                //Action to load main Data on mounted Component
                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }

            }

            eventBus.$on('usersOnline', payload => {
                this.usersOnline = payload
            });
            let currentUser = localStorage.getItem('currentUser');

            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket?.send(JSON.stringify(this.message))

        }
    }
</script>

<style scoped lang="scss">
    .bugged-domains-table {
        width: 100%;
    }

    .bugged-domains-table table tr th {
        position: relative;
        overflow: visible;
    }

    .bugged-domains-list {
        overflow-wrap: anywhere;
        overflow-x: unset;

    }

    .bugged-domains-table {
        @media screen and (max-width: 1385px) {
            overflow: scroll;
        }
    }

    .filters-popover {
        top: 35px;
        @media screen and (max-width: 1385px) {
            top: 35px;
        }
    }
</style>