<template>
  <div class="container-fluid body-padding-top">
    <div class="customers main-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-sm-12" style="padding-left: 0">
            <h1 class="table-title">Customer list</h1>
          </div>
        </div>
      </div>
      <div class="container background-table" style="padding-top: 10px">
        <!--Search Field -->
        <div class="row" style="margin-bottom: 10px">
          <div class="left-side col-sm-12 col-md-6" style="text-align: left">
            <!--Search Filed -->
            <GlobalSearch filter_type="search" />
          </div>
          <div class="col-sm-12 col-md-6 right-side" style="text-align: right">
            <button @click="resetFilters()" class="main-button m-md-2">
              Reset filters
            </button>
          </div>
        </div>
        <div class="row" style="margin-bottom: 20px">
          <div
            class="col-sm-12 col-md-6 under-side-left"
            style="text-align: left"
          >
            <!--Add New Customer Button -->
            <button
              id="tooltip-addnew-button"
              class="customers-addcustomer-button"
              v-b-modal.addnewcustomer-modal
            >
              New Customer
              <font-awesome-icon icon="fa-regular fa-plus" class="fa-sm" />
            </button>
            <b-tooltip target="tooltip-addnew-button" triggers="hover">
              Add new customer
            </b-tooltip>

            <b-modal
              id="addnewcustomer-modal"
              title="Add New Customer"
              size="lg"
              @ok="addNewCustomerOk()"
              @cancel="resetSettings"
              @close="resetSettings"
            >
              <div class="newcustomer-wrapper">
                <div class="newcustomer-wrapper-fields">
                  <div>
                    <label for="add-customer-name">Customer name:</label>
                    <input
                      id="add-customer-name"
                      class="column-input-search"
                      type="text"
                      v-model.lazy="inputs.customer_name"
                      placeholder="Type customer name..."
                    />
                  </div>
                  <div>
                    <label for="add-customer-site">Customer site:</label>
                    <input
                      id="add-customer-site"
                      class="column-input-search"
                      type="text"
                      v-model.lazy="inputs.customer_site"
                      placeholder="Type customer site..."
                    />
                  </div>
                  <div>
                    <label for="add-customer-lang">Language:</label>
                    <select id="add-customer-lang" v-model="inputs.language">
                      <option v-for="lang in langs" :key="lang.id">
                        {{ lang.name }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label for="add-customer-lang-alt">Language Alt:</label>
                    <select
                      id="add-customer-lang-alt"
                      v-model="inputs.language_alt"
                    >
                      <option v-for="lang in langsGeo" :key="lang.id">
                        {{ lang.name }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label for="add-customer-total-links">Total links:</label>
                    <input
                      id="add-customer-total-links"
                      type="text"
                      disabled
                      v-model.lazy="inputs.links_number"
                      style="background: #b0b0b1"
                    />
                  </div>

                  <div>
                    <label for="add-customer-month-links-number"
                      >Links/month:</label
                    >
                    <input
                      id="add-customer-month-links-number"
                      type="text"
                      v-model.lazy="inputs.month_links_to_order"
                    />
                  </div>

                  <div>
                    <label for="add-customer-start-date">Start date:</label>
                    <input
                      id="add-customer-start-date"
                      type="date"
                      v-model.lazy="inputs.start_date"
                    />
                  </div>

                  <div style="width: 100%">
                    <label for="add-customer-notes">Notes:</label>
                    <textarea
                      id="add-customer-notes"
                      name="notes"
                      cols="20"
                      rows="5"
                      v-model.lazy="inputs.notes"
                      placeholder="Type your notes..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-modal>
            <!--Deactivate Customers Button -->
          </div>
        </div>

        <template v-if="globalLoader || loading">
          <div id="preloader">
            <div id="loader_new"></div>
          </div>
        </template>

        <div class="row">
          <div class="col-sm-12">
            <p
              class="total-customers-counter"
              style="
                text-align: left;
                color: #000;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              "
            >
              Customers: {{ returnCountItems }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="customer-list main-list">
              <v-table
                :data="returnMainData"
                :currentPage.sync="currentPage"
                :pageSize="100"
                @totalPagesChanged="totalPages = $event"
                selectionMode="multiple"
                selectedClass="table-info"
                @selectionChanged="selectedCustomers = $event"
                class="table table-responsive-md btn-table table-hover"
              >
                <thead slot="head">
                  <tr class="md-table-row">
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        style="cursor: pointer"
                        :class="{
                          green: checkIfInFilters(
                            'customer_group__name__icontains',
                            'ordering',
                            'customer_group__name'
                          )
                        }"
                        @click="showClosePopover('customer_name')"
                      />&nbsp;Customer Name
                      <div class="filters-popover" id="customer_name">
                        <Sorting
                          filter_type="ordering"
                          filter_value="customer_group__name"
                        />
                        <StringFieldFilter
                          filter_type="customer_group__name__icontains"
                        />
                      </div>
                    </th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFilters(
                            'name__icontains',
                            'ordering',
                            'name'
                          )
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('customer_site')"
                      />&nbsp;Customer Site
                      <div class="filters-popover" id="customer_site">
                        <Sorting filter_type="ordering" filter_value="name" />
                        <StringFieldFilter filter_type="name__icontains" />
                      </div>
                    </th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFilters(
                            'language__icontains',
                            'ordering',
                            'language'
                          )
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('language')"
                      />&nbsp;Geo
                      <div class="filters-popover" id="language">
                        <Sorting
                          filter_type="ordering"
                          filter_value="language"
                        />
                        <StringFieldFilter filter_type="language__icontains" />
                      </div>
                    </th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFilters(
                            'language_alt__icontains',
                            'ordering',
                            'language_alt'
                          )
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('language_alt')"
                      />&nbsp;AltGeo
                      <div class="filters-popover" id="language_alt">
                        <Sorting
                          filter_type="ordering"
                          filter_value="language_alt"
                        />
                        <StringFieldFilter
                          filter_type="language_alt__icontains"
                        />
                      </div>
                    </th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFiltersMinMax(
                            'links_number__gte',
                            'links_number__lte',
                            'ordering',
                            'links_number'
                          )
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('total_links')"
                      />&nbsp;Total links
                      <div class="filters-popover" id="total_links">
                        <Sorting
                          filter_type="ordering"
                          filter_value="links_number"
                        />
                        <MinMaxFilter
                          filter_type_min="links_number__gte"
                          filter_type_max="links_number__lte"
                        />
                      </div>
                    </th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFiltersMinMax(
                            'month_links_to_order__gte',
                            'month_links_to_order__lte',
                            'ordering',
                            'month_links_to_order'
                          )
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('links_per_month')"
                      />&nbsp;Links per month
                      <div class="filters-popover" id="links_per_month">
                        <Sorting
                          filter_type="ordering"
                          filter_value="month_links_to_order"
                        />
                        <MinMaxFilter
                          filter_type_min="month_links_to_order__gte"
                          filter_type_max="month_links_to_order__lte"
                        />
                      </div>
                    </th>
                    <th>Notes</th>
                    <th>Keywords</th>
                    <th>Log</th>
                    <th>Report</th>
                    <th>
                      <font-awesome-icon
                        icon="fa-regular fa-bars-filter"
                        :class="{
                          green: checkIfInFilters('', 'ordering', 'status')
                        }"
                        style="cursor: pointer"
                        @click="showClosePopover('status')"
                      />&nbsp;Status
                      <div class="filters-popover" id="status">
                        <Sorting filter_type="ordering" filter_value="status" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody slot="body" slot-scope="{ displayData }">
                  <!--Rows start -->
                  <v-tr
                    v-for="(program, indexProgram) in displayData"
                    :key="indexProgram"
                    :row="program"
                  >
                    <td
                      v-if="
                        program &&
                        program.customer_group &&
                        Object.keys(program.customer_group).length
                      "
                    >
                      {{
                        program.customer_group.name
                          ? program.customer_group.name
                          : ""
                      }}
                    </td>
                    <td
                      v-if="
                        program &&
                        program.customer_group &&
                        Object.keys(program.customer_group).length
                      "
                    >
                      <button
                        v-b-modal="'edit-customer' + '-' + program.id"
                        class="domains-domainname-button"
                        style="background: inherit; color: #0a1e61"
                        :readonly="!program.isEditable"
                        :class="{ editable: program.isEditable }"
                      >
                        {{ program.name ? program.name : "" }}
                      </button>

                      <b-modal
                        :id="'edit-customer' + '-' + program.id"
                        title="Edit Customer"
                        size="lg"
                        @ok="saveEditCustomerOk(program)"
                      >
                        <div class="newcustomer-wrapper">
                          <div class="newcustomer-wrapper-fields">
                            <div>
                              <label for="edit-customer-name"
                                >Customer name:</label
                              >
                              <input
                                id="edit-customer-name"
                                class="column-input-search"
                                type="text"
                                v-model.lazy="program.customer_group.name"
                                placeholder="Type customer name..."
                              />
                            </div>
                            <div>
                              <label for="edit-customer-site"
                                >Customer site:</label
                              >
                              <input
                                id="edit-customer-site"
                                class="column-input-search"
                                type="text"
                                v-model.lazy="program.name"
                                placeholder="Type customer site..."
                              />
                            </div>
                            <div>
                              <label for="edit-customer-lang">Language:</label>
                              <select
                                id="edit-customer-lang"
                                v-model="program.language"
                              >
                                <option v-for="lang in langs" :key="lang.id">
                                  {{ lang.name }}
                                </option>
                              </select>
                            </div>

                            <div>
                              <label for="edit-customer-lang-alt"
                                >Language Alt:</label
                              >
                              <select
                                id="edit-customer-lang-alt"
                                v-model="program.language_alt"
                              >
                                <option v-for="lang in langsGeo" :key="lang.id">
                                  {{ lang.name }}
                                </option>
                              </select>
                            </div>

                            <div>
                              <label for="edit-customer-total-links"
                                >Total links:</label
                              >
                              <input
                                id="edit-customer-total-links"
                                type="text"
                                disabled
                                v-model="program.links_number"
                                style="background: #b0b0b1"
                              />
                            </div>

                            <div>
                              <label for="edit-customer-month-links-number"
                                >Links/month:</label
                              >
                              <input
                                id="edit-customer-month-links-number"
                                type="text"
                                disabled
                                v-model.lazy="program.month_links_number"
                              />
                            </div>

                            <div style="width: 100%">
                              <label for="edit-customer-notes">Notes:</label>
                              <textarea
                                id="edit-customer-notes"
                                name="notes"
                                cols="20"
                                rows="5"
                                v-model.lazy="program.notes"
                                placeholder="Type your notes..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </b-modal>
                    </td>
                    <!--Customer Site Button END -->
                    <td>
                      {{ program.language }}
                    </td>
                    <td>
                      {{ program.language_alt }}
                    </td>
                    <td>
                      {{ program.links_number }}
                    </td>
                    <td>
                      {{ program.month_links_to_order }}
                    </td>
                    <td>
                      {{ program.notes }}
                    </td>
                    <td>
                      <div style="display: flex">
                        <button
                          v-b-modal="`modal-keywords-${indexProgram}`"
                          style="background: inherit"
                          @click="getKeywordsById(program.id)"
                          v-if="program.keyword_set.length > 0"
                        >
                          <font-awesome-icon
                            icon="fa-light fa-key-skeleton"
                            class="fa-xl"
                          />
                        </button>
                        <b-modal
                          :id="'modal-keywords-' + indexProgram"
                          title="Customer Keywords"
                          size="xl"
                          class="keywords-modal"
                        >
                          <table
                            v-if="customerNameKeywords.length"
                            class="table table-responsive-md"
                            style="
                              font-size: 11px;
                              display: block !important;
                              overflow: scroll !important;
                            "
                          >
                            <thead>
                              <tr class="md-table-row">
                                <th
                                  v-for="(headerTitle, index) in Object.keys(
                                    customerNameKeywords['0']
                                  )"
                                  :key="index"
                                >
                                  {{ changeHeaderStyle(headerTitle) }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  itemData, index
                                ) in customerNameKeywords"
                                :key="index"
                                class="md-table-row"
                              >
                                <td
                                  v-for="(value, index) in Object.values(
                                    itemData
                                  )"
                                  :key="index"
                                >
                                  <template v-if="typeof value === 'object'">
                                    {{ value.name }}
                                  </template>
                                  <template v-else>
                                    {{ value }}
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-else>No keywords</div>
                        </b-modal>
                        <button
                          v-if="loadingUpdateIcon == false"
                          @click="updateCustomerKeyword(program.id)"
                          style="background: inherit"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-arrows-rotate"
                            class="fa-xl"
                          />
                        </button>
                        <button
                          :id="'update-' + program.id"
                          style="display: none; background: inherit"
                        >
                          <span class="loader-39"></span>
                        </button>
                      </div>
                    </td>
                    <td>
                      <button
                        v-b-modal="`modal-${program.id}`"
                        class=""
                        style="background: inherit"
                        @click="
                          showLastUpdatesModal($event, program.id, program.name)
                        "
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-telescope"
                          class="fa-xl"
                        />
                      </button>
                      <b-modal
                        :id="'modal-' + program.id"
                        :title="currentUpdatedCustomerName"
                      >
                        <template v-if="currentUpdatedCustomer">
                          <div style="height: 600px; overflow: scroll">
                            <div
                              v-for="(item, index) in currentUpdatedCustomer"
                              :key="index"
                              style="
                                border-bottom: 1px solid #000;
                                margin-bottom: 30px;
                              "
                            >
                              <p
                                style="
                                  background-color: #eebe78;
                                  margin-bottom: 5px;
                                  padding: 0 10px;
                                  border-radius: 10px;
                                "
                              >
                                <strong>Updated date:</strong>
                                {{
                                  new Date(
                                    item.date_created
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit"
                                  })
                                }}
                              </p>
                              <p
                                style="
                                  background-color: #cdcccc;
                                  margin-bottom: 10px;
                                  padding: 0 10px;
                                  border-radius: 10px;
                                "
                              >
                                <strong>User:</strong>
                                {{ item.created_by }}
                              </p>
                              <p style="margin-bottom: 5px">
                                <strong>Changes:</strong>
                              </p>
                              <p
                                style="
                                  background-color: #99c2af;
                                  margin-bottom: 5px;
                                  padding: 0 10px;
                                  border-radius: 10px;
                                "
                              >
                                {{ item.message }}
                              </p>
                            </div>
                          </div>
                        </template>
                        <template
                          v-else-if="currentUpdatedCustomer == 'loading'"
                        >
                          <p>Loading... Please wait a second</p>
                        </template>
                        <template v-else>
                          <p>No Data</p>
                        </template>
                      </b-modal>
                    </td>
                    <td
                      v-if="
                        program &&
                        program.customer_group &&
                        Object.keys(program.customer_group).length
                      "
                    >
                      <div style="display: flex; align-items: center">
                        <button
                          v-if="!reportLinkLoadingStatus"
                          class=""
                          style="background-color: inherit; margin: 0 5px"
                          :id="'tooltip-latest-report' + indexProgram"
                          v-on:click="
                            getLastGoogleLink($event, program.customer_group.id)
                          "
                        >
                          <font-awesome-icon
                            icon="fa-light fa-file-plus "
                            class="fa-xl"
                          />
                        </button>
                        <b-tooltip
                          :target="'tooltip-latest-report' + indexProgram"
                          triggers="hover"
                          placement="left"
                        >
                          Update
                        </b-tooltip>
                        <a
                          v-if="
                            (!reportLinkLoadingStatus &&
                              program.customer_group.report_doc_url) ||
                            currentReportLinks[program.customer_group.id]
                          "
                          :href="
                            program.customer_group.report_doc_url
                              ? program.customer_group.report_doc_url
                              : currentReportLinks[program.customer_group.id]
                          "
                          :id="'tooltip-download-report' + indexProgram"
                          class=""
                          style="
                            background-color: inherit;
                            color: #000;
                            margin: 0 5px;
                          "
                          target="_blank"
                        >
                          <font-awesome-icon
                            icon="fa-light fa-folder"
                            class="fa-xl"
                          />
                        </a>
                        <b-tooltip
                          :target="'tooltip-download-report' + indexProgram"
                          triggers="hover"
                          placement="right"
                        >
                          Open
                        </b-tooltip>
                        <div
                          style="
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <span
                            v-if="reportLinkLoadingStatus"
                            class="loader-39"
                          ></span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <template v-if="program.is_active">Active</template>
                      <template v-else>Inactive</template>
                    </td>
                  </v-tr>
                </tbody>
              </v-table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div
              class="pagination-wrapper"
              style="
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
              "
            >
              <button
                v-if="returnPrevPage"
                @click="prevPage"
                class="main-button"
              >
                Previous
              </button>
              <button
                v-if="returnNextPage"
                @click="nextPage"
                class="main-button"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import _ from "lodash";
import StringFieldFilter from "../components/filters/StringFieldFilter";
import Sorting from "../components/filters/Sorting";
import MinMaxFilter from "../components/filters/MinMaxFilter";
import GlobalSearch from "../components/filters/GlobalSearch";
export default {
  name: "Customers",

  components: { GlobalSearch, MinMaxFilter, StringFieldFilter, Sorting },
  data() {
    return {
      selectedCustomers: [],
      searchText: "",
      isBlockedButtons: false,
      currentReportLinks: {},
      message: { message: {}, action: {} },
      currentPage: 1,
      totalPages: 0,
      inputs: {
        customer_name: "",
        customer_site: "",
        language: "SE",
        language_alt: "",
        links_number: 0,
        month_links_to_order: 0,
        start_date: this.getCurrentDate(),
        notes: ""
      },
      langs: [],
      langsGeo: [],
      loading: false,
      reportLinkLoadingStatus: false,
      currentUpdatedCustomer: [],
      currentUpdatedCustomerName: "",
      timer: null,
      predictionResults: [],
      customerNameKeywords: [],
      prevFilterName: "",
      loadingUpdateIcon: false
    };
  },
  computed: {
    ...mapState({
      returnMainData: (state) => state.filters.mainData,
      returnFilters: (state) => state.filters.filters,
      returnPrevPage: (state) => state.filters.prevPage,
      returnNextPage: (state) => state.filters.nextPage,
      returnCountItems: (state) => state.filters.countItems,
      globalLoader: (state) => state.filters.globalLoader
    })
  },
  methods: {
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
      const day = now.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    closePopovers(event) {
      const popovers = document.querySelectorAll(".filters-popover");
      for (let i = 0; i < popovers.length; i++) {
        const popover = popovers[i];
        const popoverIcon = popover.previousElementSibling;
        if (
          !popover.contains(event.target) &&
          !popoverIcon.contains(event.target)
        ) {
          popover.classList.remove("showPopover");
        }
      }
    },

    showClosePopover(item) {
      const popovers = document.querySelectorAll(".filters-popover");
      for (let i = 0; i < popovers.length; i++) {
        const popover = popovers[i];
        if (popover.id !== item) {
          popover.classList.remove("showPopover");
        }
      }

      const currentPopover = document.querySelector(`#${item}`);
      currentPopover.classList.toggle("showPopover");

      this.prevFilterName = this.prevFilterName === item ? "" : item;
    },

    //Reset filters button
    resetFilters() {
      this.$store.commit("cleanFilters");
      this.$store.dispatch("getData", this.returnFilters);
    },

    checkIfInFilters(setValue1, setValue2, setValue3) {
      for (const [key, value] of Object.entries(this.returnFilters)) {
        if (
          (key === setValue2 &&
            (value === setValue3 || value === "-" + setValue3)) ||
          (key === setValue1 && value)
        ) {
          return true;
        }
      }
      return false;
    },
    checkIfInFiltersMinMax(setValue, setValue1, setValue2, setValue3) {
      for (const [key, value] of Object.entries(this.returnFilters)) {
        if (
          (key === setValue2 &&
            (value === setValue3 || value === "-" + setValue3)) ||
          ((key === setValue || key === setValue1) && value)
        ) {
          return true;
        }
      }
      return false;
    },
    updateCustomerKeyword(customerID) {
      let updateKeywordStatus = document.querySelector(
        `${"#update-" + customerID}`
      );
      updateKeywordStatus.style.display = "block";
      this.loadingUpdateIcon = true;
      axios
        .get("update-customer-keywords/" + customerID)
        .then(() => {
          updateKeywordStatus.style.display = "none";
          this.loadingUpdateIcon = false;
        })
        .catch((error) => {
          console.log(error);
          updateKeywordStatus.style.display = "none";
          this.loadingUpdateIcon = false;
        });
    },
    changeHeaderStyle(title) {
      return (title.charAt(0).toUpperCase() + title.slice(1)).replace("_", " ");
    },
    //Get Keywords by Customer name ID
    getKeywordsById(customerID) {
      axios
        .get("keyword/list?customer__id=" + customerID)
        .then((res) => {
          this.customerNameKeywords = res.data.results;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: "Something wrong",
            text: err,
            timer: 2200,
            showConfirmButton: false
          });
        });
    },

    //Pagination Previous Page
    prevPage() {
      this.loading = true;
      axios.get(this.returnPrevPage).then((res) => {
        this.$store.commit("updateData", res.data.results);
        this.$store.commit("updateCountItems", res.data.count);
        this.$store.commit("updatePrevPage", res.data.previous);
        this.$store.commit("updateNextPage", res.data.next);
        this.loading = false;
      });
    },

    //Pagination 'Next Page'
    nextPage() {
      this.loading = true;
      axios.get(this.returnNextPage).then((res) => {
        this.$store.commit("updateData", res.data.results);
        this.$store.commit("updateCountItems", res.data.count);
        this.$store.commit("updatePrevPage", res.data.previous);
        this.$store.commit("updateNextPage", res.data.next);
        this.loading = false;
      });
    },

    selectSearchItem(name) {
      this.inputs.customer_name = name;
    },

    saveEditCustomerOk(customer) {
      this.editItem(customer);
    },

    predictionSearch(name) {
      axios
        .get(
          "customer-group/list?fields=name,id&page_size=5&name__icontains=" +
            name
        )
        .then((res) => {
          this.predictionResults = res.data.results;
        });
    },
    predictionApply() {},

    // Using index of the current program to toggle the property isEditable
    editItem(customer) {
      this.loading = true;
      axios
        .put("customer/list/" + customer.id + "/", {
          name: customer.name,
          language: customer.language,
          language_alt: customer.language_alt,
          links_number: customer.links_number,
          month_links_number: customer.month_links_number,
          notes: customer.notes
        })
        .then(() => {
          if (_.size(this.returnFilters) > 0) {
            this.$store.dispatch("getData", this.returnFilters);
          } else {
            this.$store.dispatch("getData", {});
          }
          this.$swal.fire({
            icon: "success",
            title: "Changed",
            text: "Customer successfully changed!",
            timer: 1000,
            showConfirmButton: false
          });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Something wrong",
            text: error,
            timer: 2200,
            showConfirmButton: true
          });
        });
      axios
        .put("customer-group/list/" + customer.customer_group.id + "/", {
          name: customer.customer_group.name,
          customer_site: customer.customer_group.id
        })
        .then(() => {
          if (_.size(this.returnFilters) > 0) {
            this.$store.dispatch("getData", this.returnFilters);
          } else {
            this.$store.dispatch("getData", {});
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Reset Modal Window when create new Customer
    resetSettings() {
      this.checkedCatsForCreateDomain = [];
      this.inputs.customer_name =
        this.inputs.language_alt =
        this.inputs.notes =
          "";
      this.inputs.language = "SE";
      this.inputs.month_links_number = 0;
    },
    //Method to Add New Customer
    async addNewCustomerOk() {
      if (this.inputs.customer_name === "") {
        this.$swal.fire({
          icon: "error",
          title: "Field is empty",
          text: "Customer name field is required!",
          // timer: 1000,
          showConfirmButton: true
        });
      }
      if (this.inputs.customer_site === "") {
        this.$swal.fire({
          icon: "error",
          title: "Field is empty",
          text: "Customer site field is required!",
          showConfirmButton: true
        });
      } else if (!this.inputs.customer_site.includes(".")) {
        this.$swal.fire({
          icon: "error",
          title: "Field is empty",
          text: "Customer site has to be in a format: xxxx.xx",
          showConfirmButton: true
        });
      } else if (this.inputs.languages === "") {
        this.$swal.fire({
          icon: "error",
          title: "Field is empty",
          text: "Customer language field is required!",
          showConfirmButton: true
        });
      } else {
        if (this.inputs.customer_site === "") {
          this.inputs.customer_site = "Noname";
        }
        if (this.inputs.links_number === "") {
          this.inputs.links_number = 0;
        }
        if (this.inputs.month_links_number === "") {
          this.inputs.month_links_number = 0;
        }
        if (this.inputs.month_links_to_order === "") {
          this.inputs.month_links_to_order = 0;
        }
        this.loading = true;
        axios
          .post("customer-list/group/", {
            data: {
              customer_name: this.inputs.customer_name,
              name: this.inputs.customer_site,
              language: this.inputs.language,
              language_alt: this.inputs.language_alt,
              links_number: this.inputs.links_number,
              month_links_number: this.inputs.month_links_number,
              start_date: this.inputs.start_date,
              notes: this.inputs.notes
              // addCats: this.addCats
            }
          })
          .then(() => {
            this.loading = false;
            if (_.size(this.returnFilters) > 0) {
              this.$store.dispatch("getData", this.returnFilters);
            } else {
              this.$store.dispatch("getData", {});
            }
            this.$swal.fire({
              icon: "success",
              title: "Done",
              text: "Customer successfully added!",
              timer: 1000,
              showConfirmButton: false
            });
            this.inputs.customer_site =
              this.inputs.customer_name =
              this.inputs.notes =
                "";
          })
          .catch((error) => {
            if (error.response) {
              // alert(error.response.data.name);
              this.loading = false;
              this.$swal.fire({
                icon: "error",
                title: "Customer name",
                text: error.response.data.error,
                // timer: 1000,
                showConfirmButton: true
              });
            }
          });
      }
    },
    //Show last Updated for each customer
    showLastUpdatesModal(e, id, name) {
      e.preventDefault();
      this.currentUpdatedCustomerName = name;
      this.currentUpdatedCustomer = [];
      this.currentUpdatedCustomer = "loading";
      this.loading = true;
      axios.get("/customer-log/list" + "?id=" + id).then((res) => {
        this.loading = false;
        this.currentUpdatedCustomer = res.data.results[0].log;
      });
    },
    getLastGoogleLink(event, id) {
      event.preventDefault();
      this.reportLinkLoadingStatus = true;
      axios
        .get("update_single_report/" + id)
        .then((response) => {
          let currentID = id;
          // this.currentReportLinks[currentID] = response.data;
          if (response) {
            this.$set(this.currentReportLinks, currentID, response.data);
            this.reportLinkLoadingStatus = false;
            this.$swal.fire({
              icon: "success",
              title: "Done",
              text: "Report link successfully updated!",
              timer: 500,
              showConfirmButton: false
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          }
        });
    },
    randomFileName() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    numOfLinksNumber(filterValue, row) {
      return (
        row.links_number >= filterValue.min &&
        row.links_number <= filterValue.max
      );
    },
    numOfMonthLinksNumber(filterValue, row) {
      return (
        row.month_links_number >= filterValue.min &&
        row.month_links_number <= filterValue.max
      );
    },
    numOfMonthLinksToOrder(filterValue, row) {
      return (
        row.month_links_to_order >= filterValue.min &&
        row.month_links_to_order <= filterValue.max
      );
    },
    nameLength(program) {
      return program.name.length;
    },

    async getTopicsById(id) {
      this.topicsById = [];
      await axios
        .get("customer-topics-list-retrieve/" + id)
        .then((response) => {
          // adding prop isEditable for each object
          this.topicsById = response.data.reverse();
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          }
        });
      this.compareTopicsCheckStatus();
    },
    async getLangs() {
      let tempLang = [];
      await axios
        .get("language/list?page_size=9999")
        .then((response) => {
          // adding prop isEditable for each object
          tempLang = response.data.results;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          }
        });
      tempLang.forEach((item) => {
        this.langs.push({ name: item.short_name });
        this.langsGeo.push({ name: item.short_name });
      });
    },
    getCurrentMonth() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let d = new Date();
      let n = d.getMonth();
      return months[n].toLowerCase().substring(0, 3);
    },
    getCurrentYear() {
      return new Date().getFullYear().toString();
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closePopovers);
  },
  async mounted() {
    document.addEventListener("click", this.closePopovers);

    if (this.$store.getters.isLoggedIn) {
      this.$store.commit("updateCurrentRoute", this.$route.name);
      //Mutation to clean filters data when you mounted Component
      this.$store.commit("cleanFilters");

      //Action to load main Data on mounted Component
      if (_.size(this.returnFilters) > 0) {
        await this.$store.dispatch("getData", this.returnFilters);
      } else {
        await this.$store.dispatch("getData", {});
      }
      await this.getCurrentMonth();
      await this.getCurrentYear();
      await this.getLangs();
    }

    // this.getTopics();

    let currentUser = localStorage.getItem("currentUser");
    this.message.action[currentUser] = {
      routeName: this.$route.name
    };
    window.socket.send(JSON.stringify(this.message));
  },
  destroyed() {
    this.$store.commit("clearData");
  }
};
</script>

<style scoped lang="scss">
.customer-list {
  width: 100%;
  /*overflow-x: auto;*/
  overflow: visible;
  @media screen and (max-width: 1200px) {
    overflow: scroll;
  }

  table {
    tr {
      th {
        overflow: visible;
        position: relative;
      }

      td {
        vertical-align: middle;
      }
    }
  }
}

.filters-popover {
  @media screen and (max-width: 1200px) {
    top: 35px;
  }
}

//Search Field
.customers-search-field-wrapper {
  display: inline-block;
  width: 225px;
  position: relative;
}

.customers-search-field {
  /*position: relative;*/
  height: 24px;
  line-height: 24px;
  /*background: #FFFFFF;*/
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  width: 225px;

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.customers-search-icon {
  position: absolute;
  left: 7px;
  top: 3px;
}

//Replace Button
.customers-replace-button {
  background-color: #fff;
  margin-left: 5px;
}

//Filter Button
.customers-filter-button {
  background-color: #fff;
  margin-left: 15px;
}

//Add New Customer Button
.customers-addcustomer-button {
  background-color: #dddde1;
  border-radius: 20px;
  color: #414048;
  padding: 5px 10px;

  &:hover {
    background: #cacacf;
  }
}

//Delete Customer Button
.customers-delete-button {
  margin-left: 5px;
  background-color: #fff;
}

//Add New Customer Styles
.newcustomer-wrapper {
  /*max-width: 400px;*/
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  /*flex-direction: column;*/

  /*div {*/
  /*    width: 48%;*/
  /*    margin: 0 1%;*/
  /*}*/

  label {
    /*border-bottom: 2px solid #3f485c;*/
    margin-top: 15px;
    width: 100%;
    color: #3f485c;
    font-weight: 600;
  }

  input {
    /*background-color: #3f485c;*/
    background: rgba(63, 72, 92, 0.05);
    color: #3f485c;
    width: 100%;
    height: 40px;
    border-radius: 5px;

    &#cats {
      height: auto;
    }

    &#cats-add {
      height: auto;
    }

    &::placeholder {
      color: #3f485c;
      opacity: 0.7;
      font-size: 14px;
    }
  }

  textarea {
    /*background-color: #3f485c;*/
    background: rgba(63, 72, 92, 0.05);
    color: #3f485c;
    width: 100%;
    border-radius: 5px;
    padding-left: 20px;
    padding-top: 10px;
    border: none;

    &::placeholder {
      color: #3f485c;
      opacity: 0.7;
      font-size: 14px;
    }
  }

  select {
    /*background-color: #3f485c;*/
    background: rgba(63, 72, 92, 0.05);
    color: #3f485c;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    border: none;

    &::placeholder {
      color: #fff;
      opacity: 0.7;
      font-size: 14px;
    }
  }
}

/*Domain Add Wrapper*/
.newcustomer-wrapper-fields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;

  div {
    width: 48%;
    margin: 0 1%;
  }
}

.newcustomer-wrapper-fields-edit {
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;

  div {
    width: 48%;
    margin: 0 1%;
  }
}

.newcustomer-wrapper-checkboxes {
  border-left: 1px solid #dee2e6;
  padding-left: 10px;
  width: 48%;
}

.modal-body {
  column-count: 2;
  overflow: scroll;
}

#extand-up {
  display: none;
}

.report-link {
  display: inline-block;
  background-color: #5c636a !important;
  text-decoration: none !important;
  transition: all ease-in-out 0.2s;
  color: #fff !important;
  padding: 0 5px;

  &-done {
    background-color: forestgreen;
    /*transition: all ease-in-out 0.2s;*/
  }
}

$loader-color: orange;
.loader-39 {
  position: relative;
  width: 0.15em;
  height: 0.15em;
  background-color: $loader-color;
  border-radius: 100%;
  animation: loader-39-1 30s infinite linear;

  &:before,
  &:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 0.3em;
    height: 1em;
    animation: loader-39-2 0.8s linear infinite;
  }

  &:after {
    width: 1em;
    height: 0.3em;
    animation: loader-39-2 1.2s linear infinite;
  }
}

@keyframes loader-39-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-39-2 {
  0% {
    box-shadow: 0.04em -0.04em 0 0.02em $loader-color;
  }
  25% {
    box-shadow: 0.04em 0.04em 0 0.02em $loader-color;
  }
  50% {
    box-shadow: -0.04em 0.04em 0 0.02em $loader-color;
  }
  75% {
    box-shadow: -0.04em -0.04em 0 0.02em $loader-color;
  }
  100% {
    box-shadow: 0.04em -0.04em 0 0.02em $loader-color;
  }
}

/*#add-new-customer {*/
/*display: none;*/
/*}*/

/*Preloader for saving*/
.preloader-fountainTextG {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;

  .fountainTextG {
    color: rgb(0, 0, 0);
    font-family: Arial;
    font-size: 18px;
    text-decoration: none;
    font-weight: normal;
    font-style: normal;
    float: left;
    animation-name: bounce_fountainTextG;
    -o-animation-name: bounce_fountainTextG;
    -ms-animation-name: bounce_fountainTextG;
    -webkit-animation-name: bounce_fountainTextG;
    -moz-animation-name: bounce_fountainTextG;
    animation-duration: 2.09s;
    -o-animation-duration: 2.09s;
    -ms-animation-duration: 2.09s;
    -webkit-animation-duration: 2.09s;
    -moz-animation-duration: 2.09s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    transform: scale(0.5);
    -o-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
  }

  #fountainTextG_1 {
    animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
  }

  #fountainTextG_2 {
    animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    -ms-animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
  }

  #fountainTextG_3 {
    animation-delay: 1.05s;
    -o-animation-delay: 1.05s;
    -ms-animation-delay: 1.05s;
    -webkit-animation-delay: 1.05s;
    -moz-animation-delay: 1.05s;
  }

  #fountainTextG_4 {
    animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
  }

  #fountainTextG_5 {
    animation-delay: 1.35s;
    -o-animation-delay: 1.35s;
    -ms-animation-delay: 1.35s;
    -webkit-animation-delay: 1.35s;
    -moz-animation-delay: 1.35s;
  }

  #fountainTextG_6 {
    animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    -ms-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
  }

  #fountainTextG_7 {
    animation-delay: 1.64s;
    -o-animation-delay: 1.64s;
    -ms-animation-delay: 1.64s;
    -webkit-animation-delay: 1.64s;
    -moz-animation-delay: 1.64s;
  }

  #fountainTextG_8 {
    animation-delay: 1.8s;
    -o-animation-delay: 1.8s;
    -ms-animation-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
    -moz-animation-delay: 1.8s;
  }

  #fountainTextG_9 {
    animation-delay: 1.95s;
    -o-animation-delay: 1.95s;
    -ms-animation-delay: 1.95s;
    -webkit-animation-delay: 1.95s;
    -moz-animation-delay: 1.95s;
  }
}

@keyframes bounce_fountainTextG {
  0% {
    transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_fountainTextG {
  0% {
    -o-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -o-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_fountainTextG {
  0% {
    -ms-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -ms-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_fountainTextG {
  0% {
    -webkit-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_fountainTextG {
  0% {
    -moz-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -moz-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}
</style>
