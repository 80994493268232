import axios from "axios";

export default {
    actions: {
        async fetchCustomersComContent(ctx) {
            ctx.commit('updateLoadingStatusCustomers', true);
            // this.state.loadingCustomersData = true;
            await axios
                .get("customer-list-keyword/")
                .then(response => {
                    // adding prop isEditable for each object
                    // console.log(response.data)
                    const customers = response.data
                        .map((customer) => ({isEditable: false, ...customer}))
                        // .sort(function (a, b) {
                        //     return (
                        //         parseFloat(
                        //             b.counters[currentYear][currentMonth].links_left_to_order
                        //         ) -
                        //         parseFloat(
                        //             a.counters[currentYear][currentMonth].links_left_to_order
                        //         )
                        //     );
                        // })
                    ctx.commit('updateCustomersComContent', customers)
                    ctx.commit('updateLoadingStatusCustomers', false);


                })
                .catch(error => {
                    console.log(error)
                })

            // this.state.loadingCustomersData = false;

        },
        fetchKeywords(ctx, customerID) {
            axios
                .get("keyword-list-customer-id/" + customerID)
                .then((response) => {
                    const keywords = response.data;
                    ctx.commit('updateKeywords', keywords)
                })
                // .catch(error => console.log(error));
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });

            // console.log(this.providers)
        },
    },
    mutations: {
        updateLoadingStatusCustomers(state, status) {
          state.loadingStatusCustomers = status
        },
        updateCustomersComContent(state, customers) {
            state.customers = customers;
        },
        updateCustomersByMonthYear(state, {currentMonth, currentYear}) {
            console.log(state, currentMonth, currentYear)
            state.customers.filter(item => {
                return item.counters[currentYear][currentMonth].links_left_to_order > 0
            })
            state.customers.sort(function (a, b) {
                return (
                    parseFloat(
                        b.counters[currentYear][currentMonth].links_left_to_order
                    ) -
                    parseFloat(
                        a.counters[currentYear][currentMonth].links_left_to_order
                    )
                );
            })
        },
        updateKeywords(state, keywords) {
            state.keywords = keywords;
        },
        updateActiveCustomers(state, {activeCustomers, currentActiveUser}) {
            state.customers.forEach(customer => {
                activeCustomers.forEach(activeCustomer => {
                    if (customer.id == activeCustomer) {
                        customer.ordered_by = currentActiveUser;
                    }
                })
            })
        },
        removeActiveCustomers(state, id) {
            state.customers.forEach(customer => {
                if (customer.id == id) {
                    customer.ordered_by = '';
                }
            })
        },
        changeLoadingStatus(state, status) {
            state.loading = status;
        }
    },
    state: {
        customers: [],
        keywords: [],
        loading: false,
        // loadingCustomersData: false,
        loadingStatusCustomers: false
    },
    getters: {
        getCustomersComContent(state) {
            return state.customers;
        },
        getKeywords(state) {
            return state.keywords;
        },
        getLoadingStatus(state) {
            return state.loading;
        }
    }
}