import axios from "axios";
import _ from "lodash";
import Vue from 'vue'

export default {
    actions: {
        getData(ctx, filters) {
            if (ctx.state.timer) {
                clearTimeout(ctx.state.timer);
                ctx.commit('updateTimer', null)
            }
            ctx.state.timer = setTimeout(() => {
                let filterNames = Object.keys(filters)
                let filterValues = Object.values(filters)
                let requestStr = '';
                filterNames.forEach((name, index) => {
                    if (filterValues[index].includes(',') && filterValues[index].includes("-") && filterNames[index] != 'ordering' && filterNames[index] != 'month' && filterNames[index] != 'year'){

                        let strings = []
                        strings = filterValues[index].split(',')
                        strings.forEach((item) => {
                            if(item.charAt(0) == '-'){
                                requestStr += '&' + name + '_not=' + item
                            } else {
                                requestStr += '&' + name + '=' + item
                            }
                        })
                    }
                    else if(filterValues[index].charAt(0) == '-' && filterNames[index] != 'ordering' && filterNames[index] != 'month' && filterNames[index] != 'year'){
                        requestStr += '&' + name + '_not=' + filterValues[index]
                    }
                    else if(filterValues[index] === '') {
                        requestStr += '&' + name + ''
                    }
                    else {
                        requestStr += '&' + name + '=' + filterValues[index]
                    }
                })
                if(_.size(filters) > 0) {
                    ctx.commit('updateGlobalLoader', true);
                        axios
                            .get(ctx.state.currentRoute + requestStr)
                            .then(res => {
                                ctx.commit('updateData', res.data.results)
                                ctx.commit('updateCountItems', res.data.count)
                                ctx.commit('updatePrevPage', res.data.previous)
                                ctx.commit('updateNextPage', res.data.next)
                                ctx.commit('updateGlobalLoader', false)
                            })

                } else {
                    ctx.commit('updateGlobalLoader', true);
                        axios
                            .get(ctx.state.currentRoute)
                            .then(res => {
                                ctx.commit('updateData', res.data.results)
                                ctx.commit('updateCountItems', res.data.count)
                                ctx.commit('updatePrevPage', res.data.previous)
                                ctx.commit('updateNextPage', res.data.next)
                                ctx.commit('updateGlobalLoader', false)
                            })
                }
            }, 800);

        },
    },
    mutations: {
        clearData(state) {
          state.mainData = [];
        },
        updateData(state, newData) {
            if(newData.data && newData.totals) {
                state.mainData = [...newData.data, newData.totals]
            } else {
                state.mainData = newData
            }
        },
        updateDataTotalCalendar(state, newObject) {
            console.log(newObject)
            state.mainData.data.push(newObject)
        },
        updateCountItems(state, newCount) {
            state.countItems = newCount
        },
        updatePrevPage(state, prevPage) {
            state.prevPage = prevPage
        },
        updateNextPage(state, nextPage) {
            state.nextPage = nextPage
        },
        updateFilters(state, newFilter) {
            if(newFilter.keyValue) {
                state.filters[newFilter.keyValue] = newFilter.val
            } else if(newFilter.keyValueMin) {
                state.filters[newFilter.keyValueMin] = newFilter.val
            } else if(newFilter.keyNameMonth) {
                state.filters[newFilter.keyNameMonth] = newFilter.valueMonth
            } else if(newFilter.keyNameYear) {
                state.filters[newFilter.keyNameYear] = newFilter.valueYear
            } else if(newFilter.keyNameLanguage) {
                state.filters[newFilter.keyNameLanguage] = newFilter.valueLanguage
            } else if(newFilter.pageSizeCount) {
                state.filters[newFilter.pageSizeCount] = newFilter.valuePageSizeCount
            } else {
                state.filters[newFilter.keyValueMax] = newFilter.val
            }
        },
        updateCurrentRoute(state, route) {
          state.currentRoute = route
        },
        cleanFilters(state) {
            state.filters = {}
        },
        updateTimer(state, newTimer) {
            state.timer = newTimer
        },
        updateGlobalLoader(state, status) {
            state.globalLoader = status
        },
        updateActivatedDomain(state, [id, newObj]) {
            const index = state.mainData.findIndex(obj => obj.id === id);
            if(index !== -1) {
                state.mainData[index] = newObj
            }
        },
        updateDataAfterChangeDev(state, newMetaInfo) {
            state.mainData.forEach(obj => {
                if(obj.id == newMetaInfo[1]) {
                        Vue.set(obj.meta_info, 'developer', newMetaInfo[0].developer)
                        Vue.set(obj.meta_info, 'checked_by', newMetaInfo[0].checked_by)
                }
            })
        }
    },
    state: {
        mainData: [],
        filters: {},
        countItems: 0,
        prevPage: '',
        nextPage: '',
        currentRoute: '',
        globalLoader: false,
        timer: null
    },
    getters: {
        // totalCalendarRow(state) {
        //     if (state.mainData.totals) {
        //         // state.mainData.data = state.mainData.data.push(state.mainData.totals)
        //         state.mainData.data.push(state.mainData.totals);
        //     }
        // },

        // activatedSitesData (state) {
        //     return state.mainData.map(program => ({status: (program.status && program.status === 'ACTIVATED') ? program.status.toUpperCase() : '', ...program}))
        // }
    }
}