<template>
    <div class="table-provider-wrapper">
        <h2 class="options-main-title title-font-style">Providers</h2>
        <v-table :data="providers"
                 :filters="filtersProvider"
                 :currentPage.sync="currentPageProvider"
                 :pageSize="5"
                 @totalPagesChanged="totalPagesProvider = $event"
                 class="table table-responsive-md btn-table">
            <thead slot="head">
            <tr>
                <th colspan="2"></th>
                <th>
                    Full name
                </th>
                <th>
                    Short Name
                </th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr>
                <td colspan="4">
                    <input placeholder="Filter by provider" class="form-control"
                           v-model="filtersProvider.providerName.value"/>
                </td>
            </tr>
            <tr class="options-add">
                <td colspan="2" class="domain-table__cell">
                    <button
                            style="width: auto; background: inherit;" class="btn btn-indigo btn-sm m-0"
                            id="tooltip-create-provider"
                            v-on:click="createProviders">
<!--                        <font-awesome-icon :icon="['fas', 'plus-square']"/>-->
                        <font-awesome-icon icon="fa-regular fa-plus" class="fa-xl" />
                    </button>
                    <b-tooltip target="tooltip-create-provider" triggers="hover">
                        Add provider
                    </b-tooltip>
                </td>
                <td class="options-table__cell"><input type="text" v-model="inputs_providers.name"
                                                       placeholder="Type full name..."></td>
                <td class="options-table__cell"><input type="text" v-model="inputs_providers.short_name"
                                                       placeholder="Type short name..."></td>

            </tr>

            <tr class=""
                v-for="(program, indexProgram) in displayData"
                :key="indexProgram">
                <td>
                    <button
                            @click="editItemProviders(indexProgram, displayData)"
                            class=""
                            style="background: inherit;"
                            :id="'tooltip-edit-provider' + indexProgram"

                    >
                        <font-awesome-icon v-if="!program.isEditable" icon="fa-light fa-edit" class="fa-lg"/>
                        <font-awesome-icon v-else icon="fa-light fa-save" class="fa-lg"/>

                    </button>
                    <b-tooltip :target="'tooltip-edit-provider' + indexProgram" triggers="hover">
                        Edit
                    </b-tooltip>
                </td>
                <td>
                    <button class="" type="button" value="Remove" style="background: inherit;"
                              :id="'tooltip-remove-provider' + indexProgram"
                              v-on:click="removeProviders(program)" variant="danger">
<!--                        <font-awesome-icon :icon="['fas', 'trash']"/>-->
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />
                    </button>
                    <b-tooltip :target="'tooltip-remove-provider' + indexProgram" triggers="hover">
                        Remove
                    </b-tooltip>
                </td>
                <td v-for="(column, indexColumn) in columns_providers" :key="indexColumn">
                    <input
                            v-model.lazy="program[column]"
                            :readonly="!program.isEditable"
                            :class="{ 'editable': program.isEditable }"
                    >

                </td>
            </tr>
            </tbody>
        </v-table>
        <smart-pagination
                :currentPage.sync="currentPageProvider"
                :totalPages="totalPagesProvider"
                :maxPageLinks="5"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import { mapState } from 'vuex';

    export default {
        name: "ProviderTable",
        data() {
            return {
                // providers: [],
                currentPageProvider: 1,
                totalPagesProvider: 0,
                filtersProvider: {
                    providerName: {value: '', keys: ['name']},
                },
                columns_providers: [
                    'name', 'short_name'
                ],
                inputs_providers: {
                    name: '',
                    short_name: ''
                },
                counter: 0,
                isBlockedButtons: false
            }
        },
        computed: {
            ...mapState({
                providers: state => state.providerList.providers
            })
        },
        methods: {
            createProviders: function () {

                if (this.inputs_providers.name === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'Provider name field is required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })

                } else {

                    axios.post('content-provider-list/', {
                        data: {
                            name: this.inputs_providers.name,
                            short_name: this.inputs_providers.short_name
                        }
                    }).then(res => {

                        const newProvider = res.data
                        newProvider.isEditable = false


                        this.$store.commit('createProvider', newProvider)

                        this.inputs_providers.name = this.inputs_providers.short_name = '';
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Provider successfully added!',
                            timer: 1000,
                            showConfirmButton: false,
                        })
                    })
                }
            },
            removeProviders: function (program) {
                let currentElArr = this.providers.indexOf(program);

                axios
                    .delete('content-provider-list/' + program.id)
                    .then(() => {
                            this.$store.commit('removeProvider', currentElArr)

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Removed',
                                text: 'Provider successfully removed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        }
                    )
                    .catch(error => {
                        console.log(error)
                    });
            },
            editItemProviders(index, displayData) {
                if (!displayData[index].isEditable) {

                    if (this.isBlockedButtons && !displayData[index].isEditable) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Stop",
                            text: "Save previous field before editing next fields",
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                        return false;
                    }

                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = true;

                } else {
                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = false;
                    axios
                        .put('content-provider-list/' + displayData[index].id + '/', {
                            data: {
                                name: displayData[index].name,
                                short_name: displayData[index].short_name,
                            }
                        })
                        .then(
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Changed',
                                text: 'Provider successfully changed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        )
                        .catch(error => {
                            console.log(error)
                        });
                }
            }
        },
        created() {}
    }
</script>

<style scoped>

</style>