<template>
    <div class="container-fluid body-padding-top">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="table-title">Content list</h1>
            </div>
        </div>
        <div class="content-list main-wrapper background-table">
            <div class="container-fluid">
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-sm-12 col-md-6" style="text-align: left;">
                        <GlobalSearch filter_type="search"/>
                    </div>
                    <div class="col-sm-12 col-md-6 actions-button" style="text-align: right;">
                        <button @click="resetFilters()" class="main-button m-md-2">Reset filters</button>
                        <button @click="getExportFile()" class="main-button">Export Excel File</button>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-sm-12">
                        <div v-if="selectedDomains.length" class="content-action-buttons" style="text-align: left;">
                            <button class="domains-delete-button" type="button" value="Remove"
                                    style="background: inherit;"
                                    id="tooltip-deactivate-group-domains-button"
                                    v-on:click.left.exact="deactivateGroupDomain"
                            >
                                <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg"/>
                            </button>
                            <b-tooltip target="tooltip-deactivate-group-domains-button" triggers="hover">
                                Deactivate domains
                            </b-tooltip>
                        </div>
                    </div>
                </div>
                <template v-if="globalLoader || loading">
                    <div id="preloader">
                        <div id="loader_new"></div>
                    </div>
                </template>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="content-table">
                            <v-table
                                    :data="returnMainData"
                                    :currentPage.sync="currentPage"
                                    :pageSize="100"
                                    selectionMode="multiple"
                                    selectedClass="table-info"
                                    @totalPagesChanged="totalPages = $event"
                                    @selectionChanged="selectedDomains = $event"
                                    class="table table-responsive-md btn-table table-hover">
                                <thead slot="head">
                                <tr class="md-table-row">
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('name__icontains', 'ordering', 'name')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('name')"/>&nbsp; Name
                                        <div class="filters-popover" id="name">
                                            <Sorting filter_type="ordering" filter_value="name"/>
                                            <StringFieldFilter filter_type="name__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('customer_id__name__icontains', 'ordering', 'customer_id__name')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('customer_name')"/>&nbsp; Customer Name
                                        <div class="filters-popover" id="customer_name">
                                            <Sorting filter_type="ordering" filter_value="customer_id__name"/>
                                            <StringFieldFilter filter_type="customer_id__name__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('domain_id__name__icontains', 'ordering', 'domain_id__name')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('domain_name')"/>&nbsp; Domain Name
                                        <div class="filters-popover" id="domain_name">
                                            <Sorting filter_type="ordering" filter_value="domain_id__name"/>
                                            <StringFieldFilter filter_type="domain_id__name__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFiltersMinMax('wordpress_publish_date__gte','wordpress_publish_date__lte', 'ordering', 'wordpress_publish_date')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('wp_publish_date')"/>&nbsp;WP Publish Date
                                        <div class="filters-popover" id="wp_publish_date">
                                            <Sorting filter_type="ordering" filter_value="wordpress_publish_date"/>
                                            <MinMaxFilterDate filter_type_min="wordpress_publish_date__gte"
                                                          filter_type_max="wordpress_publish_date__lte"/>
                                        </div></th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('language__icontains', 'ordering', 'language')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('language')"/>&nbsp; Language
                                        <div class="filters-popover" id="language">
                                            <Sorting filter_type="ordering" filter_value="language"/>
                                            <StringFieldFilter filter_type="language__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('topic__icontains', 'ordering', 'topic')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('topic')"/>&nbsp; Topic
                                        <div class="filters-popover" id="topic">
                                            <Sorting filter_type="ordering" filter_value="topic"/>
                                            <StringFieldFilter filter_type="topic__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('date_of_order__icontains', 'ordering', 'date_of_order')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('date_of_order')"/>&nbsp; Month of Order
                                        <div class="filters-popover" id="date_of_order">
                                            <Sorting filter_type="ordering" filter_value="date_of_order"/>
<!--                                            <StringFieldFilter filter_type="month_of_order__icontains"/>-->
                                            <MinMaxFilterDate filter_type_min="date_of_order__gte"
                                                              filter_type_max="date_of_order__lte"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('keyword__icontains', 'ordering', 'keyword')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('keyword')"/>&nbsp; Keyword
                                        <div class="filters-popover" id="keyword">
                                            <Sorting filter_type="ordering" filter_value="keyword"/>
                                            <StringFieldFilter filter_type="keyword__icontains"/>
                                        </div>
                                    </th>
                                    <th>Exact Match Link</th>
                                    <th>Phrase Match Link</th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('landing_page__icontains', 'ordering', 'landing_page')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('landing_page')"/>&nbsp; Landing page
                                        <div class="filters-popover" id="landing_page">
                                            <Sorting filter_type="ordering" filter_value="landing_page"/>
                                            <StringFieldFilter filter_type="landing_page__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('internal_link__icontains', 'ordering', 'internal_link')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('internal_link')"/>&nbsp; Internal Link
                                        <div class="filters-popover" id="internal_link">
                                            <Sorting filter_type="ordering" filter_value="internal_link"/>
                                            <StringFieldFilter filter_type="internal_link__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('external_link__icontains', 'ordering', 'external_link')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('external_link')"/>&nbsp; External Link
                                        <div class="filters-popover" id="external_link">
                                            <Sorting filter_type="ordering" filter_value="external_link"/>
                                            <StringFieldFilter filter_type="external_link__icontains"/>
                                        </div>
                                    </th>
                                    <th>
                                        <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                           :class="{ green: checkIfInFilters('text_link__icontains', 'ordering', 'text_link')  }"
                                                           style="cursor: pointer;"
                                                           @click="showClosePopover('text_link')"/>&nbsp; Text Link
                                        <div class="filters-popover" id="text_link">
                                            <Sorting filter_type="ordering" filter_value="text_link"/>
                                            <StringFieldFilter filter_type="text_link__icontains"/>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                <v-tr class="" v-for="row in displayData"
                                      :key="row.id" :row="row">
                                    <td>{{row.name}}</td>
                                    <template v-if="row.customer_id === null">
                                        <td>None</td>
                                    </template>
                                    <template v-else>
                                        <td>{{row.customer_id.name}}</td>
                                    </template>
                                    <template v-if="row.domain_id === null">
                                        <td>None</td>
                                    </template>
                                    <template v-else>
                                        <td>{{row.domain_id.name}}</td>
                                    </template>
                                    <td>{{row.wordpress_publish_date}}</td>
                                    <td>{{row.language}}</td>
                                    <td>{{row.topic}}</td>
                                    <td>{{dateFilter(row.date_of_order)}}</td>
                                    <td>{{row.keyword ? row.keyword : 'None'}}</td>
                                    <td>
                                        {{row.exact_match_link ? row.exact_match_link : 'None'}}
                                    </td>
                                    <td>
                                        {{row.phrase_match_link}}
                                    </td>
                                    <td>
                                        {{row.landing_page ? row.landing_page : 'None'}}
                                    </td>
                                    <td>{{row.internal_link ? row.internal_link : 'None'}}</td>
                                    <td>{{row.external_link ? row.external_link : 'None'}}</td>
                                    <td>{{row.text_link ? row.text_link : 'None'}}</td>
                                </v-tr>
                                </tbody>
                            </v-table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="pagination-wrapper"
                             style="display: flex; justify-content: center; margin-bottom: 30px;">
                            <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                            <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from "moment";
    import JQuery from 'jquery';
    import {eventBus} from '../main'
    import {mapState} from "vuex";
    import _ from "lodash";
    import GlobalSearch from "../components/filters/GlobalSearch";
    import StringFieldFilter from "../components/filters/StringFieldFilter";
    import Sorting from "../components/filters/Sorting";
    import MinMaxFilterDate from "../components/filters/MinMaxFilterDate";

    window.$ = JQuery


    export default {
        name: "ContentDRF",
        components: {
            GlobalSearch, StringFieldFilter, Sorting, MinMaxFilterDate
        },
        data() {
            return {
                selectedDomains: [],
                searchText: '',
                searchResult: [],
                monthSearch: '',
                counter: 0,
                usersOnline: [],
                message: {'message': {}, 'action': {}},
                currentRoute: '',
                username: '',
                startDate: '2015-01-01',
                finishDate: moment(new Date()).endOf('month').format("YYYY-MM-DD"),
                loading: false,
                loadingTable: false,
                activeButton: '',
                mainDataTable: [],
                excelData: [],
                excelDataReportLinks: [],
                currentPage: 1,
                totalPages: 0,
                columns: [
                    {
                        label: "Name",
                        field: "name"
                    },
                    {
                        label: "Customer Name",
                        field: "customer_name"
                    },
                    {
                        label: "Domain Name",
                        field: "domain_name"
                    },
                    {
                        label: "Wordpress Publish Date",
                        field: "wordpress_publish_date"
                    },
                    {
                        label: "Language",
                        field: "language"
                    },
                    {
                        label: "Topic",
                        field: "topic"
                    },
                    {
                        label: "Month of Order",
                        field: "month_of_order"
                    },
                    {
                        label: "Keyword",
                        field: "keyword"
                    },
                    {
                        label: "Exact Match Link",
                        field: "exact_match_link"
                    },
                    {
                        label: "Phrase Match Links",
                        field: "phrase_match_link"
                    },
                    {
                        label: "Landing Page",
                        field: "landing_page"
                    },
                    {
                        label: "Internal Link",
                        field: "internal_link"
                    },
                    {
                        label: "Internal Links Percent",
                        field: "internal_links_percent"
                    },
                    {
                        label: "External Link",
                        field: "external_link"
                    },
                    {
                        label: "Text Link",
                        field: "text_link"
                    },
                    {
                        label: "Require Trust Link",
                        field: "require_trust_link"
                    },
                    {
                        label: "Trust Link NoFollow Percent",
                        field: "trust_link_nofollow_percent"
                    },
                    {
                        label: "number of images",
                        field: "number_of_images"
                    },
                    {
                        label: "Publish URL",
                        field: "domain_name"
                    },
                    {
                        label: "Wordpress Publish Url",
                        field: "wordpress_publish_url"
                    },
                    {
                        label: "Wordpress Publish Status",
                        field: "wordpress_publish"
                    },
                    {
                        label: "Commercial/Starter",
                        field: "commercial"
                    }
                ],
                starterColumns: [
                    {
                        label: "Topic",
                        field: "topic"
                    },
                    {
                        label: "Keyword",
                        field: "keyword"
                    },
                    {
                        label: "Link Text",
                        field: "link_text"
                    },
                    {
                        label: "Link Url",
                        field: "link_url"
                    },
                    {
                        label: "Publish Url",
                        field: "wordpress_publish_url"
                    },
                    {
                        label: "Batch",
                        field: "batch"
                    },
                ],
                reportLinksColumns: [
                    {
                        label: "Month/Year",
                        field: 'month'
                    },
                    {
                        label: "Keyword",
                        field: "keyword"
                    },
                    {
                        label: "Anchor text",
                        field: "text_link"
                    },
                    {
                        label: "Landing page",
                        field: "landing_page"
                    },
                    {
                        label: "URL",
                        field: "url"
                    },
                    {
                        label: "Domain",
                        field: "domain_name"
                    }

                ],
                prevFilterName: '',
            }
        },
        computed: {
            ...mapState({
                returnMainData: state => state.filters.mainData,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                returnCountItems: state => state.filters.countItems,
                globalLoader: state => state.filters.globalLoader,
            }),

        },
        methods: {
            async getExportFile() {
                let url = 'content/export?&page_size=9000000';
                let filename = 'content_list.xls';

                this.loading = true;

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    console.log(`${key}: ${value}`);
                    url = url + '&' + key + '=' + value;
                }

                try {
                    const response = await axios.get(url, { responseType: 'blob' });
                    if (response.status === 200) {

                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                            if (filenameMatch != null && filenameMatch[1]) {
                                filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''));
                            }
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        console.log(`File '${filename}' downloaded successfully.`);
                    } else {
                        console.log('Failed to download the file.');
                    }
                } catch (error) {
                    console.error('Error occurred while downloading the file:', error);
                } finally {
                    this.loading = false;
                }
            },
            closePopovers(event) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    const popoverIcon = popover.previousElementSibling;
                    if (!popover.contains(event.target) && !popoverIcon.contains(event.target)) {
                        popover.classList.remove('showPopover');
                    }
                }
            },


            showClosePopover(item) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    if (popover.id !== item) {
                        popover.classList.remove('showPopover');
                    }
                }

                const currentPopover = document.querySelector(`#${item}`);
                currentPopover.classList.toggle('showPopover');

                this.prevFilterName = this.prevFilterName === item ? '' : item;
            },
            //Reset filters button
            resetFilters() {
                this.$store.commit('cleanFilters');
                this.$store.dispatch('getData', this.returnFilters)
            },
            checkIfInFilters(setValue1, setValue2, setValue3) {

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ( (key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue1) && value) ) {
                        return true;
                    }
                }
                return false;
            },
            checkIfInFiltersMinMax(setValue, setValue1, setValue2, setValue3) {
                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ((key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue || key === setValue1) && value) ) {
                        return true;
                    }
                }
                return false;
            },
            //Pagination Previous Page
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },
            deactivateGroupDomain() {
                let groupDomainsIDs = [];

                if (!this.selectedDomains.length) {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Select at least one domain',
                        // text: 'Select at least one domain',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } else {
                    this.selectedDomains.forEach(domain => {
                        groupDomainsIDs.push(domain.id)
                    })
                    this.loading = true;
                    axios
                        .post('content-commercial-order-remove-multiple/', {
                            data: groupDomainsIDs
                        })
                        .then(() => {
                            groupDomainsIDs.forEach(domainIDtoRemove => {
                                this.mainDataTable = this.mainDataTable.filter(data => data.id != domainIDtoRemove)
                            })
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Domains have been removed',
                                // text: 'Select at least one domain',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                            this.selectedDomains = []
                            if (_.size(this.returnFilters) > 0) {
                                this.$store.dispatch('getData', this.returnFilters);
                            } else {
                                this.$store.dispatch('getData', {});
                            }
                        }).finally(() => {
                        groupDomainsIDs = []
                        this.loading = false;
                    })
                }
            },
            removeDomain(program) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You will remove domain",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        program.is_active = false
                        program.status = 'DEACTIVATED'
                        let statusResponse = null
                        axios
                            .delete('content-commercial-order-remove-single/' + program.id + '/')
                            .then(response => {
                                statusResponse = response.status
                                if (statusResponse == 200) {
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Deactivated',
                                        text: 'Domain successfully removed!',
                                        timer: 700,
                                        showConfirmButton: false,
                                    })
                                    let removeIndex = this.mainDataTable.map(function (item) {
                                        return item.id;
                                    }).indexOf(program.id)
                                    this.mainDataTable.splice(removeIndex, 1)
                                }
                                this.selectedDomains = []
                                if (_.size(this.returnFilters) > 0) {
                                    this.$store.dispatch('getData', this.returnFilters);
                                } else {
                                    this.$store.dispatch('getData', {});
                                }
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    }
                })
            },
            async updateContent(content) {
                if (this.counter == 0) {
                    // displayData[index].isEditable = !displayData[index].isEditable
                    content.isEditable = !content.isEditable
                    this.counter = 1;
                } else if (this.counter == 1) {
                    const data = Object.assign({}, content)
                    data.wordpress_publish_date = new Date(data.wordpress_publish_date).toISOString()
                    await axios.put('content-list/' + content.id + '/', {
                        data
                    })
                    this.counter = 0
                    content.isEditable = !content.isEditable
                }
            },
            sendRangeData() {
                this.loadingTable = true
                axios
                    .get("content-list-id/daterange/" + this.startDate + "/" + this.finishDate)
                    .then(response => {
                        this.mainDataTable = response.data.map(program => ({isEditable: false, ...program}))
                        this.mainDataTable.forEach(item => {
                            item.date_created = this.parseDateTime(item.date_created)
                            this.customerSites.forEach(item2 => {
                                if (item.customer_id == item2.id) {
                                    item.customer_name = item2.name
                                }
                            })
                            this.domains.forEach(item3 => {
                                if (item.domain_id == item3.id) {
                                    item.domain_name = item3.name
                                }
                            })
                        })
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.status);
                            if (error.response.status == 416 || error.response.status == 404) {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error.response.data.error,
                                    showConfirmButton: true,
                                })
                            }
                            if (error.response.status == 500) {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Check entered date range',
                                    showConfirmButton: true,
                                })
                            }
                        }
                    })
                    .finally(() => (this.loadingTable = false))
            },
            parseDateTime(time) {
                return moment(time).subtract(10, 'days').calendar()
            },
            chooseIDNew(currentID) {
                this.excelData.splice(0, this.excelData.length);
                this.getMainTable(currentID, this.picked);
            },
            checkCustomerName(customerID) {
                if (customerID !== null || customerID !== 0) {
                    let customerNames = this.customers.filter(item => {
                        return item.id === customerID
                    })
                    if (customerNames.length > 0) {
                        return customerNames[0].name
                    } else {
                        return 'Unavailable'
                    }

                } else {
                    return 'Unavailable'
                }
            },
            checkCustomerSitesName(customerID) {
                if (customerID !== null || customerID !== 0) {
                    let customerNames = this.customerSites.filter(item => {
                        return item.id === customerID
                    })
                    if (customerNames.length > 0) {
                        return customerNames[0].name
                    } else {
                        return 'Unavailable'
                    }

                } else {
                    return 'Unavailable'
                }
            },
            checkDomainName(domainID) {
                let domainNames = this.domains.filter(item => {
                    return item.id === domainID
                })
                return domainNames[0].name
            },

            //Load Batch Table
            async getMainTable(currentID, activeButton) {
                this.loadingTable = true
                await axios
                    .get("content-list-id/" + activeButton + '/' + currentID + '/' + this.startDate + "/" + this.finishDate)
                    .then(response => {
                        console.log(response)
                        this.mainDataTable = response.data.content.map(program => ({isEditable: false, ...program}))
                        this.mainDataTable.forEach(item => {
                            item.date_created = this.parseDateTime(item.date_created)
                            this.customerSites.forEach(item2 => {
                                if (item.customer_id == item2.id) {
                                    item.customer_name = item2.name
                                }
                            })
                            this.domains.forEach(item3 => {
                                if (item.domain_id == item3.id) {
                                    item.domain_name = item3.name
                                }
                            })
                        })
                    })
                    .catch((error) => {
                        if (error.response.status == 404) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Check entered date range',
                                // timer: 1000,
                                showConfirmButton: true,
                            })
                        }
                        if (error.response) {
                            this.$swal.fire({
                                icon: 'error',
                                title: error.response.data.message.charAt(0).toUpperCase() + error.response.data.message.slice(1),
                                // text: 'Select at least one domain',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                        }
                    })
                    .finally(() => (this.loadingTable = false))
                let excelContent = this.mainDataTable
                // console.log(excelContent)
                this.excelData = []
                this.excelDataReportLinks = []
                excelContent.forEach(el => {
                    // if (el.commercial) {
                        this.excelData.push({
                            'topic': el.topic,
                            'keyword': el.keyword,
                            'phrase_match_link': el.phrase_match_link,
                            'exact_match_link': el.exact_match_link,
                            'require_trust_link': el.require_trust_link,
                            'trust_link_nofollow_percent': el.trust_link_nofollow_percent + '%',
                            'internal_links_percent': el.internal_links_percent + '%',
                            'number_of_images': el.number_of_images,
                            'domain_id': el.domain_id.name,
                            'wordpress_publish_url': el.wordpress_publish_url,
                            'wordpress_publish': el.wordpress_publish,
                            'wordpress_publish_date': el.wordpress_publish_date,
                        })
                        this.excelDataReportLinks.push({
                            'month': el.date_created,
                            'keyword': el.keyword,
                            'text_link': el.text_link ? el.text_link : 'N/A',
                            'landing_page': el.landing_page ? el.landing_page : 'N/A',
                            'url': el.internal_link ? el.internal_link : 'N/A',
                            'domain_name': el.domain_name
                        })
                })
            },
            dateFilter(DateString){
                const dateParts = DateString.split("-");
                const year = dateParts[0].slice(2); // Extract the last two characters
                const month = dateParts[1];
                return year + "/" + month
            },
        },
        created() {
            if (localStorage.getItem("currentHideFiltersContent") != null) {
                let currentHideFilters = JSON.parse(localStorage.getItem('currentHideFiltersContent'));
                this.content = currentHideFilters
            }
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closePopovers);
        },
        async mounted() {
            document.addEventListener('click', this.closePopovers);

            if (this.$store.getters.isLoggedIn) {
                this.$store.commit('updateCurrentRoute', this.$route.name)
                //Mutation to clean filters data when you mounted Component
                this.$store.commit('cleanFilters')
                //Action to load main Data on mounted Component
                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }
            }
            eventBus.$on('usersOnline', payload => {
                this.usersOnline = payload
            });
            let currentUser = localStorage.getItem('currentUser');
            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket?.send(JSON.stringify(this.message))
        }
    }

</script>

<style scoped lang="scss">
    .calendar-icon-button {
        background-color: #fff;
        margin-left: 10px;
    }

    .selection-bar-wrapper {
        display: flex;
        justify-content: space-between;
        width: 227px;
        max-width: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */


            color: #000000;
            /*margin-bottom: 3px;*/
        }

        input[type='radio'] {
            box-sizing: border-box;
            appearance: none;
            background: white;
            outline: 2px solid #333;
            border: 2px solid white;
            width: 14px;
            height: 14px;
            margin-bottom: 5px;
        }

        input[type='radio']:checked {
            background: #89B49F;
            outline: 2px solid #89B49F;
        }
    }

    .rangetime-wrapper {
        display: flex;
    }

    .buttons-to-choose {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;

        button {
            width: 45%;
            margin: 3px !important;
            @media screen and (max-width: 894px) {
                width: 100%;
            }
        }
    }

    .loader {
        position: absolute;
        display: inline-block;
        top: 500px;
        left: 50%;
        max-width: 240px;
        width: 100%;
    }

    .button-action {
        box-shadow: none !important;
    }

    .content-table {
        width: 100%;
        /*overflow-x: auto;*/
    }

    .content-top-title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            /*margin-right: 20px;*/
        }
    }

    .export-excel {
        position: absolute;
        right: 0px;
    }

    .export-excel-links {
        position: absolute;
        right: 200px;
    }

    .content-table table tr th {
        position: relative;
        overflow: visible;
    }
    .content-list {
        overflow-wrap: anywhere;
        overflow-x: unset;

    }

    .content-table {
        @media screen and (max-width: 1385px) {
            overflow: scroll;
        }
    }

    .filters-popover {

        @media screen and (max-width: 1385px) {
            top: 35px;
        }

    }


</style>