<template>
    <div class="container-fluid body-padding-top">
        <template v-if="loading">
            <div id="preloader">
                <div id="loader_new"></div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="col-sm-12">
                    <h1 class="table-title">Activated domains</h1>
                    <!--                    <div><pre>{{getArrayOffUsers()}}</pre></div>-->
                </div>
            </div>
            <template v-if="globalLoader || loading">
                <div id="preloader">
                    <div id="loader_new"></div>
                </div>
            </template>
            <div class="content-list main-wrapper background-table"
                 style="    overflow-wrap: anywhere;     overflow-x: unset;">
                <div class="container-fluid">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="left-side col-sm-12 col-md-6" style="text-align: left;">
                            <!--Search Filed -->
                            <GlobalSearch filter_type="search"/>
                        </div>
                        <div class="right-side col-sm-12 col-md-6" style="text-align: right;">
                            <!--                            <a href="" @click="decryptCode()">Crypt Button</a>-->
                            <button @click="resetFilters()" class="main-button m-md-2">Reset filters</button>
                            <button @click="loadAllDomains()" class="main-button m-md-2"
                                    :class="{ greenBackground: !returnFilters.status}">Show all
                            </button>
                            <button @click="loadAllActivatedDomains()" class="main-button m-md-2"
                                    :class="{ greenBackground: returnFilters.status === 'ACTIVATED'}">Show Activated
                            </button>
                        </div>
                        <div class="col-sm-12 week-report" style="text-align: left">
                            <!--                            <select v-model="weekReportsYear" style="height: 25px; margin-right: 10px;">-->
                            <!--                                <option selected :value="2023">2023</option>-->
                            <!--                                <option :value="2022">2022</option>-->
                            <!--                                <option :value="2021">2021</option>-->
                            <!--                            </select>-->
                            <button v-b-modal.modal-week class="main-button" @click="getWeekReportNew">Get week report
                            </button>

                            <b-modal id="modal-week" title="Week Report" size="xl">
                                <!--                                <p class="my-4">{{weekReportsYear}}</p>-->
                                <div class="week-report-wrapper"
                                     style="overflow: scroll; max-width: 100%; font-size: 12px!important;">

                                    <table v-if="weekReports && weekReports['0'] && weekReports['0'].performance_record_set" class="table table-responsive-md btn-table table-hover">

                                        <thead>
                                        <tr class="md-table-row">
                                            <th>YEAR</th>
                                            <th>WEEK</th>
                                            <th v-for="(user, index) in userList" :key="index">{{user.name.toUpperCase() + ' ' +
                                                user.lastname?.toUpperCase()}}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="border-bottom: 1px solid #ccc; vertical-align: baseline;"  v-for="(item, indexItem) in weekReports" :key="indexItem">
                                            <td>{{item.year}}</td>
                                            <td>{{item.week}}</td>
                                            <td v-for="(user, index) in userList" :key="index">

                                                <div v-for="(dev, indexDev) in item.performance_record_set" :key="indexDev" style="display: flex; justify-content: center; align-items: center;">
                                                    <template v-if="user.id === dev.developer.id">
                                                        <!-- Domains Counter Link -->
                                                            <button class="main-button" v-b-modal="'dev-counter-domains' + '-' + indexDev + '-' + indexItem" style="margin: 0;">{{dev.domains_qty}}</button>
                                                        <b-modal :id="'dev-counter-domains' + '-' + indexDev + '-' + indexItem" title="Domains Done"
                                                                 size="md">
                                                            <div>
                                                                    <ul class="dev-domains-done">
                                                                        <li v-for="(domainItem, indexDomainItem) in dev.domains" :key="indexDomainItem">
                                                                            <a :href="'http://' + domainItem.name" target="_blank">{{domainItem.name}}</a></li>
                                                                    </ul>
                                                            </div>
                                                        </b-modal>
                                                        <!-- Notes Button -->
                                                            <button v-b-popover.hover.right="dev.notes.slice(0, 25) + '...'" v-b-modal="'dev-notes' + '-' + indexDev + '-' + indexItem"
                                                                    class="main-button"
                                                                    style="background: inherit; color: #0a1e61; margin: 0;"
                                                            >
                                                                <template v-if="dev.notes.length">
                                                                    <font-awesome-icon icon="fa-solid fa-comment" class="fa-lg"/>
                                                                </template>
                                                                <template v-else>
                                                                    <font-awesome-icon icon="fa-regular fa-comment" class="fa-lg"/>
                                                                </template>
                                                            </button>
                                                            <b-modal :id="'dev-notes' + '-' + indexDev + '-' + indexItem" title="Notes"
                                                                     size="md"
                                                                     @ok="saveDevNotes(dev.id, dev)">
                                                                <div>
                                                                    <textarea rows="10" cols="10" style="width: 100%;"
                                                                              v-model.lazy="dev.notes"></textarea>
                                                                </div>
                                                            </b-modal>
                                                    </template>
                                                    <template v-else></template>
                                                </div>

                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </div>

                            </b-modal>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="activated-sites-table"

                                 style="padding-top: 40px;">
                                <v-table
                                        :data="activatedDomainsData"
                                        :currentPage.sync="currentPageMainTable"
                                        :pageSize="300"
                                        @totalPagesChanged="totalPagesMainTable = $event"
                                        class="table table-responsive-md btn-table table-hover">
                                    <thead slot="head">
                                    <tr class="md-table-row">
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('name__icontains', 'ordering', 'name')  }"
                                                               @click="showClosePopover('domain_name')"
                                            />&nbsp; Domain {{returnCountItems}}
                                            <div class="filters-popover" id="domain_name">
                                                <Sorting filter_type="ordering" filter_value="name"/>
                                                <StringFieldFilter filter_type="name__icontains"/>
                                            </div>
                                        </th>

                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('meta_info__theme__icontains', 'ordering', 'meta_info__theme')  }"
                                                               @click="showClosePopover('theme')"/>&nbsp; Theme
                                            <div class="filters-popover" id="theme">
                                                <Sorting filter_type="ordering" filter_value="meta_info__theme"/>
                                                <StringFieldFilter filter_type="meta_info__theme__icontains"/>
                                            </div>
                                        </th>

                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('language__icontains', 'ordering', 'language')  }"
                                                               @click="showClosePopover('language')"/>&nbsp; TLD
                                            <div class="filters-popover" id="language">
                                                <Sorting filter_type="ordering" filter_value="language"/>
                                                <StringFieldFilter filter_type="language__icontains"/>
                                            </div>
                                        </th>
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('meta_info__priority__icontains', 'ordering', 'meta_info__priority')  }"
                                                               @click="showClosePopover('priority')"/>&nbsp; Priority
                                            <div class="filters-popover" id="priority">
                                                <Sorting filter_type="ordering" filter_value="meta_info__priority"/>
                                                <StringFieldFilter filter_type="meta_info__priority__icontains"/>
                                            </div>
                                        </th>
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('meta_info__developer__username__icontains', 'ordering', 'meta_info__developer__username')  }"
                                                               @click="showClosePopover('developer')"/>&nbsp;&nbsp;
                                            &nbsp; Assignee &nbsp; &nbsp;
                                            <div class="filters-popover" id="developer">
                                                <Sorting filter_type="ordering"
                                                         filter_value="meta_info__developer__username"/>
                                                <StringFieldFilter
                                                        filter_type="meta_info__developer__username__icontains"/>
                                            </div>
                                        </th>
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('meta_info__checked_by__username__icontains', 'ordering', 'meta_info__checked_by__username')  }"
                                                               @click="showClosePopover('checked_by')"/>&nbsp; Quality
                                            check
                                            <div class="filters-popover" id="checked_by">
                                                <Sorting filter_type="ordering"
                                                         filter_value="meta_info__checked_by__username"/>
                                                <StringFieldFilter
                                                        filter_type="meta_info__checked_by__username__icontains"/>
                                            </div>
                                        </th>
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               style="cursor: pointer;"
                                                               :class="{ green: checkIfInFilters('meta_info__server__name__icontains', 'ordering', 'meta_info__server__name__icontains')  }"
                                                               @click="showClosePopover('server')"/>&nbsp; Server
                                            <div class="filters-popover" id="server">
                                                <Sorting filter_type="ordering" filter_value="meta_info__server__name"/>
                                                <StringFieldFilter filter_type="meta_info__server__name__icontains"/>
                                            </div>
                                        </th>
                                        <!--                                        <th>Database</th>-->
                                        <th>Activation date</th>
                                        <th>SSL</th>
                                        <th>www</th>
                                        <th>Contact Form/Email</th>
                                        <th>Author</th>
                                        <th>Contact email</th>
                                        <th>
                                            <font-awesome-icon icon="fa-regular fa-bars-filter"
                                                               :class="{ green: checkIfInFilters('status__icontains', 'ordering', 'status')  }"
                                                               style="cursor: pointer;"
                                                               @click="showClosePopover('status')"/>&nbsp;Status
                                            <div class="filters-popover" id="status">
                                                <Sorting filter_type="ordering" filter_value="status"/>
                                                <StringFieldFilter filter_type="status__icontains"/>
                                            </div>
                                        </th>
                                        <th>Notes</th>
                                    </tr>
                                    </thead>
                                    <tbody slot="body" slot-scope="{displayData}">
                                    <v-tr v-for="row in displayData"
                                          :key="row.id" :row="row">
                                        <td>
                                            <a @click="getAdminCreds(row.meta_info.server.id, row.name)"
                                               target="_blank">{{row.name}}<span v-if="row.bugged"><font-awesome-icon icon="fa-solid fa-triangle-exclamation" class="fa-xl" style="color: #f20d18;" /></span></a>

                                        </td>
                                        <td>
                                            <template
                                                    v-if="row.meta_info && row.meta_info.wp_theme && row.meta_info.wp_theme.used <= 25">
                                                <button v-b-modal="`modal-${row.id}`"
                                                        @click="getThemeInfo(row.meta_info.wp_theme.id)"
                                                        style="color: green;">{{row.meta_info.wp_theme ?
                                                    row.meta_info.wp_theme.name : 'None'}}
                                                </button>
                                                <button @click="updateThemeInfo(row.id)" style="background: inherit;">
                                                    <font-awesome-icon icon="fa-solid fa-arrows-rotate" class="fa-xl"/>
                                                </button>
                                            </template>

                                            <template
                                                    v-else-if="row.meta_info && row.meta_info.wp_theme && row.meta_info.wp_theme.name">
                                                <button v-b-modal="`modal-${row.id}`"
                                                        @click="getThemeInfo(row.meta_info.wp_theme.id)"
                                                        style="color: red;">{{row.meta_info.wp_theme.name}}
                                                </button>
                                                <button @click="updateThemeInfo(row.id)" style="background: inherit;">
                                                    <font-awesome-icon icon="fa-solid fa-arrows-rotate" class="fa-xl"/>
                                                </button>
                                            </template>
                                            <template v-else>
                                                {{'No theme'}}
                                                <button @click="updateThemeInfo(row.id)" style="background: inherit;">
                                                    <font-awesome-icon icon="fa-solid fa-arrows-rotate" class="fa-xl"/>
                                                </button>
                                            </template>
                                            <b-modal :id="'modal-' + row.id" title="Theme Additional Information">
                                                <h5>Theme name: {{themeInfo.name}}</h5>
                                                <h5>Theme used: <span>{{themeInfo.used_realtime}}</span></h5>
                                                <h6>List of Domains:</h6>
                                                <ul style="list-style: none; padding: 5px;">
                                                    <li v-for="(domain, index) in themeInfo.list_of_domains"
                                                        :key="index"
                                                        style="border: 1px solid black; padding: 5px; line-height: 16px;">
                                                        <p>Domain name: {{domain.domain_name}}</p>
                                                        <p>Domain built by: {{domain.domain_built_by}}</p>
                                                    </li>
                                                </ul>
                                            </b-modal>
                                        </td>
                                        <td>{{row.language}}</td>
                                        <td v-if="row && row.meta_info && row.meta_info.priority">
                                            <select v-if="row.meta_info.priority == 1"
                                                    name="priority"
                                                    v-model="row.meta_info.priority"
                                                    @change="updateDomainRow(row, 'priority', row.meta_info.priority, $event)"
                                                    style="background: #2FCC95;"
                                            >
                                                <!--                                                <option :value="6">0</option>-->
                                                <option
                                                        v-for="(value, index) in Object.keys(prioritiesObj)"
                                                        v-bind:key="index"
                                                        :value="value"
                                                >
                                                    {{prioritiesObj[value]}}

                                                </option>
                                            </select>

                                            <select v-else-if="row.meta_info.priority == 2"
                                                    name="priority"
                                                    v-model="row.meta_info.priority"
                                                    @change="updateDomainRow(row, 'priority', row.meta_info.priority, $event)"
                                                    style="background: #F7B559"
                                            >
                                                <!--                                                <option :value="6">0</option>-->
                                                <option
                                                        v-for="(value, index) in Object.keys(prioritiesObj)"
                                                        v-bind:key="index"
                                                        :value="value"
                                                >
                                                    {{prioritiesObj[value]}}

                                                </option>
                                            </select>

                                            <select v-else
                                                    name="priority"
                                                    v-model="row.meta_info.priority"
                                                    @change="updateDomainRow(row, 'priority', row.meta_info.priority, $event)"
                                                    style="background: #FF403B;"
                                            >
                                                <!--                                                <option :value="6">0</option>-->
                                                <option
                                                        v-for="(value, index) in Object.keys(prioritiesObj)"
                                                        v-bind:key="index"
                                                        :value="value"
                                                >
                                                    {{prioritiesObj[value]}}

                                                </option>
                                            </select>

                                        </td>
                                        <td v-else-if="row && row.meta_info">
                                            <select
                                                    name="priority"
                                                    v-model="row.meta_info.priority"
                                                    @change="updateDomainRow(row, 'priority', row.meta_info.priority, $event)"
                                            >
                                                <option
                                                        v-for="(value, index) in Object.keys(prioritiesObj)"
                                                        v-bind:key="index"
                                                        :value="value"
                                                >
                                                    {{prioritiesObj[value]}}
                                                </option>
                                            </select>
                                        </td>
                                        <td v-if="row && row.meta_info && row.meta_info.developer && row.meta_info.developer.name">
                                            <div style="display: flex; justify-content: space-between;">
                                                <select
                                                        name="users"
                                                        v-model="row.meta_info.developer"
                                                        :style="{background: row.meta_info.developed ? 'rgba(160,200,68,0.4)' : 'white', width: '100%'}"
                                                        @change="updateDomainRow(row, 'developer', row.meta_info.developer, $event)"
                                                >
                                                    <!--                                                <option :value="row.meta_info.user" selected disabled>Select user</option>-->
                                                    <template v-for="(user, index) in (userList)">
                                                        <option
                                                                v-if="user && user.is_active"
                                                                v-bind:key="index"
                                                                :value="user"
                                                        >
                                                            {{user.name + ' ' + user.lastname}}
                                                        </option>
                                                        <option
                                                                v-else
                                                                disabled
                                                                selected
                                                                v-bind:key="index"
                                                                :value="user"
                                                        >
                                                            {{user.name + ' ' + user.lastname}}
                                                        </option>
                                                    </template>

                                                </select>
                                                <input type="checkbox" v-model="row.meta_info.developed"
                                                       style="cursor: pointer;position: relative;opacity: 1;width: 15px; margin-left: 5px;"
                                                       @change="updateDomainRow(row, 'developed', row.meta_info.developed, $event)">
                                            </div>
                                        </td>
                                        <td v-else>
                                            <div style="display: flex; justify-content: space-between;">
                                                <select
                                                        name="users"
                                                        v-model="row.meta_info.developer"
                                                        :style="{background: row.meta_info.developed ? 'rgba(160,200,68,0.4)' : 'white', width: '100%'}"
                                                        @change="updateDomainRow(row, 'developer', row.meta_info.developer, $event)"
                                                >
                                                    <option :value="null">Select user</option>
                                                    <option
                                                            v-for="(user, index) in (userList)"
                                                            v-bind:key="index"
                                                            :value="user"
                                                    >
                                                        {{user.name + ' ' + user.lastname}}
                                                    </option>
                                                </select>
                                                <input type="checkbox" v-model="row.meta_info.developed"
                                                       style="cursor: pointer;position: relative;opacity: 1;width: 15px; margin-left: 5px;"
                                                       @change="updateDomainRow(row, 'developed', row.meta_info.developed, $event)">
                                            </div>
                                        </td>
                                        <!--Checked BY TD -->
                                        <td v-if="row && row.meta_info && row.meta_info.checked_by && row.meta_info.checked_by.name"
                                            style="position:relative;">
                                            <div style="display: flex; justify-content: space-between;">
                                                <select
                                                        name="quality"
                                                        v-model="row.meta_info.checked_by"
                                                        :style="{background: row.meta_info.checked ? 'rgba(160,200,68,0.4)' : 'white', width: '100%'}"
                                                        @change="updateDomainRow(row, 'checked_by', row.meta_info.checked_by, $event)"
                                                >
                                                    <!--                                                <option :value="row.meta_info.checked_by" selected disabled>Select user</option>-->
                                                    <template
                                                            v-for="(user, index) in (userList.filter(item => item.lastname != (row.meta_info.developer && row.meta_info.developer.lastname)))">
                                                        <option
                                                                v-if="user.is_active"
                                                                v-bind:key="index"
                                                                :value="user"
                                                        >
                                                            {{user.name + ' ' + user.lastname}}
                                                        </option>
                                                        <option
                                                                v-else
                                                                disabled
                                                                selected
                                                                v-bind:key="index"
                                                                :value="user"
                                                        >
                                                            {{user.name + ' ' + user.lastname}}
                                                        </option>
                                                    </template>
                                                </select>
                                                <input type="checkbox"
                                                       style="cursor: pointer;position: relative;opacity: 1;width: 15px; margin-left: 5px;"
                                                       v-model="row.meta_info.checked"
                                                       @change="updateDomainRow(row, 'checked', row.meta_info.checked)">
                                            </div>
                                        </td>
                                        <td v-else style="position:relative;">
                                            <div style="display: flex; justify-content: space-between;">
                                                <select
                                                        name="quality"
                                                        v-model="row.meta_info.checked_by"
                                                        :style="{background: row.meta_info.checked ? 'rgba(160,200,68,0.4)' : 'white', width: '100%'}"
                                                        @change="updateDomainRow(row, 'checked_by', row.meta_info.checked_by, $event)"
                                                >
                                                    <option :value="null">Select user</option>
                                                    <option
                                                            v-for="(user, index) in (userList.filter(item => item.lastname != (row.meta_info.developer && row.meta_info.developer.lastname)))"
                                                            v-bind:key="index"
                                                            :value="user"
                                                    >
                                                        {{user.name + ' ' + user.lastname}}
                                                    </option>
                                                </select>
                                                <input type="checkbox"
                                                       style="cursor: pointer;position: relative;opacity: 1;width: 15px; margin-left: 5px;"
                                                       v-model="row.meta_info.checked"
                                                       @change="updateDomainRow(row, 'checked', row.meta_info.checked)">
                                            </div>
                                        </td>
                                        <!--Server TD -->
                                        <td v-if="row && row.meta_info && row.meta_info.server">
                                            <select
                                                    name="server"
                                                    v-model="row.meta_info.server"
                                                    @change="updateDomainRow(row, 'server', row.meta_info.server, $event)"
                                            >
                                                <option
                                                        v-for="(server, index) in serverList"
                                                        v-bind:key="index"
                                                        :value="server"
                                                >
                                                    {{server.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td v-else>
                                            <select
                                                    name="server"
                                                    v-model="row.meta_info.server"
                                                    @change="updateDomainRow(row, 'server', row.meta_info.server, $event)"
                                            >
                                                <option :value="null" selected disabled>Select server</option>
                                                <option
                                                        v-for="(server, index) in serverList"
                                                        v-bind:key="index"
                                                        :value="server"
                                                >
                                                    {{server.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <!--                                        <td v-if="row && row.meta_info && row.meta_info && row.meta_info.database">-->
                                        <!--                                            {{row.meta_info.database}}-->
                                        <!--                                        </td>-->
                                        <!--                                        <td v-else>No database</td>-->
                                        <td v-if="row && row.meta_info && row.meta_info && row.meta_info.date_activated">
                                            {{row.meta_info.date_activated}}
                                        </td>
                                        <td v-else>No date</td>
                                        <td v-if="row && row.meta_info && row.meta_info">

                                            <select
                                                    name="ssl"
                                                    v-model="row.meta_info.ssl"
                                                    @change="updateDomainRow(row, 'ssl', row.meta_info.ssl, $event)"
                                            >
                                                <option :value="'Required'" selected>Required</option>
                                                <option :value="'Done'">Done</option>
                                            </select>
                                        </td>
                                        <td v-if="row && row.meta_info && row.meta_info">
                                            <select
                                                    name="www"
                                                    v-model="row.meta_info.www"
                                                    @change="updateDomainRow(row, 'www', row.meta_info.www, $event)"
                                            >
                                                <option :value="'Required'" selected>Required</option>
                                                <option :value="'Done'">Done</option>
                                            </select>
                                        </td>
                                        <td v-if="row && row.meta_info && row.meta_info.contact">
                                            {{row.meta_info.contact}}
                                        </td>
                                        <td v-else></td>
                                        <td v-if="row && row.meta_info && row.meta_info.author_name">
                                            {{row.meta_info.author_name}}
                                        </td>
                                        <td v-else>No Author</td>
                                        <td v-if="row && row.name && row.meta_info && row.meta_info && row.meta_info.author_name">
                                            {{row.meta_info.author_name + '@' + row.name}}
                                            <button style="background: inherit;"
                                                    @click="copyHeadersToClipboard(row.meta_info.author_name + '@' + row.name)">
                                                <font-awesome-icon icon="fa-solid fa-copy" class="fa-sm"/>
                                            </button>
                                        </td>
                                        <td v-else>No Email</td>
                                        <td>
                                            <select v-model="row.status"
                                                    @change="updateDomainMainRow(row, 'status', row.status, $event)">
                                                <option selected>NEW</option>
                                                <option>LIVE</option>
                                                <option>REPLACE</option>
                                                <option>ACTIVATED</option>
                                                <option>FIND REPLACEMENT</option>
                                                <option>STARTER</option>
                                                <option>REACTIVATED</option>
                                                <option>DEACTIVATED</option>
                                                <option>PHASED OUT</option>
                                                <option>WILL BE PHASED OUT</option>
                                                <option>RETURNED</option>
                                                <option>LOST</option>
                                            </select>
                                        </td>
                                        <td>
                                            <template v-if="row.notes.length">
                                                {{row.notes.slice(0, 25) + '...'}}
                                            </template>
                                            <template v-else>

                                            </template>
                                            <button v-b-modal="'read-notes' + '-' + row.id"
                                                    class="main-button"
                                                    style="background: inherit; color: #0a1e61;"
                                            >
                                                <template v-if="row.notes.length">
                                                    Read more
                                                </template>
                                                <template v-else>
                                                    Add Notes
                                                </template>
                                            </button>
                                            <b-modal :id="'read-notes' + '-' + row.id" title="Notes"
                                                     size="md"
                                                     @ok="saveNotes(row)">
                                                <div>
                                                    <textarea rows="10" cols="10" style="width: 100%;"
                                                              v-model="row.notes"></textarea>
                                                    <!--                                                   {{row.notes}}-->
                                                </div>
                                            </b-modal>
                                        </td>
                                    </v-tr>
                                    </tbody>
                                </v-table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="pagination-wrapper"
                                 style="display: flex; justify-content: center; margin-bottom: 30px;">
                                <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                                <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import CryptoJS from 'crypto-js';
    import _ from "lodash";
    import {mapState} from "vuex";
    import axios from "axios";
    import GlobalSearch from "../components/filters/GlobalSearch";
    import Sorting from "../components/filters/Sorting";
    import StringFieldFilter from "../components/filters/StringFieldFilter";

    export default {
        name: "ActivatedDomains",
        components: {GlobalSearch, Sorting, StringFieldFilter},
        data() {
            return {
                selectedDomains: [],
                users: ['Gera', 'Deineka'],
                currentPageMainTable: 1,
                currentPageWeekReport: 1,
                totalPagesMainTable: 0,
                totalPagesWeekReport: 0,
                loading: false,
                // priorities: [
                //     0, 1, 2, 3, 4, 5
                // ],
                prioritiesObj: {
                    1: 'High',
                    2: 'Medium',
                    3: 'Low',
                },
                userList: [],
                prevFilterName: '',
                themeInfo: [],
                weekReports: [],
                weekReportsYear: new Date().getFullYear(),
                tempLogin: '',
                tempPass: '',
                filters: {
                    name: {value: '', keys: ['domain_name']},
                },
                numberWeeks: []

            }
        },
        computed: {
            ...mapState({
                activatedDomainsData: state => state.filters.mainData,
                globalLoader: state => state.filters.globalLoader,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                returnCountItems: state => state.filters.countItems,
                serverList: state => state.serverList,
            }),
        },
        watch: {

        },
        methods: {
            saveDevNotes(ID, data) {
                axios
                    .put('performance-record/list/' + ID, data)
                    .catch(err => {
                        console.log(err)
                    })
            },
            preparedWeekReport() {

                this.userlist
            },
            getAdminCreds(ID, siteURL) {
                axios
                    .get('server/detail/' + ID)
                    .then(res => {
                        this.tempLogin = res.data.admin;
                        this.tempPass = res.data.password;
                    })
                    .then(() => {
                        this.tempLogin = this.decryptCode(this.tempLogin)
                        this.tempPass = this.decryptCode(this.tempPass)
                        this.copyToClipboard(this.tempPass)
                        setTimeout(() => window.open('http://' + siteURL + '/wp-admin', '_blank').focus(), 2000);
                        this.tempPass = ''
                    })
                    .catch(() => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Cant find password automatically',
                            timer: 2000,
                            showConfirmButton: false,
                        })
                    })
            },
            async copyToClipboard(pass) {

                try {
                    await navigator.clipboard.writeText(pass);
                    // alert('Copied');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Pass copied!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } catch ($e) {
                    console.log($e)
                    // alert('Cannot copy');
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Cannot copy!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                }
            },
            decryptCode(Base64CBC) {
                let key = "16byteslongkey!!";
                let iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');
                // let Base64CBC = 'Hj5StZWKYdPlDmW2RycDtg==';

                key = CryptoJS.enc.Utf8.parse(key);

                let decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {iv: iv, mode: CryptoJS.mode.CBC});
                decrypted = decrypted.toString(CryptoJS.enc.Utf8);

                return decrypted;
            },
            getWeekReports() {
                this.weekReports = []
                axios
                    .get('get-devs-performance/' + this.weekReportsYear)
                    .then(res => {

                        for (const [key, value] of Object.entries(res.data[this.weekReportsYear])) {
                            value.weekNumber = key
                            this.weekReports.push(value)
                        }


                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getWeekReportNew() {
                axios
                    .get('week-report/list')
                    .then(res => {
                        this.weekReports = res.data.results;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            resetFilters() {
                this.$store.commit('cleanFilters');
                this.$store.dispatch('getData', this.returnFilters)
            },
            loadAllDomains() {
                // this.$store.commit('cleanFilters')
                let keyValue = 'status';
                let val = '';
                this.$store.commit('updateFilters', {keyValue, val})
                this.$store.dispatch('getData', this.returnFilters)
                // this.$store.commit('cleanFilters')
                // this.$store.dispatch('getData', {'status' : ''});
            },
            loadAllActivatedDomains() {
                // this.$store.commit('cleanFilters')
                let keyValue = 'status';
                let val = 'ACTIVATED';
                this.$store.commit('updateFilters', {keyValue, val})
                this.$store.dispatch('getData', this.returnFilters)
                // this.$store.commit('cleanFilters')
                // this.$store.dispatch('getData', {'status' : 'ACTIVATED'});
            },
            checkIfInFilters(setValue1, setValue2, setValue3) {

                for (const [key, value] of Object.entries(this.returnFilters)) {
                    if ((key === setValue2 && (value === setValue3 || value === '-' + setValue3)) || ((key === setValue1) && value)) {
                        return true;
                    }
                }
                return false;
            },
            //Update theme info
            updateThemeInfo(ID) {
                // this.loading = true;
                axios
                    .get('check-domain-health/' + ID)
                    .then(res => {
                        this.$store.commit('updateActivatedDomain', [res.data.id, res.data])
                        this.$nextTick(() => {
                            this.$forceUpdate()
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Server error: ' + error.response.status,
                            text: 'No data was found for this domain',
                            // timer: 2000,
                            showConfirmButton: true,
                        })
                    })
            },
            //Get theme info
            getThemeInfo(ID) {
                axios
                    .get('theme/list/' + ID)
                    .then(res => {
                        this.themeInfo = res.data;
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            closePopovers(event) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    const popoverIcon = popover.previousElementSibling;
                    if (!popover.contains(event.target) && !popoverIcon.contains(event.target)) {
                        popover.classList.remove('showPopover');
                    }
                }
            },

            //Method Show or Hide Filters
            // showClosePopover(item) {
            //     if (this.prevFilterName) {
            //         if (this.prevFilterName == item) {
            //             let currentPopover = document.querySelector(`${'#' + item}`)
            //             currentPopover.classList.remove("showPopover");
            //             this.prevFilterName = '';
            //         } else {
            //             document.querySelector(`${'#' + this.prevFilterName}`).classList.remove("showPopover")
            //             this.prevFilterName = item;
            //             let currentPopover = document.querySelector(`${'#' + item}`);
            //             currentPopover.classList.toggle("showPopover");
            //         }
            //     } else {
            //         this.prevFilterName = item;
            //         let currentPopover = document.querySelector(`${'#' + item}`);
            //         currentPopover.classList.toggle("showPopover");
            //     }
            // },
            showClosePopover(item) {
                const popovers = document.querySelectorAll('.filters-popover');
                for (let i = 0; i < popovers.length; i++) {
                    const popover = popovers[i];
                    if (popover.id !== item) {
                        popover.classList.remove('showPopover');
                    }
                }

                const currentPopover = document.querySelector(`#${item}`);
                currentPopover.classList.toggle('showPopover');

                this.prevFilterName = this.prevFilterName === item ? '' : item;
            },
            //Method to copy Headers from Buld Add Window
            async copyHeadersToClipboard(text) {
                try {
                    await navigator.clipboard.writeText(text);
                    // alert('Copied');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Copied!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                } catch ($e) {
                    // alert('Cannot copy');
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Cannot copy!',
                        timer: 1000,
                        showConfirmButton: false,
                    })
                }
            },
            saveNotes(row) {
                axios
                    .put('domain/list/meta/' + row.id, row)
                    .then(() => {
                        this.$forceUpdate();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            updateDomainMainRow(row, itemName, itemValue) {
                if (this.selectedDomains.length > 0 && itemName && itemValue) {
                    this.selectedDomains.forEach(item => {
                        item[itemName] = itemValue
                        axios
                            .put('domain/list/meta/' + item.id, item)
                            .catch(err => {
                                console.log(err)
                            })
                    })
                } else {
                    axios
                        .put('domain/list/meta/' + row.id, row)
                        .catch(err => {
                            console.log(err)
                        })
                }

            },
            //Method to update row if priority changed
            updateDomainRow(row, itemName, itemValue, event) {

                this.$swal.fire({
                    title: "Do you want to save the changes?",
                    showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonText: "Save",
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        if (this.selectedDomains.length > 0 && itemName && itemValue) {

                            if (itemName === 'developer') {
                                this.selectedDomains.forEach(item => {
                                    item.meta_info[itemName] = itemValue
                                    axios
                                        .put('domain/detail/meta/' + item.meta_info.id, item.meta_info)
                                        .then(res => {
                                            this.$nextTick(() => {
                                                this.$store.commit('updateDataAfterChangeDev', [res.data, item.id])
                                                this.$store.dispatch('getData', this.returnFilters)
                                            })

                                            this.selectedDomains = []

                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                })

                            } else {
                                this.selectedDomains.forEach(item => {
                                    item.meta_info[itemName] = itemValue
                                    axios
                                        .put('domain/detail/meta/' + item.meta_info.id, item.meta_info)
                                        .catch(err => {
                                            console.log(err)
                                        })
                                })
                            }

                        } else {
                            if (itemName === 'developer') {
                                axios
                                    .put('domain/detail/meta/' + row.meta_info.id, row.meta_info)
                                    .then(res => {
                                        this.$nextTick(() => {
                                            this.$store.commit('updateDataAfterChangeDev', [res.data, row.id])
                                            this.$store.dispatch('getData', this.returnFilters)
                                        })

                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })
                            } else {
                                axios
                                    .put('domain/detail/meta/' + row.meta_info.id, row.meta_info)
                                    .catch(err => {
                                        console.log(err)
                                    })
                            }

                        }
                    } else if (result.isDenied) {
                        // console.log(event.target.value)
                        if (event.target.checked) {
                            event.target.checked = !event.target.checked;
                        } else {
                            this.$store.dispatch('getData', this.returnFilters)
                        }
                    }
                });

            },
            //Pagination Previous Page
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },
            getUsers() {
                axios
                    .get('developer/list')
                    .then(res => {
                        this.userList = res.data.results
                    })
            },
            // getArrayOffUsers() {
            //     let users = []
            //     this.userList.map((user) => ({
            //         name: user.name.replace(/\./g, ' '),
            //         ...user,
            //     }))
            //     // this.userList.forEach(user => {
            //     //     user.name.replace(/\./g,' ')
            //     //
            //     //     users.push(user.name.replace(/\./g,' '))
            //     //     users.push()
            //     // })
            //     users = this.userList
            //     return users;
            // }
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closePopovers);
        },
        async mounted() {
            document.addEventListener('click', this.closePopovers);
            if (this.$store.getters.isLoggedIn) {
                this.getUsers();
                this.$store.dispatch('getServerList')
                this.$store.commit('updateCurrentRoute', this.$route.name)
                //Mutation to clean filters data when you mounted Component
                this.$store.commit('cleanFilters')

                //Action to load main Data on mounted Component
                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {

                    // await this.$store.dispatch('getData', {});
                    let keyValue = 'status';
                    let val = 'ACTIVATED';
                    this.$store.commit('updateFilters', {keyValue, val})
                    this.$store.dispatch('getData', this.returnFilters)
                }
            }
        },
        destroyed() {
            this.$store.commit('clearData');
        }
    }
</script>

<style scoped lang="scss">

    .activated-sites-table table tr th {
        position: relative;
        overflow: visible;
    }

    .activated-sites-table {

        @media screen and (max-width: 1425px) {
            overflow: scroll;
        }

    }


    .filters-popover {

        @media screen and (max-width: 1425px) {
            top: 35px;
        }

    }

    .week-report-wrapper th {
        padding: 3px 5px;
        border-top: none!important;
        border-left: none!important;
        border-right: none!important;
    }
    .dev-domains-done {
        list-style: auto;
        margin-left: 20px;
    }

</style>