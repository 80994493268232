export default {

    actions: {},
    mutations: {
        updateGlobalOrderList(state, newOrderList) {
            state.globalOrderList = newOrderList
        },
        updateGlobalCurrentMonth(state, newMonth) {
            state.globalOrderList.month_of_order = newMonth
        },
        updateMonthIfDateMoreThanTen(state, currentDateOfMonth) {
            state.globalOrderList.month_of_order = currentDateOfMonth
        },
        updateYearIfMonthIsDecember(state, currentYear) {
            state.globalOrderList.year_of_order = currentYear
        },
        updateOrderListNumber(state, number) {
            state.orderListNumber = number
        }
    },
    state: {
        globalOrderList: {
            customers: {},
            content_provider_id: null,
            month_of_order: '',
            year_of_order: new Date().getFullYear().toString(),
            start_date: "",
            finish_date: "",
            language: ''
        },
        orderListFull: false,
        orderListNumber: 0
    }
}