import axios from "axios";

export default {
    actions: {
        getGrapsDataAll(ctx) {
            // console.log('Fetch graphs DATA')
            ctx.commit('updateLodingStatus', true);
            let endpoints = [
                'month-orders-status/',
                'get-orders-forecast',
                'get-stats',
            ];
            // ctx.state.loadingGraphs = true;
            return axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
                axios.spread(({data: ordersCreated}, {data: regionalOrders}, {data: stats}) => {
                    ctx.commit("updateOrdersCreated", ordersCreated);
                    ctx.commit("updateDomainStatuses", stats);
                    ctx.commit('updateRegionalOrders', regionalOrders)
                    ctx.commit('updateLodingStatus', false);
                })
            );


        },
        getRecentOrders(ctx) {
            let orderNames = [];
            let orderNamesObj = [];
            return axios
                .get("contentorder-list-dashboard/")
                .then(response => {
                    response.data.forEach(item => {

                        orderNames.push(item['1']);

                    })
                    orderNames.reverse();
                    orderNames.forEach(item => {
                        orderNamesObj.push({name: item})
                    })

                    const obj = orderNamesObj.reduce((acc, c) => {
                        const letter = c.name[0] + c.name[1];
                        acc[letter] = (acc[letter] || []).concat(c.name);
                        return acc;
                    }, {})

                    orderNamesObj = Object.entries(obj).map(([letter, names]) => {
                        return {letter, names}
                    }).sort((a, b) => a.letter > b.letter);

                    ctx.commit("updateRecentOrders", {orderNamesObj, orderNames});
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        },
        // getRecentCustomers(ctx) {
        //     return axios
        //         .get("customer-list-recently-updated/")
        //         .then(response => {
        //             const customerUpdated = []
        //             response.data.forEach(item => {
        //                 customerUpdated.push({'name': item.name, 'id': item.id})
        //             })
        //
        //             const recentCustomers = customerUpdated.reverse()
        //             ctx.commit("updateRecentCustomers", recentCustomers);
        //
        //         })
        //         .catch(function (error) {
        //             if (error.response) {
        //                 console.log(error.response.status);
        //             }
        //         })
        // },
        getUnorderedLinks(ctx) {

            let unorderedCustomers = [];
            let unorderedLinks = [];
            return axios
                .get("dashboard-customers-links/")
                .then(response => {

                    const recentUnfinishedOrders = response.data;
                    recentUnfinishedOrders.forEach(item => {
                        unorderedCustomers.push(item.customer_site)
                        unorderedLinks.push(item.links_left_to_order)
                    })

                    ctx.commit('updateUnorderedCustomersLinks', {unorderedCustomers, unorderedLinks})

                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        },
        getGraphDomainsLive(ctx, {formatDateBefore, currentDate}) {
            let domainsToBeLive = [];
            let domainsLive = [];
            let domainsDown = [];
            let dateGraph = [];
            let spamAnchors = [];
            let totalLinks = [];
            let customersLive = [];
            let customersSitesLive = [];
            let totalLinksLive = [];
            let totalLinksFound = [];
            return axios

                .get('get-graph/' + formatDateBefore + '/' + currentDate)
                .then(response => {
                    response.data.data.forEach(item => {
                        domainsToBeLive.push(item.domains_to_be_live);
                        domainsLive.push(item.domains_live);
                        domainsDown.push(item.domains_down);
                        dateGraph.push(item.date);
                        spamAnchors.push(item.spam);
                        totalLinks.push(item.links_found);
                        customersLive.push(item.customers_live);
                        customersSitesLive.push(item.customers_sites_live);
                        totalLinksLive.push(item.links_live);
                        totalLinksFound.push(item.links_found);
                    })
                    ctx.commit('updateDates', {formatDateBefore, currentDate})
                    ctx.commit('updateGraphDomainsLive', {
                        domainsToBeLive,
                        domainsLive,
                        domainsDown,
                        dateGraph,
                        spamAnchors,
                        totalLinks,
                        customersLive,
                        customersSitesLive,
                        totalLinksLive,
                        totalLinksFound
                    })
                }).catch(err => {
                    return err
                })
        },
    },

    mutations: {
        updateLodingStatus(state, status) {
            state.loadingGraphs = status
        },
        updateRecentOrders(state, recentOrders) {
            state.recentOrders = recentOrders;
        },
        updateRecentCustomers(state, recentCustomers) {
            state.recentCustomers = recentCustomers;
        },
        updateDomainStatuses(state, stats) {
            state.stats = stats;
        },
        updateUnorderedCustomersLinks(state, unorderedCustomersLinks) {
            state.unorderedCustomersLinks = unorderedCustomersLinks;
        },
        updateGraphDomainsLive(state, graphOnlineData) {
            state.graphOnlineData = graphOnlineData;
        },
        updateDates(state, newDates) {
            state.startDate2 = newDates.formatDateBefore;
            state.finishDate2 = newDates.currentDate;
        },
        updateRegionalOrders(state, regionalOrders) {
            state.regionalOrders = regionalOrders;
        },
        updateOrdersCreated(state, newOrders) {
            state.ordersCreated = newOrders
        }

    },
    state: {
        recentOrders: [],
        recentCustomers: [],
        stats: [],
        unorderedCustomersLinks: [],
        graphOnlineData: [],
        regionalOrders: [],
        ordersCreated: [],
        startDate2: '',
        finishDate2: '',
        loadingGraphs: false
    },
    getters: {
        getRecentCustomers(state) {
            return state.recentCustomers;
        },
        getRecentOrders(state) {
            return state.recentOrders;
        },
        getUnorderedCustomersLinks(state) {
            return state.unorderedCustomersLinks;
        },
        getGraphOnlineData(state) {
            return state.graphOnlineData;
        }
    }
}