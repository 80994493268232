<template>
    <div id="chart" class="chart__wrapper" style="padding: 10px 10px 10px; height: 318px;">
        <div class="orders-created-titles" style="display: flex; justify-content: center;">
            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000;">Publish status: {{returnCurrentMonth()}}</h2>
<!--            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000;">{{}}</h2>-->
        </div>
        <apexchart type="radialBar" height="224" :options="chartOptions" :series="series"></apexchart>
        <h6 class="orders-created-labels">Total orders: {{totalOrders}}</h6>
        <h6 class="orders-created-labels green">Published: {{publishedOrders}}</h6>
        <h6 class="orders-created-labels red">Unpublished: {{unPublished}}</h6>
    </div>
</template>

<script>
    // import {D3PieChart} from 'vue-d3-charts';
    // import {VueApexCharts} from 'apexcharts';
    import {mapState} from "vuex";


    export default {
        name: "OrdersCreatedApex",
        components: {
            // apexchart: VueApexCharts,
        },
        data() {
            return {
                currentDate: new Date().toLocaleDateString(),
                totalOrders: 0,
                publishedOrders: 0,
                unPublished: 0,
                series: [0],
                chartOptions: {
                    chart: {
                        height: 200,
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 20,
                                size: '70%',
                                image: '../assets/smile.png',
                                imageWidth: 33,
                                imageHeight: 29,
                                imageClipped: false
                            },
                            dataLabels: {
                                name: {
                                    show: true,
                                    color: '#000'
                                },
                                value: {
                                    show: true,
                                    color: '#333',
                                    offsetY: 20,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    colors: ['#23CC90'],
                    // fill: {
                    //     type: 'image',
                    //     color: '#000',
                    //     // image: {
                    //     //     src: ['../src/assets/smile.png'],
                    //     // }
                    // },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Done'],
                },
            }
        },
        computed: {
            ...mapState({
                returnOrdersCreated: state => state.graphsData.ordersCreated,
                // returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                // returnRegionalOrders: state => state.graphsData.regionalOrders,
            })
        },
        watch: {
            returnOrdersCreated: {
                handler() {
                    this.returnOrdersCreated();
                },
                deep: true
            }
        },
        methods: {
            returnCurrentMonth() {
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const d = new Date();

                return monthNames[d.getMonth()];
                // document.write("The current month is " + monthNames[d.getMonth()]);
            },
            // updateRegionalOrders() {
            //     let readyOrders = [];
            //     Object.entries(this.returnRegionalOrders).forEach(item => {
            //         readyOrders.push({name: item['0'], "Month left": item['1'].month_left})
            //     })
            //     console.log(readyOrders)
            //     this.chart_data = readyOrders.sort((a, b) => (a["Month left"] > b["Month left"]) ? 1 : -1);
            //     // console.log(Object.entries(this.returnRegionalOrders))
            //
            // },
            updateOrdersCreated() {

                let totalPercent = Math.round((this.returnOrdersCreated.total_published * 100 / this.returnOrdersCreated.total))
                this.$set(this.series, 0, totalPercent)
                // this.series.push(totalPercent)
                this.totalOrders = this.returnOrdersCreated.total
                this.publishedOrders = this.returnOrdersCreated.total_published
                this.unPublished = this.returnOrdersCreated.total_unpublished
                // let readyOrdersCreated = [];

                // readyOrdersCreated.push({orders: this.returnOrdersCreated.total_published, name: 'Done'})
                // readyOrdersCreated.push({orders: this.returnOrdersCreated.total_unpublished, name: 'Left'})

                // this.returnOrdersCreated.totalLinksLive.forEach(linkLive => {
                //     readyLinksStats.push({'Links live': linkLive})
                // })
                // this.returnGraphDomainsData.totalLinksFound.forEach((linkFound, index) => {
                //     readyLinksStats[index]['Links found'] = linkFound
                // })
                // this.returnGraphDomainsData.dateGraph.forEach((date, index) => {
                //     readyLinksStats[index].date = date
                // })
                // console.log(readyLinksStats)

                // this.chart_data = readyOrdersCreated
            }
        },
        mounted() {
            this.updateOrdersCreated();

        }
    }
</script>


<style scoped lang="scss">

    .orders-created-labels {
        text-align: left;
        font-size: 14px;
    }
    .green {
        color: #23CC90;
    }
    .red {
        color: red;
    }

    .tick {
        transform: rotate(-45deg) !important;
    }

    .chart__wrapper {
        .chart__axis-x.chart__axis-x--barchart {
            .tick {
                transform: rotate(-45deg) !important;
            }
        }
    }

</style>