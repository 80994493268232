import { render, staticRenderFns } from "./LangsTable.vue?vue&type=template&id=57c3d15c&scoped=true&"
import script from "./LangsTable.vue?vue&type=script&lang=js&"
export * from "./LangsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c3d15c",
  null
  
)

export default component.exports