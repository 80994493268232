<template>
    <section class="orders-cart">
        <template v-if="loading">
            <div id="preloader">
                <div id="loader_new"></div>
            </div>
        </template>
        <template v-else>
            <div class="container body-padding-top">
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="table-title">Finalized orders</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12" >
                        <div class="preorder-cart" style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); background: #fff; padding-bottom: 20px;">
                            <v-table :data="preorderContent"
                                     :filters="filters"
                                     :currentPage.sync="currentPage"
                                     :pageSize="10"
                                     @totalPagesChanged="totalPages = $event"
                                     ref="myTable"
                                     class="table table-responsive-md btn-table">
                                <thead slot="head">
                                <tr>
                                    <v-th sort-key="name">Batch Number</v-th>
                                    <v-th sort-key="commercial">Order Type</v-th>

                                    <v-th sort-key="date_created">Date</v-th>
                                    <th>Ordered by</th>
                                    <th>Remove order</th>
                                </tr>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                <tr>
                                    <td>
                                        <input placeholder="Filter batch" class="form-control"
                                               v-model="filters.batch_number.value"/></td>
                                    <td></td>

                                    <td>
                                        <input placeholder="Filter date" class="form-control"
                                               v-model="filters.date.value"/></td>
                                    <td>
                                        <input placeholder="Filter email" class="form-control"
                                               v-model="filters.order_by.value"/></td>
                                    <td></td>
                                </tr>
                                <tr v-for="(content, index) in displayData" :key="index">
                                    <td style="cursor: pointer;"
                                        v-on:click="chooseBatchID(content.id, content.name, content.language, $event)">
                                        {{content.name}}
                                    </td>
                                    <td>{{content.commercial ? 'Comercial' : 'Starter'}}</td>

                                    <td>{{content.date_created}}</td>
                                    <td>{{content.created_by}}</td>
                                    <td><a class="preorder-cart-remove__cta" href="javascript:void(0)"
                                           @click="removeOrder(content)">
                                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />
                                    </a></td>
                                </tr>
                                </tbody>
                            </v-table>
                            <smart-pagination
                                    :currentPage.sync="currentPage"
                                    :totalPages="totalPages"
                                    :maxPageLinks="10"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="loading">
<!--            <div class="loader">Loading...</div>-->
            <div id="preloader">
                <div id="loader_new"></div>
            </div>
        </template>
        <template v-else-if="orderContentLoaded">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order-buttons-wrapper">
                            <div class="data-to-excel-cta">
                                <button type="button" v-on:click="onexport" class="main-button">Excel
                                    download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="order-list" style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); background: #fff; padding-bottom: 20px; margin-top: 20px;">
                            <v-table :data="orderContent"
                                     :currentPage.sync="currentPageContent"
                                     :pageSize="10"
                                     @totalPagesChanged="totalPagesContent = $event"
                                     ref="myTable"
                                     class="table table-responsive-md btn-table">
                                <thead slot="head">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>&emsp;&emsp;&emsp;Name&emsp;&emsp;&emsp;</th>
                                    <th>&emsp;&emsp;Customer Name&emsp;&emsp;</th>
                                    <th>&emsp;&emsp;Domain Name&emsp;&emsp;</th>
                                    <th>Language</th>
                                    <th>&emsp;&emsp;&emsp;&emsp;Topic&emsp;&emsp;&emsp;&emsp;</th>
                                    <th>Month of Order</th>
                                    <th>Keyword</th>
                                    <th>Keyword Negative</th>
                                    <th>Exact Match Links</th>
                                    <th>Phrase Match Links</th>
                                    <th>Wordpress publish date</th>
                                    <th>Landing page</th>
                                </tr>
                                </thead>
                                <tbody slot="body" slot-scope="{displayData}">
                                <tr v-for="content in displayData" :key="content.id">
                                    <td>
                                        <button class="" style="background: inherit;" type="button" value="Save"
                                                  v-on:click="updateContent(content)" variant="">
                                            <font-awesome-icon v-if="content.isEditable" icon="fa-light fa-save" class="fa-lg"/>
                                            <font-awesome-icon v-else icon="fa-light fa-edit" class="fa-lg"/>
                                        </button>
                                    </td>
                                    <td>
                                        <button class="" style="background: inherit;" type="button" value="Remove"
                                                  v-on:click="removeDomain(content)" variant="danger">
                                            <font-awesome-icon :icon="['fas', 'trash']"/>
                                        </button>
                                    </td>
                                    <template v-if="content.isEditable">
                                        <td>
                                            <input type="text" v-model="content.name">
                                        </td>
                                        <template v-if="content.customer_id === null">
                                            <td>None</td>
                                        </template>
                                        <template v-else>
                                            <td>
                                                {{content.customer_id.name}}
                                            </td>
                                        </template>

                                        <template v-if="content.domain_id === null">
                                            <td>None</td>
                                        </template>
                                        <template v-else>
                                            <td>
                                                <input type="text" v-model="content.domain_id.name">
                                            </td>
                                        </template>
                                        <td>{{content.language}}</td>
                                        <td>
                                            <textarea name="topic" cols="20" rows="3"
                                                      v-model="content.topic"></textarea>
                                        </td>
                                        
                                        <td>
                                            <input type="text" v-model="content.month_of_order">
                                        </td>
                                        <td>
                                            <textarea name="keywords" cols="20" rows="3"
                                                      v-model="content.keyword"></textarea>
                                        </td>
                                        <td>
                                            <textarea name="keyword_negative" cols="20" rows="3"
                                                      v-model="content.keyword_negative"></textarea>
                                        </td>
                                        <td>
                                            <textarea name="exact" cols="20" rows="3"
                                                      v-model="content.exact_match_link"></textarea>
                                        </td>
                                        <td>
                                            <textarea name="phrase" cols="20" rows="3"
                                                      v-model="content.phrase_match_link"></textarea>
                                        </td>
                                        <td><input type="datetime-local" v-model="content.wordpress_publish_date"></td>
                                        <td>
                                            <textarea name="landing" cols="20" rows="3"
                                                      v-model="content.landing_page"></textarea>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            {{content.name}}
                                        </td>
                                        <template v-if="content.customer_id === null">
                                            <td>None</td>
                                        </template>
                                        <template v-else>
                                            <td>
                                                {{content.customer_id.name}}
                                            </td>
                                        </template>

                                        <template v-if="content.domain_id === null">
                                            <td>None</td>
                                        </template>
                                        <template v-else>
                                            <td>
                                                {{content.domain_id.name}}
                                            </td>
                                        </template>
                                        <td>{{content.language}}</td>
                                        <td>
                                            {{content.topic}}
                                        </td>
                                        <td>{{dateFilter(content.date_of_order)}}</td>
                                        <td>
                                            {{content.keyword}}
                                        </td>
                                        <td>
                                            {{content.keyword_negative}}
                                        </td>
                                        <td>
                                            {{content.exact_match_link}}
                                        </td>
                                        <td>
                                            {{content.phrase_match_link}}
                                        </td>
                                        <td>
                                            {{content.wordpress_publish_date}}
                                        </td>
                                        <td>
                                            {{content.landing_page}}
                                        </td>
                                    </template>
                                </tr>
                                </tbody>
                            </v-table>
                            <smart-pagination
                                    :currentPage.sync="currentPageContent"
                                    :totalPages="totalPagesContent"
                                    :maxPageLinks="10"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>

    import axios from 'axios';
    import JQuery from 'jquery';
    import moment from 'moment';
    import XLSX from 'xlsx'
    import {mapState} from "vuex";
    window.$ = JQuery;

    export default {
        name: "OrdersCart",
        components: {},
        data() {
            return {
                message: {'message': {}, 'action': {}},
                Datas: {
                    'order_info': [],
                    'additional_info': []
                },
                formatdate: moment(new Date()).format('YYYY-MM-DDThh:mm'),
                choosedName: '',
                choosedLanguage: '',
                counterArticlesForExport: null,
                paginate: ['sortedContent'],
                orderContent: [],
                sortBy: '',
                sortDirection: 'asc',
                loading: false,
                loading2: false,
                counter: 0,
                orderContentLoaded: false,
                filters: {
                    batch_number: {value: '', keys: ['name']},
                    order_type: {value: '', keys: ['commercial']},
                    language: {value: '', keys: ['language']},
                    date: {value: '', keys: ['date_created']},
                    order_by: {value: '', keys: ['created_by']},
                },
                currentPage: 1,
                totalPages: 0,
                currentPageContent: 1,
                totalPagesContent: 0
            }
        },
        computed: {
            ...mapState({
                customers: state => state.finalizedOrder.customersOrders,
                domains: state => state.domainList.domains,
                preorderContent: state => state.finalizedOrder.preorderContent
            }),
        },
        watch: {
            orderContent: {
                handler(val) {
                    if (val) {
                        this.orderContentLoaded = true
                    }
                },
                deep: true
            },
        },
        methods: {
            getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                return Math.round(randomNum / 50) * 50;

            },
            onexport() { // On Click Excel download button
                this.Datas.order_info.forEach((item, index) => {

                    item['Minimum Words'] = this.orderContent[index].minimum_words
                })
                // export json to Worksheet of Excel
                // only array possible
                let orderWS = XLSX.utils.json_to_sheet(this.Datas.order_info)
                let additionalWS = XLSX.utils.json_to_sheet(this.Datas.additional_info)

                // A workbook is the name given to an Excel file
                let wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, orderWS, 'Order Information') // sheetAName is name of Worksheet
                XLSX.utils.book_append_sheet(wb, additionalWS, 'Additional Information')

                // export Excel file
                XLSX.writeFile(wb, (this.choosedLanguage + ' ' + this.orderContent.length + ' articles - ' + this.choosedName + '.xlsx')) // name of the file is 'book.xlsx'
            },
            removeOrder(order) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.loading = true
                        axios
                            .delete('content-commercial-order-remove/' + order.id)
                            .then((response) => {
                                if (response.status == 200) {
                                    let currentElArr = this.preorderContent.indexOf(order);
                                    this.preorderContent.splice(currentElArr, 1);
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Removed',
                                        text: 'Order successfully removed!',
                                        timer: 1000,
                                        showConfirmButton: false,
                                    })
                                    this.loading = false
                                }
                            })
                            .catch(() => {
                                this.loading = false
                            });
                    }
                })
            },
            removeDomain(program) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You will remove domain",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios
                            .delete('content-commercial-order-remove-single/' + program.id + '/')
                            .then(response => {
                                if (response.status == 200) {
                                    program.is_active = false
                                    program.status = 'DEACTIVATED'
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Deactivated',
                                        text: 'Domain successfully removed!',
                                        timer: 700,
                                        showConfirmButton: false,
                                    })
                                    let removeIndex = this.orderContent.map(function (item) {
                                        return item.id;
                                    }).indexOf(program.id)
                                    this.orderContent.splice(removeIndex, 1)
                                }
                            })
                    }
                })
            },
            async updateContent(content) {
                if (this.counter == 0) {
                    content.isEditable = !content.isEditable
                    this.counter = 1;
                } else if (this.counter == 1) {
                    const data = Object.assign({}, content)
                    data.wordpress_publish_date = new Date(data.wordpress_publish_date).toISOString()
                    await axios.put('content-list/' + content.id + '/', {
                        data
                    })
                    this.counter = 0
                    content.isEditable = !content.isEditable
                }
            },
            checkCustomerName(customerID) {
                if (customerID !== null && customerID !== 0) {
                    let customerNames = this.customers.filter(item => {
                        return item.id === customerID
                    })
                    return customerNames[0].name
                } else {
                    return 'Unavailable'
                }
            },
            checkDomainName(domainID) {
                let domainNames = this.domains.filter(item => {
                    return item.id === domainID
                })
                return domainNames[0].name
            },
            cleanArray() {
                this.data.splice(0, this.data.length);
            },
            async getOrderContent(currentID) {
                this.orderContent = [];
                this.loading2 = true
                await axios
                    .get("content/list?contentorder__id=" + currentID)
                    .then(response => {
                        this.orderContent = response.data.results
                        Object.keys(this.orderContent).forEach(key => {
                            this.orderContent[key].wordpress_publish_date = new Date(this.orderContent[key].wordpress_publish_date)
                            this.orderContent[key].wordpress_publish_date = moment(this.orderContent[key].wordpress_publish_date).format('YYYY-MM-DDThh:mm')
                        });
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    })
                    .finally(() => (this.loading2 = false))
                let excelContent = this.orderContent
                excelContent.forEach(el => {
                    this.Datas.additional_info.push({
                        'Domain': el.domain_id.name,
                        'Subject': el.topic,
                        'Customer': el.customer_id?.name ? el.customer_id.name : '',
                        'Language': el.language,
                        'Batch': this.choosedName,
                        'Phrase/exact': el.exact_match ? 'exact' : 'phrase',
                        'Keyword': el.keyword,
                        'Negative Keywords': el.keyword_negative,
                        'Landingpage': el.landing_page

                    })
                    if (el.commercial) {
                        this.Datas.order_info.push({
                            'Topic': el.topic,
                            'Keyword': el.keyword,
                            'Negative Keywords': el.keyword_negative,
                            'Phrase Match Links': el.phrase_match_link,
                            'Exact Match Links': el.exact_match_link,
                            'Require Trust Link': el.require_trust_link,
                            'Trust Link Nofollow Percent': el.trust_link_nofollow_percent + '%',
                            'Internal Links Percent': el.internal_links_percent + '%',
                            'Number Of Images': el.number_of_images,
                            'Publish URL': el.domain_id.name,
                            'Wordpress Publish Url': el.wordpress_publish_url,
                            'Wordpress Publish Status': el.wordpress_publish,
                            'Wordpress Publish Date': el.wordpress_publish_date,
                            'Minimum Words': el.minimum_words
                        })
                    } else {
                        this.Datas.order_info.push({
                            'Topic': el.topic,
                            'Keyword': el.keyword,
                            'Link Text': el.publish_url,
                            'Link Url': el.publish_url,
                            'Publish Url': el.domain_id.name,
                            'Wordpress Publish Url': el.wordpress_publish_url,
                            'Batch': this.choosedName,
                            'Wordpress Auto Split': 1,
                            'Minimum Words': el.minimum_words
                        })
                    }
                })
            },
            applyRangeFilter() {
                this.Datas.additional_info = [];
                let excelContent = this.orderContent
                excelContent.forEach(el => {
                    let domName = '';
                    let custName = '';
                    this.domains.forEach(item => {
                        if (el.domain_id == item.id) {
                            domName = item.name;
                        }
                    })
                    this.customers.forEach(item => {
                        if (el.customer_id == item.id) {
                            custName = item.name;
                        }
                    })
                    this.Datas.additional_info.push({
                        'Domain': domName,
                        'Subject': el.topic,
                        'Customer': custName,
                        'Language': el.language,
                        'Batch': this.choosedName,
                        'Phrase/exact': el.exact_match ? 'exact' : 'phrase',
                        'Keyword': el.keyword,
                        'Landingpage': el.landing_page,
                        'Minimum Words': this.getRandomInt(this.minRangeValue, this.maxRangeValue)
                    })
                })
                alert('Filter Added')

            },
            async chooseBatchID(currentID, currentName, contentLanguage, event) {
                JQuery('.currentContent').removeClass('currentContent');
                event.target.classList.add("currentContent");
                event.preventDefault();
                this.choosedName = currentName;
                this.choosedLanguage = contentLanguage;
                this.Datas.order_info = [];
                this.Datas.additional_info = [];
                this.getOrderContent(currentID);
            },
            sort: function (s) {
                if (s === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = s;
            },
            dateFilter(DateString){
                const dateParts = DateString.split("-");
                const year = dateParts[0].slice(2); // Extract the last two characters
                const month = dateParts[1];
                return year + "/" + month
            },
        },
        async created() {
            if (this.$store.getters.isLoggedIn) {
                this.$store.dispatch('fetchPreorderContent')
            }
        },
        async mounted() {
            let currentUser = localStorage.getItem('currentUser')
            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket.send(JSON.stringify(this.message))
        },
    }
</script>

<style scoped lang="scss">
    .orders-cart {
        .preorder-cart {
            width: 100%;
            margin: 0 auto;
        }
    }

    .currentContent {
        background-color: #89B49F !important;
    }

    .order-list {
        width: 100%;
        overflow-x: auto;
        position: relative;
    }

    .table {
        overflow-x: auto;
        overflow-y: auto;
        table-layout: auto;
        width: 100%;
    }

    .preorder-cart-remove__cta {
        color: orange !important;
    }

    .order-buttons-wrapper {
        display: flex;
        margin-top: 50px;
    }

    .data-to-excel-cta {
    }

    .min-words {
        display: flex;
        margin-left: 20px;

        input {
            border: 1px solid #000;
        }
    }

    .order-list {
        table {
            textarea {
                background-color: rgba(38, 48, 62, 0.05);
                border: none;
                padding: 2px 4px;
            }
        }
    }


</style>