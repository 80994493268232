<template>
    <div class="graphs-wrapper bugged-links">
        <h6>Bugged links</h6>
        <b-table striped hover :items="mainData" :fields="fields"></b-table>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "Bugged Links",
        components: {},
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        sortable: true
                    },
                    {
                        key: 'bugged_message',
                        sortable: true
                    },
                    {
                        key: 'keyword_count',
                        sortable: true
                    },
                ],
                mainData: [],

            }
        },
        computed: {},
        methods: {
            getData() {
                axios
                .get('customers-bugged/list')
                .then((res) => {
                    this.mainData = Object.values(res.data.results)
                })
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped lang="scss">
.bugged-links {

    background-color: #ffffff;
    height: 327px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    padding-top: 10px;
    overflow: scroll;
    h6 {
        font-size: 12px;
        font-weight: 700;
        text-align: center;
    }
}
</style>