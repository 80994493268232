import axios from "axios";

export default {
    actions: {
        fetchUsers(ctx) {
            return axios
                .get('developer/list')
                .then(response => {

                    const users = response.data.results.map(program => ({isEditable: false, ...program})).reverse()

                    ctx.commit('updateUsers', users)

                }).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });
        }
    },
    mutations: {
        updateUsers(state, users) {
            state.users = users;
        },
        createUser(state, users) {
            state.users.unshift(users)
        },
        removeUser(state, users) {
            state.users.splice(users, 1);
        },
        deactivateUserFromList(state, userID) {
            state.users.forEach(user => {
                if(user.id === userID) {
                    user.is_active = false;
                }
            })
        }
    },
    state: {
        users: []
    },
    getters: {

    }
}