import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'
import Login from '../pages/Login'
import GoogleToken from '../pages/GoogleToken'
import DomainsDRF from '../pages/DomainsDRF'
import BuggedDomainsDRF from '../pages/BuggedDomainsDRF'
import ActivatedDomains from "../pages/ActivatedDomains";
import CustomersDRF from '../pages/CustomersDRF'
import CalendarDRF from '../pages/CalendarDRF'
import ContentDRF from '../pages/ContentDRF'
import Options from '../pages/Options'
import OrderContent from '../pages/OrderContent'
import OrderCommercialContentBeta from '../pages/OrderCommercialContent'
import OrdersCart from '../pages/OrdersCart'
import Dialogue from '../pages/Dialogue'
import Secure from '../pages/Secure'


Vue.use(VueRouter)

let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/login/:id',
            name: 'googletoken',
            component: GoogleToken
        },
        {
            path: '/secure',
            name: 'secure',
            component: Secure,
        },
        {
            path: '/domains',
            name: 'domain/list?',
            component: DomainsDRF,
        },
        {
            path: '/bugged-domains',
            name: 'bugged-domain/list?&page_size=500',
            component: BuggedDomainsDRF,
        },
        {
            path: '/activated-domains',
            name: 'domain/list/meta?',
            component: ActivatedDomains,
        },
        {
            path: '/customers',
            name: 'customer/list?',
            component: CustomersDRF,
        },
        {
            path: '/calendar',
            name: 'orders-planning/list?',
            component: CalendarDRF
        },
        {
            path: '/content',
            name: 'content/list?',
            component: ContentDRF,
        },
        {
            path: '/options',
            name: 'options',
            component: Options,
        },
        {
            path: '/order-content',
            name: 'order',
            component: OrderContent,
        },
        {
            path: '/order-commercial-content',
            name: 'customer/keywords/list?',
            component: OrderCommercialContentBeta,
        },
        {
            path: '/orders-cart',
            name: 'orders-cart',
            component: OrdersCart,
        },
        {
            path: '/dialogue',
            name: 'dialogue',
            component: Dialogue,
        }
    ]
})


export default router