<template>
<!--    <div class="string-field-wrapper">-->
<!--        <input type="text" placeholder="Search..." v-model="inputValue">-->
<!--    </div>-->
    <div class="domains-search-field-wrapper">
        <font-awesome-icon icon="fa-regular fa-magnifying-glass " class="domains-search-icon fa-xl" />
        <input type="text" class="domains-search-field" placeholder="Search..." v-model="inputValue">
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "GlobalSearch",
        props: ['filter_type'],
        data() {
            return {
                inputValue: ''
            }
        },
        watch: {
          inputValue: {
              handler(val) {
                  let keyValue = this.filter_type
                  this.$store.commit('updateFilters', {keyValue, val})
                  this.$store.dispatch('getData', this.returnFilters)
              }
          }
        },
        computed: {
            ...mapState({
                returnFilters: state => state.filters.filters,
            }),
        },
        methods: {

        },
        mounted() {
            // this.$store.commit('updateFilters', this.filter_type)
        }
    }
</script>

<style scoped lang="scss">
    /*.string-field-wrapper {*/
    /*    input {*/
    /*        border: 1px solid #e5e7eb!important;*/
    /*        border-radius: 4px;*/
    /*        width: auto;*/
    /*        height: 30px;*/
    /*    }*/
    /*}*/
    //Search Field
    .domains-search-field-wrapper {
        display: inline-block;
        width: 225px;
        position: relative;

    }

    .domains-search-field {
        /*position: relative;*/
        height: 24px;
        line-height: 24px;
        /*background: #FFFFFF;*/
        border: 1px solid #C9C9C9;
        border-radius: 2px;
        width: 225px;

        &:hover {
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }

    .domains-search-icon {
        position: absolute;
        left: 7px;
        top: 3px;
    }
</style>