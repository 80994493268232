import axios from "axios";

export default {
    actions: {
        fetchOrderCustomers(ctx) {
            ctx.commit('updateLoadingContentOrderListStatus', true);
            return axios
                .get("customer/list")
                .then(response => {
                    // adding prop isEditable for each object
                    const customersOrders = response.data.results.reverse()
                    ctx.commit('updateOrderCustomers', customersOrders)
                    ctx.commit('updateLoadingContentOrderListStatus', false);
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        },
        fetchPreorderContent(ctx) {
            ctx.commit('updateLoadingContentOrderListStatus', true);
            return axios
                .get("content-order/list?fields=id,name,date_created,created_by,commercial&page_size=999999")
                .then(response => {
                    // adding prop isEditable for each object
                    const preorderContent = response.data.results;
                    ctx.commit('updatePreorderContent', preorderContent)
                    ctx.commit('updateLoadingContentOrderListStatus', false);
                }).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                })
        }
    },
    mutations: {
        updateLoadingContentOrderListStatus(state, status) {
            state.loadingContentOrderListStatus = status
        },
        updateOrderCustomers(state, customersOrders) {
            state.customersOrders = customersOrders;
        },
        updatePreorderContent(state, preorderContent) {
            state.preorderContent = preorderContent;
        }
    },
    state: {
        customersOrders: [],
        preorderContent: [],
        loadingContentOrderListStatus: false
    },
    getters: {
    }
}