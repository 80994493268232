<template>
    <div class="container-fluid body-padding-top">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="table-title">Calendar</h1>
            </div>
        </div>
        <div class="calendar-list main-wrapper background-table">
            <div class="container-fluid">
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-sm-12 col-md-6" style="text-align: left;">
                        <GlobalSearch filter_type="search"/>
                    </div>
                    <div class="col-sm-12 col-md-6" style="text-align: left;">
                        <div class="top-sets">
                            <div style="margin: 0 5px 0 0; display: flex; align-items: center;">
                                <label for="years" style="font-weight: bold; flex: 1 0 auto;">Year &nbsp;</label>
                                <select name="years" id="years" v-model="calendarYear" class="select-year-field"
                                        @change="changeYear()">
                                    <option v-for="year in years" v-bind:key="year.id" :value="year">{{year}}</option>
                                </select>
                            </div>
                            <div style="margin: 0; display: flex; align-items: center;">
                                <label for="months" style="font-weight: bold; flex: 1 0 auto;">Month &nbsp;</label>
                                <select name="months" id="months" v-model="calendarMonthNumber"
                                        class="select-month-field">
                                    <option v-for="(value, key) in months" :key="key" :value="value">{{ key }}</option>
                                </select>
                                <button style="margin: 0 0 0 20px; background: inherit;" value="prev"
                                        id="prevPage"
                                        @click="changeMonth('left')"
                                        class=""
                                        data-direction="left" :disabled="calendarMonthNumber === 1">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" class="fa-xl"/>
                                </button>
                                <button style="margin: 0; background: inherit;" value="next"
                                        id="nextPage"
                                        @click="changeMonth('right')"
                                        class=""
                                        data-direction="right" :disabled="calendarMonthNumber === 12">
                                    <font-awesome-icon icon="fa-solid fa-arrow-right" class="fa-xl"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="globalLoader || loading">
                    <div id="preloader">
                        <div id="loader_new"></div>
                    </div>
                </template>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="calendar-table">
                            <v-table
                                    :data="returnMainData"
                                    :currentPage.sync="currentPage"
                                    :pageSize="-1"
                                    @totalPagesChanged="totalPages = $event"
                                    class="table table-responsive-md btn-table table-hover">

                                <thead slot="head">
                                <tr class="md-table-row">
                                    <th rowspan="2"></th>
                                    <th rowspan="2">Log</th>
                                    <th rowspan="2">Customer name</th>
                                    <th rowspan="2">Customer site</th>

                                    <!-- Loop over the displayed months -->
                                    <template v-for="(i, monthIndex) in endingMonth - startingMonth + 1">
                                        <th :key="monthIndex + generateRandomNumberWithTenDigits()" colspan="3"
                                            :class="i % 2 === 0 ? 'column-dark' : ''">{{
                                            getMonthLabel(startingMonth + i - 1) }}
                                        </th>
                                    </template>
                                </tr>
                                <tr>
                                    <!-- Loop over the displayed months -->
                                    <template v-for="(i, linksIndex) in endingMonth - startingMonth + 1">
                                        <th :key="linksIndex + generateRandomNumberWithTenDigits()"
                                            :class="i % 2 === 0 ? 'column-dark' : ''">LPM
                                        </th>
                                        <th :key="linksIndex + generateRandomNumberWithTenDigits()"
                                            :class="i % 2 === 0 ? 'column-dark' : ''">ML
                                        </th>
                                        <th :key="linksIndex + generateRandomNumberWithTenDigits()"
                                            :class="i % 2 === 0 ? 'column-dark' : ''">REQ
                                        </th>
                                    </template>
                                </tr>
                                </thead>

                                <tbody slot="body" slot-scope="{ displayData }">
                                <v-tr class="" v-for="(row, index) in displayData" :key="index" :row="row">
                                    <td>
                                        <button v-if="row.customer != 'Total'" class="" style="background: inherit;"
                                                @click="saveLinksTable(row.id, row)">
                                            <font-awesome-icon icon="fa-light fa-save" class="fa-lg"/>
                                        </button>
                                    </td>
                                    <td>
                                        <button v-if="row.customer != 'Total'" v-b-modal="`modal-${row.id}`"
                                                class=""
                                                style="background: inherit;"
                                                @click="showLastUpdatesModal($event, row.customer.id, row.customer.name,)">
                                            <font-awesome-icon icon="fa-solid fa-telescope" class="fa-xl"/>
                                        </button>
                                        <b-modal :id="'modal-' + row.id" :title="currentUpdatedCustomerName">
                                            <template v-if="currentUpdatedCustomer">
                                                <div style="height: 600px; overflow: scroll;">
                                                    <div v-for="(item, index) in currentUpdatedCustomer"
                                                         :key="index"
                                                         style="border-bottom: 1px solid #000; margin-bottom: 30px;">
                                                        <p style="background-color: #eebe78; margin-bottom: 5px; padding: 0 10px; border-radius: 10px;">
                                                            <strong>Updated
                                                                date:</strong> {{new
                                                            Date(item.date_created).toLocaleDateString('en-US',
                                                            {year: 'numeric', month: '2-digit', day: '2-digit', hour:
                                                            '2-digit', minute: '2-digit'})}}</p>
                                                        <p style="    background-color: #cdcccc; margin-bottom: 10px; padding: 0 10px; border-radius: 10px;">
                                                            <strong>User:</strong>
                                                            {{item.created_by }}</p>
                                                        <p style="margin-bottom: 5px;"><strong>Changes:</strong></p>
                                                        <p style="background-color: #99c2af; margin-bottom: 5px; padding: 0 10px; border-radius: 10px;">
                                                            {{ item.message }}</p>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else-if="currentUpdatedCustomer == 'loading'">
                                                <p>Loading... Please wait a second</p>
                                            </template>
                                            <template v-else>
                                                <p>No Data</p>
                                            </template>
                                        </b-modal>
                                    </td>
                                    <!-- Customer Name -->
                                    <td v-if="row && row.customer && row.customer.customer_group && row.customer.customer_group.name ">
                                        {{ row.customer.customer_group.name }}
                                    </td>
                                    <td v-else-if="row.customer === 'Total'">Total</td>
                                    <td v-else>No data</td>

                                    <!-- Customer Site -->
                                    <td v-if="row && row.customer && row.customer.name">{{ row.customer.name }}</td>
                                    <td v-else-if="row.customer === 'Total'">Total</td>
                                    <td v-else>No site</td>

                                    <!-- Loop over the selected month and the two previous and two next months -->
                                    <template v-for="(i, valueIndex) in endingMonth - startingMonth + 1">
                                        <!-- Display the columns for the current month -->
                                        <td :key="valueIndex + generateRandomNumberWithTenDigits()"
                                            :id="valueIndex + generateRandomNumberWithTenDigits()"
                                            :class="i % 2 === 0 ? 'column-dark' : ''">
                                            <input v-if="row.customer !== 'Total'" type="text"
                                                   v-model.lazy="row['month_' + (parseInt(startingMonth) + i - 1)]">
                                            <span v-else><strong>{{row['month_' + (parseInt(startingMonth) + i - 1)]}}</strong></span>
                                            <button v-if="row.customer !== 'Total'" style="background: inherit; margin-top: 5px;"
                                                    @click="updateNextMonths(row.id, parseInt(startingMonth) + i - 1, row['month_' + (parseInt(startingMonth) + i - 1)])">
                                                <font-awesome-icon icon="fa-solid fa-arrow-right"
                                                                   class="fa-xl"/>
                                            </button>
                                        </td>
                                        <td :key="valueIndex + generateRandomNumberWithTenDigits()"
                                            :id="valueIndex + generateRandomNumberWithTenDigits()"
                                            :class="i % 2 === 0 ? 'column-dark' : ''"><strong v-if="row.customer == 'Total'">{{ row['month_'
                                            + (parseInt(startingMonth) + i - 1) + '_live'] }}</strong><span v-else>{{ row['month_'
                                            + (parseInt(startingMonth) + i - 1) + '_live'] }}</span>
                                        </td>
                                        <td :key="valueIndex + generateRandomNumberWithTenDigits()"
                                            :id="valueIndex + generateRandomNumberWithTenDigits()"
                                            :style="{
  color: row['month_' + (parseInt(startingMonth) + i - 1) + '_alert'] === 1 ? 'green' :
         row['month_' + (parseInt(startingMonth) + i - 1) + '_alert'] === 2 ? 'red' :
         'black',
  'font-weight': row['month_' + (parseInt(startingMonth) + i - 1) + '_alert'] === 1 || row['month_' + (parseInt(startingMonth) + i - 1) + '_alert'] === 2 ? 'bold' : 'normal'
}"
                                            :class="i % 2 === 0 ? 'column-dark' : ''"><strong v-if="row.customer == 'Total'">{{ row['month_'
                                            + (parseInt(startingMonth) + i - 1) + '_to_be_live'] }}</strong><span v-else>{{ row['month_'
                                            + (parseInt(startingMonth) + i - 1) + '_to_be_live'] }}</span>
                                        </td>
                                    </template>
                                </v-tr>
                                </tbody>
                            </v-table>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="returnMainData">
                    <div class="col-sm-12">
                        <div class="pagination-wrapper"
                             style="display: flex; justify-content: center; margin-bottom: 30px;">
                            <button v-if="returnPrevPage" @click="prevPage" class="main-button">Previous</button>
                            <button v-if="returnNextPage" @click="nextPage" class="main-button">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import axios from "axios";
    import { mapState } from "vuex";
    import GlobalSearch from "../components/filters/GlobalSearch";


    export default {
        name: "CalendarDRF",
        components: {
            GlobalSearch,
        },
        data() {
            return {
                tableSettings: {
                    columns: [],
                    data: [],
                },
                loading: false,
                currentPage: 1,
                totalPages: 0,
                calendarYear: this.returnCurrentYear(),
                calendarMonthNumber: this.returnCurrentMonthNumber(),
                currentUpdatedCustomer: [],
                currentUpdatedCustomerName: '',
                years: this.generateYearArray(),
                months: {
                    January: 1,
                    February: 2,
                    March: 3,
                    April: 4,
                    May: 5,
                    June: 6,
                    July: 7,
                    August: 8,
                    September: 9,
                    October: 10,
                    November: 11,
                    December: 12
                },
            }
        },
        computed: {
            ...mapState({
                returnMainData: state => state.filters.mainData,
                returnFilters: state => state.filters.filters,
                returnPrevPage: state => state.filters.prevPage,
                returnNextPage: state => state.filters.nextPage,
                // returnCountItems: state => state.filters.countItems,
                globalLoader: state => state.filters.globalLoader,
            }),
            startingMonth() {
                return Math.max(parseInt(this.calendarMonthNumber) - 2, 1);
            },
            endingMonth() {
                return Math.min(parseInt(this.calendarMonthNumber) + 2, 12);
            },
            displayData() {
                // Filter the data based on the selected month and the next four months
                if(this.returnMainData) {
                    const filteredData = this.returnMainData.filter((row) => {
                        const month = parseInt(row.month_1); // Assuming the month data is stored in month_1 field

                        return month >= this.startingMonth && month <= this.endingMonth;
                    });

                    return filteredData;
                } else {
                    return null;
                }

            },

        },
        methods: {
            //Generate years array
            generateYearArray() {
                const currentYear = new Date().getFullYear();
                const years = [];

                for (let i = currentYear - 2; i <= currentYear + 3; i++) {
                    years.push(String(i));
                }

                return years;
            },
            //Show last Updated for each customer
            showLastUpdatesModal(e, id, name, program) {
                e.preventDefault();
                console.log(program)
                this.currentUpdatedCustomerName = name
                this.currentUpdatedCustomer = []
                this.currentUpdatedCustomer = 'loading'
                this.loading = true;
                axios
                    .get('/customer-log/list' + '?id=' + id)
                    .then((res) => {
                        this.loading = false;
                        this.currentUpdatedCustomer = res.data.results[0].log
                    })
            },
            generateRandomNumberWithTenDigits() {
                const min = 1000000000; // Minimum 10-digit number (10^9)
                const max = 9999999999; // Maximum 10-digit number (10^10 - 1)
                const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
                return randomNumber;
            },
            async saveLinksTable(id, row) {
                await axios
                    .put('orders-planning/list/' + id, row)

                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }
            },
            async updateNextMonths(id, monthNumber, value) {
                await axios
                    .get('orders-planning/populate/' + id + '/' + monthNumber + '/' + value)

                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }
            },
            getMonthLabel(month) {
                const monthNames = [
                    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
                ];

                return monthNames[month - 1];
            },
            async changeYear() {
                this.$store.commit('updateCurrentRoute', this.$route.name + 'year=' + this.calendarYear)

                if (_.size(this.returnFilters) > 0) {
                    await this.$store.dispatch('getData', this.returnFilters);
                } else {
                    await this.$store.dispatch('getData', {});
                }
            },
            changeMonth(pos) {
                if (pos == 'right') {
                    this.calendarMonthNumber += 1
                } else if (pos == 'left') {
                    this.calendarMonthNumber -= 1
                }
            },
            returnCurrentYear() {
                return new Date().getFullYear();
            },
            returnCurrentMonthNumber() {
                const d = new Date();
                return d.getMonth() + 1;
            },
            //Pagination Previous Page
            prevPage() {
                this.loading = true;
                axios
                    .get(this.returnPrevPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

            //Pagination 'Next Page'
            nextPage() {
                this.loading = true;
                axios
                    .get(this.returnNextPage)
                    .then(res => {
                        this.$store.commit('updateData', res.data.results)
                        this.$store.commit('updateCountItems', res.data.count)
                        this.$store.commit('updatePrevPage', res.data.previous)
                        this.$store.commit('updateNextPage', res.data.next)
                        this.loading = false;
                    })
            },

        },
        beforeCreate() {
            this.$store.commit('updateData', [])
        },
        created() {

        },
        async mounted() {
            if (this.$store.getters.isLoggedIn) {

                this.$store.commit('updateCurrentRoute', this.$route.name + 'year=' + this.calendarYear)
                //Mutation to clean filters data when you mounted Component
                this.$store.commit('cleanFilters')
            }
        }
    }
</script>

<style scoped lang="scss">
    td {
        vertical-align: initial;
        white-space: nowrap;
        button {
            margin-left: 5px;
        }
        input {
            width: 50px;

        }
    }
    .calendar-table {
        width: 100%;
    }

    .calendar-table table tr th {
        position: relative;
        overflow: visible;
    }

    .calendar-list {
        overflow-wrap: anywhere;
        overflow-x: unset;

    }

    .calendar-table {
        @media screen and (max-width: 1385px) {
            overflow: scroll;
        }
    }

    .top-sets {
        display: flex;
        justify-content: flex-start;
        transform: translateX(-137px);

        div {
            margin: 10px 10px;
        }
    }

    .column-dark {
        /*background-color: #e5e2e2 !important;*/
        background-color: whitesmoke !important;
    }
</style>