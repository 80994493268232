import axios from 'axios';
import _ from "lodash";

export default {
    actions: {
        fetchDomains(ctx) {
            return axios
                .get('domain-list/')
                .then(response => {
                    const domains = response.data.map(program => ({isEditable: false, statusSaving: false, ...program})).reverse();
                    // domains.filter(item => {
                    //     if(item.language_alt == null) {
                    //         return item.language_alt = '';
                    //     } else if (item.batch_number == null) {
                    //         return item.batch_number = '';
                    //     }
                    // })
                    // this.$swal.fire({
                    //     icon: 'success',
                    //     title: 'Loaded',
                    //     text: 'Domains successfully loaded!',
                    //     timer: 1000,
                    //     showConfirmButton: false,
                    // })
                    ctx.commit("updateDomains", domains);
                })
        },
        editDomain(ctx, domain) {
            ctx.commit('editDomain', domain)
        }
    },
    mutations: {
        updateDomains(state, domains) {
            state.domains = domains;
        },
        createDomain(state, newDomain) {
            state.domains.unshift(newDomain);
        },
        editDomain(state, domain) {
            state.domains.forEach(item => {
                if (item.id == domain.id) {
                    Object.assign(item, domain)
                }
            })
        },
        updateDomain(state, domain) {
            state.domains.forEach(item => {
                if (item.id === domain.id) {
                    Object.assign(item, domain)
                }
            })
        },
        replaceDomain(state, domain) {
            state.domains.forEach((item, key) => {
                if (item.id === domain.id) {
                    state.domains[key] = item
                }
            })
        },
        removeGroupDomains(state, domains) {
            domains.forEach(domain => {
                state.domains = state.domains.filter((data) => {
                    return data.id != domain
                } )
            })

        },
        deactivateGroupDomains(state, domains) {
            let newDomains = domains.data;

            newDomains.forEach(newDomain => {
                const itemIndex = state.domains.findIndex(x => x.id === newDomain.id)
                state.domains[itemIndex].is_active = false
            })
        },
    },
    state: {
        domains: []
    },
    getters: {
        allDomains(state) {
            return state.domains;
        },
        returnSortedDomainsCount(state) {
            return _.size(state.domains);
        },
        // excludeDomains(state) {
        //     return state.todos.filter(todo => todo.done)
        // }
    }
}