import { render, staticRenderFns } from "./LinkSpams.vue?vue&type=template&id=7d5c234c&scoped=true&"
import script from "./LinkSpams.vue?vue&type=script&lang=js&"
export * from "./LinkSpams.vue?vue&type=script&lang=js&"
import style0 from "./LinkSpams.vue?vue&type=style&index=0&id=7d5c234c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d5c234c",
  null
  
)

export default component.exports