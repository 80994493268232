
export default {
    google_login_url: process.env.VUE_APP_GOOGLE_LOGIN_URL,
    socket_url: process.env.VUE_APP_SOCKET_URL,
    axios_api_url: process.env.VUE_APP_AXIOS_API_URL,
}
// export default {
//     google_login_url: 'http://localhost:8000',
//     socket_url: 'ws:127.0.0.1:8000/ws/',
//     axios_api_url: 'http://127.0.0.1:8000/api/'
// }