// import axios from "axios";

import axios from "axios";

export default {
    actions: {
        fetchOptions(ctx) {
           return axios
                .get("options/list")
                .then(response => {
                    const optionsData = response.data.results.map(option => ({isEditable: false, ...option})).reverse();

                    ctx.commit('updateOptions', optionsData)
                }).catch(function (error) {
                if (error.response) {
                    console.log(error.response.status);
                }
            });
        }
    },
    mutations: {
        updateOptions(state, optionsData) {
            state.optionsData = optionsData;
        },
        createOption(state, option) {
            state.optionsData.unshift(option)
        }
    },
    state: {
        optionsData: []
    },
    getters: {

    }
}