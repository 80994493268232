<template>
    <div id="chart" class="chart__wrapper" style="padding: 10px 10px 10px; height: 318px;">
        <div class="orders-created-titles" style="display: flex; justify-content: center;">
            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000; text-align: center">
                Created orders: {{returnCurrentMonthFullName ? returnCurrentMonthFullName.slice(0,1).toUpperCase() + returnCurrentMonthFullName.slice(1) : ''}}</h2>
<!--            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000;">-->
<!--                {{returnCurrentMonth()}}</h2>-->
        </div>
        <apexchart type="radialBar" height="224" :options="chartOptions" :series="series"></apexchart>
<!--        <h6 class="orders-created-labels">Total orders: {{totalOrders}}</h6>-->
        <h6 class="orders-created-labels green">Left to order: {{linkLeftToOrderTotal}}</h6>
        <h6 class="orders-created-labels red">To be ordered: {{linksPerMonthTotal}}</h6>
    </div>
</template>

<script>
    // import {D3PieChart} from 'vue-d3-charts';
    // import {VueApexCharts} from 'apexcharts';
    import axios from "axios";
    import {mapState} from "vuex";


    export default {
        name: "MonthDueLinks",
        props: ['month', 'year'],
        components: {
            // apexchart: VueApexCharts,
        },
        data() {
            return {
                currentDate: new Date().toLocaleDateString(),
                // totalOrders: 0,
                // publishedOrders: 0,
                // unPublished: 0,
                series: [],
                chartOptions: {
                    chart: {
                        height: 200,
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 20,
                                size: '70%',
                                image: '../assets/smile.png',
                                imageWidth: 33,
                                imageHeight: 29,
                                imageClipped: false
                            },
                            dataLabels: {
                                name: {
                                    show: true,
                                    color: '#000'
                                },
                                value: {
                                    show: true,
                                    color: '#333',
                                    offsetY: 20,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    colors: ['#23CC90'],
                    // fill: {
                    //     type: 'image',
                    //     color: '#000',
                    //     // image: {
                    //     //     src: ['../src/assets/smile.png'],
                    //     // }
                    // },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Done'],
                },
                linkLeftToOrderTotal: 0,
                linksPerMonthTotal: 0
            }
        },
        computed: {
            ...mapState({
                // returnOrdersCreated: state => state.graphsData.ordersCreated,
                // returnCustomers: state => state.orderCommercialContent.customers,
                // returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                // returnRegionalOrders: state => state.graphsData.regionalOrders,
            }),
            returnCurrentMonthFullName() {
                let months = {
                    'jan': 'january',
                    'feb': 'february',
                    'mar': 'march',
                    'apr': 'april',
                    'may': 'may',
                    'jun': 'june',
                    'jul': 'july',
                    'aug': 'august',
                    'sep': 'september',
                    'oct': 'october',
                    'nov': 'november',
                    'dec': 'december'
                }
                return months[this.month]
            },
        },
        watch: {
            // returnGraphDomainsData: {
            //     handler(val) {
            //         console.log(val)
            //         this.returnOrdersCreated();
            //     },
            //     deep: true
            // },
            month: {
                handler(val) {
                    if(val) {
                        this.getDataByMonthYear();
                    }

                }
            },
            year: {
                handler(val) {
                    if(val) {
                        this.getDataByMonthYear();
                    }

                }
            }
        },
        methods: {
            getDataByMonthYear() {
                axios
                .get('month-order-stats/'+ this.month + '-' + this.year)
                .then(res => {
                    this.series = []
                    // console.log(res)
                    this.linkLeftToOrderTotal = res.data.left_to_order
                    this.linksPerMonthTotal = res.data.to_order

                    let totalPercent = this.linkLeftToOrderTotal * 100 / this.linksPerMonthTotal
                    this.series.push(Math.round(100 - totalPercent))
                })
                .catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            }
        },
        async mounted() {
            if(this.month && this.year) {
                this.getDataByMonthYear()
            }

            // if(this.$store.state.monthDueLinks) {
            //     this.returnLinksLeftToOrderTotal();
            // } else {
            //     await this.updateOrdersCreated();
            // }

            // await this.returnLinksLeftToOrderTotal();


        }
    }
</script>


<style scoped lang="scss">

    .orders-created-labels {
        text-align: left;
        font-size: 14px;
    }

    .green {
        color: #23CC90;
    }

    .red {
        color: red;
    }

    .tick {
        transform: rotate(-45deg) !important;
    }

    .chart__wrapper {
        .chart__axis-x.chart__axis-x--barchart {
            .tick {
                transform: rotate(-45deg) !important;
            }
        }
    }

</style>