<template>
    <div class="table-provider-wrapper">
        <h2 class="options-main-title title-font-style">User List</h2>
        <v-table :data="users"
                 :filters="filtersUser"
                 :currentPage.sync="currentPageUser"
                 :pageSize="5"
                 @totalPagesChanged="totalPagesUser = $event"
                 class="table table-responsive-md btn-table">
            <thead slot="head">
            <tr>
                <th colspan="2"></th>
                <th>
                    Name
                </th>
                <th>
                    Lastname
                </th>
                <th>Email</th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr>
                <td colspan="5">
                    <input placeholder="Filter by name" class="form-control"
                           v-model="filtersUser.name.value"/>
                </td>
            </tr>
            <tr class="options-add">
                <td colspan="2" class="domain-table__cell">
                    <button
                            style="width: auto; background: inherit;" class="btn btn-indigo btn-sm m-0"
                            id="tooltip-create-provider"
                            v-on:click="createUsers">
<!--                        <font-awesome-icon :icon="['fas', 'plus-square']"/>-->
                        <font-awesome-icon icon="fa-regular fa-plus" class="fa-xl" />
                    </button>
                    <b-tooltip target="tooltip-create-provider" triggers="hover">
                        Add provider
                    </b-tooltip>
                </td>
                <td class="options-table__cell"><input type="text" v-model="inputs_users.name"
                                                       placeholder="Type first name..."></td>
                <td class="options-table__cell"><input type="text" v-model="inputs_users.lastname"
                                                       placeholder="Type last name..."></td>
                <td class="options-table__cell"><input type="text" v-model="inputs_users.email"
                                                       placeholder="Type email..."></td>

            </tr>

            <tr class=""
                v-for="(program, indexProgram) in displayData"
                :key="indexProgram">
                <td>
                    <button
                            @click="editItemUsers(indexProgram, displayData)"
                            class=""
                            style="background: inherit;"
                            :id="'tooltip-edit-user' + indexProgram"

                    >
                        <font-awesome-icon v-if="!program.isEditable" icon="fa-light fa-edit" class="fa-lg"/>
                        <font-awesome-icon v-else icon="fa-light fa-save" class="fa-lg"/>

                    </button>
                    <b-tooltip :target="'tooltip-edit-user' + indexProgram" triggers="hover">
                        Edit
                    </b-tooltip>
                </td>
                <td>
                    <button class="" type="button" value="Remove" style="background: inherit;"
                              :id="'tooltip-remove-provider' + indexProgram"
                              v-on:click="removeUsers(program)" variant="danger">
<!--                        <font-awesome-icon :icon="['fas', 'trash']"/>-->
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />
                    </button>
                    <b-tooltip :target="'tooltip-remove-provider' + indexProgram" triggers="hover">
                        Remove
                    </b-tooltip>
                </td>
                <td v-for="(column, indexColumn) in columns_users" :key="indexColumn">
                    <template v-if="program.is_active">
                        <input
                                v-model.lazy="program[column]"
                                :readonly="!program.isEditable"
                                :class="{ 'editable': program.isEditable }"
                        >
                    </template>
                    <template v-else>
                        <input
                                style="background: #f3f3f3;"
                                v-model.lazy="program[column]"
                                :readonly="!program.isEditable"
                                :class="{ 'editable': program.isEditable }"
                        >
                    </template>


                </td>
            </tr>
            </tbody>
        </v-table>
        <smart-pagination
                :currentPage.sync="currentPageUser"
                :totalPages="totalPagesUser"
                :maxPageLinks="5"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import { mapState } from 'vuex';

    export default {
        name: "UserTable",
        data() {
            return {
                // providers: [],
                currentPageUser: 1,
                totalPagesUser: 0,
                filtersUser: {
                    name: {value: '', keys: ['name']},
                },
                columns_users: [
                    'name', 'lastname', 'email'
                ],
                inputs_users: {
                    name: '',
                    lastname: '',
                    email: ''
                },
                counter: 0,
                isBlockedButtons: false
            }
        },
        computed: {
            ...mapState({
               users: state => state.userList.users
            })
        },
        methods: {
            createUsers: function () {

                if (this.inputs_users.name === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'User name field is required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })

                } else {

                    axios.post('developer/list', {

                            name: this.inputs_users.name,
                            lastname: this.inputs_users.lastname,
                            email: this.inputs_users.email

                    }).then(res => {

                        const newUser = res.data
                        newUser.isEditable = false
                        // console.log(newProvider)


                        this.$store.commit('createUser', newUser)

                        this.inputs_users.name = this.inputs_providers.lastname = this.inputs_users.email = '';
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'User successfully added!',
                            timer: 1000,
                            showConfirmButton: false,
                        })
                    })
                }
            },
            removeUsers: function (program) {
                // let currentElArr = this.users.indexOf(program);

                axios
                    .put('developer/list/' + program.id, {
                        is_active: false
                    })
                    .then(() => {
                            // this.$store.commit('removeUser', currentElArr)
                            this.$store.commit('deactivateUserFromList', program.id)

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Removed',
                                text: 'User successfully removed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        }
                    )
                    .catch(error => {
                        console.log(error)
                    });
            },
            editItemUsers(index, displayData) {
                if (!displayData[index].isEditable) {

                    if (this.isBlockedButtons && !displayData[index].isEditable) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Stop",
                            text: "Save previous field before editing next fields",
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                        return false;
                    }

                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = true;

                } else {
                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = false;
                    axios
                        .put('developer/list/' + displayData[index].id, {

                                name: displayData[index].name,
                                lastname: displayData[index].lastname,
                                email: displayData[index].email,

                        })
                        .then(
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Changed',
                                text: 'User successfully changed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        )
                        .catch(error => {
                            console.log(error)
                        });
                }
            }
        },
        created() {}
    }
</script>

<style scoped>

</style>