import Vue from 'vue'
import VueCookies from 'vue-cookies';
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BPagination } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './router/index'
import VuePaginate from 'vue-paginate'
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store/index'
// import axios from 'axios'
import VueExcelXlsx from "vue-excel-xlsx";
// import VueExcelXlsx from "./components/vue-excel-xlsx";
import SmartTable from 'vuejs-smart-table'
//Fontawasome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faTrashAlt, faClone, faPlusSquare, faMinusSquare, faFileExport, faList, faDownload, faShoppingCart, faTrashRestore, faExchangeAlt, faBinoculars, faBookOpen, faTrashRestoreAlt, faComment as fasComment  } from '@fortawesome/free-solid-svg-icons'
/* add some pro styles */
import { faCalendar, faFloppyDisk, faPlus, faBarsFilter, faMagnifyingGlass, faPaste, faComment as farComment, faBagsShopping } from '@fortawesome/pro-regular-svg-icons'
import { faFilePlus, faFolderOpen, faArrowDownToLine, faFolder, faStop, faEdit, faSave, faSquareCheck, faArrowUpArrowDown, faKeySkeleton, faFileExcel, faCartShopping } from '@fortawesome/pro-light-svg-icons'
import { faFilter, faArrowsRotate, faArrowRight, faArrowLeft, faEye, faXmark, faLink, faTrash, faTelescope, faCopy ,faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { } from '@fortawesome/pro-thin-svg-icons'
// import { faFeather } from '@fortawesome/pro-thin-svg-icons'
// import { faHorseSaddle } from '@fortawesome/pro-duotone-svg-icons'
// import { faFilter } from '@fortawesome/sharp-solid-svg-icons'
//Material design
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//Material end
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BModal } from 'bootstrap-vue'
import { VBModal } from 'bootstrap-vue'

//Auth2 Google
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.use(VueCookies);


Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

// axSet();

// Vue.prototype.$http = axios
// const token = localStorage.getItem('token')
// if (token) {
//     Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token

// } else {
//     Vue.prototype.$http.defaults.headers.common['Authorization'] = null
// }
//Fontawasome
library.add({faUserSecret, faBagsShopping, fasComment, farComment, faTriangleExclamation, faCartShopping, faFileExcel, faStop, faKeySkeleton, faCopy, faPaste, faArrowUpArrowDown, faSquareCheck, faTrashRestoreAlt, faFilePlus, faPlus, faArrowDownToLine, faFolder, faTelescope, faEdit, faXmark, faBarsFilter, faFloppyDisk, faLink, faArrowRight, faArrowLeft, faCalendar, faFolderOpen, faEye, faArrowsRotate, faFilter, faMagnifyingGlass, faTrash, faTrashAlt, faClone, faSave, faPlusSquare, faMinusSquare, faFileExport, faList, faDownload, faShoppingCart, faTrashRestore, faExchangeAlt, faBinoculars, faBookOpen})


Vue.component('apexchart', VueApexCharts)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-modal', BModal)
Vue.directive('b-modal', VBModal)
Vue.use(SmartTable)
Vue.use(VueExcelXlsx);
//Material use
Vue.use(VueMaterial)
// Vue.use(axios)
// Vue.use(axios)
//Material end
Vue.config.productionTip = false
Vue.use(VueSimpleAlert);
Vue.use(VueSweetalert2);
Vue.use(VuePaginate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('b-pagination', BPagination)
Vue.use(VueRouter)

export const eventBus = new Vue()

new Vue({
    store,

    render: h => h(App),
    router,
}).$mount('#app')

