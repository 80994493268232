<template>
    <div class="table-language-wrapper">
        <h2 class="options-main-title title-font-style">Countries</h2>
        <v-table :data="returnLanguages"
                 :filters="filtersLang"
                 :currentPage.sync="currentPageLang"
                 :pageSize="5"
                 @totalPagesChanged="totalPagesLang = $event"
                 class="table table-responsive-md btn-table">
            <thead slot="head">
            <tr>
                <th colspan="2"></th>
                <v-th sort-key="name">
                    Full name
                </v-th>
                <v-th sort-key="short_name">
                    Short Name
                </v-th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr>
                <td colspan="4">
                    <input placeholder="Filter by lang" class="form-control"
                           v-model="filtersLang.fullName.value"/>
                </td>
            </tr>
            <tr class="options-add ">
                <!--<td class="domain-table__cell"></td>-->
                <td colspan="2" class="domain-table__cell">
                    <button
                            style="width: auto; background: inherit;" class="" id="tooltip-create-lang"
                            v-on:click="createLang">
<!--                        <font-awesome-icon :icon="['fas', 'plus-square']"/>-->
                        <font-awesome-icon icon="fa-regular fa-plus" class="fa-xl" />
                    </button>
                    <b-tooltip target="tooltip-create-lang" triggers="hover">
                        Add new language
                    </b-tooltip>
                </td>
                <td class="options-table__cell">
                    <input type="text" v-model="inputs_langs.name"
                           placeholder="Type full name..."></td>
                <td class="options-table__cell"><input type="text" v-model="inputs_langs.short_name"
                                                       placeholder="Type short name..."></td>

            </tr>

            <tr class="" v-for="(program, indexProgram) in displayData"
                :key="indexProgram">
                <td>
                    <button
                            @click="editItemLang(indexProgram, displayData)"
                            class=""
                            style="background: inherit;"
                            :id="'tooltip-edit-lang' + indexProgram"

                    >
                        <b-tooltip :target="'tooltip-edit-lang' + indexProgram" triggers="hover">
                            Edit
                        </b-tooltip>
                        <font-awesome-icon v-if="!program.isEditable" icon="fa-light fa-edit" class="fa-lg"/>
                        <font-awesome-icon v-else icon="fa-light fa-save" class="fa-lg"/>
                    </button>
                </td>
                <td>
                    <button class="" type="button" value="Remove" style="background: inherit;"
                              :id="'tooltip-remove-lang' + indexProgram"
                              v-on:click="removeLang(program)" variant="danger">
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />

<!--                        <font-awesome-icon :icon="['fas', 'trash']"/>-->
                    </button>
                    <b-tooltip :target="'tooltip-remove-lang' + indexProgram" triggers="hover">
                        Remove
                    </b-tooltip>
                <td v-for="(column, indexColumn) in columns_langs" :key="indexColumn">
                    <input
                            v-model.lazy="program[column]"
                            :readonly="!program.isEditable"
                            :class="{ 'editable': program.isEditable }"
                    >

                </td>
            </tr>
            </tbody>
        </v-table>
        <smart-pagination
                :currentPage.sync="currentPageLang"
                :totalPages="totalPagesLang"
                :maxPageLinks="5"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import {mapState} from "vuex";
    // import {eventBus} from "../../main";

    export default {
        name: "LangsTable",
        data() {
            return {
                currentPageLang: 1,
                totalPagesLang: 0,
                filtersLang: {
                    fullName: {value: '', keys: ['name']},
                    shortName: {value: '', keys: ['short_name']},
                },
                columns_langs: [
                    'name', 'short_name'
                ],
                inputs_langs: {
                    name: '',
                    short_name: ''
                },
                isBlockedButtons: false
            }
        },
        computed: {
            ...mapState({
                returnLanguages: state => state.langList.languages
            })
        },
        methods: {
            removeLang: function (program) {
                let currentElArr = this.returnLanguages.indexOf(program);

                axios
                    .delete('language/list/' + program.id)
                    .then(() => {
                            this.$store.commit('removeLanguageOptions', currentElArr);
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Removed',
                                text: 'Language successfully removed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        }
                    )
                    .catch(error => {
                        console.log(error)
                    });
            },
            createLang: function () {

                if (this.inputs_langs.name === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Field is empty',
                        text: 'Language name field is required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })

                } else {

                    axios.post('language/list', {
                        data: {
                            name: this.inputs_langs.name,
                            short_name: this.inputs_langs.short_name
                        }
                    }).then(response => {
                        this.$store.commit('createLanguage', {
                            name: response.data.name,
                            short_name: response.data.short_name,
                            id: response.data.id,
                            isEditable: false
                        })

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Language successfully added!',
                            timer: 1000,
                            showConfirmButton: false,
                        })

                        this.inputs_langs.name = '';
                        this.inputs_langs.short_name = '';

                    })
                }
            },
            editItemLang(index, displayData) {
                if (!displayData[index].isEditable) {

                    if (this.isBlockedButtons && !displayData[index].isEditable) {
                        this.$swal.fire({
                            icon: "error",
                            title: "Stop",
                            text: "Save previous field before editing next fields",
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                        return false;
                    }

                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = true;

                } else {
                    displayData[index].isEditable = !displayData[index].isEditable
                    this.isBlockedButtons = false;
                    axios
                        .put('language/list/' + displayData[index].id + '/', {
                            data: {
                                name: displayData[index].name,
                                short_name: displayData[index].short_name,
                            }
                        })
                        .then(
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Changed',
                                text: 'Lang successfully changed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        )
                        .catch(error => {
                            console.log(error)
                        });
                }
            }
        },
        created() {}
    }
</script>

<style scoped>

</style>