<template>
    <div class="sorting-wrapper">
        <div>
            <input type="radio" id="a-z" :value="filter_value" v-model="inputValue">
            <label for="a-z">Sort A -> Z</label>
        </div>
        <div>
            <input type="radio" id="z-a" :value="'-'+filter_value" v-model="inputValue">
            <label for="z-a">Sort Z -> A</label>
        </div>
        <div>
            <input type="radio" id="normal" :value="reset" v-model="inputValue">
            <label for="normal">Reset</label>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Sorting",
        props: ['filter_type', 'filter_value'],
        data() {
            return {
                inputValue: '',
                reset: ''
            }
        },

        computed: {
            ...mapState({
                returnFilters: state => state.filters.filters,
            }),
        },
        watch: {
            inputValue: {
                handler(val) {
                    let keyValue = this.filter_type
                    this.$store.commit('updateFilters', {keyValue, val})
                    this.$store.dispatch('getData', this.returnFilters)
                }
            },
            returnFilters: {
                handler() {
                    this.inputValue = ''
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    .sorting-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;
        div {
            width: 50%;
            display: flex;
            justify-content: flex-start;
        }
        input {
            width: auto;
            margin-right: 10px;

        }
        label {
            font-weight: 400;
        }
    }
</style>