<template>
    <div class="graphs-wrapper">
        <D3BarChart :config="chart_config" :datum="chart_data" :title="'Country Stats'"></D3BarChart>
        <select v-model="chart_config.values">
<!--            <option value="" disabled>Choose status</option>-->
            <option :value="[d]" v-for="(d, index) in ['Commercial Content', 'Starter Content', 'Activated Domain', 'Live Domain', 'New Domain', 'Total Domain']" :key="index">{{d}}</option>
        </select>
        <select v-model="chart_config.currentKey">
<!--            <option value="" disabled>Choose status</option>-->
            <option :value="d.country" v-for="(d, index) in chart_data" :key="index">{{d.country}}</option>
        </select>
<!--        <button @click="this.updateCountriesStats">Update</button>-->
    </div>
</template>

<script>
    import {D3BarChart} from 'vue-d3-charts';
    import {mapState} from "vuex";

    export default {
        name: "CountryStats",
        components: {
            D3BarChart,
        },
        data() {
            return {
                chart_data: [
                    // {'Commercial Content': 1648, 'Starter Content': 9613, 'Activated Domain': 4563, 'Live Domain': 3254, 'New Domain': 3243, 'Total Domain': 4354,   country: 'EN'},
                    // {'Commercial Content': 1648, 'Starter Content': 9613, 'Activated Domain': 4563, 'Live Domain': 3254, 'New Domain': 3243, 'Total Domain': 4354,   country: 'BE'},
                    // {'Commercial Content': 1648, 'Starter Content': 9613, 'Activated Domain': 4563, 'Live Domain': 3254, 'New Domain': 3243, 'Total Domain': 4354,   country: 'LK'},
                    // {'Commercial Content': 1648, 'Starter Content': 9613, 'Activated Domain': 4563, 'Live Domain': 3254, 'New Domain': 3243, 'Total Domain': 4354,   country: 'TR'},
                ],
                chart_config: {
                    key: 'country',
                    currentKey: 'EN',
                    values: ['Commercial Content', 'Starter Content', 'Activated Domain', 'Live Domain', 'New Domain', 'Total Domain'],
                    axis: {
                        yTicks: 3
                    },
                    color: {
                        default: '#222f3e',
                        current: '#41B882'
                    }
                }
            }
        },
        computed: {
            ...mapState({
                returnDomainStatuses: state => state.graphsData.stats,
                // returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                // returnRegionalOrders: state => state.graphsData.regionalOrders,
            })
        },
        watch: {
            returnRegionalOrders: {
                handler(val) {
                    console.log(val)
                    this.updateCountriesStats();
                },
                deep: true
            },
            'returnDomainStatuses.country_stats': {
                async handler() {
                            this.updateCountriesStats();
                },
                immediate: true
            }
        },
        methods: {
            updateCountriesStats() {
                let readyCountries = [];
                // Object.keys(this.returnDomainStatuses.country_stats)

                if (typeof this.returnDomainStatuses.country_stats !== 'undefined' && this.returnDomainStatuses.country_stats !== null) {
                    let arrayOfCountries = Object.keys(this.returnDomainStatuses.country_stats)
                    arrayOfCountries.forEach((country, index) => {
                        readyCountries.push({country: country})

                        // console.log(this.returnDomainStatuses.country_stats[`${country}`].content_commercial)
                        readyCountries[index]['Commercial Content'] = this.returnDomainStatuses.country_stats[`${country}`].content_commercial
                        readyCountries[index]['Starter Content'] = this.returnDomainStatuses.country_stats[`${country}`].content_starter
                        readyCountries[index]['Activated Domain'] = this.returnDomainStatuses.country_stats[`${country}`].domain_activated
                        readyCountries[index]['Live Domain'] = this.returnDomainStatuses.country_stats[`${country}`].domain_live
                        readyCountries[index]['New Domain'] = this.returnDomainStatuses.country_stats[`${country}`].domain_new
                        readyCountries[index]['Total Domain'] = this.returnDomainStatuses.country_stats[`${country}`].domain_total

                    })
                } else {
                    // 👇️ this runs
                    console.log('⛔️ Object is falsy');
                }


                this.chart_data = readyCountries

                // this.chart_data = readyOrders.sort((a, b) => (a["Month left"] > b["Month left"]) ? 1 : -1);
                // console.log(Object.entries(this.returnRegionalOrders))

            }
        },
        mounted() {
            // this.updateCountriesStats();

        }
    }
</script>

<style scoped lang="scss">

</style>