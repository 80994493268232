<template>

    <div class="homepage container-fluid body-padding-top">
        <template v-if="loading">
            <div class="preloader">
                <div class="loader_new"></div>F
            </div>
        </template>
        <template v-else-if="isLoggedIn">
            <div class="row" style="margin-bottom: 0px;">
                <div class="col-sm-12 col-md-6" style="text-align: left;">
                    <div class="graph-range" style="height: auto; text-align: left;">
                        <div class="graph-range-wrapper" style="display: flex; width: 410px;">
                            <input type="date" v-model="startDate" class="main-input"
                                   style="border: 1px solid rgb(38, 48, 62); margin: 0 10px 0 0;"/>
                            <input type="date" v-model="currentDate" class="main-input"
                                   style="border: 1px solid rgb(38, 48, 62); margin: 0 10px;"/>
                            <button v-on:click="getGraphDomainsRange" class="main-button" style="margin-bottom: 0;">Update</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6" style="text-align: right">
                    <div class="homepage-status" >
                        <h3 class="title-font-style" style="font-size: 18px; text-align: left;
                            transform: translateX(-30%);">Hello, {{username}} | Login Status: <span
                            style="color: green;">Success</span></h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-5">
                    <LinkSpams />
                </div>
                <div class="col-sm-12 col-md-5">
                    <OrderCapacityNew />
                </div>
                <div class="col-sm-12 col-md-2">
                    <OrdersCreatedApex />
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-5">
                    <LiveDomains />
                </div>
                <div class="col-sm-12 col-md-5">
                    <CountryStats />
                </div>
                <div class="col-sm-12 col-md-2">
                    <MonthDueLinks />
                </div>
            </div>
        </template>

        <template v-else-if="this.$store.state.status != 'success'">
            <div class="row">
                <div class="col-sm-12">
                    <div class="homepage-status" style="padding-top: 200px;">
                        <h3>Hello! Please login or re-login to your account.</h3>
                        <p>{{error}}</p>
                        <p>Login Status: <span style="color: red;">Not logged in</span></p>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="preloader">
                <div class="loader_new"></div>
            </div>
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
    // import moment from "moment";
    import {mapState} from "vuex";
    import { mapGetters } from 'vuex'
    import JQuery from 'jquery';
    import LiveDomains from "../components/LiveDomains";
    import LinkSpams from "../components/LinkSpams";
    import OrderCapacityNew from "../components/OrderCapacityNew";
    import CountryStats from "../components/CountryStats";
    import OrdersCreatedApex from "../components/OrdersCreatedApex";
    import MonthDueLinks from "../components/MonthDueLinks";
    window.$ = JQuery


    export default {
        name: "Home",
        components: {
            OrdersCreatedApex,
            MonthDueLinks,
            OrderCapacityNew,
            LiveDomains,
            LinkSpams,
            CountryStats},
        data() {
            return {
                loading: false,
                username: '',
                email: '',
                error: '',
                recentUnfinishedOrders: [],
                domainsMatchingList: [],
                domainsMatchingListNames: [],
                stats: [],

                seriesCustomersLive: [{
                    name: 'Customers Live',
                    data: []
                }, {
                    name: 'Customers Sites Live',
                    data: []
                }],
                customersLiveOptions: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    colors: ['#26303e', '#008100'],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: ''
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                },
                seriesTotalLinks: [{
                    name: 'Links Live',
                    data: []
                }, {
                    name: 'Links found',
                    data: []
                }],
                totalLinksOptions: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    colors: ['#26303e', '#008100'],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: ''
                        }
                    },
                    fill: {
                        opacity: 1
                    }
                },

                seriesUnorderedLinks: [{
                    name: 'Unordered Links',
                    // data: this.returnUnorderedCustomersLinks.unorderedLinks
                    data: []
                    // data: [44,22,44]
                }],
                unorderedLinksOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                    },
                    colors: ['#26303e'],
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            columnWidth: '50%',
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: 2
                    },

                    grid: {
                        row: {
                            colors: ['#fff', '#f2f2f2']
                        }
                    },
                    xaxis: {
                        labels: {
                            rotate: -45
                        },
                        // categories: this.returnUnorderedCustomersLinks.unorderedCustomers,
                        categories: [],
                        tickPlacement: 'on'
                    },
                    yaxis: {
                        title: {
                            text: 'Unordered Links',
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            type: "horizontal",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined,
                            inverseColors: true,
                            opacityFrom: 0.85,
                            opacityTo: 0.85,
                            stops: [50, 0, 100]
                        },
                    }
                },

                domainOptions: {
                    chart: {
                        id: 'Domains Chart',
                        events: {}
                    },
                    colors: ['#26303e', '#008100'],
                    xaxis: {
                        categories: [],
                        tickAmount: 12
                    },
                    markers: {
                        // size: 1,
                    }
                },
                spamOptions: {
                    chart: {
                        id: 'Spam Chart'
                    },
                    colors: ['#26303e'],
                    xaxis: {
                        categories: [],
                        tickAmount: 12
                    },
                    markers: {
                        // size: 1,
                    }
                },
                linksOptions: {
                    chart: {
                        id: 'Total links amount'
                    },
                    colors: ['#26303e'],
                    xaxis: {
                        categories: [],
                        tickAmount: 12
                    },
                    markers: {
                        // size: 1,
                    }
                },
                series: [
                    {
                        name: 'Domains live',
                        data: []
                    },
                    {
                        name: 'Domains to be live',
                        data: []
                    }
                ],
                seriesSpam: [
                    {
                        name: 'Spam',
                        data: []
                    }
                ],
                seriesLinks: [
                    {
                        name: 'Links',
                        data: []
                    }
                ],

                //Regional Apex Chart
                seriesRegionalOrders: [
                    //     {
                    //     name: 'PRODUCT A',
                    //     data: [44, 55, 41, 67, 22, 43]
                    // },
                    // {
                    //     name: 'Min Possible Orders',
                    //     data: []
                    // }, {
                    //     name: 'Max Possible Orders',
                    //     data: []
                    // }, {
                    //     name: 'Possible Orders',
                    //     data: []
                    // },
                    // {
                    //     name: 'Month Left',
                    //     data: []
                    // }
                ],
                chartOptionsRegionalOrders: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        // stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom',
                                offsetX: -10,
                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10
                        },
                    },
                    xaxis: {
                        // type: 'datetime',
                        categories: [],
                    },
                    // yaxis: {
                    //     logarithmic: true,
                    // },
                    legend: {
                        position: 'right',
                        offsetY: 40
                    },
                    fill: {
                        opacity: 1
                    }
                },
                //End Regional Apex Chart
                currentUpdatedCustomer: []
            }
        },
        computed: {
            ...mapState({
                returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                returnLogStatus: state => state.loginState.token,
            }),
            ...mapGetters([
                'isLoggedIn'
            ]),
            startDate: {
                // getter
                get() {
                    return this.$store.state.graphsData.startDate2
                },
                // setter
                set(newValue) {
                    this.$store.state.graphsData.startDate2 = newValue
                }
            },
            currentDate: {
                get() {
                    return this.$store.state.graphsData.finishDate2
                },
                set(newValue) {
                    this.$store.state.graphsData.finishDate2 = newValue
                }
            }
        },
        methods: {
            showLastUpdatesModal(e, id) {
                e.preventDefault();
                this.currentUpdatedCustomer = []
                this.currentUpdatedCustomer = 'loading'
                axios
                .get('/customer-list/' + id)
                .then(res => {
                    this.currentUpdatedCustomer = res.data
                })

            },
            async getGraphDomainsRange() {

                await this.$store.dispatch("getGraphDomainsLive", {
                    formatDateBefore: this.startDate,
                    currentDate: this.currentDate
                })
                this.pushGraphDomainsLiveToData();

            },
            getUserInfo() {
                axios.get('users/get-user/')
                    .then(resp => {
                        if (resp.data.error) {
                            this.error = resp.data.error
                        }
                        let strName = resp.data.username
                        let beforeDot = strName.substring(0, strName.indexOf("."));
                        let firstName = beforeDot.charAt(0).toUpperCase() + beforeDot.slice(1)
                        let afterDot = strName.substr(strName.indexOf('.')).slice(1);
                        let secondName = afterDot.charAt(0).toUpperCase() + afterDot.slice(1)
                        this.username = firstName + ' ' + secondName;
                        localStorage.setItem('currentUser', resp.data.username)
                        this.email = resp.data.user

                    })
                    .catch(() => {
                        this.logout()
                    })
            },
            logout: function () {
                this.$store.dispatch('logout')
                localStorage.removeItem('currentUser')
            },
            getBestDomainsMatchingKeyNames() {
                if (this.domainsMatchingList.length > 0) {
                    this.domainsMatchingList.forEach(item => {
                        this.domainsMatchingListNames.push(item['0'])
                    });
                }
            },
            pushUnorderedLinksToData() {
                this.unorderedLinksOptions = {
                    ...this.unorderedLinksOptions, ...{
                        xaxis: {
                            labels: {
                                rotate: -45
                            },
                            categories: this.returnUnorderedCustomersLinks.unorderedCustomers,
                            tickPlacement: 'on'
                        }
                    }
                }
                this.seriesUnorderedLinks = [{
                    data: this.returnUnorderedCustomersLinks.unorderedLinks
                }]
            },
            pushGraphDomainsLiveToData() {
                this.totalLinksOptions = {
                    ...this.totalLinksOptions, ...{
                        xaxis: {
                            categories: this.returnGraphDomainsData.dateGraph,
                            tickAmount: 12
                        }
                    }
                }

                this.seriesTotalLinks['0'].data = this.returnGraphDomainsData.totalLinksLive
                this.seriesTotalLinks['1'].data = this.returnGraphDomainsData.totalLinksFound

                this.customersLiveOptions = {
                    ...this.customersLiveOptions, ...{
                        xaxis: {
                            categories: this.returnGraphDomainsData.dateGraph,
                            tickAmount: 12
                        }
                    }
                }

                this.seriesCustomersLive['0'].data = this.returnGraphDomainsData.customersLive
                this.seriesCustomersLive['1'].data = this.returnGraphDomainsData.customersSitesLive
                // this.domainOptions = {
                //     ...this.domainOptions, ...{
                //         xaxis: {
                //             categories: this.returnGraphDomainsData.dateGraph,
                //             tickAmount: 12
                //         }
                //     }
                // }

                // this.series['0'].data = this.returnGraphDomainsData.domainsToBeLive;
                // this.series['2'].data = this.returnGraphDomainsData.domainsLive;
                // this.series['1'].data = this.returnGraphDomainsData.domainsDown;

                this.spamOptions = {
                    ...this.spamOptions, ...{
                        xaxis: {
                            categories: this.returnGraphDomainsData.dateGraph,
                            tickAmount: 12
                        }
                    }
                }
                this.seriesSpam['0'].data = this.returnGraphDomainsData.spamAnchors

                this.linksOptions = {
                    ...this.linksOptions, ...{
                        xaxis: {
                            categories: this.returnGraphDomainsData.dateGraph,
                            tickAmount: 12
                        }
                    }
                }
                this.seriesLinks['0'].data = this.returnGraphDomainsData.totalLinks

            },
            pushGraphRegionalOrders() {
                let filteredArray = Object.values(this.returnRegionalOrders).sort((a, b) => (a.month_left < b.month_left) ? 1 : -1)
                let languages = []
                let monthLeft = []
                let customerNames = []
                let langsCusts = []
                filteredArray.filter(item => {
                    languages.push(item.language)
                })
                filteredArray.filter(item => {
                    customerNames.push(item.maximal_possible_orders_for_customer_name)
                })
                languages.forEach((lang, index) => {
                    langsCusts.push(customerNames[index] + ' - ' + lang)
                })
                filteredArray.filter(item => {
                    monthLeft.push(item.month_left)
                })

                this.chartOptionsRegionalOrders = {
                    ...this.chartOptionsRegionalOrders, ...{
                        xaxis: {
                            categories: langsCusts,
                            tickAmount: 24
                        },
                        yaxis: {
                            show: true,
                            min: 0,
                            max: 12,
                        }
                    }
                }
                let newSeries = [{
                    name: 'Month Left',
                    data: monthLeft
                }]
                this.$set(this, "seriesRegionalOrders", newSeries);

            }
        },
        // created() {

        // },
        mounted() {
            if (this.$store.getters.isLoggedIn) {
                this.getUserInfo();
            }
            // setTimeout(() => {
            //     if (this.$store.getters.isLoggedIn) {
            //     }
            // }, 5000)
        }
    }
</script>

<style scoped lang="scss">
    .homepage-item ul.recent-updated-customers-ul {
        padding-left: 0!important;
    }
    .recent-updated-customers-li {
        overflow: hidden;
    }
    .recent-updated-customers-button {
        margin-bottom: 5px;
        width: 100%;
    }

    .country-stats {
        table {
            tr {
                td {
                    padding: 0 5px;
                    border: 1px solid rgb(38, 48, 62);
                }
            }
        }
    }

    .status-stats {
        table {
            tr {
                td {
                    padding: 0 5px;
                    border: 1px solid rgb(38, 48, 62);
                }
            }
        }
    }

    .updateGraphButton {
        background-color: rgb(38, 48, 62);
        color: #fff;
        padding: 0 20px;
    }

    .small {
        max-width: 600px;
        margin: 150px auto;
    }

    .homepage {
        h5 {
            /*background-color: rgb(38, 48, 62);*/
            /*background-color: rgb(41, 51, 66);*/
            color: #000;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .homepage {
        &-item {
            /*background-color: rgb(242, 242, 242);*/
            color: #26303E !important;
            padding: 15px 10px;
            /*border-radius: 5px;*/
            display: inline-block;
            height: 440px;
            overflow-y: scroll;
            width: 100%;
            box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
            /*border: 1px solid rgb(38, 48, 62);*/
            &-noscroll {
                overflow-y: hidden;
            }
            &:hover {
                text-decoration: none;
            }
            ul {
                text-align: left;
                padding-left: 20px !important;
                list-style: none;
            }
        }
    }
</style>