<template>
    <D3BarChart :config="chart_config" :datum="chart_data"></D3BarChart>
</template>

<script>
    import { D3BarChart } from 'vue-d3-charts';
    import {mapState} from "vuex";

    export default {
        components: {
            D3BarChart,
        },
        data() {
            return {
                chart_data: [
                    //...
                    // {lorem: 1648, ipsum: 9613, dolor: 1390, sit: 9130, year: '2007'},
                    // {lorem: 2479, ipsum: 6315, dolor: 1560, sit: 6150, year: '2008'},
                    // {lorem: 3200, ipsum: 12541, dolor: 5421, sit: 2541, year: '2009'},
                ],
                chart_config: {
                    key: 'customer',
                    values: ['month_covered'],
                    orientation: 'horizontal',
                    axis: {
                        yTicks: 6
                    },
                    color: {
                        keys: {
                            // active_month: '#ecac7d',
                            // avg_links_per_month: '#F98B3B',
                            // domains: '#F26643',
                            // domains_shortage: '#FF463D',
                            // links: '#C92C39',
                            month_covered: '#23CC90',
                        },
                    },
                },
                newData: [],
                // flattenedArray: []
            };
        },
        computed: {
            ...mapState({
                // returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                returnRegionalOrders: state => state.graphsData.regionalOrders,
            })
        },
        mounted() {
            this.convertData();
        },
        methods: {
            convertData() {
                this.newData = Object.values(this.returnRegionalOrders);

               this.chart_data = this.newData.reduce((accumulator, currentArray) => {
                    // Concatenate the current array with the accumulator
                    return accumulator.concat(currentArray).filter((item) => item.month_covered < 20);
                }, []);
               // this.chart_data.filter((item) => item.month_covered < 20)
            }
        }
    };
</script>

<style lang="scss">
.chart.chart--barchart {
    overflow: initial;
    padding-left: 75px;
}

</style>
