<template>

    <div class="option-list" style="margin-top: 20px;">
        <h2 class="options-main-title title-font-style">Settings</h2>
        <v-table :data="optionsData"
                 :currentPage.sync="currentPageOption"
                 :pageSize="100"
                 @totalPagesChanged="totalPagesOption = $event"
                 class="table table-responsive-md btn-table">
            <thead slot="head">
            <tr class="md-table-row">
                <th colspan="2"></th>
                <th>Name</th>
                <th>Value</th>
                <th>Meta Name</th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr class="" id="add-new-option">
                <!--<td class="domain-table__cell"></td>-->
                <td colspan="2" class="">
                    <button
                            style="width: auto; background: inherit;" class="" id="tooltip-add-google"
                            v-on:click="createOption">
<!--                        <font-awesome-icon :icon="['fas', 'plus-square']"/>-->
                        <font-awesome-icon icon="fa-regular fa-plus" class="fa-xl" />
                    </button>
                    <b-tooltip target="tooltip-add-google" triggers="hover">
                        Add new
                    </b-tooltip>
                </td>
                <td class=""><input class="column-input-search" type="text" v-model="optionInputs.name"
                                    placeholder="Type name..."></td>
                <td class=""><input class="column-input-search" type="text" v-model="optionInputs.value"
                                    placeholder="Type value..."></td>
                <td class=""><input class="column-input-search" type="text"
                                    v-model="optionInputs.meta_name"
                                    placeholder="Type meta name..."></td>
            </tr>
            <tr class="" v-for="(option, indexProgram) in displayData"
                :key="indexProgram">
                <td>
                    <button
                            @click="editOption(indexProgram, displayData)"
                            class=""
                            style="background: inherit;"
                            :id="'tooltip-edit-google' + indexProgram"

                    >
<!--                        <font-awesome-icon v-if="option.isEditable" :icon="['fas', 'save']"/>-->
<!--                        <font-awesome-icon v-else :icon="['fas', 'edit']"/>-->
                        <font-awesome-icon v-if="!option.isEditable" icon="fa-light fa-edit" class="fa-lg"/>
                        <font-awesome-icon v-else icon="fa-light fa-save" class="fa-lg"/>
                    </button>
                    <b-tooltip :target="'tooltip-edit-google' + indexProgram" triggers="hover">
                        Edit
                    </b-tooltip>
                </td>
                <td>
                    <button class="" type="button" value="Remove" style="background: inherit;"
                              :id="'tooltip-remove-google' + indexProgram"
                              v-on:click="deleteOption(option)" variant="danger">
<!--                        <font-awesome-icon :icon="['fas', 'trash']"/>-->
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-lg" />
                    </button>
                    <b-tooltip :target="'tooltip-remove-google' + indexProgram" triggers="hover">
                        Remove
                    </b-tooltip>
                </td>
                <td><input
                        v-model="option.name"
                        :readonly="!option.isEditable"
                        :class="{ 'editable': option.isEditable }"
                ></td>
                <td style="display: flex; align-items: center;"><input
                        v-model="option.value"
                        :readonly="!option.isEditable"
                        :class="{ 'editable': option.isEditable }"
                ><a :href="option.value" v-if="option.value.includes('http')" style="display: inline-block; margin-left: 5px;" target="_blank"><font-awesome-icon icon="fa-solid fa-link" class="" /></a>
                </td>
                <td><input
                        v-model="option.meta_value"
                        :readonly="!option.isEditable"
                        :class="{ 'editable': option.isEditable }"
                ></td>
            </tr>
            </tbody>
        </v-table>
        <smart-pagination
                :currentPage.sync="currentPageOption"
                :totalPages="totalPagesOption"
                :maxPageLinks="5"
        />
    </div>

</template>

<script>
    import axios from "axios";
    import {mapState} from "vuex";

    export default {
        name: "OptionsTable",
        data() {
            return {
                // optionsData: [],
                domain_url: Array,
                keyword_url: Array,
                currentPageOption: 1,
                totalPagesOption: 0,
                optionInputs: {
                    meta_name: '',
                    name: '',
                    value: '',
                    id: null
                },
                counter: 0,
            }
        },
        computed: {
            ...mapState({
                optionsData: state => state.optionsData.optionsData
            })
        },
        methods: {
            // getOptionData() {
            //     axios
            //         .get("options-list/")
            //         .then(response => {
            //             this.optionsData = response.data.map(option => ({isEditable: false, ...option})).reverse();
            //         }).catch(function (error) {
            //         if (error.response) {
            //             console.log(error.response.status);
            //         }
            //     });
            //
            // },
            // getDomainUrl() {
            //     axios
            //         .get("options-list/domain_doc_url")
            //         .then(response => {
            //             this.domain_url = response.data
            //         }).catch(function (error) {
            //         if (error.response) {
            //             console.log(error.response.status);
            //         }
            //     });
            // },
            saveDomainUrl() {
                axios
                    .put("options-list/domain_doc_url", {
                        data: this.domain_url
                    }).then(() => {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Done',
                            text: 'Domains url successfully updated!',
                            timer: 600,
                            showConfirmButton: false,
                        })
                    }
                ).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });

            },
            // getKeywordUrl() {
            //     axios
            //         .get("options-list/keyword_doc_url")
            //         .then(response => {
            //             this.keyword_url = response.data
            //         }).catch(function (error) {
            //         if (error.response) {
            //             console.log(error.response.status);
            //         }
            //     });
            //
            // },
            saveKeywordUrl() {
                axios
                    .put("options-list/keyword_doc_url", {
                        data: this.keyword_url
                    }).then(this.$swal.fire({
                    icon: 'success',
                    title: 'Done',
                    text: 'Keywords url successfully updated!',
                    timer: 600,
                    showConfirmButton: false,
                })).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });

            },
            createOption() {

                if (this.optionInputs.meta_name === '' || this.optionInputs.name === '' || this.optionInputs.value === '') {

                    this.$swal.fire({
                        icon: 'error',
                        title: 'Fields are empty',
                        text: 'All fields are required!',
                        // timer: 1000,
                        showConfirmButton: true,
                    })
                } else {
                    let tempID = null;
                    axios.post('options-list/', {
                        data: {
                            meta_value: this.optionInputs.meta_name,
                            name: this.optionInputs.name,
                            value: this.optionInputs.value
                        }
                    }).catch(error => {
                        if (error.response) {
                            console.log(error.response.status);
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Field unique',
                                text: 'Field name must be unique!',
                                // timer: 1000,
                                showConfirmButton: true,
                            })
                            this.optionInputs.meta_name = '';
                            this.optionInputs.name = '';
                            this.optionInputs.value = '';
                        }
                    }).then(response => {
                            tempID = response.data.id;

                            if (tempID) {

                                this.$store.commit('createOption', {
                                    meta_value: this.optionInputs.meta_name,
                                    name: this.optionInputs.name,
                                    value: this.optionInputs.value,
                                    isEditable: false,
                                    id: tempID
                                })

                                // this.optionsData.unshift({
                                //     meta_value: this.optionInputs.meta_name,
                                //     name: this.optionInputs.name,
                                //     value: this.optionInputs.value,
                                //     isEditable: false,
                                //     id: tempID
                                // })

                                this.optionInputs.meta_name = '';
                                this.optionInputs.name = '';
                                this.optionInputs.value = '';
                                tempID = null;

                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Done',
                                    text: 'Option successfully added!',
                                    timer: 1000,
                                    showConfirmButton: false,
                                })
                                // console.log('NET')
                            }
                        }
                    )
                }
            },
            async editOption(index, displayData) {
                if (this.counter === 0) {

                    displayData[index].isEditable = !displayData[index].isEditable


                    this.counter = 1;
                } else {
                    displayData[index].isEditable = !displayData[index].isEditable

                    await axios
                        .put('options-crud/' + displayData[index].id, {
                            data: {
                                meta_value: displayData[index].meta_value,
                                name: displayData[index].name,
                                value: displayData[index].value
                            }
                        })
                        .then(
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Changed',
                                text: 'Option successfully changed!',
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        )
                        .catch(error => {
                            console.log(error)
                        });

                    this.counter = 0;
                }
            },
            deleteOption(program) {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You will remove option",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, remove it!'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // program.is_active = false
                        // program.status = 'DEACTIVATED'
                        let statusResponse = null
                        axios
                            .delete('options-crud/' + program.id)
                            .then(response => {
                                statusResponse = response.status
                                if (statusResponse == 201) {
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'Removed',
                                        text: 'Option successfully removed!',
                                        timer: 700,
                                        showConfirmButton: false,
                                    })
                                    let removeIndex = this.optionsData.map(function (item) {
                                        return item.id;
                                    }).indexOf(program.id)
                                    this.optionsData.splice(removeIndex, 1)
                                }
                            })
                            .catch(error => {
                                console.log(error)
                            });
                    }
                })
            }
        },
        created() {
            // this.getOptionData();
            if (this.$store.getters.isLoggedIn) {
                // this.getDomainUrl();
                // this.getKeywordUrl();
            }

        }
    }
</script>

<style scoped>

</style>