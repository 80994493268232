import axios from "axios";

export default {
    actions: {
        fetchCustomersContent(ctx) {
            return axios
                .get("customer-group-list-short/")
                .then(response => {
                    const customers = response.data
                    ctx.commit('updateCustomersContent', customers)
                })
                // .catch(error => console.log(error));
                .catch(function (error) {
                    if (error.response) {
                        // console.log(error.response.data);
                        console.log(error.response.status);
                    }
                })
        },
        fetchCustomerSites(ctx) {
            return axios
                .get("customer-site-list-short/")
                .then(response => {
                    const customerSites = response.data
                    ctx.commit('updateCustomerSites', customerSites)
                })
                // .catch(error => console.log(error));
                .catch(function (error) {
                    if (error.response) {
                        // console.log(error.response.data);
                        console.log(error.response.status);
                    }
                })
        },
        fetchDomainsContent(ctx) {
            return axios
                .get("domain-list-short/")
                .then(response => {
                    const domains = response.data
                    ctx.commit('updateDomainsContent', domains)
                })
                // .catch(error => console.log(error));
                .catch(function (error) {
                    if (error.response) {
                        // console.log(error.response.data);
                        console.log(error.response.status);
                    }
                })
        },
        fetchBatchNumbers(ctx) {
            axios
                .get("content-order-list-short/")
                .then(response => {
                    const batchNumbers = response.data
                    ctx.commit('updateBatchNumbers', batchNumbers)
                })
                // .catch(error => console.log(error));
                .catch(function (error) {
                    if (error.response) {
                        // console.log(error.response.data);
                        console.log(error.response.status);
                    }
                })
                .finally(() => (this.loading = false))
        }
    },
    mutations: {
        updateCustomersContent(state, customers) {
            state.customers = customers;
        },
        updateCustomerSites(state, customerSites) {
            state.customerSites = customerSites;
        },
        updateDomainsContent(state, domains) {
            state.domains = domains;
        },
        updateBatchNumbers(state, batchNumbers) {
            state.batchNumbers = batchNumbers;
        },
        addNewCustomer(state, {newCust, customerName}) {
            state.customers.unshift({id: newCust.customer_group, name: customerName});
        },
    },
    state: {
        customers: [],
        customerSites: [],
        domains: [],
        batchNumbers: []
    },
    getters: {

    }
}