import axios from "axios";

export default  {
    state: {
        status: '',
        token: '',
        user: {},
        // shopCard: JSON.parse(localStorage.getItem('orderListStore'))
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, {accessTok, user_email}) {
            // console.log(accessTok, user_email)
            state.status = 'success'
            state.token = accessTok
            state.user = user_email
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        updateToken(state, newToken) {
            state.token = newToken
        }

    },
    actions: {
        async loginGoogle({commit}, user_details) {
            commit('auth_request')
            // console.log(user_details)
            localStorage.setItem('token', user_details.accessTok)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user_details.accessTok
            commit('auth_success', user_details)
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status
    }
}