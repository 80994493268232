<template>
    <div class="row" style="margin-bottom: 10px;">
        <template v-if="loading">
            <div class="row">
                <div class="col-sm-12">
                    <div class="loader" style="margin: 0 auto;">Loading...</div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="col-sm-12 col-md-4 " style="text-align: left;">
                <template v-if="selectedDomains.length > 0">
                    <button class="main-button" @click="deselectAllTest" v-if="selectedCategory.length > 0">Deselect all</button>
                </template>
                <template v-else>
                    <button class="main-button" @click="selectAllTest" v-if="selectedCategory.length > 0">Select all</button>
                </template>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="choose-provider-wrapper">
                    <label for="providers" style="font-weight: bold; margin-right: 10px;">Choose provider: </label>
                    <select name="providers" id="providers" class="order-content-button"
                            v-model="orderList.content_provider_id">
                        <option v-for="provider in providers" :key="provider.id" :value="provider.id">
                            {{provider.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-4" style="text-align: right;">
                <button @click="orderContent" class="main-button main-button-order">
                    Order
                </button>
            </div>
            <div class="col-sm-12" style="margin-top: 20px;">
                <div class="starter-content-table">
                    <v-table :data="filteredProducts"
                             :filters="filtersDomains"
                             :currentPage.sync="currentPage"
                             :pageSize="20"
                             @totalPagesChanged="totalPages = $event"
                             selectionMode="multiple"
                             selectedClass="table-info"
                             @stateChanged="selectedDomains = $event.selectedRows"
                             @selectionChanged="selectedDomains = $event"
                             class="table table-responsive-md btn-table table-hover"
                             ref="startContentTable">
                        <thead slot="head">
                        <tr class="md-table-row">

                            <v-th sort-key="name">Name</v-th>
                            <v-th sort-key="subject">Subject</v-th>
                            <th>
                                <template>
                                    <div>
                                        <b-form-select class="order-content-button" v-model="selectedCategory" :options="optionsLanguageLive">SE
                                        </b-form-select>
                                    </div>
                                </template>
                            </th>
                            <v-th sort-key="status">Status</v-th>
                        </tr>
                        </thead>
                        <tbody slot="body" slot-scope="{displayData}">
                        <tr class="">
                            <!--                        <td></td>-->
                            <td><input placeholder="Filter by name" class="form-control"
                                       v-model="filtersDomains.domainName.value"/></td>
                            <td><input placeholder="Filter by subject" class="form-control"
                                       v-model="filtersDomains.subjectName.value"/></td>
                            <td><input placeholder="Filter by language" class="form-control"
                                       v-model="filtersDomains.languageName.value"/></td>
                            <td><input placeholder="Filter by status" class="form-control"
                                       v-model="filtersDomains.statusName.value"/></td>
                        </tr>
                        <v-tr class="" v-for="(program, indexProgram) in displayData"
                              :key="indexProgram" :row="program">


                            <!--                        <td><input style="width: 20px; height: 20px; cursor: pointer;"-->
                            <!--                                   class="order-starter-checkbox" type="checkbox" :value="program.id" v-model="selected"-->
                            <!--                                   number-->
                            <!--                        ></td>-->
                            <!--                        <td></td>-->
                            <td>{{program.name}}</td>
                            <td>{{program.subject}}</td>
                            <td>{{program.language}}</td>
                            <td>{{program.status}}</td>


                        </v-tr>
                        </tbody>
                    </v-table>
                    <smart-pagination
                            :currentPage.sync="currentPage"
                            :totalPages="totalPages"
                            :maxPageLinks="10"
                    />
                </div>
            </div>
        </template>

    </div>

</template>

<script>
    import axios from "axios";

    export default {
        name: "StartContentTable",
        props: {
            programs: [],
            providers: [],
            axiosURL: String
        },
        data() {
            return {
                selectedDomains: [],
                filtersDomains: {
                    domainName: {value: '', keys: ['name']},
                    subjectName: {value: '', keys: ['subject']},
                    languageName: {value: '', keys: ['language']},
                    statusName: {value: '', keys: ['status']},
                },
                selectedCategory: '',
                selected: [],
                optionsLanguageLive: [
                    {value: '', text: 'All Languages'}
                ],
                orderList: {
                    domain_id: [],
                    content_provider_id: 1
                },
                currentPage: 1,
                totalPages: 0,
                loading: false,
            }
        },
        watch: {
            programs: function () { // watch it
                this.getAllAvailableLanguages()
            },
            selectedDomains: {
                handler() {
                    this.parseSelectedDomains();
                }
            },
        },
        computed: {
            filteredProducts() {

                if (this.selectedCategory === '') {
                    return this.programs;
                } else {
                    const category = this.selectedCategory;
                    return this.programs
                        .filter((program) => program.language === category)
                }
            },
            // selectAll: {
            //     get: function () {
            //         return this.filteredProducts ? this.selected.length == this.filteredProducts.length : false;
            //     },
            //     set: function (value) {
            //         let selected = [];
            //
            //         if (value) {
            //             this.filteredProducts.forEach(function (domain) {
            //                 selected.push(domain.id);
            //             });
            //         }
            //
            //         this.selected = selected;
            //
            //
            //         this.$set(this.orderList, 'domain_id', this.selected)
            //         // this.orderList.domain_id = this.selected;
            //
            //     }
            // }
        },
        methods: {
            parseSelectedDomains() {
                let sortsDomains = [];
                this.selectedDomains.forEach(domain => {
                    sortsDomains.push(domain.id)
                })
                this.selected = sortsDomains;
            },
            selectAllTest() {
                this.$refs.startContentTable.selectAll()
            },
            deselectAllTest() {
                this.$refs.startContentTable.deselectAll()
            },
            refreshParent() {
                this.$emit('refresh-parent');
            },
            orderContent() {
                this.$set(this.orderList, 'domain_id', this.selected)

                if (this.orderList.domain_id.length !== 0) {
                    this.loading = true;
                    axios.post(this.axiosURL, {
                        data: this.orderList

                    }).then(response => {
                        if (typeof response.data === 'object' && response.data !== null) {
                            this.refreshParent();
                            this.loading = false;
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Done',
                                text: 'Content ordered!',
                                timer: 2000,
                                showConfirmButton: false,
                            })
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 500) {
                            this.loading = false;
                            this.$swal.fire({
                                icon: 'error',
                                title: 'error',
                                text: 'Select provider',
                                timer: 2000,
                                showConfirmButton: true,
                            })
                        }

                        if (error.response.status == 416) {
                            this.loading = false;
                            this.$swal.fire({
                                icon: 'error',
                                title: 'error',
                                text: 'Only one language range should be selected',
                                timer: 2000,
                                showConfirmButton: true,
                            })
                        }
                    })
                    this.orderList.domain_id = []
                    this.selected = []
                } else {
                    // alert('Please select min 1 site!')
                    this.$swal.fire({
                        icon: 'error',
                        title: 'error',
                        text: 'Please select min 1 site!',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
            },
            getAllAvailableLanguages() {
                let allLangs = [];
                let uniqueLangs = [];
                this.programs.forEach(lang => {
                    allLangs.push(lang.language)
                })
                $.each(allLangs, function (i, el) {
                    if ($.inArray(el, uniqueLangs) === -1) uniqueLangs.push(el);
                });
                uniqueLangs.forEach(item => {
                    this.optionsLanguageLive.push({value: item, text: item})
                })
            }
        },
        mounted() {
            this.getAllAvailableLanguages();
        }
    }
</script>

<style scoped lang="scss">
    .order-provider-button {
        /*margin-top: 20px;*/
        outline: none;
        border: none;
        height: 24px;
        line-height: 24px;
        background: #FFFFFF;
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /*line-height: 15px;*/
        /* identical to box height */
        transition: all ease-in-out .2s;


        color: #000000;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin: 0 0 10px;

        &:hover {
            transform: translateY(-3px);
            transition: all ease-in-out .2s;
        }
    }

    .order-content-button {
        line-height: 24px;
        height: 24px;
        background: #fff;
        max-width: 128px;
        width: 100%;
        border: 1px solid #C9C9C9;
        border-radius: 2px;

    }

</style>