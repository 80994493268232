import Vue from 'vue'
import VueCookies from 'vue-cookies';
import Vuex from 'vuex'
import axios from 'axios'
import loginState from "./modules/loginState";
import domainList from "./modules/domainList";
// import topicList from "./modules/topicList";
import customerList from "./modules/customerList";
import graphsData from "./modules/graphsData";
import optionsData from "./modules/optionsData";
import langList from "./modules/langList";
import providerList from "./modules/providerList";
import userList from "./modules/userList";
import orderCommercialContent from "./modules/orderCommercialContent";
import finalizedOrder from "./modules/finalizedOrder";
import contentList from "./modules/contentList";
import filters from "./modules/filters";
import orderBasket from "./modules/orderBasket";

Vue.use(Vuex)
Vue.use(VueCookies);


export default new Vuex.Store({
    modules: {
        loginState,
        domainList,
//         topicList,
        customerList,
        graphsData,
        optionsData,
        langList,
        orderCommercialContent,
        providerList,
        userList,
        finalizedOrder,
        contentList,
        orderBasket,
        filters
    },
    actions: {
        getServerList(ctx) {
          axios
              .get('server/list')
              .then(res => {
                  const results = res.data.results.map(server => ({isEditable: false, ...server}));
                  ctx.commit('updateServerList', results)
              })
        },
        fetchCurrentTheme(ctx) {
            return axios
                .get('get-preferences')
                .then(res => {
                    //Check appTheme
                    if (res.data.appTheme) {
                        const currentTheme = res.data.appTheme
                        ctx.commit('updateTheme', currentTheme)
                    } else {
                        const currentTheme = ''
                        ctx.commit('updateTheme', currentTheme)
                    }
                    //Check appSize
                    if (res.data.currentSize) {
                        const currentSize = res.data.currentSize
                        ctx.commit('updateSize', currentSize)
                    } else {
                        const currentSize = 'mediumSize'
                        ctx.commit('updateSize', currentSize)
                    }
                })
        },
        setCurrentTheme(ctx, newTheme) {
            return axios
                .post('get-preferences', { appTheme: newTheme})
                .then(res => {
                    const newTheme = res.data.appTheme
                    ctx.commit('updateTheme', newTheme)
                })
        },
        setCurrentSize(ctx, newSize) {
            return axios
                .post('get-preferences', {currentSize: newSize})
                .then(res => {
                    const newSize = res.data.currentSize
                    ctx.commit('updateSize', newSize)
                })
        },
        getServerActionsLog(ctx) {
            return axios
                .get('get-log')
                .then(res => {
                    if (res.data) {
                        const serverLog = res.data
                        ctx.commit('updateServerActionsLog', serverLog)
                    } else {
                        const serverLog = 'No Data'
                        ctx.commit('updateServerActionsLog', serverLog)
                    }

                })

        }
    },

    mutations: {
        updateServerList(state, serverList) {
          state.serverList = serverList
        },
        createServer(state, server) {
          state.serverList.unshift(server)
        },
        removeServer(state, server) {
          state.serverList.splice(server, 1)
        },
        updateTheme(state, newTheme) {
            state.themeSettings.appTheme = newTheme
        },
        updateSize(state, newSize) {
            state.themeSettings.themeSize = newSize
        },
        updateServerActionsLog(state, newLog) {
            state.serverActionsLog = newLog
        },
        csrfTokenUpdate(state, newToken) {
            state.csrfToken = newToken
        }

    },
    state: {
        themeSettings: {
            appTheme: '',
            themeSize: 'mediumSize',
        },
        serverActionsLog: [],
        csrfToken: '',
        loadingData: false,
        monthDueLinks: false,
        serverList: []
    }
})