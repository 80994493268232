<template>
    <div id="app" :class="[returnTheme != '' ? returnTheme : '', returnSize]">
        <div class="test-site-alert">You are currently working on test version Offpage site</div>
        <div id="app-wrapper">
            <div class="container-fluid">
                <b-navbar toggleable="lg" type="light" class="container-fluid" fixed="top" ref="navBox">
                    <b-navbar-brand to="/"><img src="./assets/pixel-new-logo.png" alt="Logo" style="width: 150px;">
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                    <b-collapse id="nav-collapse" is-nav
                                style="width: 100%; align-items: center; justify-content: space-between;">
                        <div class="left-navigation-part">
                            <b-navbar-nav v-if="isLoggedIn" class="navbar-main-left-navigation">
                                <h5 class="navbar-main-subtitles title-font-style">Domains</h5>
                                <b-nav-item to="/domains">Domain list</b-nav-item>
                                <b-nav-item to="/bugged-domains">Bugged Domains</b-nav-item>
                                <b-nav-item to="/activated-domains">Domain activation</b-nav-item>
                            </b-navbar-nav>
                            <b-navbar-nav v-if="isLoggedIn" class="navbar-main-left-navigation">
                                <h5 class="navbar-main-subtitles title-font-style">Customers</h5>
                                <b-nav-item to="/customers">Customer list</b-nav-item>
                                <b-nav-item to="/calendar">Calendar</b-nav-item>
                            </b-navbar-nav>

                            <b-navbar-nav v-if="isLoggedIn" class="navbar-main-left-navigation">
                                <h5 class="navbar-main-subtitles title-font-style">Content</h5>
                                <b-nav-item to="/content">Content list</b-nav-item>
                                <b-nav-item to="/order-content">Order Start Content</b-nav-item>
                                <b-nav-item to="/order-commercial-content">Order Commercial Content</b-nav-item>
                            </b-navbar-nav>

                            <b-navbar-nav v-if="isLoggedIn" class="navbar-main-left-navigation">
                                <h5 class="navbar-main-subtitles title-font-style">Options</h5>
                                <b-nav-item to="/options">Options</b-nav-item>
                            </b-navbar-nav>
                        </div>

                        <b-navbar-nav>
                            <section class="server-chat" v-if="isLoggedIn && isOption">
                                <div class="server-chat-wrapper">
                                    <p v-for="message in chatActions" class="message" :key="message.id">{{message}}</p>
                                </div>
                            </section>
                        </b-navbar-nav>

                        <!-- Right aligned nav items -->
                        <b-navbar-nav class="ml-auto" style="justify-content: flex-end; align-items: center">

                            <Dialogue :userActions="userActions" v-if="isLoggedIn"/>

                            <b-nav-item to="/order-commercial-content" v-if="isLoggedIn"><span
                                    style="display: flex; align-items: center;">
                                <font-awesome-icon icon="fa-light fa-cart-shopping" class="fa-xl"/>
                                <strong style="margin-left: 8px; font-size: 14px;">{{returnLinksCount}}</strong></span>
                            </b-nav-item>
                            <b-button v-b-modal.modal-basket class=""
                                      style="background: inherit; color: #000; box-shadow: none;" v-if="isLoggedIn">
                                Basket
                            </b-button>
                            <b-modal id="modal-basket" title="Basket" hide-footer>
                                <OrderBasket/>
                            </b-modal>
                            <b-nav-item to="/orders-cart" v-if="isLoggedIn">
                                Finalized orders
                            </b-nav-item>
                            <b-nav-item href="javascript:void(0)" @click="logout" v-if="isLoggedIn">
                                Logout
                            </b-nav-item>
                            <b-nav-item to="/login" v-if="!isLoggedIn">
                                Login
                            </b-nav-item>
                        </b-navbar-nav>
                    </b-collapse>
                </b-navbar>
            </div>
            <template v-if="isOption">
                <div class="settings-bar">
                    <div class="switch-field switch-field--size">

                        <input type="radio" id="smallSize"
                               name="themeSize" value="smallSize" v-model="themeSize">
                        <label for="smallSize">Small</label>


                        <input type="radio" id="mediumSize"
                               name="themeSize" value="mediumSize" v-model="themeSize">
                        <label for="mediumSize">Medium</label>

                        <input type="radio" id="largeSize"
                               name="themeSize" value="largeSize" v-model="themeSize">
                        <label for="largeSize">Large</label>

                    </div>
                    <div class="last-updates ">
                        <button class="main-button" style="color: #FFF;"
                                @click="showLastUpdatesModal($event)">Show Last Updates
                        </button>
                    </div>
                </div>
            </template>

            <div class="last-updates-wrapper">
                <div class="last-updates-modal">
                    <div class="last-updates-modal__header">
                        <h4>Server Actions Log</h4>
                        <b-button href="#" class="last-updates-modal__header--close btn btn-indigo btn-sm m-0"
                                  style="color: #fff;" @click="closeLastUpdatesModal($event)">X
                        </b-button>
                    </div>
                    <div class="last-updates-modal__content">


                        <div v-for="(item, index) in serverActionsLog" :key="index" style="text-align: left;">
                            <h6 class="server-date-updated">Date updated: {{item.date_updated}}</h6>
                            <h6 class="server-report-date-updated">Updated reports date:
                                {{item.updated_reports_date}}</h6>
                            <!--                                <h6 class="server-changed-customers-list">Changed customers list:<br> <p>{{item.changed_customers_list}}</p></h6>-->
                            <h6 class="server-changed-customers-list"> Changed customers list:<br></h6>
                            <ul style="list-style: none; column-count: 3">
                                <li v-for="(li, index) in item.changed_customers_list" :key="index">{{li}}</li>
                            </ul>
                            <h6 class="server-update-errors">Update errors:<br></h6>
                            <ul v-if="item.update_errors" style="list-style: none;">
                                <li v-for="(error, index) in Object.keys(item.update_errors)" :key="index"><strong>{{error + ': '}}</strong> {{item.update_errors[error]}}
                                </li>
                            </ul>
                            <hr>
                        </div>

                    </div>
                </div>
            </div>


            <template v-if="loadingGraphsStatus || loadingCustomersStatus || loading">
                <div id="preloader">
                    <div id="loader_new"></div>
                </div>

            </template>
            <template v-else>
                <router-view/>
            </template>


        </div>

    </div>


</template>

<script>

    import Dialogue from './pages/Dialogue'
    import _ from 'lodash';
    import axios from 'axios';
    import {eventBus} from './main'
    import JQuery from 'jquery';
    import {mapState} from "vuex";
    import settings from "./site-settings";
    import OrderBasket from "./components/OrderBasket";

    window.$ = JQuery

    $(document).on("click", function (event) {
        let $trigger = $(".hidefilter-group");
        if ($trigger !== event.target && !$trigger.has(event.target).length) {
            $(".hidefilter-dropdown").slideUp("fast");
        }
    })
    $(document).click(function (e) {
        if ($(e.target).is('.last-updates-wrapper')) {
            const body = document.querySelector("body");
            $('.last-updates-modal').fadeOut(300);
            $('.last-updates-wrapper').fadeOut(300);
            body.style.overflow = "auto";
        }
    });

    export default {
        name: 'App',
        components: {
            OrderBasket,
            Dialogue
        },
        data() {
            return {
                hideChatWindow: false,
                message: {'message': {}, 'action': {}},
                testMes: {
                    'message': [
                        'Pushka', 'raketa', 'petarda', 'Hi how are you? I am fine', 'taftdfsaydts'
                    ]
                },
                chatActions: [],
                userActions: {},
                counterOrder: 0,
                counterNames: [],
                customers: [],
                currentDate: new Date(),
                socketErrorsCount : 10,
                loading: false
            }
        },
        computed: {
            isOption() {
                return this.$route.name === 'options'
            },
            isLoggedIn: function () {
                return this.$store.getters.isLoggedIn
            },
            ...mapState({
                returnAllCustomersApp: state => state.customerList.customersApp,
                returnTheme: state => state.themeSettings.appTheme,
                returnSize: state => state.themeSettings.themeSize,
                serverActionsLog: state => state.serverActionsLog,
                orderList: state => state.orderBasket.globalOrderList,
                loadingGraphsStatus: state => state.graphsData.loadingGraphs,
                loadingCustomersStatus: state => state.orderCommercialContent.loadingStatusCustomers,
                loadingContentOrderListStatus: state => state.finalizedOrder.loadingContentOrderListStatus,
                orderListNumber: state => state.orderBasket.orderListNumber
            }),
            appTheme: {
                // getter
                get() {
                    return this.$store.state.themeSettings.appTheme
                },
                // setter
                set(newValue) {
                    this.$store.dispatch('setCurrentTheme', newValue)
                    // this.$store.state.appTheme = newValue
                }
            },

            themeSize: {
                // getter
                get() {
                    return this.$store.state.themeSettings.themeSize
                },
                // setter
                set(newValue) {
                    this.$store.dispatch('setCurrentSize', newValue)
                    // this.$store.state.appTheme = newValue
                }
            },
            returnLinksCount() {
                let counter = 0

                Object.values(this.orderList.customers).forEach(customer => {
                    counter += _.size(customer)
                })
                return counter
            },
        },
        watch: {
            returnLinksCount: {
                handler() {
                    this.$store.commit('updateOrderListNumber', this.returnLinksCount)
                }
            }
        },
        methods: {
            getCookieCSRF() {
                axios
                    .get('get-token')
                    .finally(() => {
                        const csrftoken = this.$cookies.get("X-Csrftoken");

                        this.$store.commit('csrfTokenUpdate', csrftoken)
                        let newStateToken = this.$store.state.csrfToken
                        if (newStateToken.length > 1) {
                            axios.defaults.headers.common['X-Csrftoken'] = newStateToken;
                        } else {
                            // console.error('CSRF token not found');
                        }
                    })

            },
            showLastUpdatesModal(e) {
                e.preventDefault();
                this.$store.dispatch("getServerActionsLog")
                let modalWindow = document.querySelector(".last-updates-modal");
                let modalWindowOverlay = document.querySelector(".last-updates-wrapper");
                const body = document.querySelector("body");
                $(modalWindow).fadeIn(300)
                $(modalWindowOverlay).fadeIn(300)
                body.style.overflow = "hidden";
            },
            closeLastUpdatesModal(e) {
                e.preventDefault();
                let modalWindow = document.querySelector(".last-updates-modal");
                let modalWindowOverlay = document.querySelector(".last-updates-wrapper");
                const body = document.querySelector("body");
                body.style.overflow = "auto";
                $(modalWindow).fadeOut(300)
                $(modalWindowOverlay).fadeOut(300)
            },
            // scrollTopServerChat() {
            //     // $('.server-chat-wrapper').animate({scrollTop: '0px'});
            //     // this.chatActions.reverse();
            //     let chatWindow = document.querySelector('.server-chat-wrapper');
            //     chatWindow.scrollTop = chatWindow.scrollHeight;
            //
            //     chatWindow.scrollTop = 0;
            //     // setTimeout(function(){
            //     //     chatWindow.scrollTop = 0;
            //     // }, 500);
            // },
            hideChat(event) {
                event.preventDefault();
                let chatWindow = document.querySelector('.server-chat');
                if (this.hideChatWindow) {
                    chatWindow.style.transform = 'translateX(0px)';
                    this.hideChatWindow = false
                } else {
                    chatWindow.style.transform = 'translateX(255px)';
                    this.hideChatWindow = true
                }
            },
            deleteAllCookies() {
                let cookies = document.cookie.split(";");

                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.deleteAllCookies();
                    })
                    .then(() => {
                        this.$router.push('/login')
                    })
            },
            beforeUnloadAction(event) {
                event.preventDefault();
                let loggedInUser = localStorage.getItem('currentUser')
                delete this.userActions[loggedInUser]
            },
            onOver() {
                this.$refs.dropdown.visible = true;
            },
            onLeave() {
                this.$refs.dropdown.visible = false;
            },
            connectionWS(token) {
                window.socket = new WebSocket(settings.socket_url + token);

                window.socket.onopen = function () {
                    console.log("Connection established");
                };

                //
                window.socket.onclose = (event) => {
                    if (event.wasClean) {
                        console.log('Connection clearly closed');

                    } else {
                        console.log('Connection lost');

                    }
                    // if (this.socketErrorsCount > 0){
                    //     let accessTok = this.$cookies.get("user_token");
                    //     this.connectionWS(accessTok);
                    // }
                    // this.socketErrorsCount -=1
                    // let accessTok = this.$cookies.get("user_token");
                    // this.connectionWS(accessTok);
                };

                window.socket.onmessage = (event) => {
                    // IF NO ACTION MEANS THAT USERS ARE THE FRESH MEAT

                    let jsonEvent = JSON.parse(event.data)
                    if (jsonEvent.action == "") {
                        let users = JSON.parse(jsonEvent.user)
                        users.forEach(user => {
                            this.userActions[user] = {
                                'routeName': ''
                            }
                        })
                        let currentUserActions = Object.keys(this.userActions)
                        let difference = currentUserActions.filter(x => users.indexOf(x) === -1);

                        difference.forEach(userLeft => {
                            delete this.userActions[userLeft]
                        })


                        eventBus.$emit('userAction', this.userActions)
                    } else if (jsonEvent.user == 'service_bot') {
                        this.chatActions.unshift(jsonEvent.action)
                    } else {
                        this.userActions = {...this.userActions, ...jsonEvent.action}
                        eventBus.$emit('userAction', this.userActions)
                    }
                };

                window.socket.onerror = function (error) {
                    console.log('Socket auto reconnected' + error.message)
                };
            },
            disconnect() {
                window.socket.close();
                console.log("Connection is closed.");
            },
            checkTokenStatus() {
                let accessTok = this.$cookies.get("user_token");

                let api_url = settings.axios_api_url;
                axios
                    .get(api_url + 'users/get-user/', {
                        headers: {
                            'Authorization': 'Bearer ' + accessTok
                        }
                    })
                    .then(() => {
                        this.allMethodsForStartApp();
                    })
                    .catch(err => {
                        console.log(err)
                        this.logout();
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Server error',
                            text: 'Your authorization data is old. Please re-login',
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                    })
            },
            checkServerStatus() {
                axios
                    .get('ping')
                    .catch(error => {
                        // handle error
                        console.log(error);
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Server error',
                            text: 'BACK END IS DOWN. Please ask admin',
                            // timer: 1000,
                            showConfirmButton: true,
                        })
                    })
            },
            formatDate(date) {
                let d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },

            formatDateBeforeTenDays() {

                const dt = new Date();
                dt.setDate(dt.getDate() - 15);
                let month = '' + (dt.getMonth() + 1),
                    day = '' + dt.getDate(),
                    year = dt.getFullYear();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');

            },
            //Load Customers Com Content
            loadContentPage() {
                this.$store.dispatch('fetchCustomersContent');
                this.$store.dispatch('fetchCustomerSites');
                this.$store.dispatch('fetchDomainsContent');
                // this.$store.dispatch('fetchBatchNumbers');
            },
            // loadCustContent() {
            //     // this.loadingComContent = true;
            //     this.$store.commit('changeLoadingStatus', true)
            //     this.$store.dispatch('fetchCustomersComContent').then(() => {
            //         this.$store.commit('changeLoadingStatus', false)
            //     })
            // },
            //Load all VUEX modules
            loadVuexModules() {
                this.loading = true;
                const allVuexData = [
                    this.$store.dispatch("fetchCurrentTheme"),
                    // this.$store.dispatch("getRecentCustomers"),
                    this.$store.dispatch("getRecentOrders"),
                    // this.$store.dispatch("fetchTopics"),
                    this.$store.dispatch("getUnorderedLinks"),
                    this.$store.dispatch("fetchLanguages"),
                    this.$store.dispatch("fetchOptions"),
                    this.$store.dispatch("fetchProviders"),
                    this.$store.dispatch("fetchUsers"),
                    this.$store.dispatch("getGraphDomainsLive", {
                        formatDateBefore: this.formatDateBeforeTenDays(),
                        currentDate: this.formatDate(this.currentDate)
                    }),
                    this.$store.dispatch('fetchOrderCustomers'),
                ];

                Promise.all(allVuexData).then(values => {
                    values.forEach(item => {
                        if (item) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Server Error',
                                text: item.response.data.error,
                                // timer: 1000,
                                showConfirmButton: true,
                            })
                        }
                    })


                }).finally(() => {
                    this.loading = false;
                    this.loadContentPage();
                }).catch(() => {
                    this.loading = false;
                });
            },
            axSet() {
                axios.defaults.baseURL = settings.axios_api_url
            },
            checkIfDateMoreThanTen() {
                const d = new Date();
                const currentDateOfMonth = d.getDate();
                const currentYear = new Date().getFullYear();
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                if (currentDateOfMonth > 10 && monthNames[d.getMonth()] == 'December') {
                    // if(_.isEmpty(state.globalOrderList.customers)) {
                    //     state.globalOrderList.month_of_order = monthNames[d.getMonth()];
                    // }
                    this.$store.commit('updateMonthIfDateMoreThanTen', 'January')
                    this.$store.commit('updateYearIfMonthIsDecember', currentYear + 1)
                } else if (currentDateOfMonth > 10) {
                    // if(_.isEmpty(state.globalOrderList.customers)) {
                    //     state.globalOrderList.month_of_order = monthNames[d.getMonth() + 1];
                    // }
                    this.$store.commit('updateMonthIfDateMoreThanTen', monthNames[d.getMonth() + 1])
                }
            },
            async allMethodsForStartApp() {
                setTimeout(() => {
                    let actualHeaderHeight = this.$refs.navBox.$el.clientHeight + 20;
                    document.querySelector('#app-wrapper').style.paddingTop = actualHeaderHeight + 'px';
                }, 3000)


                // console.log(bodyPaddingTop)
                let accessTok = await this.$cookies.get("user_token");
                let user_email = await this.$cookies.get("user_email");
                if (accessTok) {
                    await this.axSet();
                    await this.$store.dispatch('loginGoogle', {accessTok, user_email})


                    window.addEventListener('beforeunload', this.beforeUnloadAction)
                    if (this.$store.getters.isLoggedIn) {
                        this.connectionWS(accessTok);
                        this.$store.dispatch('getGrapsDataAll')
                        await this.getCookieCSRF();
                        await this.loadVuexModules();
                        axios.get("get-cart")
                            .then((response) => {
                                // console.log(response.data)
                                if(response.data != null) {
                                    this.$store.commit('updateGlobalOrderList', response.data.data)
                                    this.$store.commit('updateOrderListNumber', this.returnLinksCount)
                                    if (this.orderListNumber == 0) {
                                        this.checkIfDateMoreThanTen();
                                    }
                                }
                            });
                    }
                } else {
                    await this.$router.push('/login')
                }

                //Check if testsite
                let appPath = window.location.host;
                let testSiteAttention = document.querySelector('.test-site-alert');
                if (appPath.includes("test", 0)) {
                    // console.log('HER')
                    // mainNavbar.style.setProperty('background-color', 'red', 'important');
                    testSiteAttention.style.setProperty('display', 'block', 'important');
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: 'You are currently working on test version Offpage site',
                        // timer: 500,
                        showConfirmButton: true,
                    })
                }

                //If server is down show alert
                this.axSet();
                this.checkServerStatus();
            }


        },
        beforeMount() {

        },
        async created() {


        },
        async mounted() {
            await this.checkTokenStatus()
        }
    }

</script>

<style lang="scss">
    //Import fonts
    /*@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');*/
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
    @import './assets/styles/main.scss';


    .app-wrapper {
        padding-top: 10px;
    }

    .test-site-alert {
        display: none;
        background-color: red;
        color: #fff;
        padding: 0 20px;
    }

    //Last Updates Modal Window
    .last-updates-wrapper {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 70%);
        z-index: 999;
        top: 0;
    }

    .last-updates {
        margin-left: 10px;
    }

    .last-updates-modal {
        display: none;
        position: absolute;
        width: 500px;
        height: 700px;
        background-color: #fff;
        border: 1px solid #26303E;
        border-radius: 10px;
        color: #000;
        left: 50%;
        top: 200px;
        transform: translate(-50%, 0%);
        z-index: 99;
        overflow: hidden;

        &__header {
            width: 100%;
            position: relative;
            padding: 10px 0;
            background-color: #26303E;
            color: #fff;

            h4 {
                margin-bottom: 0;
            }

            &--close {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }

        &__content {
            padding: 20px;
            overflow: scroll;
            height: calc(100% - 48.8px);
        }
    }

    .last-updates__cta {

        /*display: inline-block;*/
        /*background-color: #26303E;*/
        /*color: #26303E;*/
        /*&:hover {*/
        /*    */
        /*}*/
    }

    .server-date-updated {
        background-color: blanchedalmond;
    }

    .server-report-date-updated {
        background-color: darkgray;
    }

    .server-changed-customers-list {
        background-color: #e4f5e4;
    }

    .server-update-errors {
        background-color: #f8eeee;
    }

    //Switch Styles
    .settings-bar {
        display: flex;
    }

    .switch-field {
        display: inline-flex;
        margin-bottom: 5px;
        overflow: hidden;

        &--size {
            margin-left: 10px;
        }
    }

    .switch-field input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
    }

    .switch-field label {
        background-color: #fff;
        color: #26303E;
        font-size: 14px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        padding: 0px 10px;
        margin-right: -1px;
        /*border: 1px solid #ccc;*/
        /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);*/
        transition: all 0.1s ease-in-out;
    }

    .switch-field label:hover {
        cursor: pointer;
    }

    .switch-field input:checked + label {
        background-color: #ccc;
        color: #fff;
        box-shadow: none;
    }

    .switch-field label:first-of-type {
        /*border-radius: 4px 0 0 4px;*/
    }

    .switch-field label:last-of-type {
        /*border-radius: 0 4px 4px 0;*/
    }

    //Main Styles
    .smallSize {
        font-size: 10px !important;

        input {
            font-size: 10px !important;
        }

        td {
            button {
                font-size: 10px !important;
            }
        }

    }

    .mediumSize {
        font-size: 12px !important;

        input {
            font-size: 12px !important;
        }

        td {
            button {
                font-size: 12px !important;
            }
        }

    }

    .largeSize {
        font-size: 16px !important;

        input {
            font-size: 16px !important;
        }

        td {
            button {
                font-size: 16px !important;
            }
        }

    }

    .darkTheme {
        background-color: #2c3a4f;
        /*font-size: 12px!important;*/
        color: #fff;

        h1, h2, h3, h4, h5, h6 {
            color: #26303E;
            background-color: #fff !important;
        }

        .customer-final-order h5 {
            background-color: forestgreen !important;
        }

        input {
            /*font-size: 12px!important;*/
            background-color: #fff !important;
            color: #26303E !important;
        }

        select {
            color: #000;
        }

        td {
            color: #fff;
        }

        .domains-replace-wrapper {
            input:first-child {
                background-color: #eedbdb !important;
            }

            input:nth-child(2) {
                background-color: #cceccc !important;
            }
        }

        .options {
            table {
                border: 1px solid #fff !important;
            }
        }

        .navbar-main-subtitles {
            padding: 0 5px 0 5px;
            color: #000;
        }

        //Calendar
        .calendar-customers-table {
            table {
                td {
                    background-color: #fff;
                    color: #26303E;
                }
            }
        }

        .calendar-sites-table {
            table {
                td {
                    background-color: #fff;
                    color: #26303E;
                }
            }
        }

        .top-sets {
            color: #fff;
        }

        //Content
        .list-to-choose {
            table {
                td {
                    background-color: #fff;
                    color: #26303E;
                }
            }
        }

        .rangetime-wrapper {
            button {
                border: 1px solid #fff !important;
            }
        }

        //Start Content Order
        .order-provider {
            color: #fff;
        }

        //Order Commercial Content
        .month-and-year-wrapper {
            color: #fff;
        }

        .customer-final-order {
            color: #26303E !important;
        }

        //Orders
        .order-list {
            textarea {
                color: #fff;
            }
        }

        .homepage {
            h5 {
                /*background-color: #26303E !important;*/
                color: #000 !important;
            }

            div {
                td {
                    /*color: #26303E;*/
                }
            }
        }

        .last-updates-modal__header {
            h4 {
                background-color: #26303E !important;
                color: #fff !important;
            }
        }

    }

    .lightTheme {
        h1, h2, h3, h4, h5, h6 {
            color: red !important;
            background-color: #000 !important;
        }

        font-size: 16px !important;
    }

    table td {
        border: none;
    }

    //Navigation

    .navbar {
        background-color: #fff !important;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
        /*color: #000000!important;*/
    }

    .navbar-nav .nav-link {
        /*color: rgba(255, 255, 255, 1) !important;*/
        color: #000000 !important;
        line-height: 7px;
        transition: all ease-in-out .2s;
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 1px;
            width: 0%;
            background-color: #000;
            left: 0;
            bottom: 0;
            transition: all ease-in-out .2s;
        }

        &:hover {
            text-decoration: none !important;
            /*border-bottom: 1px solid #000;*/
            &:before {
                content: '';
                display: inline-block;
                position: absolute;
                height: 1px;
                width: 100%;
                background-color: #000;
                left: 0;
                bottom: 0;
            }

            transition: all ease-in-out .2s;
        }

        @media screen and (max-width: 1580px) {
            font-size: 14px;
            line-height: 12px;
            text-align: left;
        }
        @media screen and (max-width: 1300px) {
            font-size: 12px;
        }

    }

    .nav-link.router-link-exact-active.router-link-active {
        /*border-bottom: 1px solid #000;*/
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: #000;
            left: 0;
            bottom: 0;
        }

        transition: all ease-in-out .2s;
    }

    .dropdown-item {
        color: #26303E !important;
        text-decoration: none !important;
    }

    #nav-collapse {
        justify-content: flex-end;
    }


    .navbar-main-left-navigation {
        flex-direction: column !important;
        align-items: start;
        padding: 0 20px !important;
        position: relative;
        /*border-right: 1px solid #fff;*/
        &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 1px;
            height: 70%;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            background-color: #fff;
            opacity: .4;

        }

        &:last-child {
            &:after {
                width: 0;
            }

        }

    }

    .left-navigation-part {
        display: flex;
    }

    .navbar-main-subtitles {
        font-weight: 500;
        color: #000;
        margin-bottom: 0px;
        padding: 0.4rem 0.5rem;
        font-size: 1.1rem;

    }

    //Old pagination styles for some pages

    .paginate-links {
        display: flex;
        list-style: none;
        justify-content: center;
    }

    .paginate-links.programs {
        user-select: none;
    }

    .paginate-links li {
        margin: 0 10px;
        font-size: 20px;
        color: #f7b558;
        cursor: pointer;
    }

    .paginate-links.programs a {
        cursor: pointer;
    }

    .paginate-links.programs li.active a {
        font-weight: bold;
    }

    .paginate-links.programs li.next:before {
        content: ' | ';
        margin-right: 13px;
        color: #ddd;
    }

    .paginate-links.programs li.disabled a {
        color: #ccc;
        cursor: no-drop;
    }

    //Main styles

    .table-info {
        --bs-table-bg: #f5e0c7 !important;
        --bs-table-striped-bg: #f5e0c7 !important;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #f5e0c7 !important;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #f5e0c7 !important;
        --bs-table-hover-color: #000;

    }

    #app {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        font-weight: 300;
        /*margin-top: 60px;*/
        th {
            /*font-weight: 400;*/
            background-color: #fff;
            color: #000;
            vertical-align: middle;
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            border-bottom: 1px solid #D2D2D2 !important;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            /*line-height: 15px;*/
            /* identical to box height */


            /*color: #000000;*/
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /*line-height: 15px;*/
            /* identical to box height */


            color: #000000;
            border-bottom: 1px solid #D2D2D2 !important;

            input {
                /*font-weight: 300;*/
                /*background-color: rgba(38, 48, 62, 0.05);*/
                background: #FFFFFF;
                border: 1px solid #C9C9C9;
                border-radius: 2px;
                color: #000;
                font-size: 12px !important;
                line-height: 24px;
                height: 24px;
            }

            select {
                background: #FFFFFF;
                border: 1px solid #C9C9C9;
                border-radius: 2px;
                color: #000;
                font-size: 12px !important;
                line-height: 24px;
                height: 24px;
            }

        }
    }

    //Buttons and Inputs
    button {
        border: none !important;
    }

    input {
        width: 100%;
        background-color: inherit;
        border: none;
        text-align: center;
    }

    //List styles
    ol, ul {
        padding-left: 0 !important;
    }

    h1 {
        /*text-shadow: 1px 1px 5px rgba(247,181,88,0.57);*/
    }

    //Smart Pagination and Table styles
    .table {
        overflow-x: auto;
        overflow-y: auto;
        table-layout: auto;
        width: 100%;
    }

    .smart-pagination {

        .pagination {
            justify-content: center !important;
        }

        .page-link {
            color: #f7b558;
        }

        .page-item.active .page-link {
            background-color: #26303E !important;
            border-color: #26303E !important;
            color: #fff !important;
        }
    }

    //Table rows styles

    /*.table-body-rows:nth-child(even) {*/
    /*    background-color: #fff;*/
    /*}*/

    /*.table-body-rows:nth-child(even) td input {*/
    /*    background-color: #fff;*/
    /*}*/

    //Editable for input
    .editable {
        border: 2px solid #26303E;
    }

    .currentCustomer {
        background-color: #f7b558 !important;
    }

    .currentButton {
        background-color: #f7b558 !important;
    }

    //SIGN IN STYLE
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-floating:focus-within {
        z-index: 2;
    }

    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    th {
        border: 1px solid #dee2e6 !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    //Color Classes

    .main-color-orange {
        background-color: #f7b558 !important;
        color: #fff !important;
    }

    //Hide Visible Main Styles
    .box-hidden {
        /*display: none;*/
        opacity: 0;
        visibility: hidden;
        transform: translateY(-36px);
        transition: all .25s ease-in-out;
        display: none;

    }

    .box-visible {
        margin-top: 0;
        opacity: 1;
        transition: all .25s ease-in-out;
        /*display: table-row;*/
        visibility: visible;
        transform: translateY(0px);

    }
</style>

<style lang="scss">
    .top-main-title {
        font-size: 20px;
        margin-bottom: 20px;
        text-transform: uppercase;
        background-color: #3f485c;
        color: #fff;
    }

    #modal-1___BV_modal_body_.modal-body {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    #modal-860___BV_modal_body_.modal-body {
        display: flex !important;
        flex-direction: column-reverse !important;
        column-count: 1;
    }

    #my-modal-19 .modal-body {
        column-count: 2;
    }

    /*.modal-body {*/
    /*     column-count: 2;*/
    /* }*/

    .checkout-link {

    }

    $color-loader: #f7b558;

    .loader {
        width: 250px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: helvetica, arial, sans-serif;
        text-transform: uppercase;
        font-weight: 900;
        color: $color-loader;
        letter-spacing: 0.2em;
        background-color: #ccc;

        &::before, &::after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: $color-loader;
            position: absolute;
            animation: load .7s infinite alternate ease-in-out;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    @keyframes load {
        0% {
            left: 0;
            height: 30px;
            width: 15px
        }
        50% {
            height: 8px;
            width: 40px
        }
        100% {
            left: 235px;
            height: 30px;
            width: 15px
        }
    }


    .hide-columns {
        flex-direction: column;
        /*transform: translateY(100%);*/
        display: flex;
        /*justify-content: flex-start;*/
        align-items: flex-start;
        background-color: #b0c3dd;

        label {
            /*border: 1px solid #000;*/
            font-weight: 300;
        }

        input {
            width: 40px;
            height: 15px;
        }
    }

    .hide-columns-cell {
        width: 100%;
        justify-content: space-between;
        background-color: #fff;
        /*border: 1px solid #fff;*/
        /*border-top: none;*/
        /*border-bottom: none;*/
        /*border-left: none;*/
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border: 1px solid #C9C9C9;
        /*cursor: pointer;*/
        color: #000;
    }

    .hideColumn {
        display: none;
        border-bottom: 1px solid #fff;
    }

    .hidefilter-group {
        position: relative;
        text-align: left;
        background-color: #fff !important;
        padding: 0;
    }

    .hidefilter-button {
        display: inline-block;
        /*background-color: #5c636a;*/
        text-decoration: none !important;
        transition: all ease-in-out .2s;
        /*color: #fff !important;*/
        padding: 0 10px;
        width: 100%;
        text-align: center;

        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #000 !important;
        background-color: #fff !important;
        border: 1px solid #C9C9C9;
        border-radius: 2px;
        height: 24px;
        line-height: 24px;

        &:hover {
            /*background-color: rgba(92, 99, 106, 0.99) !important;*/
            text-decoration: none !important;
            transition: all ease-in-out .2s;
        }

    }

    .hidefilter-dropdown {
        position: absolute;
        bottom: 0;
        display: none;
        transform: translateY(100%);
        z-index: 1;
        left: -100px;
    }

    .server-chat {
        /*position: fixed;*/
        /*right: 0;*/
        /*bottom: 0;*/
        position: relative;
        /*background: #26303E;*/
        background: #fff;
        /*height: 100px;*/
        padding: 1.2em .5em .5em .5em;
        /*overflow: auto;*/
        width: 200px;
        /*margin: 0 auto 2em auto;*/
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
        /*border: 0.1px solid #26303E;*/
        transition: all ease-in-out .2s;
        /*border-top-left-radius: 20px 20px;*/
        z-index: 999;

        &:before {
            content: "Server Messages";
            font-size: 14px;
            /*color: #000;*/
            color: #000;
            position: absolute;
            left: 0;
            top: 0px;
            /* transform: translateX(-50%); */
            right: 0;
            /*background: #1e2731;*/
            background: rgba(0, 0, 0, 0.18);
            background: #fff;
            /*box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);*/
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            z-index: 999;

        }
    }

    .hide-buttons-wrapper {
        /*position: relative;*/
        /*left: 0;*/
        /*width: 100%;*/
        /*display: inline-block;*/
    }

    .server-chat-wrapper {
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 100px;
        padding-top: 4px;
    }

    .hide-chat {

        display: inline-block;
        background-color: #ccc;
        position: absolute;
        border-top-left-radius: 20px 20px;
        top: 0;
        left: 0;

        &:hover {
            text-decoration: none !important;
        }
    }

    .message {
        border-radius: 10px;
        margin: 3px;
        text-align: left;
        line-height: 11px;
        font-size: 9px;
        padding: 0px;
        color: #000;
    }
    
    .chart__wrapper{
        border-radius: 3px;
        padding: 5px;
    }
</style>
