<template>
    <div class="string-field-wrapper">
        <input type="text" placeholder="Search..." v-model="inputValue">
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "StringFieldFilter",
        props: ['filter_type'],
        data() {
            return {
                inputValue: ''
            }
        },

        computed: {
            ...mapState({
                returnFilters: state => state.filters.filters,
            }),
        },
        watch: {
            inputValue: {
                handler(val) {
                    console.log(val)
                    let keyValue = this.filter_type
                    // this.$store.dispatch('getData', val)
                    this.$store.commit('updateFilters', {keyValue, val})
                    this.$store.dispatch('getData', this.returnFilters)
                }
            },
            // 'this.returnFilters.language__icontains'(newVal) {
            //     console.log('Watching for filters:', newVal)
            // },
            returnFilters: {
                handler() {
                    this.inputValue = ''
                }
            },
        },
        methods: {},
        mounted() {
            // this.$store.commit('updateFilters', this.filter_type)
        }
    }
</script>

<style scoped lang="scss">
    .string-field-wrapper {
        input {
            border: 1px solid #e5e7eb !important;
            border-radius: 4px;
            width: auto;
            height: 30px;
        }
    }
</style>