import axios from "axios";

export default {
    actions: {
        fetchProviders(ctx) {
            return axios
                .get('provider/list?page_size=99999')
                .then(response => {

                    const providers = response.data.results.map(program => ({isEditable: false, ...program})).filter(function (n) {
                        return n;
                    }).reverse()

                    ctx.commit('updateProviders', providers)

                }).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });
        }
    },
    mutations: {
        updateProviders(state, providers) {
            state.providers = providers;
        },
        createProvider(state, provider) {
            state.providers.unshift(provider)
        },
        removeProvider(state, provider) {
            state.providers.splice(provider, 1);
        }
    },
    state: {
        providers: []
    },
    getters: {

    }
}