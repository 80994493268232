<template>
    <div class="container-fluid body-padding-top">
        <div class="row">
            <div class="col-sm-12">
                <h1 class="table-title">Order Start Content</h1>
            </div>
        </div>
        <template v-if="loading">
            <div id="preloader">
                <div id="loader_new"></div>
            </div>
        </template>
        <template v-else>
            <div class="start-content main-wrapper background-table">
                <div class="container-fluid " style="padding-top: 10px;">
                    <div v-if="loading" class="loader">Loading...</div>
                    <!--Start Content Table Component -->
                    <StartContentTable v-if="!loading" @refresh-parent="refresh" :programs="domains" :providers="providers" :axiosURL="axiosURL" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
    import StartContentTable from "../components/orderStartContent/StartContentTable";

    export default {
        name: 'OrderContent',
        components: {StartContentTable},
        data() {
            return {
                domains: [],
                message: {'message': {}, 'action': {}},
                columns: [
                    'name', 'subject', 'language', 'status'
                ],
                providers: [],
                counter: 0,
                languages: [],
                loading: false,
                axiosURL: 'content-starter-order/'
            }
        },
        computed: {
        },
        methods: {
            refresh() {
                this.getDomains();
            },
            getDomains() {
                this.loading = true;
                axios
                    .get('domain/list?status=NEW&is_active=True&page_size=99999&fields=id,name,subject,language,is_active,status')
                    .then(response => {
                        this.domains = response.data.results.map(program => (
                            {isEditable: false, statusSaving: false, isChecked: false, ...program}
                            ))
                            .reverse()
                            .filter(function (n) {
                            return n.is_active === true && n.status === 'NEW' && n.subject !== null && n.subject?.toLowerCase() != 'new' && n.subject !== 'nan' && n.subject !== '' && n.subject.toLowerCase() !== 'none';
                        })
                        this.loading = false;
                    })
            },
            //Get providers data
            getProviders() {
                axios
                    .get("provider/list?page_size=9999")
                    .then(response => {
                        // adding prop isEditable for each object
                        this.providers = response.data.results.map(program => ({isEditable: false, ...program})).filter(function (n) {
                            return n;
                        }).reverse()
                    })
                    .catch(function (error) {
                        if (error.response) {
                            console.log(error.response.status);
                        }
                    });
                    console.log(this.providers)
            },
        },
        created() {
            if (this.$store.getters.isLoggedIn) {
                this.getProviders();
            }
        },
        mounted() {
            this.getDomains();
            let currentUser = localStorage.getItem('currentUser')
            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket?.send(JSON.stringify(this.message))
        }
    }
</script>

<style lang="scss" scoped>
    .starter-content-table {
        width: 100%;
        overflow-x: auto;
    }

    .order-provider {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        border-radius: 5px;
        border: 1px solid #26303e;
    }
</style>


