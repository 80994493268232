<template>
    <div class="order-basket-wrapper">
        <template v-if="loading">
            <div id="preloader">
                <div id="loader_new"></div>
            </div>
        </template>
        <template v-else>
            <h6 class="basket-title">Order:</h6>
            <div class="order-list-wrapper">
                <template v-if="addedCustomer.length > 0">
                    <ul style="list-style: none; padding-bottom: 10px; margin-bottom: 0!important;">
                        <li
                                v-for="item in addedCustomer"
                                v-bind:key="item.id"
                                class="has-dropdown"
                                @click="toggle"
                                style="text-align: left; padding-left: 0px; position: relative; color: #000; background-color: #fff; font-size: 13px; margin: 5px 10px;"
                        >
                            {{ item.name }} - {{ item.domainsCounter }} links
                            <span
                                    @click="removeCustomer(item.id)"
                                    style="cursor: pointer; position: absolute; right: 5px; font-size: 12px; top: 5px;"
                            ><font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl"/></span
                            >
                            <ul style="list-style: none; " class="basket-domains-list">
                                <li v-for="domain in item.domains" v-bind:key="domain.id">
                                    <p style="font-size: 12px; margin: 0px 0px; padding: 0px 0 0 20px;">
                                        {{ domain.name }}
                                        <span
                                                @click="
                            removeCustomerDomain({
                              customerId: item.id,
                              domainId: domain.id,
                            })
                          "
                                                style="cursor: pointer; position: absolute; right: 5px; font-size: 10px;"
                                        ><font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl"/></span
                                        >
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <p>Basket is empty</p>
                </template>
            </div>
            <div class="order-settings-wrapper">
                <p style="margin-bottom: 0; font-size: 13px;">Select date:</p>
                <input style="text-align: left;     max-width: 120px;" type="date" v-model="startDate"/><br>
                <input style="text-align: left;     max-width: 120px;" type="date" v-model="finishDate"/><br>
                <label style="margin-right: 10px;" for="checkbox">Publish date override</label>
                <input
                        style="width: auto; height: 13px; opacity: 1;"
                        type="checkbox"
                        id="checkbox"
                        v-model="isCheckedDate"
                />
            </div>
        </template>
        <div class="order-button" style="display: flex; justify-content: space-between; margin-top: 15px;">
            <template v-if="false">
                <button class="main-button" disabled
                >Order all
                </button>
            </template>
            <template v-else>
                <button
                        @click="orderContentLive"
                        class="main-button main-button-order"
                >Order all
                </button>
            </template>
            <button @click="clearBasket" class="main-button">Clear Basket <span>🗑️</span></button>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import axios from "axios";
    import {mapState} from "vuex";
    import moment from "moment";
    import {eventBus} from "../main";
    export default {
        name: "OrderBasket",
        data() {
            return {
                startDate: moment(new Date()).format("YYYY-MM-DD"),
                finishDate: moment(new Date()).format("YYYY-MM-DD"),
                isCheckedDate: false,
                message: {message: {}, action: {}},
                loading: false,
            }
        },
        computed: {
            ...mapState({
                orderList: state => state.orderBasket.globalOrderList,
            }),
            addedCustomer() {
                const customersArray = Object.keys(this.orderList.customers);
                const userCart = [];
                customersArray.forEach((el) => {
                    const customerDomainsID = Object.keys(this.orderList.customers[el]);
                    const customerDomainsList = [];
                    customerDomainsID.forEach((domain) => {
                        customerDomainsList.push({
                            name: this.orderList.customers[el][domain].domainName,
                            id: domain,
                        });
                    });
                    userCart.push({
                        name: this.orderList.customers[el][customerDomainsID[0]].name,
                        id: el,
                        domains: customerDomainsList,
                        domainsCounter: _.size(customerDomainsList),
                        orderNum:
                        this.orderList.customers[el][customerDomainsID[0]].orderNumber,
                    });
                });
                return userCart.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1));
            },
        },

        methods: {
            toggle: function (event) {
                event.target.classList.toggle("is-open");
            },
            removeCustomer(id) {
                this.$delete(this.orderList.customers, id);
                this.postActualBasket(this.orderList);
            },
            removeCustomerDomain({customerId, domainId}) {
                this.$delete(this.orderList.customers[customerId], domainId);
                this.postActualBasket(this.orderList);
            },
            async clearBasket() {
                const customersArrayBasket = Object.keys(this.orderList.customers);
                customersArrayBasket.forEach((customer) => {
                    this.$delete(this.orderList.customers, customer);
                });
                this.orderList.month_of_order = ''
                this.orderList.year_of_order = ''
                this.orderList.language = ''

                this.postActualBasket(this.orderList);
                localStorage.setItem("currentLanguage", '')
            },
            getCurrentMonth() {
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ];
                let d = new Date();
                let n = d.getMonth();
                let currentDateOfMonth = d.getDate();
                if (currentDateOfMonth >= 10) {
                    this.$store.commit('updateGlobalCurrentMonth', months[n + 1])
                } else {
                    this.$store.commit('updateGlobalCurrentMonth', months[n])

                }
            },
            //Function when you clicked Ordel All button
            orderContentLive: function () {
                let currentUser = localStorage.getItem("currentUser");
                this.message.action[currentUser] = {
                    routeName: this.$route.name,
                    orderContent: currentUser + " " + "has created new order!",
                };
                window.socket.send(JSON.stringify(this.message));
                if (this.isCheckedDate) {
                    this.orderList.start_date = this.startDate;
                    this.orderList.finish_date = this.finishDate;
                }
                this.loading = true
                axios
                    .post("content-commercial-order/", {
                        data: this.orderList,
                    })
                    .then((response) => {
                        if (Object.keys(response.data.messages).length !== 0) {
                            let sites = Object.keys(response.data.messages);
                            this.$swal.fire({
                                icon: "error",
                                title: "This domains was already assigned by other user:",
                                // text: 'Next sites was added by other users:',
                                html: sites.join("<br>"),
                                showConfirmButton: true,
                            });
                        } else {
                            this.loading = false;
                            eventBus.$emit('gotOrderBasket', 1)
                            this.$swal.fire({
                                icon: "success",
                                title: "Done",
                                text: "Content ordered!",
                                timer: 2000,
                                showConfirmButton: false,
                            });
                        }
                    }).then(() => {
                    localStorage.setItem("currentLanguage", '')
                    this.orderList.customers = {}
                    this.orderList.language = ''
                    this.postActualBasket(this.orderList)
                    this.loading = false;
                })
                    .catch((error) => {
                        if (error.response) {
                            this.loading = false
                            this.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: error.response.status,
                                showConfirmButton: true,
                            });
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            this.loading = false
                            this.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: error.request,
                                showConfirmButton: true,
                            });
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                            this.loading = false
                            this.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: 'Error' + error.message,
                                showConfirmButton: true,
                            });
                        }
                    })
            },
            postActualBasket(list) {
                axios.post("get-cart", {
                    data: list,
                }).then(() => {
                })
            },
        },
        mounted() {
            axios.get("get-cart").then((response) => {
                this.$store.commit('updateGlobalOrderList', response.data.data)
            });
        }
    }

</script>

<style scoped lang="scss">
    .order-basket-wrapper {
        width: auto;
        padding: 10px 10px;
        max-height: 80vh;
        overflow: scroll;

    }

    .basket-title {
        text-align: left;
        border-bottom: 1px solid #ccc;
        text-transform: uppercase;
        font-size: 13px;
    }

    .order-list-wrapper {
        max-height: 500px;
        overflow: scroll;
    }

    .basket-domains-list {
        li:nth-child(even) {
            background: rgba(243, 241, 241, 0.4);
        }
    }

    .order-settings-wrapper {
        padding: 10px 0;
        border-top: 1px solid #ccc;

        p {
            font-size: 12px;
        }

        label {
            font-size: 14px;
        }

        input {
            font-size: 12px;
        }
    }

    .basket-clear-button {
        font-size: 12px;
    }

    .has-dropdown {
        cursor: pointer;
    }

    .has-dropdown:not(.is-open) ul {
        display: none;
    }
</style>