<template>
    <div>
        <!--<h1>{{this.$route.params.id}}</h1>-->
        <h2>Redirecting...</h2>
    </div>
</template>

<script>
    export default {
        name: "GoogleToken",
        methods: {
          loginGoogle() {
              // let email = this.email
              let accessTok = this.$route.params.id
              this.$store.dispatch('loginGoogle', accessTok )
                  .then(() => this.$router.push('/'))
                  .then(() => location.reload())
                  // .catch(err => console.log(err))
                  // .catch((err) => {
                  //     console.log(err)
                  // })
          }
        },
        created() {
            this.loginGoogle();
        }
    }
</script>

<style scoped>

</style>