import axios from "axios";

export default {
    actions: {
        fetchLanguages(ctx) {
            axios
                .get("language/list?page_size=9999")
                .then(response => {
                    // adding prop isEditable for each object
                    const languages = response.data.results.map(program => ({isEditable: false, ...program})).reverse()
                    ctx.commit('updateLanguages', languages)
                }).catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status);
                    }
                });

        },
    },
    mutations: {
        updateLanguages(state, languages) {
            state.languages = languages;
        },
        createLanguage(state, newLanguage) {
            state.languages.unshift(newLanguage);
        },
        removeLanguageOptions(state, currentEl) {
            state.languages.splice(currentEl, 1);
        }
    },
    state: {
        languages: []
    },
    getters: {
        getLanguages(state) {
            return state.languages;
        }
    }
}