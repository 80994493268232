<template>
    <div class="container-fluid body-padding-top">
        <div class="options-list main-wrapper">
            <div class="container background-table" style="padding-top: 10px;">
                <div class="row">
                    <div v-if="loading" class="loader">Loading...</div>
                </div>
                <div class="row">

                    <div class="col-sm-12 col-md-6">
                        <!--Language Table Component -->
                        <LangsTable/>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <!--Provider Table Component -->
                        <ProviderTable/>
                    </div>
                    <hr>
                    <div class="col-sm-12 col-md-6">
                        <!--Server Table Component -->
                        <ServerTable/>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <!--User Table Component -->
                        <UserTable/>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12">
                        <!--Options Table Component -->
                        <OptionsTable/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import axios from 'axios';
    // import {eventBus} from "../main";
    import LangsTable from "../components/options/LangsTable";
    import ProviderTable from "../components/options/ProviderTable";
    import OptionsTable from "../components/options/OptionsTable";
    import ServerTable from "../components/options/ServerTable";
    import UserTable from "../components/options/UserTable";


    export default {
        name: 'Options',

        components: {OptionsTable, ProviderTable, LangsTable, ServerTable, UserTable},
        data() {
            return {
                message: {'message': {}, 'action': {}},
                loading: false,
                // count: 0
            }
        },
        //Watcher for preloader counter
        // watch: {
        //     count: {
        //         handler(value) {
        //             let optionWrapper = document.querySelector('#options-form');
        //             if (value == 4) {
        //                 this.loading = false
        //                 optionWrapper.style.display = "block";
        //             }
        //         },
        //         immediate: true // This ensures the watcher is triggered upon creation
        //     }
        // },
        computed: {
        },
        methods: {

            setNewTheme(event) {
                event.preventDefault();
                this.$store.dispatch('setCurrentTheme', 'darkTheme')
            },
            setNewThemeLight(event) {
                event.preventDefault();
                this.$store.dispatch('setCurrentTheme', '')
            },
            setSize(size, event) {
                event.preventDefault();
                this.$store.dispatch('setCurrentSize', size)
            },
        },
        created() {
            //eventBus is getting values from options components
            // eventBus.$on('preloaderOptions', data => {
            //     this.count = this.count + data.countPreloader
            // })
        },
        mounted() {
            this.$store.dispatch('getServerList')
            let currentUser = localStorage.getItem('currentUser')
            this.message.action[currentUser] = {
                "routeName": this.$route.name
            }
            window.socket.send(JSON.stringify(this.message))
        }
    }

</script>

<style scoped lang="scss">
    //Style Section
    .style-settings {
        border: 2px solid #000;
    }

    .style-font-size {
        border: 1px solid #000;


        &__title {
            background-color: #26303E;
            color: #fff;
            padding: 10px 0;
        }

        form {
            padding: 0 10px 20px 10px;

            .block {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            input {
                width: 20px;
                margin-right: 10px;
            }

            /*label {*/
            /*    display: inline-block;*/
            /*    width: 140px;*/
            /*    text-align: right;*/
            /*}*/
        }
    }

    #options-form {
        display: none;
    }

    .option-list {
        width: 100%;
        overflow-x: auto;
    }

    .editable {
        border: 2px solid green;
    }

    .button-action {
        min-width: 3rem
    }

    .options-search-wrapper {
        margin-bottom: 20px;
    }

    .options-search-input {
        height: 26px;
        line-height: 26px;
    }

    .options-search-button {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding: 0 40px;
        background-color: orange;
        color: black;
        border: none;
        cursor: pointer;
        transition: all ease-in-out .2s;
    }

    .options-search-button:hover {
        color: white;
        background-color: orangered;
        transition: all ease-in-out .2s;
    }

    .options-table {
        width: 700px;
        max-width: 100%;
        margin: 0 auto;
    }

    .domain-add input {
        /*width: 100px;*/
    }

    .google-docs-urls {
        padding: 50px 0;
        margin-bottom: 50px;
        display: flex;
        justify-content: space-around;
        border: 1px solid #000;

        .domains-url {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 400px;
            width: 100%;

            label {
                margin-bottom: 10px;
                font-size: 24px;
            }

            input[type="text"] {
                margin-bottom: 10px;
            }

            button {
                /*text-transform: uppercase;*/
            }
        }

        .keywords-url {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 400px;
            width: 100%;

            label {
                margin-bottom: 10px;
                font-size: 24px;
            }

            input[type="text"] {
                margin-bottom: 10px;
            }

            button {
                /*text-transform: uppercase;*/
            }
        }
    }


</style>

