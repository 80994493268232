<template>
    <div>

        <div class="dialogue">
            <ul class="dialogue-list">
                <li class="dialogue-circle" v-for="(user, name) in userActions" v-bind:key="user.id">{{name.charAt(0).toUpperCase() +
                    (name.substr( name.indexOf('.') + 1).charAt(0).toUpperCase())}}
                    <template v-if="user.routeName.length > 0">
                        <div class="dialogue-info">Active page: {{ user.routeName }}</div>
                        <template v-if="user.orderContent">
                            <div class="order-content-created blink">{{user.orderContent}}</div>
                        </template>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../main'


    export default {

        name: "Dialogue",
        components: {},

        data() {
            return {
                userActions: []
            }
        },
        mounted() {
            eventBus.$on('userAction', payload => {
                this.userActions = payload
                // this.$forceUpdate();
            })
        },
        created() {
        },
        computed: {
        },
        methods: {
            setFromComputed() {
                this.connected = true;
                // this.fromComputed = this.currentRouteName;
                if(this.connected == true) {
                    this.sendMes(this.currentRouteName)
                }

            },
            connect() {
                this.chatSocket = new WebSocket(
                    'wss:' + 'offpage.pixel.se:8001/ws/' + localStorage['token']);
                this.chatSocket.onopen = () => {
                    this.status = "connected";
                    // this.dialogs.push({event: "Connected to", message: 'User Activity'})
                    this.chatSocket.onmessage = ({data}) => {
                        // this.dialogs.push(JSON.parse(data));
                        // this.username = data['user']
                        const userdata = JSON.parse(data)
                        // const userdata = JSON.parse(data.message)

                        if (userdata.message['action'] != null) {
                            this.newAction = userdata.message['action']
                            this.currentUser = userdata.message['user']
                        }
                        // this.currentPageActive = userdata['action'];
                        if (userdata['user'] != null && this.users.includes(userdata['user']) === false ){
                            this.users = userdata['user']


                            this.newUsers = JSON.parse("[" + this.users + "]")

                            this.users = this.newUsers['0']

                            // this.$nextTick(function () {
                            //     this.currentPageActive = this.currentRouteName;
                            // })

                        }
                        // if (userdata['user'] != null && userdata['online'] === 'offline' ){
                        //
                        //    this.users = this.users.filter(function(val){
                        //                 return val != userdata['user'];
                        //             });
                        // }

                    };
                };
                // this.chatSocket.addEventListener('message', function (event) {
                //     console.log('Message from server ', event.data);
                // });
            },

            disconnect() {
                this.chatSocket.close();
                this.status = "disconnected";
                // this.dialogs = [];
            },

            sendMes(mes) {
                this.chatSocket.send(JSON.stringify({
                    'message': {
                        'user': localStorage.getItem('currentUser'),
                        'action': mes
                    }, //'username': sessionStorage.getItem("username")
                }));
                // this.dialogs.push({event: "Sent message", message: this.form.textarea});
                // this.form.textarea = "";
            },
        }
    }
</script>


<style scoped lang="scss">
    .orders-cart {
        .preorder-cart {
            // max-width: 600px;
            width: 100%;
            margin: 0 auto;
        }
    }

    .currentContent {
        background-color: orange !important;
    }

    .order-list {
        width: 100%;
        overflow-x: auto;
        position: relative;
        padding-top: 30px;
    }

    .table {
        overflow-x: auto;
        overflow-y: auto;
        table-layout: auto;
        width: 100%;
    }

    .preorder-cart-remove__cta {
        color: orange !important;
    }

    .data-to-excel-cta {
        position: absolute;
        top: 0px;
        left: 0;
        /*z-index: 9999999;*/
    }

    .dialogue {
        padding: 10px;
        background-color: #fff;
    }

    .dialogue-list {
        margin-bottom: 0;
        display: flex;
    }

    .dialogue-circle {
        position: relative;
        /*display: inline-block;*/
        width: 30px;
        height: 30px;
        border-radius: 3px;
        /*border-radius: 50%;*/
        /*background: green;*/
        /*background: rgba(0, 0, 0, 0.18);*/
        background: #fff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
        /*color: #fff;*/
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        &:hover > .dialogue-info{
            display: inline-block;
        }
    }

    .dialogue-circle-moreinfo {
        /*padding: 7px 5px;*/
        display: inline-block;
        position: absolute;
        background-color: #fff;
        padding: 10px 5px;
        border-radius: 6px;
        color: #000;
        bottom: -70px;
        left: -100px;
        /*display: none;*/

    }
    .dialogue-info {
        /*max-width: 100px;*/
        width: 200px;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        top:30px;
        left:-45px;
        background: #FFFFFF;
        color: #000000;
        display: none;
    }
    .order-content-created {
        background-color: #fff;
        color: #000;
        border: 1px solid #C9C9C9;
        border-radius: 2px;
        position: absolute;
        bottom: -50px;
        z-index: 9999;
        font-weight: 400;
        padding: 5px;
        /* max-width: 200px; */
        width: 150px;
    }
    .blink {
        animation: blink-animation 1s steps(5, start) infinite;
        -webkit-animation: blink-animation 1s steps(5, start) infinite;
    }
    @keyframes blink-animation {
        to {
            visibility: hidden;
        }
    }
    @-webkit-keyframes blink-animation {
        to {
            visibility: hidden;
        }
    }
</style>

