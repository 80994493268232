<template>
    <div id="chart" class="chart__wrapper" style="padding: 10px 10px 10px; height: 318px;">
        <div class="orders-created-titles" style="display: flex; justify-content: center;">
            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000; text-align: center">
                Created orders: {{returnCurrentMonth()}}</h2>
<!--            <h2 style=" display: inline-block; font-style: normal;font-weight: 600;font-size: 12px;line-height: 12px;color: #000000;">-->
<!--                {{returnCurrentMonth()}}</h2>-->
        </div>
        <apexchart type="radialBar" height="224" :options="chartOptions" :series="series"></apexchart>
<!--        <h6 class="orders-created-labels">Total orders: {{totalOrders}}</h6>-->
        <h6 class="orders-created-labels green">Left to order: {{linkLeftToOrderTotal}}</h6>
        <h6 class="orders-created-labels red">To be ordered: {{linksPerMonthTotal}}</h6>
    </div>
</template>

<script>
    // import {D3PieChart} from 'vue-d3-charts';
    // import {VueApexCharts} from 'apexcharts';
    import axios from "axios";
    import {mapState} from "vuex";


    export default {
        name: "MonthDueLinks",
        components: {
            // apexchart: VueApexCharts,
        },
        data() {
            return {
                currentDate: new Date().toLocaleDateString(),
                // totalOrders: 0,
                // publishedOrders: 0,
                // unPublished: 0,
                series: [],
                chartOptions: {
                    chart: {
                        height: 200,
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 20,
                                size: '70%',
                                image: '../assets/smile.png',
                                imageWidth: 33,
                                imageHeight: 29,
                                imageClipped: false
                            },
                            dataLabels: {
                                name: {
                                    show: true,
                                    color: '#000'
                                },
                                value: {
                                    show: true,
                                    color: '#333',
                                    offsetY: 20,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    colors: ['#23CC90'],
                    // fill: {
                    //     type: 'image',
                    //     color: '#000',
                    //     // image: {
                    //     //     src: ['../src/assets/smile.png'],
                    //     // }
                    // },
                    stroke: {
                        lineCap: 'round'
                    },
                    labels: ['Done'],
                },
                linkLeftToOrderTotal: 0,
                linksPerMonthTotal: 0
            }
        },
        computed: {
            ...mapState({
                returnOrdersCreated: state => state.graphsData.ordersCreated,
                returnCustomers: state => state.orderCommercialContent.customers,
                // returnGraphDomainsData: state => state.graphsData.graphOnlineData,
                // returnRegionalOrders: state => state.graphsData.regionalOrders,
            })
        },
        watch: {
            returnGraphDomainsData: {
                handler(val) {
                    console.log(val)
                    this.returnOrdersCreated();
                },
                deep: true
            }
        },
        methods: {
            returnLinksLeftToOrderTotal() {
                let currentYear = new Date().getFullYear();
                let currentYearShort = this.returnCurrentMonth().slice(0, 3).toLowerCase();
                let linkLeftToOrderTotal = 0
                let linksPerMonthTotal = 0

                this.returnCustomers.forEach(item => {

                    if(item['counters'][currentYear][currentYearShort].links_left_to_order > 0) {
                        linkLeftToOrderTotal += item['counters'][currentYear][currentYearShort].links_left_to_order
                        // console.log(item['counters'][currentYear][currentYearShort].prev_month_live_links_number)
                        // console.log(item.customer_site)
                    }
                    linksPerMonthTotal += item['counters'][currentYear][currentYearShort].links_per_month
                })

                this.linkLeftToOrderTotal = linkLeftToOrderTotal
                this.linksPerMonthTotal = linksPerMonthTotal

                this.series = [];

                let totalPercent = this.linkLeftToOrderTotal * 100 / this.linksPerMonthTotal
                this.series.push(Math.round(100 - totalPercent))
            },
            returnCurrentMonth() {
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const d = new Date();

                return monthNames[d.getMonth()];
                // document.write("The current month is " + monthNames[d.getMonth()]);
            },
            // updateRegionalOrders() {
            //     let readyOrders = [];
            //     Object.entries(this.returnRegionalOrders).forEach(item => {
            //         readyOrders.push({name: item['0'], "Month left": item['1'].month_left})
            //     })
            //     console.log(readyOrders)
            //     this.chart_data = readyOrders.sort((a, b) => (a["Month left"] > b["Month left"]) ? 1 : -1);
            //     // console.log(Object.entries(this.returnRegionalOrders))
            //
            // },
            updateOrdersCreated() {
                axios
                .get('month-order-stats')
                .then(res => {
                    // console.log(res.data)
                    this.linkLeftToOrderTotal = res.data.left_to_order
                    this.linksPerMonthTotal = res.data.to_order

                    let totalPercent = this.linkLeftToOrderTotal * 100 / this.linksPerMonthTotal
                    this.series.push(Math.round(100 - totalPercent))
                })
                .catch(error => {
                    console.log(error)
                })

                // this.totalOrders = this.returnOrdersCreated.total
                // this.publishedOrders = this.returnOrdersCreated.total_published
                // this.unPublished = this.returnOrdersCreated.total_unpublished
            }
        },
        async mounted() {
            if(this.$store.state.monthDueLinks) {
                this.returnLinksLeftToOrderTotal();
            } else {
                await this.updateOrdersCreated();
            }

            // await this.returnLinksLeftToOrderTotal();


        }
    }
</script>


<style scoped lang="scss">

    .orders-created-labels {
        text-align: left;
        font-size: 14px;
    }

    .green {
        color: #23CC90;
    }

    .red {
        color: red;
    }

    .tick {
        transform: rotate(-45deg) !important;
    }

    .chart__wrapper {
        .chart__axis-x.chart__axis-x--barchart {
            .tick {
                transform: rotate(-45deg) !important;
            }
        }
    }

</style>