import { render, staticRenderFns } from "./CountryStats.vue?vue&type=template&id=800824d2&scoped=true&"
import script from "./CountryStats.vue?vue&type=script&lang=js&"
export * from "./CountryStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "800824d2",
  null
  
)

export default component.exports